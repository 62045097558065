var _country = [
    { "id": "Afghanistan", "text": "Afghanistan" },
    { "id": "Albania", "text": "Albania" },
    { "id": "Algeria", "text": "Algeria" },
    { "id": "Andorra", "text": "Andorra" },
    { "id": "Angola", "text": "Angola" },
    { "id": "AntiguaandBarbuda", "text": "AntiguaandBarbuda" },
    { "id": "Argentina", "text": "Argentina" },
    { "id": "Armenia", "text": "Armenia" },
    { "id": "Australia", "text": "Australia" },
    { "id": "Austria", "text": "Austria" },
    { "id": "Azerbaijan", "text": "Azerbaijan" },
    { "id": "Bahamas,The", "text": "Bahamas,The" },
    { "id": "Bahrain", "text": "Bahrain" },
    { "id": "Bangladesh", "text": "Bangladesh" },
    { "id": "Barbados", "text": "Barbados" },
    { "id": "Belarus", "text": "Belarus" },
    { "id": "Belgium", "text": "Belgium" },
    { "id": "Belize", "text": "Belize" },
    { "id": "Benin", "text": "Benin" },
    { "id": "Bhutan", "text": "Bhutan" },
    { "id": "Bolivia", "text": "Bolivia" },
    { "id": "BosniaandHerzegovina", "text": "BosniaandHerzegovina" },
    { "id": "Botswana", "text": "Botswana" },
    { "id": "Brazil", "text": "Brazil" },
    { "id": "Britain", "text": "Britain" },
    { "id": "Brunei", "text": "Brunei" },
    { "id": "Bulgaria", "text": "Bulgaria" },
    { "id": "BurkinaFaso", "text": "BurkinaFaso" },
    { "id": "Burundi", "text": "Burundi" },
    { "id": "Cambodia", "text": "Cambodia" },
    { "id": "Cameroon", "text": "Cameroon" },
    { "id": "Canada", "text": "Canada" },
    { "id": "CapeVerde", "text": "CapeVerde" },
    { "id": "CentralAfricanRepublic", "text": "CentralAfricanRepublic" },
    { "id": "Chad", "text": "Chad" },
    { "id": "Chile", "text": "Chile" },
    { "id": "China", "text": "China" },
    { "id": "Colombia", "text": "Colombia" },
    { "id": "Comoros", "text": "Comoros" },
    { "id": "Congo", "text": "Congo" },
    { "id": "CostaRica", "text": "CostaRica" },
    { "id": "CoteD'Ivoire", "text": "CoteD'Ivoire" },
    { "id": "Croatia", "text": "Croatia" },
    { "id": "Cuba", "text": "Cuba" },
    { "id": "Cyprus", "text": "Cyprus" },
    { "id": "CzechRepublic", "text": "CzechRepublic" },
    { "id": "Czechoslovakia", "text": "Czechoslovakia" },
    { "id": "Denmark", "text": "Denmark" },
    { "id": "Djibouti", "text": "Djibouti" },
    { "id": "Dominica", "text": "Dominica" },
    { "id": "DominicanRepublic", "text": "DominicanRepublic" },
    { "id": "Ecuador", "text": "Ecuador" },
    { "id": "Egypt", "text": "Egypt" },
    { "id": "ElSalvador", "text": "ElSalvador" },
    { "id": "England", "text": "England" },
    { "id": "EquatorialGuinea", "text": "EquatorialGuinea" },
    { "id": "Eritrea", "text": "Eritrea" },
    { "id": "Estonia", "text": "Estonia" },
    { "id": "Ethiopia", "text": "Ethiopia" },
    { "id": "Fiji", "text": "Fiji" },
    { "id": "Finland", "text": "Finland" },
    { "id": "France", "text": "France" },
    { "id": "Gabon", "text": "Gabon" },
    { "id": "Gambia, The", "text": "Gambia, The" },
    { "id": "Georgia", "text": "Georgia" },
    { "id": "Germany", "text": "Germany" },
    { "id": "Ghana", "text": "Ghana" },
    { "id": "GreatBritain", "text": "GreatBritain" },
    { "id": "Greece", "text": "Greece" },
    { "id": "Greenland", "text": "Greenland" },
    { "id": "Grenada", "text": "Grenada" },
    { "id": "Guatemala", "text": "Guatemala" },
    { "id": "Guinea", "text": "Guinea" },
    { "id": "Guinea - Bissau", "text": "Guinea - Bissau" },
    { "id": "Guyana", "text": "Guyana" },
    { "id": "Haiti", "text": "Haiti" },
    { "id": "Honduras", "text": "Honduras" },
    { "id": "Hungary", "text": "Hungary" },
    { "id": "Iceland", "text": "Iceland" },
    { "id": "India", "text": "India" },
    { "id": "Indonesia", "text": "Indonesia" },
    { "id": "Iran", "text": "Iran" },
    { "id": "Iraq", "text": "Iraq" },
    { "id": "Ireland", "text": "Ireland" },
    { "id": "Ireland, Northern", "text": "Ireland, Northern" },
    { "id": "Israel", "text": "Israel" },
    { "id": "Italy", "text": "Italy" },
    { "id": "Jamaica", "text": "Jamaica" },
    { "id": "Japan", "text": "Japan" },
    { "id": "Java", "text": "Java" },
    { "id": "Jordan", "text": "Jordan" },
    { "id": "Kazakhstan", "text": "Kazakhstan" },
    { "id": "Kenya", "text": "Kenya" },
    { "id": "Kiribati", "text": "Kiribati" },
    { "id": "Korea, North", "text": "Korea, North" },
    { "id": "Korea, South", "text": "Korea, South" },
    { "id": "Kuwait", "text": "Kuwait" },
    { "id": "Kyrgyzstan", "text": "Kyrgyzstan" },
    { "id": "Laos", "text": "Laos" },
    { "id": "Latvia", "text": "Latvia" },
    { "id": "Lebanon", "text": "Lebanon" },
    { "id": "Lesotho", "text": "Lesotho" },
    { "id": "Liberia", "text": "Liberia" },
    { "id": "Libya", "text": "Libya" },
    { "id": "Liechtenstein", "text": "Liechtenstein" },
    { "id": "Lithuania", "text": "Lithuania" },
    { "id": "Luxembourg", "text": "Luxembourg" },
    { "id": "Macedonia", "text": "Macedonia" },
    { "id": "Madagascar", "text": "Madagascar" },
    { "id": "Malawi", "text": "Malawi" },
    { "id": "Malaysia", "text": "Malaysia" },
    { "id": "Maldives", "text": "Maldives" },
    { "id": "Mali", "text": "Mali" },
    { "id": "Malta", "text": "Malta" },
    { "id": "MarshallIslands", "text": "MarshallIslands" },
    { "id": "Mauritania", "text": "Mauritania" },
    { "id": "Mauritius", "text": "Mauritius" },
    { "id": "Mexico", "text": "Mexico" },
    { "id": "Micronesia", "text": "Micronesia" },
    { "id": "Moldova", "text": "Moldova" },
    { "id": "Monaco", "text": "Monaco" },
    { "id": "Mongolia", "text": "Mongolia" },
    { "id": "Morocco", "text": "Morocco" },
    { "id": "Mozambique", "text": "Mozambique" },
    { "id": "Myanmar", "text": "Myanmar" },
    { "id": "Namibia", "text": "Namibia" },
    { "id": "Nauru", "text": "Nauru" },
    { "id": "Nepal", "text": "Nepal" },
    { "id": "Netherlands, The", "text": "Netherlands, The" },
    { "id": "NewZealand", "text": "NewZealand" },
    { "id": "Nicaragua", "text": "Nicaragua" },
    { "id": "Niger", "text": "Niger" },
    { "id": "Nigeria", "text": "Nigeria" },
    { "id": "NorthKorea", "text": "NorthKorea" },
    { "id": "Norway", "text": "Norway" },
    { "id": "NorthernIreland", "text": "NorthernIreland" },
    { "id": "Oman", "text": "Oman" },
    { "id": "Pakistan", "text": "Pakistan" },
    { "id": "Palau", "text": "Palau" },
    { "id": "Panama", "text": "Panama" },
    { "id": "PapuaNewGuinea", "text": "PapuaNewGuinea" },
    { "id": "Paraguay", "text": "Paraguay" },
    { "id": "Peru", "text": "Peru" },
    { "id": "Philippines, The", "text": "Philippines, The" },
    { "id": "Poland", "text": "Poland" },
    { "id": "Portugal", "text": "Portugal" },
    { "id": "Qatar", "text": "Qatar" },
    { "id": "Romania", "text": "Romania" },
    { "id": "Russia", "text": "Russia" },
    { "id": "Rwanda", "text": "Rwanda" },
    { "id": "St.KittsandNevis", "text": "St.KittsandNevis" },
    { "id": "St.Lucia", "text": "St.Lucia" },
    { "id": "St.VincentandtheGrenadines", "text": "St.VincentandtheGrenadines" },
    { "id": "Samoa", "text": "Samoa" },
    { "id": "SanMarino", "text": "SanMarino" },
    { "id": "SaoTomeandPrincipe", "text": "SaoTomeandPrincipe" },
    { "id": "SaudiArabia", "text": "SaudiArabia" },
    { "id": "Scotland", "text": "Scotland" },
    { "id": "Senegal", "text": "Senegal" },
    { "id": "Seychelles", "text": "Seychelles" },
    { "id": "SierraLeone", "text": "SierraLeone" },
    { "id": "Singapore", "text": "Singapore" },
    { "id": "Slovakia", "text": "Slovakia" },
    { "id": "Slovenia", "text": "Slovenia" },
    { "id": "SolomonIslands", "text": "SolomonIslands" },
    { "id": "Somalia", "text": "Somalia" },
    { "id": "SouthAfrica", "text": "SouthAfrica" },
    { "id": "SouthKorea", "text": "SouthKorea" },
    { "id": "Spain", "text": "Spain" },
    { "id": "SriLanka", "text": "SriLanka" },
    { "id": "Sudan", "text": "Sudan" },
    { "id": "Suriname", "text": "Suriname" },
    { "id": "Swaziland", "text": "Swaziland" },
    { "id": "Sweden", "text": "Sweden" },
    { "id": "Switzerland", "text": "Switzerland" },
    { "id": "Syria", "text": "Syria" },
    { "id": "Taiwan", "text": "Taiwan" },
    { "id": "Tajikistan", "text": "Tajikistan" },
    { "id": "Tanzania", "text": "Tanzania" },
    { "id": "Thailand", "text": "Thailand" },
    { "id": "Togo", "text": "Togo" },
    { "id": "Tonga", "text": "Tonga" },
    { "id": "TrinidadandTobago", "text": "TrinidadandTobago" },
    { "id": "Tunisia", "text": "Tunisia" },
    { "id": "Turkey", "text": "Turkey" },
    { "id": "Turkmenistan", "text": "Turkmenistan" },
    { "id": "Tuvalu", "text": "Tuvalu" },
    { "id": "Uganda", "text": "Uganda" },
    { "id": "Ukraine", "text": "Ukraine" },
    { "id": "UnitedArabEmirates", "text": "UnitedArabEmirates" },
    { "id": "UnitedKingdom", "text": "UnitedKingdom" },
    { "id": "UnitedStates", "text": "UnitedStates" },
    { "id": "Uruguay", "text": "Uruguay" },
    { "id": "Uzbekistan", "text": "Uzbekistan" },
    { "id": "Vanuatu", "text": "Vanuatu" },
    { "id": "VaticanCity", "text": "VaticanCity" },
    { "id": "Venezuela", "text": "Venezuela" },
    { "id": "Vietnam", "text": "Vietnam" },
    { "id": "Wales", "text": "Wales" },
    { "id": "Yemen", "text": "Yemen" },
    { "id": "Yugoslavia", "text": "Yugoslavia" },
    { "id": "Zambia", "text": "Zambia" },
    { "id": "Zimbabwe", "text": "Zimbabwe" }
];



//绑定国家
function sortCountry() {
    let tempsort = [];
    _country.forEach(function (item, index) {
        tempsort.push(item.text);
    });
    return tempsort;
}

export { sortCountry}
