<template>
  <div>
    <!-- <navlangbar /> -->
    <van-nav-bar :right-text="$t('app.language')" @click-right="show = true" />
    <section
      style="padding: 30px 30px; display: flex; flex-flow: column nowrap"
    >
      <div style="font-size: 20px; font-weight: bold; display: none">
        {{ $t("app.accountpwdlogin") }}
      </div>
      <div style="font-size: 20px; font-weight: bold; text-align: center">
        <img fit="contain" :style="{ 'text-align': 'center' }" :src="logo" />
      </div>
      <div>
        <van-cell-group inset>
          <van-field
            v-model="email"
            clearable
            :placeholder="$t('app.pleaseinputaccount')"
            style="        background-color: WhiteSmoke;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;"
            ref="email"
            @blur="blur"
          >
          </van-field>
          <van-field
            v-model="pwd"
            center
            clearable
            :placeholder="$t('app.pleaseinputpassword')"
            style="background-color: WhiteSmoke;border-bottom-left-radius:8px;border-bottom-right-radius:8px;"
            ref="pwd"
            type="password"
          >
          </van-field>
        </van-cell-group>
        
      </div>
      <div style="border-radius: 5px; overflow: hidden">
        <van-button
          color="#13b5b1"
          :type="type"
          block
          :disabled="loginDisabled"
          :loading-text="$t('loading')"
          @click="login"
          >{{ $t("app.login") }}</van-button
        >
      </div>
      <div>
        <router-link to="/RetrievePwd" style="color: #c8c9cc">{{
          $t("app.refundpassword")
        }}</router-link>
        <span style="float: right"
          ><router-link to="/Register" style="color: #c8c9cc">{{
            $t("app.registernow")
          }}</router-link></span
        >
      </div>
    </section>

    <van-popup v-model="show" position="right" :style="{ height: '100%' }">
      <div class="lang">
        <div @click="changelang('en')">
          <span
            :style="{ backgroundImage: 'url(' + gb + ')' }"
            class="i18n"
          ></span
          >English
        </div>
        <div @click="changelang('zh')">
          <span
            :style="{ backgroundImage: 'url(' + cn + ')' }"
            class="i18n"
          ></span
          >中文简体
        </div>
        <div @click="changelang('hk')">
          <span
            :style="{ backgroundImage: 'url(' + hk + ')' }"
            class="i18n"
          ></span
          >中文繁體
        </div>
        <div @click="changelang('jp')">
          <span
            :style="{ backgroundImage: 'url(' + jp + ')' }"
            class="i18n"
          ></span
          >日本語
        </div>
        <div @click="changelang('th')">
          <span
            :style="{ backgroundImage: 'url(' + th + ')' }"
            class="i18n"
          ></span
          >ภาษาไทย
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import gb from "@/assets/image/gb.svg";
import hk from "@/assets/image/hk.svg";
import th from "@/assets/image/th.svg";
import cn from "@/assets/image/cn.svg";
import jp from "@/assets/image/jp.svg";
import navlangbar from "@/components/NavlangBar";
import { isPhone } from "@/utils/commons";
import { loginByPwd } from "@/api";
import { vantLocales } from "@/lang";
    import { Notify, Dialog } from 'vant';
export default {
  name: "LoginCrm",
  components: { navlangbar },
  mounted() {
    //
    //if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
    //    window.location.href = "http://trader.mt4ea.io";
    //}
    //
    this.$store.commit("toggleTabbarShow", false);
    if (!this.email) {
      this.$refs.email.focus();
    } else {
      this.$refs.pwd.focus();
    }

    let _this = this;
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e;
      if (ev.keyCode === 13) {
        _this.login();
      }
    };
  },
  created() {
    //console.log(this.$route.meta.keepAlive);
    //console.log(this.tabbarShow);
  },
  watch: {
    email(newVal, oldVal) {
      if (this.email.length > 0) {
        this.loginDisabled = false;
        this.type = "info";
      } else {
        this.loginDisabled = "disabled";
        this.type = "info";
      }
    },
    pwd(newVal, oldVal) {
      if (this.pwd.length > 0) {
        this.loginDisabled = false;
        this.type = "info";
      } else {
        this.loginDisabled = "disabled";
        this.type = "default";
      }
    },
  },
  data() {
    return {
      type: "default",
      email: this.$store.state.email,
      pwd: "",
      
      loginDisabled: "disabled",
      isLoading: false,
      logo: require("../../assets/image/logo.png"),
      show: false,
      hk: hk,
      th: th,
      gb: gb,
      cn: cn,
      jp: jp,
    };
  },
  methods: {
    changelang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      vantLocales(lang);
      this.show = false;
    },
    blur() {
      if (this.email.length > 0) {
        this.$store.commit("updateEmail", this.email);
      }
    },
    login() {
      this.isLoading = true;
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      localStorage.removeItem("email");
      let params = {
        account: this.email,
        password: this.pwd,
      };
      loginByPwd(params).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.$store.commit("login", res.msg);
          if (res.msg.userinfo.active == 3) {
            this.$store.commit("toggleTabbarShow", true);
            this.$router.push("/home_account");
          } else {
            this.$store.commit("toggleTabbarShow", false);
            this.$router.push("/init");
          }
        } else {
            this.isLoading = false;
            Notify({ type: 'danger', message: this.$t(res.msg) });
          this.$refs.pwd.focus();
        }
      });
    },
  },
};
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.i18n {
  background-size: 40px;
  width: 48px;
  height: 46px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
.lang > div {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.other18n {
}
.van-nav-bar >>> .van-nav-bar__text {
  color: #13b5b1;
}
    .van-cell-group--inset{
        border-radius:0
    }
</style>
