var PhoneCodeLan_CN =
    [
        { key: "93", value: "阿富汗(+93)" },
        { key: "355", value: "阿尔巴尼亚(+355)" },
        { key: "213", value: "阿尔及尼亚(+213)" },
        { key: "376", value: "安道尔(+376)" },
        { key: "1264", value: "安圭拉岛(英)(+1264)" },
        { key: "244", value: "安哥拉(+244)" },
        { key: "1268", value: "安提瓜和巴布达(+1268)" },
        { key: "54", value: "阿根廷(+54)" },
        { key: "374", value: "亚美尼亚(+374)" },
        { key: "247", value: "阿森松(英)(+247)" },
        { key: "61", value: "澳大利亚(+61)" },
        { key: "43", value: "奥地利(+43)" },
        { key: "994", value: "阿塞拜疆(+994)" },
        { key: "1242", value: "巴哈马国(+1242)" },
        { key: "973", value: "巴林(+973)" },
        { key: "880", value: "孟加拉国(+880)" },
        { key: "1246", value: "巴巴多斯(+1246)" },
        { key: "375", value: "白俄罗斯(+375)" },
        { key: "32", value: "比利时(+32)" },
        { key: "501", value: "伯利兹(+501)" },
        { key: "229", value: "贝宁(+229)" },
        { key: "1441", value: "百慕大群岛(英)(+1441)" },
        { key: "591", value: "玻利维亚(+591)" },
        { key: "267", value: "博茨瓦纳(+267)" },
        { key: "55", value: "巴西(+55)" },
        { key: "673", value: "文莱(+673)" },
        { key: "359", value: "保加利亚(+359)" },
        { key: "226", value: "布基纳法索(+226)" },
        { key: "95", value: "缅甸(+95)" },
        { key: "257", value: "布隆迪(+257)" },
        { key: "237", value: "喀麦隆(+237)" },
        { key: "1", value: "美国/加拿大(+1)" },
        { key: "1345", value: "开曼群岛(英)(+1345)" },
        { key: "236", value: "中非(+236)" },
        { key: "235", value: "乍得(+235)" },
        { key: "56", value: "智利(+56)" },
        { key: "86", value: "中国(+86)" },
        { key: "57", value: "哥伦比亚(+57)" },
        { key: "242", value: "刚果(+242)" },
        { key: "682", value: "科克群岛(新)(+682)" },
        { key: "506", value: "哥斯达黎加(+506)" },
        { key: "53", value: "古巴(+53)" },
        { key: "357", value: "塞浦路斯(+357)" },
        { key: "420", value: "捷克共和国(+420)" },
        { key: "45", value: "丹麦(+45)" },
        { key: "253", value: "吉布提(+253)" },
        { key: "1890", value: "多米尼加共和国(+1890)" },
        { key: "593", value: "厄瓜多尔(+593)" },
        { key: "20", value: "埃及(+20)" },
        { key: "503", value: "萨尔瓦多(+503)" },
        { key: "372", value: "爱沙尼亚(+372)" },
        { key: "251", value: "埃塞俄比亚(+251)" },
        { key: "679", value: "斐济(+679)" },
        { key: "358", value: "芬兰(+358)" },
        { key: "33", value: "法国(+33)" },
        { key: "594", value: "法属圭亚那(+594)" },
        { key: "241", value: "加蓬(+241)" },
        { key: "220", value: "冈比亚(+220)" },
        { key: "995", value: "格鲁吉亚(+995)" },
        { key: "49", value: "德国(+49)" },
        { key: "233", value: "加纳(+233)" },
        { key: "350", value: "直布罗陀(英)(+350)" },
        { key: "30", value: "希腊(+30)" },
        { key: "1809", value: "格林纳达(+1809)" },
        { key: "1671", value: "关岛 (+1671)" },
        { key: "502", value: "危地马拉(+502)" },
        { key: "224", value: "几内亚 (+224)" },
        { key: "592", value: "圭亚那 (+592)" },
        { key: "509", value: "海地(+509)" },
        { key: "504", value: "洪都拉斯(+504)" },
        { key: "852", value: "香港(+852)" },
        { key: "36", value: "匈牙利(+36)" },
        { key: "354", value: "冰岛(+354)" },
        { key: "91", value: "印度(+91)" },
        { key: "62", value: "印度尼西亚(+62)" },
        { key: "98", value: "伊朗(+98)" },
        { key: "964", value: "伊拉克(+964)" },
        { key: "353", value: "爱尔兰(+353)" },
        { key: "972", value: "以色列(+972)" },
        { key: "39", value: "意大利(+39)" },
        { key: "225", value: "科特迪瓦(+225)" },
        { key: "1876", value: "牙买加(+1876)" },
        { key: "81", value: "日本(+81)" },
        { key: "962", value: "约旦(+962)" },
        { key: "855", value: "柬埔寨(+855)" },
        { key: "327", value: "哈萨克斯坦 (+327)" },
        { key: "254", value: "肯尼亚(+254)" },
        { key: "82", value: "韩国(+82)" },
        { key: "965", value: "科威特(+965)" },
        { key: "331", value: "吉尔吉斯斯坦(+331)" },
        { key: "856", value: "老挝(+856)" },
        { key: "371", value: "拉脱维亚(+371)" },
        { key: "266", value: "黎巴嫩(+266)" },
        { key: "231", value: "利比里亚(+231)" },
        { key: "218", value: "利比亚(+218)" },
        { key: "423", value: "列支敦士登(+423)" },
        { key: "370", value: "立陶宛(+370)" },
        { key: "352", value: "卢森堡(+352)" },
        { key: "853", value: "澳门(+853)" },
        { key: "261", value: "马达加斯加(+261)" },
        { key: "265", value: "马拉维(+265)" },
        { key: "60", value: "马来西亚(+60)" },
        { key: "960", value: "马尔代夫(+960)" },
        { key: "223", value: "马里(+223)" },
        { key: "356", value: "马耳他(+356)" },
        { key: "1670", value: "马里亚纳群岛(+1670)" },
        { key: "230", value: "毛里求斯(+230)" },
        { key: "52", value: "墨西哥(+52)" },
        { key: "373", value: "摩尔多瓦共和国(+373)" },
        { key: "377", value: "摩纳哥(+377)" },
        { key: "976", value: "蒙古(+976)" },
        { key: "1664", value: "蒙特塞拉特(+1664)" },
        { key: "212", value: "摩洛哥(+212)" },
        { key: "258", value: "莫桑比克(+258)" },
        { key: "264", value: "纳米尼亚(+264)" },
        { key: "674", value: "瑙鲁(+674)" },
        { key: "977", value: "尼泊尔(+977)" },
        { key: "599", value: "荷属安的列斯(+599)" },
        { key: "31", value: "荷兰(+31)" },
        { key: "64", value: "新西兰(+64)" },
        { key: "505", value: "尼加拉瓜(+505)" },
        { key: "227", value: "尼日尔(+227)" },
        { key: "234", value: "尼日利亚(+234)" },
        { key: "850", value: "朝鲜(+850)" },
        { key: "47", value: "挪威(+47)" },
        { key: "968", value: "阿曼(+968)" },
        { key: "92", value: "巴基斯坦(+92)" },
        { key: "507", value: "巴拿马(+507)" },
        { key: "675", value: "巴布亚新几内亚(+675)" },
        { key: "595", value: "巴拉圭(+595)" },
        { key: "51", value: "秘鲁(+51)" },
        { key: "63", value: "菲律宾(+63)" },
        { key: "48", value: "波兰(+48)" },
        { key: "689", value: "法属波利尼西亚(+689)" },
        { key: "351", value: "葡萄牙(+351)" },
        { key: "1787", value: "波多黎各(+1787)" },
        { key: "974", value: "卡塔尔(+974)" },
        { key: "262", value: "留尼汪(+262)" },
        { key: "40", value: "罗马尼亚(+40)" },
        { key: "7", value: "俄罗斯(+7)" },
        { key: "1758", value: "圣卢西亚(+1758)" },
        { key: "1784", value: "圣文森特(+1784)" },
        { key: "684", value: "东萨摩亚(+684)" },
        { key: "685", value: "西萨摩亚(+685)" },
        { key: "378", value: "圣马力诺 (+378)" },
        { key: "239", value: "圣多美和普林西比(+239)" },
        { key: "966", value: "沙特阿拉伯(+966)" },
        { key: "221", value: "塞内加尔(+221)" },
        { key: "248", value: "塞舌尔(+248)" },
        { key: "232", value: "塞拉利(+232)" },
        { key: "65", value: "新加坡(+65)" },
        { key: "421", value: "斯洛伐克(+421)" },
        { key: "386", value: "斯洛文尼亚(+386)" },
        { key: "677", value: "所罗门群岛(+677)" },
        { key: "252", value: "索马里(+252)" },
        { key: "27", value: "南非(+27)" },
        { key: "34", value: "西班牙(+34)" },
        { key: "94", value: "斯里兰卡(+94)" },
        { key: "1758", value: "圣卢西亚(+1758)" },
        { key: "1784", value: "圣文森特和格林纳丁斯(+1784)" },
        { key: "249", value: "苏丹(+249)" },
        { key: "597", value: "苏里南(+597)" },
        { key: "268", value: "斯威士兰(+268)" },
        { key: "46", value: "瑞典(+46)" },
        { key: "41", value: "瑞士(+41)" },
        { key: "963", value: "叙利亚(+963)" },
        { key: "886", value: "台湾(+886)" },
        { key: "992", value: "塔吉克斯坦(+992)" },
        { key: "255", value: "坦桑尼亚(+255)" },
        { key: "66", value: "泰国(+66)" },
        { key: "228", value: "多哥(+228)" },
        { key: "676", value: "汤加(+676)" },
        { key: "1868", value: "特立尼达和多巴哥(+1868)" },
        { key: "216", value: "突尼斯(+216)" },
        { key: "90", value: "土耳其 (+90)" },
        { key: "993", value: "土库曼斯坦(+993)" },
        { key: "256", value: "乌干达(+256)" },
        { key: "380", value: "乌克兰(+380)" },
        { key: "971", value: "阿拉伯联合酋长国(+971)" },
        { key: "44", value: "英国(+44)" },
        { key: "598", value: "乌拉圭(+598)" },
        { key: "233", value: "乌兹别克斯坦(+233)" },
        { key: "58", value: "委内瑞拉(+58)" },
        { key: "84", value: "越南(+84)" },
        { key: "967", value: "也门(+967)" },
        { key: "381", value: "南斯拉夫(+381)" },
        { key: "263", value: "津巴布韦(+263)" },
        { key: "243", value: "刚果(+243)" },
        { key: "260", value: "赞比亚(+260)" }
    ];

var PhoneCodeLan_EN = [
    { key: "93", value: "Afghanistan (+93)" },
    { key: "355", value: "Albania (+355)" },
    { key: "213", value: "Algeria (+213)" },
    { key: "376", value: "Andorra (+376)" },
    { key: "1264", value: "Anguilla (+1264)" },
    { key: "244", value: "Angola (+244)" },
    { key: "1268", value: "Antigua and Barbuda (+1268)" },
    { key: "54", value: "Argentina (+54)" },
    { key: "374", value: "Armenia (+374)" },
    { key: "247", value: "Ascension (+247)" },
    { key: "61", value: "Australia (+61)" },
    { key: "43", value: "Austria (+43)" },
    { key: "994", value: "Azerbaijan (+994)" },
    { key: "1242", value: "Bahamas (+1242)" },
    { key: "973", value: "Bahrain (+973)" },
    { key: "880", value: "Bangladesh (+880)" },
    { key: "1246", value: "Barbados (+1246)" },
    { key: "375", value: "Belarus (+375)" },
    { key: "32", value: "Belgium (+32)" },
    { key: "501", value: "Belize (+501)" },
    { key: "229", value: "Benin (+229)" },
    { key: "1441", value: "Bermuda Is. (+1441)" },
    { key: "591", value: "Bolivia (+591)" },
    { key: "267", value: "Botswana (+267)" },
    { key: "55", value: "Brazil (+55)" },
    { key: "673", value: "Brunei (+673)" },
    { key: "359", value: "Bulgaria (+359)" },
    { key: "226", value: "Burkina-faso (+226)" },
    { key: "95", value: "Burma (+95)" },
    { key: "257", value: "Burundi (+257)" },
    { key: "237", value: "Cameroon (+237)" },
    { key: "1", value: "America/Canada (+1)" },
    { key: "1345", value: "Cayman Is. (+1345)" },
    { key: "236", value: "Central African Republic (+236)" },
    { key: "235", value: "Chad (+235)" },
    { key: "56", value: "Chile (+56)" },
    { key: "86", value: "China (+86)" },
    { key: "57", value: "Colombia (+57)" },
    { key: "242", value: "Congo (+242)" },
    { key: "682", value: "Cook Is. (+682)" },
    { key: "506", value: "Costa Rica (+506)" },
    { key: "53", value: "Cuba (+53)" },
    { key: "357", value: "Cyprus (+357)" },
    { key: "420", value: "Czech Republic (+420)" },
    { key: "45", value: "Denmark (+45)" },
    { key: "253", value: "Djibouti (+253)" },
    { key: "1890", value: "Dominica Rep. (+1890)" },
    { key: "593", value: "Ecuador (+593)" },
    { key: "20", value: "Egypt (+20)" },
    { key: "503", value: "EI Salvador (+503)" },
    { key: "372", value: "Estonia (+372)" },
    { key: "251", value: "Ethiopia (+251)" },
    { key: "679", value: "Fiji (+679)" },
    { key: "358", value: "Finland (+358)" },
    { key: "33", value: "France (+33)" },
    { key: "594", value: "French Guiana (+594)" },
    { key: "241", value: "Gabon (+241)" },
    { key: "220", value: "Gambia (+220)" },
    { key: "995", value: "Georgia (+995)" },
    { key: "49", value: "Germany (+49)" },
    { key: "233", value: "Ghana (+233)" },
    { key: "350", value: "Gibraltar (+350)" },
    { key: "30", value: "Greece (+30)" },
    { key: "1809", value: "Grenada (+1809)" },
    { key: "1671", value: "Guam (+1671)" },
    { key: "502", value: "Guatemala (+502)" },
    { key: "224", value: "Guinea (+224)" },
    { key: "592", value: "Guyana (+592)" },
    { key: "509", value: "Haiti (+509)" },
    { key: "504", value: "Honduras (+504)" },
    { key: "852", value: "Hong Kong (+852)" },
    { key: "36", value: "Hungary (+36)" },
    { key: "354", value: "Iceland (+354)" },
    { key: "91", value: "India (+91)" },
    { key: "62", value: "Indonesia (+62)" },
    { key: "98", value: "Iran (+98)" },
    { key: "964", value: "Iraq (+964)" },
    { key: "353", value: "Ireland (+353)" },
    { key: "972", value: "Israel (+972)" },
    { key: "39", value: "Italy (+39)" },
    { key: "225", value: "Ivory Coast (+225)" },
    { key: "1876", value: "Jamaica (+1876)" },
    { key: "81", value: "Japan (+81)" },
    { key: "962", value: "Jordan (+962)" },
    { key: "855", value: "Kampuchea (Cambodia) (+855)" },
    { key: "327", value: "Kazakstan (+327)" },
    { key: "254", value: "Kenya (+254)" },
    { key: "82", value: "Korea (+82)" },
    { key: "965", value: "Kuwait (+965)" },
    { key: "331", value: "Kyrgyzstan (+331)" },
    { key: "856", value: "Laos (+856)" },
    { key: "371", value: "Latvia (+371)" },
    { key: "266", value: "Lesotho (+266)" },
    { key: "231", value: "Liberia (+231)" },
    { key: "218", value: "Libya (+218)" },
    { key: "423", value: "Liechtenstein (+423)" },
    { key: "370", value: "Lithuania (+370)" },
    { key: "352", value: "Luxembourg (+352)" },
    { key: "853", value: "Macao (+853)" },
    { key: "261", value: "Madagascar (+261)" },
    { key: "265", value: "Malawi (+265)" },
    { key: "60", value: "Malaysia (+60)" },
    { key: "960", value: "Maldives (+960)" },
    { key: "223", value: "Mali (+223)" },
    { key: "356", value: "Malta (+356)" },
    { key: "1670", value: "Mariana Is. (+1670)" },
    { key: "230", value: "Mauritius (+230)" },
    { key: "52", value: "Mexico (+52)" },
    { key: "373", value: "Republic of Moldova (+373)" },
    { key: "377", value: "Monaco (+377)" },
    { key: "976", value: "Mongolia (+976)" },
    { key: "1664", value: "Montserrat Is (+1664)" },
    { key: "212", value: "Morocco (+212)" },
    { key: "258", value: "Mozambique (+258)" },
    { key: "264", value: "Namibia (+264)" },
    { key: "674", value: "Nauru (+674)" },
    { key: "977", value: "Nepal (+977)" },
    { key: "599", value: "Netheriands Antilles (+599)" },
    { key: "31", value: "Netherlands (+31)" },
    { key: "64", value: "New Zealand (+64)" },
    { key: "505", value: "Nicaragua (+505)" },
    { key: "227", value: "Niger (+227)" },
    { key: "234", value: "Nigeria (+234)" },
    { key: "850", value: "North Korea (+850)" },
    { key: "47", value: "Norway (+47)" },
    { key: "968", value: "Oman (+968)" },
    { key: "92", value: "Pakistan (+92)" },
    { key: "507", value: "Panama (+507)" },
    { key: "675", value: "Papua New Cuinea (+675)" },
    { key: "595", value: "Paraguay (+595)" },
    { key: "51", value: "Peru (+51)" },
    { key: "63", value: "Philippines (+63)" },
    { key: "48", value: "Poland (+48)" },
    { key: "689", value: "French Polynesia (+689)" },
    { key: "351", value: "Portugal (+351)" },
    { key: "1787", value: "Puerto Rico (+1787)" },
    { key: "974", value: "Qatar (+974)" },
    { key: "262", value: "Reunion (+262)" },
    { key: "40", value: "Romania (+40)" },
    { key: "7", value: "Russia (+7)" },
    { key: "1758", value: "Saint Lueia (+1758)" },
    { key: "1784", value: "Saint Vincent (+1784)" },
    { key: "684", value: "Samoa Eastern (+684)" },
    { key: "685", value: "Samoa Western (+685)" },
    { key: "378", value: "San Marino (+378)" },
    { key: "239", value: "Sao Tome and Principe (+239)" },
    { key: "966", value: "Saudi Arabia (+966)" },
    { key: "221", value: "Senegal (+221)" },
    { key: "248", value: "Seychelles (+248)" },
    { key: "232", value: "Sierra Leone (+232)" },
    { key: "65", value: "Singapore (+65)" },
    { key: "421", value: "Slovakia (+421)" },
    { key: "386", value: "Slovenia (+386)" },
    { key: "677", value: "Solomon Is (+677)" },
    { key: "252", value: "Somali (+252)" },
    { key: "27", value: "South Africa (+27)" },
    { key: "34", value: "Spain (+34)" },
    { key: "94", value: "Sri Lanka (+94)" },
    { key: "1758", value: "St.Lucia (+1758)" },
    { key: "1784", value: "St.Vincent (+1784)" },
    { key: "249", value: "Sudan (+249)" },
    { key: "597", value: "Suriname (+597)" },
    { key: "268", value: "Swaziland (+268)" },
    { key: "46", value: "Sweden (+46)" },
    { key: "41", value: "Switzerland (+41)" },
    { key: "963", value: "Syria (+963)" },
    { key: "886", value: "Taiwan (+886)" },
    { key: "992", value: "Tajikstan (+992)" },
    { key: "255", value: "Tanzania (+255)" },
    { key: "66", value: "Thailand (+66)" },
    { key: "228", value: "Togo (+228)" },
    { key: "676", value: "Tonga (+676)" },
    { key: "1868", value: "Trinidad and Tobago (+1868)" },
    { key: "216", value: "Tunisia (+216)" },
    { key: "90", value: "Turkey (+90)" },
    { key: "993", value: "Turkmenistan (+993)" },
    { key: "256", value: "Uganda (+256)" },
    { key: "380", value: "Ukraine (+380)" },
    { key: "971", value: "United Arab Emirates (+971)" },
    { key: "44", value: "United Kingdom (+44)" },
    { key: "598", value: "Uruguay (+598)" },
    { key: "233", value: "Uzbekistan (+233)" },
    { key: "58", value: "Venezuela (+58)" },
    { key: "84", value: "Vietnam (+84)" },
    { key: "967", value: "Yemen (+967)" },
    { key: "381", value: "Yugoslavia (+381)" },
    { key: "263", value: "Zimbabwe (+263)" },
    { key: "243", value: "Zaire (+243)" },
    { key: "260", value: "Zambia (+260)" }
];

function sortByKey() {
    let newsort = PhoneCodeLan_EN.sort(function (a, b) {
        var x = parseInt(a["key"]); var y = parseInt(b["key"]);
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    let tempsort = [];
    newsort.forEach(function (item, index) {
        tempsort.push(item.key);
    });
    return tempsort;
}

export { sortByKey }