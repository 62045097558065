<template>
  <div>
    <navlangbar />
    <section
      style="padding: 30px 30px; display: flex; flex-flow: column nowrap"
    >
      <div style="font-size: 20px; font-weight: bold">
        {{ $t("resetCrmPwd") }}
      </div>
      <div>
        {{ $t("pleaseConfirmNewPwdBefore") }}&nbsp;{{
          this.model.email
        }}&nbsp;{{ $t("pleaseConfirmNewPwdAfter") }}
      </div>
      <div>
        <van-cell-group>
          <van-field
            v-model="model.pwd"
            center
            clearable
            :placeholder="$t('app.pleaseinputpassword')"
            ref="pwd"
            style="background-color: WhiteSmoke"
            type="password"
          >
          </van-field>
          <van-field
            v-model="model.repwd"
            center
            clearable
            :placeholder="$t('app.pleaseinputrepassword')"
            style="background-color: WhiteSmoke"
            ref="repwd"
            type="password"
          >
          </van-field>
        </van-cell-group>
      </div>
      <div>
        <van-button
          color="#13b5b1"
          block
          :disabled="loginDisabled"
          @click="submit"
          >{{ $t("ok") }}</van-button
        >
      </div>
    </section>
    <Vcode
      :show="isShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import navlangbar from "@/components/NavlangBar";
import { setPassword } from "@/api";
    import { Toast, Notify, Dialog} from "vant";

export default {
  name: "SetNewPwd",
  components: { navlangbar, Toast, Vcode },
  data() {
    return {
      type: "default",
      loginDisabled: true,
      isShow: false,
      model: {
        email: "",
        repwd: "",
        pwd: "",
        ticket: "",
      },
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", false);
    this.readdata();
  },
  watch: {
    "model.pwd"(newVal, oldVal) {
      if (newVal.length >= 8 && newVal.length <= 18) {
        this.loginDisabled = false;
        this.type = "info";
      } else {
          Notify({ type: 'danger', message: this.$t("ChangePwd.pwdLengthError") });
        this.loginDisabled = "disabled";
        this.type = "default";
      }
    },
    "model.repwd"(newVal, oldVal) {
      if (
        newVal.length >= 8 &&
        newVal.length <= 18 &&
        newVal == this.model.pwd
      ) {
        this.loginDisabled = false;
        this.type = "info";
      } else {
        this.loginDisabled = "disabled";
        this.type = "default";
          Notify({ type: 'danger', message: this.$t("ChangePwd.pwdLengthError") });
      }
    },
  },
  methods: {
    readdata() {
      var email = this.$route.query.email;
      var ticket = this.$route.query.ticket;
      if (
        ticket == undefined ||
        email == undefined ||
        email.trim().length <= 0 ||
        ticket.trim().length <= 0
      ) {
          Dialog.alert({
              message: this.$t("dataerror"),
          }).then(() => {
              this.$router.push("/");
          });
        
      }
      this.model.email = email;
      this.model.ticket = ticket;
    },
    submit() {
      this.isShow = true;
    },
    success(msg) {
      this.isShow = false;
      let params = {
        email: this.model.email,
        ticket: this.model.ticket,
        pwd: this.model.pwd,
        repwd: this.model.repwd,
      };
      setPassword(params).then((res) => {
        if (res.code !== 200) {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        } else {
            Notify({ type: 'success', message: this.$t("success") });
          this.$router.push("/");
        }
      });
    },
    close() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.van-button {
    border-radius: 8px;
}
</style>
