<template>
  <div ref="my_mdj_input" class="hezi"></div>
</template>
<script>
/**
 * @description 使用原生dom input type=“file” 来调用相机或者选择图片 解决使用uni-app中api部分手机 ,
 * @作者：manfulmr
 * @property {function} isWeixin,isMQQbrowser,isAndroid 用于获取手机浏览器的内核
 * @return files文件信息，可在父组件中chooseImage绑定此方法在具体操作
 */
import { isWeixin, isMQQbrowser, isAndroid } from "@/common/isphone";
import http from "@/utils/http";
import { initDoc, getinitInfo } from "@/api";
export default {
  name: "CreateChooseImg",
  methods: {
    // 动态创建 input 上传img元素 主要为了兼容
    /**  
			 * uni.chooseImg在调用相册和拍照在不同的手机中显示不同 无法调用相机 等问题
			 * 在各个机型都可以点击 file 调用相册 和 摄像头拍照 
				1. 在老版本的安卓中，必须加上capture，否则只能调用相册 
				2. 在IOS中 加了capture，就只能调用摄像头不能调用相册
			 */
    createUploadImgEle() {
      //   console.log("动态创建 input 上传img元素");
      let input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.multiple = "multiple";
      input.className = "input-img_elm";
      input.style.position = "absolute";
      input.style.left = "0";
      input.style.top = "0";
      input.style.width = "100%";
      input.style.height = "100%";
      input.style.overflow = "hidden";
      input.style.opacity = 0;
      input.onchange = (event) => {
        let params = new FormData();
        // console.log("动态添加的input 选取照片", event.target.files);
        let file = event.target.files;
        if (!file.length) return;
        file = Array.prototype.slice.call(file);
        params.file = file[0];
        http.upload(http.imageurl, params).then((res) => {
          if (res.IsSuccess) {
            this.$toast.success(this.$t("success"));
            this.$emit("filePath", res.FilePath);
            this.updatedoc(this.$attrs.name, res.FilePath);
          } else {
            this.$toast.fail(res.Message);
          }
        });
        //此方法也可以放到 store中
        // this.$emit("chooseImageUseH5", files);
      };
      // console.log("使用了什么内核：", window.navigator.userAgent);
      try {
        if (isAndroid() && isWeixin() && !isMQQbrowser()) {
          // console.log("强制添加摄像头调用功能");
          input.capture = "camera";
        }
      } catch (e) {
        console.log("创建原生input元素报错 ：", e);
      }
      this.$refs.my_mdj_input.appendChild(input);
    },
    async updatedoc(type, url) {
      const params = {
        type: type,
        url: url,
      };
      let res = await initDoc(params);
      res.code == 200
        ? this.$toast.success(this.$t("success"))
        : this.$toast.fail(res.msg);
      this.$toast.clear();
    },
  },
  mounted() {
    this.createUploadImgEle();
  },
};
</script>
<style scoped>
</style>

