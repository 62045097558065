<template>
  <div class="underling" @scroll.passive="onScroll($event)">
    <van-nav-bar
      :title="$t('Menu.underling')"
      left-arrow
      @click-left="$router.go(-1)"
      :border="false"
      fixed
    />
    <van-cell
      :title="Search"
      :value="$t('app.switchAccount')"
      center
      is-link
      class="underling-first-cell"
      :border="false"
      to="/member/funds/Underling/accound"
    >
      <template #icon>
        <van-icon name="manager-o" color="#13b5b1" size="16" />
      </template>
    </van-cell>
    <div class="underling-content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('nomore')"
          @load="onLoad"
        >
          <van-cell
            v-for="(item, index) in records"
            :key="index"
            icon="friends-o"
            :title="item.Login"
            :label="formattype(item.UserLevel)"
            center
            class="underling-content-cell"
          >
            <template #default>
              <div class="underling-content-cell-value">
                <p class="underling-content-cell-value-time">
                  {{
                    item.RegDate.replace(/T/g, " ")
                      .replace(/\+/g, " ")
                      .replace(/\.[\d]{3}Z/, "")
                  }}
                </p>
                <p class="underling-content-cell-value-propery">
                  {{
                    formatrelation(
                      item.Login,
                      item.ParentLogin ? item.parentlogin : 0
                    )
                  }}
                </p>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
      <div class="van-safe-area-bottom"></div>
    </div>
    <div class="back-top" @click="backTop" v-show="backTopShow">
      <van-tag type="primary" size="large" color="rgba(21, 184, 181, .5)">
        <template #default>
          <van-icon name="arrow-up" />
        </template>
      </van-tag>
    </div>
    <!-- <NavBar :title="$t('Menu.underling')" value="切换账号"></NavBar> -->
    <!-- <div class="box">
      <van-dropdown-menu active-color="#25a4bb">
        <van-dropdown-item
          v-model="selectedvalue"
          :options="option"
          @change="fliter"
        />
      </van-dropdown-menu>

      <van-collapse v-model="activeNames">
        <van-collapse-item v-for="(item, index) in records" :key="index">
          <template #title>
            <div>
              <strong>{{ item.Login }}</strong>
            </div>
            <div>{{ formattype(item.UserLevel) }}</div>
          </template>
          <template #right-icon>
            <div>
              <div style="color: darkgray">
                {{
                  item.RegDate.replace(/T/g, " ")
                    .replace(/\+/g, " ")
                    .replace(/\.[\d]{3}Z/, "")
                }}
              </div>
              <div style="float: right">
                <span>{{
                  formatrelation(
                    item.Login,
                    item.ParentLogin ? item.parentlogin : 0
                  )
                }}</span>
              </div>
            </div>
          </template>
          <van-row>
            <van-col span="24"
              >{{ $t("parent") }}:
              {{ item.ParentLogin ? item.parentlogin : 0 }}</van-col
            >
          </van-row>
        </van-collapse-item>
      </van-collapse>
      <div style="margin-bottom: 180px; text-align: center; padding: 25px 0px">
        <span v-if="records.length < total" @click="nextpage()">{{
          $t("app.clickLoadMore")
        }}</span>
        <span v-else>{{ $t("app.noMoreData") }}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { getsubusersrecored, getUserLogins } from "@/api";
    import { Toast, Notify, Dialog } from "vant";
export default {
  name: "Underling",
  components: { NavBar },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      records: [],
      total: 0,
      selectedvalue: 0,
      color: "green",
      option: [],
      activeNames: [],
      OrderName: "OrderDate",
      OrderType: "desc",
      Search: "",
      Search2: "",
      loading: false,
      finished: false,
      refreshing: false,
      event: "",
      backTopShow: false,
    };
  },
  mounted() {},
  watch: {},
  created() {
    // console.log(this.$route.params.data);
    this.Search = localStorage.getItem('account_chose');
    this.loadPageData();
    this.getAccount();
  },
  methods: {
    onScroll(e) {
      const target = e.target;
      this.event = target;
      target.scrollTop > 200
        ? (this.backTopShow = true)
        : (this.backTopShow = false);
    },
    backTop() {
      const time = setInterval(() => {
        this.event.scrollTop -= 200;
        if (this.event.scrollTop <= 0) {
          clearInterval(time);
        }
      }, 10);
    },
    // loadData() {
    //   getUserLogins({}).then((res) => {
    //     if (res.code == 200) {
    //       res.msg.forEach((element) => {
    //         this.option.push({ text: element, value: element });
    //       });
    //       if (res.msg.length >= 1) {
    //         this.Search = res.msg[0];
    //         this.selectedvalue = res.msg[0];
    //       }
    //       this.loadPageData();
    //     }
    //   });
    // },
    loadPageData() {
      getsubusersrecored({
        offset: this.pageIndex,
        pageSize: 12,
        dateTime: "1970-01-01—9999-12-12",
        orderName: this.OrderName,
        order: this.OrderType,
        search: this.Search,
      }).then((res) => {
        if ((res.code = 200)) {
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
          this.finished = true;
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    // nextpage() {
    //   this.pageIndex++;
    //   this.loadPageData();
    // },
    Sort(value) {
      // debugger
      this.$refs.item.toggle();
      switch (value) {
        case "OrderDate":
          this.OrderName = "OrderDate";
          break;
        case "MoneyUSD":
          this.OrderName = "MoneyUSD";
          break;
      }
      if (this.OrderType == "desc") {
        this.OrderType = "asc";
      } else {
        this.OrderType = "desc";
      }
      this.pageIndex = 1;
      this.loadPageData();
    },
    fliter(value) {
      this.Search = value;
      this.pageIndex = 1;
      this.loadPageData();
    },
    formattype(value) {
      switch (value) {
        case 0:
          return this.$t("NewAccount.tradingAccount");
        default:
          return this.$t("NewAccount.agentAccount");
      }
    },
    formatrelation(login, parentlogin) {
      if (parentlogin == this.selectedvalue) {
        return this.$t("directly");
      } else {
        return this.$t("secondary");
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
        this.loading = false;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    async getAccount() {
      let res = await getUserLogins({});
      console.log(res);
      if (res.code == 200) {
        localStorage.setItem('account_chose',res.msg[0] )
        // this.Search = this.$route.params.data || `${res.msg[0]}`;
      }
    },
  },
};
</script>

<style scoped>
.underling {
  background-color: #f2f3f5;
  min-height: 100vh;
  overflow: auto;
}
.underling::-webkit-scrollbar {
  width: 5px;
  height: 20px;
  display: block;
  background-color: #fff;
}
.underling::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.underling-first-cell {
  position: fixed;
  top: 45px;
  left: 0;
  z-index: 9999;
}
.underling-first-cell >>> .van-cell__title {
  font-size: 16px;
  font-weight: 700;
  color: #13b5b1;
}
.underling-first-cell >>> .van-cell__value {
  font-size: 12px;
  color: #13b5b1;
}
.underling-first-cell >>> .van-cell__right-icon {
  color: #13b5b1;
}
.box {
  overflow-y: auto;
  margin: 0 10px;
}
.fa {
  color: #25a4bb;
}
.dropdown-menu-option-active-color {
  color: #25a4bb;
}
.van-icon-manager-o {
  margin-right: 12px;
}
.underling-content {
  padding-top: 1.9rem;
}
.underling-content-cell-value-time {
  color: darkgray;
}
.underling-content-cell {
  padding: 0 16px;
}
.underling-content-cell >>> .van-cell__label {
  margin-top: 0;
}
.back-top {
  position: fixed;
  bottom: 180px;
  right: 20px;
}
</style>
