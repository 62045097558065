<template>
<div>
     <NavBar :title="$t('termail')"></NavBar>
    <div class="container">
        <div class="box">
            <div class="customerspace"></div>
            <van-cell-group :title="$t('android')" class="cblock">
                <i class="fa fa-android " ></i>
                <div class="button"> 
                    <van-button round block type="info"  url="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=zh&referrer=ref_id%3d5047166642681696120%26utm_source%3dwww.metatrader4.com%26utm_campaign%3ddownload" >{{ $t("download")}}</van-button>
                </div>                
            </van-cell-group>
            <div class="customerspace"></div>
            <van-cell-group :title="$t('ios')" class="cblock">
                <i class="fa fa-apple"></i>
                <div class="button"> 
                    <van-button round block type="info" url="https://apps.apple.com/cn/app/metatrader-4/id496212596?utm_campaign=download&utm_source=www.metatrader4.com" >{{ $t("download")}}</van-button>
                </div>        
            </van-cell-group>
            <div class="customerspace"></div>
            <van-cell-group :title="$t('windows')" class="cblock">
                <i class="fa fa-windows"></i>
                <div class="button"> 
                    <van-button round block type="info" url="https://download.mql5.com/cdn/web/14441/mt4/tradelandholdingsltd4setup.exe" >{{ $t("download")}}</van-button>
                </div>        
            </van-cell-group>
            <div class="customerspace"></div>
            <div style="margin-bottom:150px;"></div>
        </div>
    </div>
</div>
</template>
<script>
    import NavBar from "@/components/NavBar";
    export default {
        name: "Terminal",
        components: { NavBar },
        data() {
            return {
                title:"交易终端"
            }
        },
        computed: {},
        created() {},
        mounted() {},
        beforeDestroy() {},
        methods: {},
    };
</script>

<style scoped>
    .box {
        overflow-y: auto;
        margin: 0 10px;
    }
    .button{
        margin: 15px;
        padding-bottom: 15px;;
    }
    .container {
        background-color: #f7f7f7;
    }

    .space {
        margin-top: 15px;
    }

    .van-cell-group__title {
        background-color: white;
    }

    .van-uploader {
        margin: 10px;
    }

    .cblock {
        text-align: center;
        font-size: 16px;
    }

    .imgPreview {
        width: 100%;
    }

    .customerspace {
        height: 10px;
    }

    .van-uploader__preview-image {
        height: unset;
        width: unset;
    }
    .fa{
        font-size: 130px;
        margin: 15px auto;
    }
</style>