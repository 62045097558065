<template>
  <div class="box">
    <van-nav-bar
      :title="$t('ChangePwd.changeCrmPwd')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <!-- <NavBar></NavBar> -->
    <section style="padding: 10px; display: flex; flex-flow: column nowrap">
      <!-- <div style="font-size: 20px; font-weight: bold; text-align: center">
        {{ $t("ChangePwd.changeCrmPwd") }}
      </div> -->
      <div>
        <van-form @submit="onSumbmit">
          <van-cell-group :border="false" class="crmpwd">
            <van-field
              readonly
              v-model="model.userName"
              clickable
              :label="$t('ChangePwd.userName') + '：'"
              :placeholder="$t('ChangePwd.userName')"
              :border="false"
            />

            <van-field
              v-model="model.password"
              clickable
              type="password"
              ref="password"
              :label="$t('ChangePwd.password') + '：'"
              :border="false"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^\S{8,}$/,
                  message: '*' + $t('ChangePwd.pleaseInputPassword'),
                },
              ]"
            />

            <van-field
              v-model="model.passwordAgain"
              clickable
              type="password"
              ref="passwordAgain"
              :label="$t('ChangePwd.passwordAgain') + '：'"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^\S{8,}$/,
                  message: '*' + $t('ChangePwd.pleaseInputPassword'),
                },
              ]"
              :border="false"
            />

            <van-field
              class="code"
              v-model="model.code"
              center
              clearable
              ref="code"
              :label="$t('verificationCode') + '：'"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message: '*' + $t('pleaseInputVerificationCode'),
                },
              ]"
            >
              <template #button>
                <!-- <van-button
                  size="small"
                  type="info"
                  color="#13b5b1"
                  :disabled="sendCodeDisabled"
                  @click="showVCode"
                  >{{ $t("sendVerificationCode") }}</van-button
                > -->
                <CountDown @click.native="showVCode"></CountDown>
              </template>
            </van-field>
          </van-cell-group>

          <div class="button">
            <van-button
              :type="type"
              native-type="submit"
              :loading="isLoading"
              :loading-text="$t('loading')"
              block
              color="#13b5b1"
              :disabled="changeCrmPwdDisabled"
              >{{ $t("Funds.submitApplication") }}</van-button
            >
          </div>
        </van-form>
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
    import { Toast, Dialog, Notify } from "vant";
import { changeCrmPwd, sendVerifyCode } from "@/api";
export default {
  name: "CrmPwd",
  components: { NavBar, Vcode,
    CountDown: () => import("@/components/CounDown.vue"),
  },
  data() {
    return {
      type: "default",
      changeCrmPwdDisabled: true,
      sendCodeDisabled: false,
      vcodeShow: false,
      userinfo: this.$store.state.userinfo,
      isLoading: false,
      model: {
        userName: "",
        code: "",
        password: "",
        passwordAgain: "",
      },
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
  },
  watch: {
    // 当对象中的属性发生增变化时触发
    model: {
      handler: function (val, oldVal) {
        if (
          val.password.length >= 8 &&
          val.passwordAgain.length >= 8 &&
          val.code.length == 8
        ) {
          this.changeCrmPwdDisabled = false;
        } else {
          this.changeCrmPwdDisabled = true;
        }
      },
      deep: true,
    },
  },
  // watch: {
  //   "model.code"(newVal) {
  //     if (newVal.length == 8) {
  //       this.changeCrmPwdDisabled = false;
  //       this.type = "info";
  //     } else {
  //       this.changeCrmPwdDisabled = true;
  //       this.type = "default";
  //     }
  //   },
  //   "model.passwordAgain"(newVal) {
  //     let pwd = this.model.password;
  //     if (newVal.length >= 6 && pwd.length >= 6 && newVal == pwd) {
  //       this.changeCrmPwdDisabled = false;
  //       this.type = "info";
  //     } else {
  //       this.changeCrmPwdDisabled = true;
  //       this.type = "default";
  //     }
  //   },
  //   "model.password"(newVal) {
  //     let pwd = this.model.passwordAgain;
  //     if (newVal.length >= 6 && pwd.length >= 6 && newVal == pwd) {
  //       this.changeCrmPwdDisabled = false;
  //       this.type = "info";
  //     } else {
  //       this.changeCrmPwdDisabled = true;
  //       this.type = "default";
  //     }
  //   },
  // },
  created() {
    this.model.userName = this.userinfo.email;
  },
  methods: {
    showVCode() {
      this.sendCodeDisabled = true;
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 9,
      };
      sendVerifyCode(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    onSumbmit() {
      this.isLoading = true;
      this.changeCrmPwdDisabled = true;
      let params = {
        userName: this.model.userName,
        password: this.model.password,
        passwordAgain: this.model.passwordAgain,
        code: this.model.code,
      };
      let { password, passwordAgain } = this.model;
      if (password === passwordAgain) {
        changeCrmPwd(params).then((res) => {
          this.isLoading = false;
          this.changeCrmPwdDisabled = false;
          if (res.code !== 200) {
              Dialog.alert({
                  message: this.$t(res.msg),
              }).then(() => {
                  // on close
              });
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("userinfo");
            localStorage.removeItem("email");
            const dialog = Dialog.alert({
              message: this.$t(res.msg),
             /* theme: "round-button",*/
            }).then(() => {
              // on close
            });
            // const toast = Toast.loading({
            //   duration: 0,
            //   forbidClick: true,
            //   message: this.$t(res.msg),
            // });
            let second = 4;
            const timer = setInterval(() => {
              second--;
              if (second) {
                dialog.message = this.$t(res.msg);
              } else {
                clearInterval(timer);
                Toast.clear();
                this.$router.push("/");
              }
            }, 1000);
          }
        });
      } else {
        Dialog.alert({
          message: "The two passwords you typed do not match.",
          /*theme: "round-button",*/
        }).then(() => {
          // on close
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.box {
  background-color: #f7f8fa;
}
.crmpwd {
  /* background-color: transparent; */
  border-radius: 8px;
  overflow: hidden;
}
.van-button {
  border-radius: 5px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crmpwd .van-field {
  display: flex;
  flex-direction: column;
}
.crmpwd .van-field:last-child {
  flex-direction: row;
}
.crmpwd .van-field:last-child >>> .van-cell__title {
  width: 4em;
}
.van-field >>> .van-field__label {
  width: 100%;
}
.van-field >>> .van-field__body {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
}
.code >>> .van-field__label {
  margin-right: 0;
}
.code >>> .van-field__body {
  border: none;
}
.code >>> .van-field__control {
  border-bottom: 1px solid #eee;
}
.button {
  margin-top: 20px;
}
</style>
