<template>
  <div>
    <van-nav-bar
      :title="$t('copyapply')"
      left-arrow
      @click-left="$router.back()"
      :placeholder="true"
      fixed
    />
    <div class="box">
      <div style="margin: 15px 0; display: none">
        <el-alert
          :title="$t('tips')"
          :description="$t('notrepeatandnotsignal')"
          type="warning"
          :closable="false"
          show-icon
        >
        </el-alert>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="signalvalue"
          :label="$t('signalname')"
          label-width="1.5rem"
          :placeholder="$t('pleaseselect')"
          @click="signal.show = true"
          readonly
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="loginvalue"
          :label="$t('copyaccount')"
          label-width="1.5rem"
          :placeholder="$t('pleaseselect')"
          :rules="[{ required: true }]"
          @click="login.show = true"
          readonly
        />
        <van-field
          v-model="typevalue"
          :label="$t('copytype')"
          label-width="1.5rem"
          :placeholder="$t('pleaseselect')"
          :rules="[{ required: true }]"
          @click="type.show = true"
          readonly
        />
        <van-field
          v-model="copyvalue"
          type="number"
          :label="$t('copyvalue')"
          label-width="1.5rem"
          :placeholder="placetext"
          :disabled="typeIdvalue == 3"
        />
        <van-field
          v-model="directionvalue"
          :label="$t('copydirection')"
          label-width="1.5rem"
          :placeholder="$t('pleaseselect')"
          :rules="[{ required: true }]"
          @click="direction.show = true"
          readonly
        />
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            color="#13b5b1"
            >{{ $t("ok") }}</van-button
          >
        </div>
      </van-form>
    </div>
    <van-action-sheet
      v-model="signal.show"
      :actions="signal.actions"
      @select="SignalOnSelect"
      :cancel-text="$t('cancel')"
      @cancel="onCancel"
    />
    <van-action-sheet
      v-model="login.show"
      :actions="login.actions"
      @select="loginOnSelect"
      :cancel-text="$t('cancel')"
      @cancel="onCancel"
    />
    <van-action-sheet
      v-model="type.show"
      :actions="type.actions"
      @select="typeOnSelect"
      :cancel-text="$t('cancel')"
      @cancel="onCancel"
    />
    <van-action-sheet
      v-model="direction.show"
      :actions="direction.actions"
      @select="directionOnSelect"
      :cancel-text="$t('cancel')"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
    import { GetCanCopyLogins, GetAllsignal, applycopy } from "@/api";
    import { Notify, Dialog } from 'vant';
export default {
  name: "applyfollow",
  components: { NavBar },
  data() {
    return {
      signalvalue: "",
      signalIdvalue: 0,
      signal: {
        show: false,
        actions: [],
      },
      loginvalue: "",
      login: {
        show: false,
        actions: [],
      },
      typevalue: "",
      type: {
        show: false,
        actions: [
          { id: 1, name: this.$t("percentage") },
          { id: 2, name: this.$t("fixedlot") },
          { id: 3, name: this.$t("equityratio") },
        ],
      },
      typeIdvalue: 0,
      copyvalue: "",
      directionvalue: "",
      directionidvalue: 0,
      direction: {
        show: false,
        actions: [
          { id: 0, name: this.$t("positive") },
          { id: 1, name: this.$t("reverse") },
        ],
      },
      placetext: "请输入",
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {},
  created() {},
  methods: {
    loadData() {
      this.placetext = this.$t("pleaseinput");
      GetCanCopyLogins({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            this.login.actions.push({ id: element, name: element });
          });
        }
      });
      GetAllsignal({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            if (this.$route.query.ID == element.ID) {
              this.signalvalue = element.Name;
              this.signalIdvalue = element.ID;
            }
            this.signal.actions.push({ id: element.ID, name: element.Name });
          });
        }
      });
    },
    onSubmit(value) {
      applycopy({
        Type: this.directionidvalue,
        FollowSignalID: this.signalIdvalue,
        Login: this.loginvalue,
        FollowType: this.typeIdvalue,
        FollowTypeValue: this.copyvalue,
      }).then((res) => {
        if (res.code == 200) {
            Notify({ type: 'success', message: this.$t(res.msg) });
          this.$router.push("/applyingcopy");
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    SignalOnSelect(value) {
      this.signal.show = false;
      this.signalvalue = value.name;
      this.signalIdvalue = value.id;
    },
    loginOnSelect(value) {
      this.login.show = false;
      this.loginvalue = value.name;
    },
    typeOnSelect(value) {
      this.type.show = false;
      this.typeIdvalue = value.id;
      this.copyvalue = "";
      this.typevalue = value.name;
      switch (value.id) {
        case 1:
          this.placetext = this.$t("percentageholder");
          break;
        case 2:
          this.placetext = this.$t("fixedlotholder");
          break;
        case 3:
          this.placetext = this.$t("equityratioholder");
          break;
      }
    },
    directionOnSelect(value) {
      this.direction.show = false;
      this.directionvalue = value.name;
      this.directionidvalue = value.id;
    },
    onCancel() {},
  },
};
</script>

<style scoped>
.box {
  background-color: #f7f8fa;
  min-height: calc(100vh - 50px);
}
.fa {
  color: #25a4bb;
}
.dropdown-menu-option-active-color {
  color: #25a4bb;
}
.van-form {
  padding-top: 10px;
}
</style>
