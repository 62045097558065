<template>
  <div class="container">
    <van-steps :active="active" class="box space">
      <van-step>{{ $t("completematerial") }}</van-step>
      <van-step>{{ $t("personalinformation") }}</van-step>
      <van-step>{{ $t("identityinformaiton") }}</van-step>
      <van-step>{{ $t("perfectsuccess") }}</van-step>
    </van-steps>
    <div class="space"></div>
    <div class="box">
      <div :style="{ display: active == 1 ? 'block' : 'none' }">
        <van-form @submit="onOneSubmit">
          <van-field
            v-model="model.username"
            :label="$t('username')"
            label-width="1.2rem"
            :placeholder="$t('username')"
            readonly
          />
          <van-row>
            <van-col span="10">
              <van-field
                readonly
                clickable
                name="picker"
                :value="model.phonevalue"
                :label="$t('phone')"
                label-width="1.2rem"
                :placeholder="$t('areacode')"
                @click="model.showPhonePicker = true"
                :rules="[{ required: true }]"
              />
            </van-col>
            <van-col span="14">
              <van-field
                v-model="model.phone"
                :name="$t('phone')"
                :placeholder="$t('pleaseinputphone')"
                :rules="[{ required: true }]"
              />
            </van-col>
          </van-row>
          <van-field
            readonly
            clickable
            name="picker"
            :value="model.countryvalue"
            :label="$t('country')"
            label-width="1.2rem"
            :placeholder="$t('pleaseselectcountry')"
            @click="model.showcountrypicker = true"
          />

          <van-field
            :style="{ display: ischina ? 'none' : 'inline-flex' }"
            v-model="model.province"
            :label="$t('province')"
            label-width="1.2rem"
            :placeholder="$t('pleaseselectprovince')"
          />
          <van-field
            :style="{ display: ischina ? 'none' : 'inline-flex' }"
            v-model="model.city"
            :label="$t('city')"
            label-width="1.2rem"
            :placeholder="$t('pleaseselectcity')"
          />

          <van-field
            :style="{ display: ischina ? 'inline-flex' : 'none' }"
            readonly
            clickable
            name="picker"
            :value="model.provincevalue"
            :label="$t('province')"
            label-width="1.2rem"
            :placeholder="$t('pleaseselectprovince')"
            @click="model.showprovincepicker = true"
          />
          <van-field
            :style="{ display: ischina ? 'inline-flex' : 'none' }"
            readonly
            clickable
            name="picker"
            :value="model.cityvalue"
            :label="$t('city')"
            label-width="1.2rem"
            :placeholder="$t('pleaseselectcity')"
            @click="model.showcitypicker = true"
          />
          <van-field
            v-model="model.address"
            :label="$t('address')"
            label-width="1.2rem"
            :placeholder="$t('pleaseinputaddress')"
            :rules="[{ required: true }]"
          />
          <div style="margin: 16px">
            <van-button round block type="primary" native-type="submit">{{
              $t("submit")
            }}</van-button>
          </div>
        </van-form>
        <van-popup v-model="model.showPhonePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="model.phonecolumns"
            :confirm-button-text="$t('ok')"
            :cancel-button-text="$t('cancel')"
            @confirm="onConfirm"
            @cancel="model.showPhonePicker = false"
          />
        </van-popup>
        <van-popup v-model="model.showcountrypicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="model.countries"
            :confirm-button-text="$t('ok')"
            :cancel-button-text="$t('cancel')"
            @confirm="onCountryConfirm"
            @cancel="model.showcountrypicker = false"
          />
        </van-popup>

        <van-popup v-model="model.showprovincepicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="model.provinces"
            :confirm-button-text="$t('ok')"
            :cancel-button-text="$t('cancel')"
            @confirm="onProvinceConfirm"
            @cancel="model.showprovincepicker = false"
          />
        </van-popup>
        <van-popup v-model="model.showcitypicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="model.cities"
            :confirm-button-text="$t('ok')"
            :cancel-button-text="$t('cancel')"
            @confirm="onCityConfirm"
            @cancel="model.showcitypicker = false"
          />
        </van-popup>
      </div>
      <div :style="{ display: active == 2 ? 'block' : 'none' }">
        <van-cell-group :title="$t('front')" class="cblock">
          <van-uploader
            class="front"
            name="1"
            :before-read="beforeRead"
            :after-read="afterReadFront"
            v-model="fileList.frontImg"
            multiple
            :max-count="1"
            :max-size="20 * 1024 * 1024"
          />
        </van-cell-group>
        <div class="customerspace"></div>
        <van-cell-group :title="$t('back')" class="cblock">
          <van-uploader
            class="back"
            name="2"
            :before-read="beforeRead"
            :after-read="afterReadBack"
            v-model="fileList.backImg"
            multiple
            :max-count="1"
            :max-size="20 * 1024 * 1024"
          />
        </van-cell-group>
        <div class="customerspace"></div>
        <van-cell-group :title="$t('proofofaddress')" class="cblock">
          <van-uploader
            class="proff"
            name="3"
            :before-read="beforeRead"
            :after-read="afterReadProff"
            v-model="fileList.ProffImg"
            multiple
            :max-count="1"
            :max-size="20 * 1024 * 1024"
          />
        </van-cell-group>
        <div class="customerspace"></div>
      </div>
      <div :style="{ display: active == 3 ? 'block' : 'none' }">
        <div
          style="margin: 16px"
          :style="{ display: islogin ? 'none' : 'block' }"
        >
          <van-button round block type="primary" to="/member/newaccount?id=0">{{
            $t("opentradeaccount")
          }}</van-button>
        </div>
        <div
          style="margin: 16px"
          :style="{ display: islogin ? 'none' : 'block' }"
        >
          <van-button round block type="primary" to="/member/newaccount?id=1">{{
            $t("openagentaccount")
          }}</van-button>
        </div>
        <div
          style="margin: 16px"
          :style="{ display: islogin ? 'block' : 'none' }"
        >
          <van-button round block type="primary" @click="tohome">{{
            $t("tohome")
          }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sortByKey } from "@/assets/js/phone.code.js";
import { sortCountry } from "@/assets/js/country.js";
import { getProvince, getCities } from "@/assets/js/mapchina.js";
    import { initPersioninfo, initDoc, getinitInfo } from "@/api";
    import { Notify, Dialog } from 'vant';
import http from "@/utils/http";
export default {
  data() {
    return {
      active: this.$store.state.userinfo.active,
      ischina: false,
      islogin: this.$store.state.userinfo.havelogin,
      model: {
        username: this.$store.state.userinfo.name,
        province: "",
        phone: "",
        city: "",
        address: "",
        phonevalue: "",
        phonecolumns: [],
        showPhonePicker: false,
        countries: [],
        countryvalue: "",
        showcountrypicker: false,
        cities: [],
        cityvalue: "",
        showcitypicker: false,
        provinces: [],
        provincevalue: "",
        showprovincepicker: false,
      },
      fileList: {
        frontImg: [],
        backImg: [],
        ProffImg: [],
        idone: require("@/assets/image/id001.png"),
        idtwo: require("@/assets/image/id002.png"),
        idthree: require("@/assets/image/id003.png"),
      },
    };
  },
  computed: {},
  mounted() {
    // this.$store.commit("toggleTabbarShow", false);
    this.loadData();
  },
  beforeDestroy() {},
  methods: {
    tohome() {
      this.$store.commit("toggleTabbarShow", true);
      this.$router.push("/home_account");
    },
    loadData() {
      this.model.phonecolumns = sortByKey();
      this.model.countries = sortCountry();
      this.model.provinces = getProvince();
      getinitInfo({}).then((res) => {
        if (res.code == 200) {
          this.model.username = res.msg.user.Name;
          this.model.address = res.msg.user.Address;
          this.model.phone = res.msg.user.Phone;
          this.model.phonevalue = res.msg.user.PhoneCode;
          this.model.countryvalue =
            res.msg.user.Country == null ? "" : res.msg.user.Country;
          if (
            res.msg.user.Country &&
            res.msg.user.Country.toLowerCase() == "china"
          ) {
            this.model.provincevalue = res.msg.user.Province;
            this.model.cityvalue = res.msg.user.City;
            this.ischina = true;
          } else {
            this.model.province = res.msg.user.Province;
            this.model.city = res.msg.user.City;
            this.ischina = false;
          }

          let cur = this;
          res.msg.docs.forEach(function (item, index) {
            let objCur = {
              url: item.ImgUrl,
            };
            switch (item.Type) {
              case 1:
                cur.fileList.frontImg.push(objCur);
                break;
              case 2:
                cur.fileList.backImg.push(objCur);
                break;
              case 3:
                cur.fileList.ProffImg.push(objCur);
                break;
            }
          });
        } else {
          this.$router.push("/");
        }
      });
    },
    onOneSubmit(values) {
      let p, c;
      if (this.ischina) {
        p = this.model.provincevalue;
        c = this.model.cityvalue;
      } else {
        p = this.model.province;
        c = this.model.city;
      }
        if (!(this.model.countryvalue && this.model.countryvalue.length)) {
            Dialog.alert({
                message: this.$t("pleaseselectcountry"),
            }).then(() => {
                // on close
            });
        return ;
      }
        if (p.length == 0 || c.length == 0) {
            Dialog.alert({
                message: this.$t("pleaseinputprovinceorcity"),
            }).then(() => {
                // on close
            });
        return ;
      }
      let params = {
        username: this.model.username,
        phone: this.model.phone,
        phonecode: this.model.phonevalue,
        country: this.model.countryvalue,
        city: c,
        province: p,
        address: this.model.address,
      };
      initPersioninfo(params).then((res) => {
        if (res.code == 200) {
            Notify({ type: 'success', message: this.$t("success") });
          if (res.msg == "skipnext") {
            let userinfo = this.$store.state.userinfo;
            userinfo.active = 3;
            this.$store.commit("updateActive", userinfo);
            this.active = 3;
          } else {
            this.active = 2;
          }
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        }
      });
    },
    sorry() {
      this.$toast("功能暂未上线~");
    },
    onConfirm(value) {
      this.model.phonevalue = value;
      this.model.showPhonePicker = false;
    },
    onCountryConfirm(value) {
      this.model.countryvalue = value;
      this.model.showcountrypicker = false;
      this.ischina = value.toLowerCase() == "china";
    },
    onProvinceConfirm(value) {
      this.model.provincevalue = value;
      this.model.cities = getCities(value);
      this.model.cityvalue = "";
      this.model.showprovincepicker = false;
    },
    onCityConfirm(value) {
      this.model.cityvalue = value;
      this.model.showcitypicker = false;
    },
    beforeRead(file) {
        if (!file.type.includes("image")) {
            Dialog.alert({
                message: this.$t("pleaseuploadpicture"),
            }).then(() => {
                // on close
            });
        
        return false;
      }
        if (file.size > 1024 * 1024 * 3) {
            Dialog.alert({
                message: this.$t("uploadFileRangeSize"),
            }).then(() => {
                // on close
            });
       
        return false;
      }
      return true;
    },
      afterReadFront(file, name) {
          let data = {
              base64Str: file.content
          }
      this.$toast.loading(this.$t("uploading"));
          http.upload(http.imageBase64Url, data).then((res) => {
        console.log(res);
        if (res.IsSuccess) {
            Notify({ type: 'success', message: this.$t("success") });
          this.updatedoc(name.name, res.FilePath);
        }
      });
    },
      afterReadBack(file, name) {
          let data = {
              base64Str: file.content
          }
          this.$toast.loading(this.$t("uploading"));
          http.upload(http.imageBase64Url, data).then((res) => {
        if (res.IsSuccess) {
            Notify({ type: 'success', message: this.$t("success") });
          this.updatedoc(name.name, res.FilePath);
        }
      });
    },
      afterReadProff(file, name) {
          let data = {
              base64Str: file.content
          }
          this.$toast.loading(this.$t("uploading"));
          http.upload(http.imageBase64Url, data).then((res) => {
        if (res.IsSuccess) {
            Notify({ type: 'success', message: this.$t("success") });
          this.updatedoc(name.name, res.FilePath);
        }
      });
    },
    updatedoc(type, url) {
      let params = {
        type: type,
        url: url,
      };
      initDoc(params).then((res) => {
          if (res.code == 200) {
              Notify({ type: 'success', message: this.$t("uploadsuccess") });
          if (res.msg == "nextstep") {
            let userinfo = this.$store.state.userinfo;
            userinfo.active = 3;
            this.$store.commit("updateActive", userinfo);
            this.active = 3;
          }
        } else {
              Notify({ type: 'danger', message: this.$t(res.msg) });
        }
        this.$toast.clear();
      });
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
}
.container {
  padding: 10px;
  background-color: #f7f8fa;
}
.van-cell-group__title {
  background-color: white;
}
.cblock {
  text-align: center;
}
.imgPreview {
  width: 100%;
}
.customerspace {
  height: 10px;
}
.van-uploader__preview-image {
  height: unset;
  width: unset;
}
/*
.box >>> div {
  width: 100%;
}
.clock >>> .van-uploader__upload {
  margin: 8px;
  height: 4.5rem;
} 

*/
.cblock >>> div {
  width: 100%;
}
.box >>> .van-uploader__upload {
  margin: 10px;
  height: 4.5rem;
}
.box >>> .van-uploader__preview {
  margin: 8px;
}
.front >>> .van-uploader__upload {
  background: url("../../assets/image/id001.png") no-repeat;
  background-size: 100%;
}
.back >>> .van-uploader__upload {
  background: url("../../assets/image/id002.png") no-repeat;
  background-size: 100%;
}
.proff >>> .van-uploader__upload {
  background: url("../../assets/image/id003.png") no-repeat;
  background-size: 100%;
}
.box >>> .van-uploader__preview-image {
  width: 100%;
  height: 3.5rem;
}
</style>