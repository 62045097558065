<template>
  <div class="new-account">
    <van-nav-bar
      :title="
        $route.query.id == 0
          ? $t('NewAccount.tradingAccount')
          : $t('NewAccount.agentAccount')
      "
      left-arrow
      @click-left="$router.go(-1)"
      :border="false"
    />
    <section style="padding: 10px; display: flex; flex-flow: column nowrap">
      <div
        style="
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          color: #13b5b1;
          padding: 30px 14px;
        "
      >
        {{ $t("NewAccount.accountApplication") }}
      </div>
      <van-cell-group :border="false">
        <van-field
          readonly
          clickable
          :value="AccountType.accountTypeText"
          :label="$t('NewAccount.accountType')"
          :placeholder="$t('NewAccount.chooseAccountType')"
          @click="showAccountTypePopup"
        />
        <van-popup
          v-model="AccountType.show"
          bind:close="onClose"
          position="bottom"
        >
          <van-picker
            :title="$t('NewAccount.accountType')"
            show-toolbar
            :columns="AccountType.columns"
            @confirm="onConfirmAccountType"
            @cancel="onCancel"
            @change="onChange"
            :default-index="AccountType.defaultIndex"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          :value="Leverage.leverageText"
          :label="$t('NewAccount.leverage')"
          :placeholder="$t('NewAccount.chooseLeverage')"
          @click="showLeveragePopup"
        />
        <van-popup
          v-model="Leverage.show"
          bind:close="onClose"
          position="bottom"
        >
          <van-picker
            :title="$t('NewAccount.leverage')"
            show-toolbar
            :columns="Leverage.columns"
            @confirm="onConfirmLeverage"
            @cancel="onCancel"
            @change="onChange"
            :default-index="Leverage.defaultIndex"
          />
        </van-popup>

        <van-field
          v-model="agent"
          clearable
          :label="$t('Register.userAgent')"
          :placeholder="$t('Register.userAgent')"
          type="number"
        >
        </van-field>

        <van-field
          v-model="code"
          center
          clearable
          ref="code"
          :label="$t('verificationCode')"
          :placeholder="$t('pleaseInputVerificationCode')"
        >
          <template #button>
            <CountDown @click.native="showVCode"></CountDown>
            <!-- <van-button
              size="small"
              color="#13b5b1"
              :disabled="sendCodeDisabled"
              @click="showVCode"
              >{{ $t("sendVerificationCode") }}</van-button
            > -->
          </template>
        </van-field>
      </van-cell-group>
      <div class="new-account-submit">
        <van-button
          :type="type"
          color="#13b5b1"
          block
          :disabled="newAccountDisabled"
          :loading="isLoading"
          :loading-text="$t('loading')"
          @click="toOpenAccount"
          >{{ $t("NewAccount.submitApplication") }}</van-button
        >
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
    import { Toast, Dialog, Notify } from "vant";
import { getLoginType, sendVerifyCode, newAccount } from "@/api";
export default {
  name: "NewAccount",
  components: { 
    NavBar,
    Vcode,
    CountDown: () => import("@/components/CounDown.vue"),
  },
  data() {
    return {
      type: "default",
      newAccountDisabled: true,
      sendCodeDisabled: false,
      agent: "",
      code: "",
      isAgent: 0,
      vcodeShow: false,
      userinfo: this.$store.state.userinfo,
      Leverage: {
        show: false,
        columns: [
          { id: 100, text: "1:100" },
          { id: 200, text: "1:200" },
          { id: 300, text: "1:300" },
          { id: 400, text: "1:400" },
          { id: 500, text: "1:500" },
        ],
        leverageText: "1:100",
        leverageValue: "100",
        defaultIndex: 0,
      },
      isLoading: false,
      AccountType: {
        show: false,
        columns: [
          //{ id: 100, text: "1:100" },
        ],
        accountTypeText: "",
        accountTypeValue: "",
        defaultIndex: 0,
      },
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
    var id = this.$route.query.id;
    this.isAgent = id;
  },
  watch: {
    code(newVal) {
      if (newVal.length == 8) {
        this.newAccountDisabled = false;
        this.type = "info";
      } else {
        this.newAccountDisabled = "disabled";
        this.type = "default";
      }
    },
  },
  created() {
    let params = { loginType: 2 };
    getLoginType(params).then((res) => {
      if (res.code !== 200) {
          Dialog.alert({
              message: this.$t(res.msg),
          }).then(() => {
              // on close
          });
      } else {
        var json = res.msg;
        for (var i = 0; i < json.length; i++) {
          this.AccountType.columns.push({
            id: json[i].Id,
            text: json[i].Name,
            leverage: json[i].Leverage,
          });
        }
        this.AccountType.accountTypeText = json[0].Name;
        this.AccountType.accountTypeValue = json[0].Id;
        this.initLeverage(json[0].Leverage);
      }
    });
  },
  methods: {
    onConfirmLeverage(value, index) {
      console.log(`当前值：${value.id}, 当前索引：${index}`);
      this.Leverage.show = false;
      this.Leverage.leverageText = value.text;
      this.Leverage.leverageValue = value.id;
      console.log(this.Leverage.leverageText);
    },
    onConfirmAccountType(value, index) {
      console.log(`当前值：${value.id}, 当前索引：${index}`);
      this.AccountType.show = false;
      this.AccountType.accountTypeText = value.text;
      this.AccountType.accountTypeValue = value.id;
      console.log(this.AccountType.accountTypeText);
      this.AccountType.columns.forEach((x) => {
        if (x.id == value.id) {
          this.initLeverage(x.leverage);
          //{ id: 100, text: "1:100" },
          //console.log("leverage:" + x.leverage);
        }
      });
      console.log();
    },
    initLeverage(leverage) {
      this.Leverage.columns = [];
      this.Leverage.columns.push({ id: leverage, text: "1:" + leverage });
      this.Leverage.leverageText = "1:" + leverage;
      this.Leverage.leverageValue = leverage;
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value.id}, 当前索引：${index}`);
    },
    onCancel() {
      this.Leverage.show = false;
      this.AccountType.show = false;
    },
    toRegister() {},
    showLeveragePopup() {
      this.Leverage.show = true;
    },
    showAccountTypePopup() {
      this.AccountType.show = true;
    },
    showVCode() {
      this.sendCodeDisabled = "disabled";
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 5,
      };
      sendVerifyCode(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    toOpenAccount() {
      this.isLoading = true;
      this.newAccountDisabled = true;
      let params = {
        leverage: this.Leverage.leverageValue,
        loginType: this.AccountType.accountTypeValue,
        code: this.code,
        agent: this.agent,
        type: this.isAgent,
      };
      if (params.agent.length == 0) {
        params.agent = 0;
      }
      newAccount(params).then((res) => {
        this.isLoading = false;
        this.newAccountDisabled = false;
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
          this.$router.push({
            path: "/home",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.new-account-submit {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

.new-account >>> .van-popup {
  margin-bottom: 0;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
