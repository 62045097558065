var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('van-nav-bar',{attrs:{"title":_vm.$t('ChangePwd.changeCrmPwd'),"left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('section',{staticStyle:{"padding":"10px","display":"flex","flex-flow":"column nowrap"}},[_c('div',[_c('van-form',{on:{"submit":_vm.onSumbmit}},[_c('van-cell-group',{staticClass:"crmpwd",attrs:{"border":false}},[_c('van-field',{attrs:{"readonly":"","clickable":"","label":_vm.$t('ChangePwd.userName') + '：',"placeholder":_vm.$t('ChangePwd.userName'),"border":false},model:{value:(_vm.model.userName),callback:function ($$v) {_vm.$set(_vm.model, "userName", $$v)},expression:"model.userName"}}),_c('van-field',{ref:"password",attrs:{"clickable":"","type":"password","label":_vm.$t('ChangePwd.password') + '：',"border":false,"name":"pattern","rules":[
              {
                required: true,
                pattern: /^\S{8,}$/,
                message: '*' + _vm.$t('ChangePwd.pleaseInputPassword'),
              } ]},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('van-field',{ref:"passwordAgain",attrs:{"clickable":"","type":"password","label":_vm.$t('ChangePwd.passwordAgain') + '：',"name":"pattern","rules":[
              {
                required: true,
                pattern: /^\S{8,}$/,
                message: '*' + _vm.$t('ChangePwd.pleaseInputPassword'),
              } ],"border":false},model:{value:(_vm.model.passwordAgain),callback:function ($$v) {_vm.$set(_vm.model, "passwordAgain", $$v)},expression:"model.passwordAgain"}}),_c('van-field',{ref:"code",staticClass:"code",attrs:{"center":"","clearable":"","label":_vm.$t('verificationCode') + '：',"name":"pattern","rules":[
              {
                required: true,
                pattern: /^[0-9]*$/,
                message: '*' + _vm.$t('pleaseInputVerificationCode'),
              } ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('CountDown',{nativeOn:{"click":function($event){return _vm.showVCode.apply(null, arguments)}}})]},proxy:true}]),model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}})],1),_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"type":_vm.type,"native-type":"submit","loading":_vm.isLoading,"loading-text":_vm.$t('loading'),"block":"","color":"#13b5b1","disabled":_vm.changeCrmPwdDisabled}},[_vm._v(_vm._s(_vm.$t("Funds.submitApplication")))])],1)],1)],1)]),_c('Vcode',{attrs:{"show":_vm.vcodeShow,"successText":_vm.$t('app.sildesuccesstext'),"failText":_vm.$t('app.sildefailtext'),"sliderText":_vm.$t('app.slidertext')},on:{"success":_vm.success,"close":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }