<template>
  <div class="bankinfo">
    <van-nav-bar
      :title="bankAddTitle"
      left-arrow
      @click-left="$router.go(-1)"
      :border="false"
      :placeholder="true"
      fixed
    />
    <van-cell-group :border="false" class="bankedit">
      <van-cell
        center
        :title="BankCards.BankName"
        :label="BankCards.BankAddress"
        class="bankedit-bank"
        :class="
          $route.query.type == 1
            ? 'china-bank'
            : $route.query.type == 2
            ? 'county-bank'
            : $route.query.type == 3
            ? 'another-bank'
            : 'county-bank'
        "
      >
        <template #icon>
          <van-image
            fit="cover"
            round
            width=".8rem"
            height=".8rem"
            :src="
              $route.query.type == 1
                ? require('@/assets/image/all-bank.png')
                : $route.query.type == 2
                ? require('@/assets/image/b-logo.png')
                : $route.query.type == 3
                ? require('@/assets/image/t-logo.png')
                : 'county-bank'
            "
          />
        </template>
        <template #default>
          <div class="bankedit-value">
            <span class="bankeddit-value-start">
              <i class="hide" v-for="star in 4" :key="star">*</i>
            </span>
            <span class="bankeddit-value-show">{{
              BankCards.CardID | getBankCode
            }}</span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <section
      style="padding: 30px 30px; display: flex; flex-flow: column nowrap"
    >
      <div>
        <van-form @submit="bankOnSubmit">
          <van-cell-group :border="false">
            <van-field
              v-model="BankCards.BankName"
              center
              clearable
              ref="BankName"
              :label="$t('Funds.bankName')"
              :placeholder="$t('Funds.pleaseInputBankName')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
              :rules="[{ required: BankCards.Type == 1 ? true : false }]"
            >
            </van-field>

            <van-field
              v-if="BankCards.Type == 1"
              v-model="BankCards.BranchName"
              center
              clearable
              ref="BranchName"
              :label="$t('Funds.branchName')"
              :placeholder="$t('Funds.pleaseInputBranchName')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
              :rules="[{ required: BankCards.Type == 1 ? true : false }]"
            >
            </van-field>

            <van-field
              v-if="BankCards.Type == 3"
              v-model="BankCards.BranchName"
              center
              clearable
              ref="BranchName"
              :label="$t('Funds.usdtTypeName')"
              :placeholder="$t('Funds.usdtTypeName')"
              @click="showUsdtTypePopup"
            />
            <van-popup
              v-model="UsdtType.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.usdtTypeName')"
                show-toolbar
                :columns="UsdtType.columns"
                @confirm="onConfirmUsdtType"
                @cancel="onCancel"
                @change="onChange"
                :default-index="UsdtType.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="BankCards.CardID"
              center
              clearable
              ref="CardID"
              type:number
              :label="cardLabel"
              :placeholder="cardPlaceholder"
              :rules="[{ required: true }]"
            >
            </van-field>

            <van-field
              v-model="BankCards.Swift"
              center
              clearable
              ref="Swift"
              :label="$t('Funds.swiftCode')"
              :placeholder="$t('Funds.pleaseInputSwiftCode')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
            >
            </van-field>

            <van-field
              v-model="BankCards.BankAddress"
              center
              clearable
              ref="BankAddress"
              :label="$t('Funds.bankAddress')"
              :placeholder="$t('Funds.pleaseInputBankAddress')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
              :rules="[{ required: BankCards.Type == 1 ? true : false }]"
            >
            </van-field>

            <van-field
              v-model="BankCards.code"
              center
              clearable
              ref="code"
              :label="$t('verificationCode')"
              :placeholder="$t('pleaseInputVerificationCode')"
              :rules="[{ required: true }]"
            >
              <template #button>
                <van-button
                  color="#13b5b1"
                  size="small"
                  type="info"
                  :disabled="sendCodeDisabled"
                  @click="showVCode"
                  >{{ $t("sendVerificationCode") }}</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
          <div class="onsubmit">
            <van-button
              color="#13b5b1"
              :type="type"
              native-type="submit"
              :loading="isLoading"
              :loading-text="$t('loading')"
              block
              :disabled="bankEditDisabled"
              >{{ $t("Funds.submitApplication") }}</van-button
            >
          </div>
        </van-form>
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
    import { Toast,Notify, Dialog } from "vant";
import { sendVerifyCode, editBank, getBankById } from "@/api";
export default {
  name: "BankEdit",
  components: { NavBar, Vcode },
  filters: {
    getBankCode(val) {
      if (!val) return "";
      val = val.toString().replaceAll(" ", "");
      return val.substring(val.length - 4);
    },
  },
  data() {
    return {
      sendCodeDisabled: true,
      vcodeShow: false,
      type: "default",
      bankEditDisabled: true,
      userinfo: this.$store.state.userinfo,
      isLoading: false,
      bankAddTitle: this.$t("Funds.bankCardEdit"),
      cardLabel: this.$t("Funds.bankCardId"),
      cardPlaceholder: this.$t("Funds.pleaseInputBankCardId"),
      BankCards: {
        BankId: 0,
        BankName: "",
        CardID: "",
        Swift: "",
        BranchName: "",
        BankAddress: "",
        Type: 0,
        code: "",
      },
      UsdtType: {
        columns: ["ERC20", "HECO", "TRC20", "OMNI"],
        show: false,
        defaultIndex: "ERC20",
      },
      bankLogo: require("@/assets/image/people-bank.svg"),
    };
  },
  mounted() {
    // this.$store.commit("toggleTabbarShow", false);
    if (this.BankCards.Type == 2) {
      //BTC
      this.bankAddTitle = this.$t("Funds.bankBtcEdit");
      this.cardLabel = this.$t("Funds.bankCardBtc");
      this.cardPlaceholder = this.$t("Funds.pleaseInputBankCardBtc");
    }
    if (this.BankCards.Type == 3) {
      //USDT
      this.bankAddTitle = this.$t("Funds.bankUsdtEdit");
      this.cardLabel = this.$t("Funds.bankCardUsdt");
      this.cardPlaceholder = this.$t("Funds.pleaseInputBankCardUsdt");
    }
  },
  watch: {
    "BankCards.CardID"(newVal) {
      if (newVal.length >= 8) {
        this.sendCodeDisabled = false;
      } else {
        this.sendCodeDisabled = true;
      }
    },
    "BankCards.code"(newVal) {
      if (newVal.length == 8 && this.BankCards.CardID.length >= 8) {
        this.bankEditDisabled = false;
        this.type = "info";
      } else {
        this.bankEditDisabled = true;
        this.type = "default";
      }
    },
  },
  created() {
    var bankId = this.$route.query.bid;
    this.BankCards.BankId = bankId;
    var type = this.$route.query.type;
    this.BankCards.Type = type;
    this.getBank();
  },
  methods: {
    showUsdtTypePopup() {
      this.UsdtType.show = true;
    },
    onConfirmUsdtType(value, index) {
      this.UsdtType.show = false;
      this.BankCards.BranchName = value;
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.UsdtType.show = false;
    },
    showVCode() {
      this.sendCodeDisabled = true;
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 4,
      };
      sendVerifyCode(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    bankOnSubmit() {
      this.isLoading = true;
      this.bankEditDisabled = true;
      let params = {
        bankName: this.BankCards.BankName,
        code: this.BankCards.code,
        cardId: this.BankCards.CardID,
        swiftCode: this.BankCards.Swift,
        type: this.BankCards.Type,
        branchName: this.BankCards.BranchName,
        bankAddress: this.BankCards.BankAddress,
        bankId: this.BankCards.BankId,
      };
      editBank(params).then((res) => {
        this.isLoading = false;
        this.bankEditDisabled = false;
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
          this.$router.push("/funds/bankinfo");
        }
      });
    },
    getBank() {
      let data = { bankId: this.BankCards.BankId };
      getBankById(data).then((res) => {
        if (res.code != 200) {
          this.$router.push("/funds/bankinfo");
        } else {
          var json = res.msg;
          this.BankCards.BankId = json.BankId;
          this.BankCards.BankName = json.BankName;
          this.BankCards.CardID = json.CardID;
          this.BankCards.Swift = json.SWIFT;
          this.BankCards.BranchName = json.BranchName;
          this.BankCards.BankAddress = json.BankAddress;
          if (this.BankCards.Type == 3 && json.BranchName.length == 0) {
            this.BankCards.BranchName = "ERC20";
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.bankinfo {
  background-color: #f7f8fa;
  min-height: 100vh;
}
section {
  background-color: #fff;
}
section div {
  margin-bottom: 16px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.van-loading span.van-loading__spinner {
  margin-top: 14px !important;
}
.onsubmit {
  border-radius: 8px;
  overflow: hidden;
}
.bankedit {
  padding: 26px 26px 12px;
}
.china-bank {
  background-image: url("~@/assets/image/visa-bank.png");
  background-position: -218px -57px;
}
.county-bank {
  /* background-image: linear-gradient(to right, #ffc300 10%, #c87d2f 50%); */
  background-image: url("~@/assets/image/b-back.png");
  background-position: -218px -57px;
}
.another-bank {
  /* background-image: linear-gradient(to right, skyblue 10%, #489dd4 50%); */
  background-image: url("~@/assets/image/tether-bank.png");
  background-position: -218px -57px;
}
.bankedit-bank {
  padding-bottom: 80px;
  border-radius: 8px;
}
.bankedit >>> .van-cell__label {
  color: #fff;
}
.bankedit >>> .van-cell__value {
  color: #fff;
}
.bankedit >>> .van-cell__title {
  color: #fff;
}
.bankeddit-value {
  position: relative;
}
.bankeddit-value-start {
  position: absolute;
  top: 3px;
  right: 34px;
}
.bankedit-regime {
  padding: 10px;
}
.bankedit-bank .van-image {
  margin-right: 0.2rem;
  padding: 8px;
  background-color: #fff;
}
</style>
