<template>
  <div class="sczj">
    <van-sticky>
      <NavBar :title="$t('identityinformaiton')"></NavBar>
    </van-sticky>
    <div class="sczj-container">
      <van-cell-group :title="$t('front')" class="front" :border="true">
        <van-image width="100%" height="100%" fit="cover" :src="fileList.idone">
          <CreateChooseImg v-on:filePath="frontFile" name="1"></CreateChooseImg>
        </van-image>
      </van-cell-group>
      <van-cell-group :title="$t('back')" class="back" :border="true">
        <van-image width="100%" height="100%" fit="cover" :src="fileList.idtwo">
          <CreateChooseImg v-on:filePath="backFile" name="2"></CreateChooseImg>
        </van-image>
      </van-cell-group>
      <van-cell-group
        :title="$t('proofofaddress')"
        class="proff"
        :border="true"
      >
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          :src="fileList.idthree"
        >
          <CreateChooseImg v-on:filePath="proffFile" name="3"></CreateChooseImg>
        </van-image>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import CreateChooseImg from "@/components/CreateChooseImg";
import NavBar from "@/components/NavBar";
    import { initDoc, getinitInfo } from "@/api";
    import { Notify, Dialog } from 'vant';
export default {
  components: { NavBar, CreateChooseImg },
  data() {
    return {
      fileList: {
        idone: require("@/assets/image/id001.png"),
        idtwo: require("@/assets/image/id002.png"),
        idthree: require("@/assets/image/id003.png"),
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    frontFile(data) {
      console.log(data);
      this.fileList.idone = data;
    },
    backFile(data) {
      this.fileList.idtwo = data;
    },
    proffFile(data) {
      this.fileList.idthree = data;
    },
    loadData() {
      getinitInfo({}).then((res) => {
        console.log(res);
        if (res.code == 200) {
          res.msg.docs.forEach((item) => {
            switch (item.Type) {
              case 1:
                this.fileList.idone = item.ImgUrl;
                break;
              case 2:
                this.fileList.idtwo = item.ImgUrl;
                break;
              case 3:
                this.fileList.idthree = item.ImgUrl;
                break;
            }
          });
        } else {
          this.$router.push("/");
        }
      });
    },
    async updatedoc(type, url) {
      const params = {
        type: type,
        url: url,
      };
      let res = await initDoc(params);
      res.code == 200
          ? Notify({ type: 'success', message: this.$t("success") })
          : Notify({ type: 'danger', message: this.$t(res.msg) });
      this.$toast.clear();
    },
  },
};
</script>

<style scoped>
.sczj {
  height: 100%;
  margin-bottom: 2rem;
}
.front,
.back,
.proff {
  height: 4.5rem;
}
.front,
.back,
.proff >>> .van-image {
  padding: .2rem;
}
</style>