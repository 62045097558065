<template>
  <div class="leverage">
    <!-- <NavBar></NavBar> -->
    <van-nav-bar
      :title="$t('Leverage.changeLeverage')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <section style="padding: 10px; display: flex; flex-flow: column nowrap">
      <!-- <div style="font-size: 20px; font-weight: bold; text-align: center">
        {{ $t("Leverage.changeLeverage") }}
      </div> -->
      <div>
        <van-form @submit="onSumbmit">
          <van-cell-group :border="false">
            <van-field
              readonly
              v-model="model.login"
              clickable
              :label="$t('Funds.login') + '：'"
              :placeholder="$t('Funds.chooseLogin')"
              @click="showUserLoginListPopup"
              :border="false"
            />
            <van-popup
              v-model="UserLoginList.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.login')"
                show-toolbar
                :columns="UserLoginList.columns"
                @confirm="onConfirmLogin"
                @cancel="onCancel"
                @change="onChange"
                :default-index="UserLoginList.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="model.legerageOld"
              center
              readonly
              clearable
              :label="$t('Leverage.leverageOld') + '：'"
              :placeholder="$t('Leverage.leverageOld')"
              :border="false"
            >
            </van-field>

            <van-field
              readonly
              v-model="model.leverage"
              clickable
              :label="$t('Leverage.leverageNew') + '：'"
              :placeholder="$t('Leverage.pleaseChooseLeverageNew')"
              @click="showLeveragePopup"
              :rules="[{ required: true }]"
              :border="false"
            />
            <van-popup
              v-model="Leverage.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Leverage.leverage')"
                show-toolbar
                :columns="Leverage.columns"
                @confirm="onConfirmLeverage"
                @cancel="onCancel"
                @change="onChange"
                :default-index="Leverage.defaultIndex"
              />
            </van-popup>

            <van-field
              class="code"
              v-model="model.code"
              center
              clearable
              ref="code"
              :label="$t('verificationCode')"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message: '*' + $t('pleaseInputVerificationCode'),
                },
              ]"
            >
              <template #button>
                <!-- <van-button
                  size="small"
                  type="info"
                  :disabled="sendCodeDisabled"
                  @click="showVCode"
                  color="#13b5b1"
                  >{{ $t("sendVerificationCode") }}</van-button
                > -->
                <CountDown @click.native="showVCode"></CountDown>
              </template>
            </van-field>
          </van-cell-group>

          <div class="button">
            <van-button
              :type="type"
              native-type="submit"
              :loading="isLoading"
              :loading-text="$t('loading')"
              block
              color="#13b5b1"
              :disabled="changeLeverageDisabled"
              >{{ $t("Funds.submitApplication") }}</van-button
            >
          </div>
        </van-form>
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
import { Toast } from "vant";
    import { Notify, Dialog } from "vant";
import {
  getUserLogins,
  getFreeBalanceByLogin,
  changeLeverage,
  sendVerifyCode,
} from "@/api";
export default {
  name: "Leverage",
  components: { NavBar, Vcode,
    CountDown: () => import("@/components/CounDown.vue"),
  },
  data() {
    return {
      type: "default",
      changeLeverageDisabled: true,
      sendCodeDisabled: false,

      vcodeShow: false,
      userinfo: this.$store.state.userinfo,
      UserLoginList: {
        show: false,
        columns: [],
        defaultIndex: 0,
      },
      Leverage: {
        show: false,
        columns: [
          { id: "1:100", text: "1:100" },
          { id: "1:200", text: "1:200" },
          { id: "1:300", text: "1:300" },
          { id: "1:400", text: "1:400" },
          { id: "1:500", text: "1:500" },
        ],
        defaultIndex: 0,
      },
      isLoading: false,
      model: {
        login: "",
        code: "",
        legerageOld: "",
        leverage: "",
      },
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
  },
  watch: {
    "model.code"(newVal) {
      if (newVal.length == 8) {
        this.changeLeverageDisabled = false;
        this.type = "info";
      } else {
        this.changeLeverageDisabled = true;
        this.type = "default";
      }
    },
  },
  created() {
    this.loadLogins();
  },
  methods: {
    onConfirmLogin(value, index) {
      //console.log(`当前值：${value.id}, 当前索引：${index}`);
      this.UserLoginList.show = false;
      this.model.login = value.id;
      this.getUserLeverage();
    },
    onConfirmLeverage(value, index) {
      this.Leverage.show = false;
      this.model.leverage = value.id;
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.UserLoginList.show = false;
      this.Leverage.show = false;
    },
    showUserLoginListPopup() {
      this.UserLoginList.show = true;
    },
    showLeveragePopup() {
      this.Leverage.show = true;
    },

    showVCode() {
      this.sendCodeDisabled = true;
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 8,
      };
      sendVerifyCode(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: "success", message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    onSumbmit() {
      this.isLoading = true;
      this.changeLeverageDisabled = true;
      let params = {
        login: this.model.login,
        leverageNew: this.model.leverage,
        leverageOld: this.model.legerageOld,
        code: this.model.code,
      };
      changeLeverage(params).then((res) => {
        this.isLoading = false;
        this.changeLeverageDisabled = false;
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    loadLogins() {
      let params = { loginType: 2 };
      getUserLogins(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
          var json = res.msg;
          if (json.length > 0) {
            for (var i = 0; i < json.length; i++) {
              this.UserLoginList.columns.push({ id: json[i], text: json[i] });
            }
            this.model.login = json[0];
            this.getUserLeverage();
          } else {
            this.$router.push("/member/newaccount?id=0");
          }
        }
      });
    },
    getUserLeverage() {
      let data = { login: this.model.login };
      getFreeBalanceByLogin(data).then((res) => {
        if (res.code != 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
          this.model.legerageOld = "1:" + res.msg.Leverage;
        }
      });
    },
  },
};
</script>

<style scoped>
.van-button {
  border-radius: 5px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.van-popup {
  margin-bottom: 0;
}
.van-notify {
  height: 60px;
}
.leverage {
  background-color: #f7f8fa;
}
.van-cell-group {
  border-radius: 8px;
  overflow: hidden;
}
.leverage .van-field {
  display: flex;
  flex-direction: column;
}
.leverage .van-field:last-child {
  flex-direction: row;
}
.van-field >>> .van-field__label {
  width: 100%;
}
.van-field >>> .van-field__value {
  width: 100%;
}
.van-field >>> .van-field__body {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
}
.code >>> .van-field__label {
  margin-right: 0;
}
.code >>> .van-field__body {
  border: none;
}
.code >>> .van-field__control {
  border-bottom: 1px solid #eee;
}
.leverage .van-field:last-child >>> .van-cell__title {
  width: 3.5em;
}
.button {
  margin-top: 20px;
}
</style>
