<template>
  <div class="mysignal">
    <NavBar :title="$t('mysignal')"></NavBar>
    <div class="box">
      <div class="box-content">
        <van-dropdown-menu active-color="#25a4bb">
          <van-dropdown-item
            v-model="value"
            :options="option"
            @change="fliter"
          />
          <van-dropdown-item :title="$t('sort')" ref="item">
            <van-cell
              center
              :title="$t('sortByTime')"
              @click="Sort('CreateTime')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  :style="{
                    display: OrderName == 'CreateTime' ? 'block' : 'none',
                  }"
                  aria-hidden="true"
                ></i>
              </template>
            </van-cell>
            <van-cell center :title="$t('signalname')" @click="Sort('Name')">
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  aria-hidden="true"
                  :style="{ display: OrderName == 'Name' ? 'block' : 'none' }"
                ></i>
              </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>

        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item, index) in records" :key="index">
            <template #title>
              <div>
                <strong>{{ item.Login }}</strong>
              </div>
              <div>{{ item.Name }}</div>
            </template>
            <template #right-icon>
              <div>
                <div style="color: darkgray">
                  {{
                    item.CreateTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
                  }}
                </div>
                <div style="float: right">
                  <van-tag :type="formatStatecolor(item.State)">
                    {{ formatStatus(item.State) }}
                  </van-tag>
                </div>
              </div>
            </template>
            <van-row>
              <van-col span="12">
                {{ $t("SignalCom")
                }}<span class="itemright">{{ item.SignalCom }}%</span>
              </van-col>
              <van-col span="12">
                {{ $t("ParentCom")
                }}<span class="itemright">{{ item.ParentCom }}%</span>
              </van-col>
              <van-col
                span="24"
                style="padding: 15px 0"
                :style="{ display: item.State == 1 ? 'block' : 'none' }"
              >
                <van-button
                  round
                  block
                  type="danger"
                  size="small"
                  @click="deleted(item.ID, 0, 'areyousuredisalbesiganl')"
                  >Disabled</van-button
                >
              </van-col>
              <van-col
                span="24"
                style="padding: 15px 0"
                :style="{ display: item.State == 1 ? 'block' : 'none' }"
              >
                <van-button
                  round
                  block
                  type="warning"
                  size="small"
                  @click="deleted(item.ID, 5, 'areyousuredeletethesignal')"
                  >Delete</van-button
                >
              </van-col>
              <van-col
                span="24"
                style="padding: 15px 0"
                :style="{ display: item.State == 0 ? 'block' : 'none' }"
              >
                <van-button
                  round
                  block
                  type="info"
                  size="small"
                  @click="deleted(item.ID, 2, 'areyousureactivethesiganl')"
                  >Activate</van-button
                >
              </van-col>
            </van-row>
          </van-collapse-item>
        </van-collapse>
        <div
          style="margin-bottom: 180px; text-align: center; padding: 25px 0px"
        >
          <span v-if="records.length < total" @click="nextpage()">
            {{ $t("app.clickLoadMore") }}
          </span>
          <span v-else>{{ $t("app.noMoreData") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
    import { getUserLogins, GetUserSignalList, DeleteSignal } from "@/api";
    import { Notify, Dialog } from 'vant';
export default {
  name: "mysignal",
  components: { NavBar },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      records: [],
      total: 0,
      value: 0,
      color: "green",
      option: [{ text: this.$t("alllogins"), value: 0 }],
      activeNames: [],
      OrderName: "CreateTime",
      OrderType: "desc",
      Search: "",
      tagtype: "success",
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {},
  created() {},
  methods: {
    loadData() {
      getUserLogins({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            this.option.push({ text: element, value: element });
          });
          this.loadPageData();
        }
      });
    },
    loadPageData() {
      GetUserSignalList({
        offset: this.pageIndex,
        pageSize: this.pageSize,
        dateTime: "1970-01-01—9999-12-12",
        orderName: this.OrderName,
        order: this.OrderType,
        search: this.Search,
      }).then((res) => {
        if ((res.code = 200)) {
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        }
      });
    },
    nextpage() {
      this.pageIndex++;
      this.loadPageData();
    },
    Sort(value) {
      // debugger
      this.$refs.item.toggle();
      this.OrderName = value;
      if (this.OrderType == "desc") {
        this.OrderType = "asc";
      } else {
        this.OrderType = "desc";
      }
      this.pageIndex = 1;
      this.loadPageData();
    },
    fliter(value) {
      this.Search = value;
      this.pageIndex = 1;
      this.loadPageData();
    },
    deleted(id, handle, msg) {
      this.$dialog
        .confirm({
          message: this.$t(msg),
          cancelButtonText: this.$t("cancel"),
          confirmButtonText: this.$t("ok"),
        })
        .then(() => {
          DeleteSignal({ ID: id, Handel: handle }).then((res) => {
            if (res.code == 200) {
                Notify({ type: 'success', message: this.$t(res.msg) });
              if (handle == 2) {
                this.$router.push("/applyingsignal");
              } else {
                this.pageIndex = 1;
                this.loadPageData();
              }
            } else {
                Dialog.alert({
                    message: this.$t(res.msg),
                }).then(() => {
                    // on close
                });
            }
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    formatStatus(value) {
      switch (value) {
        case 0:
          return this.$t("disabled");
        case 1:
          return this.$t("normal");
        default:
          return this.$t("applying");
      }
    },
    formatSignalcolor(isdel, state) {
      if (isdel == true || state === 0) {
        return "warning";
      } else if (state === 1) {
        return "success";
      } else {
        return "warning";
      }
    },
    formatStatecolor(value) {
      switch (value) {
        case 0:
          return "danger";
        case 1:
          return "primary";
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
          return "danger";
      }
    },
    formatSignalStatus(isdel, state) {
      this.tagtype = "warning";
      if (isdel == true || state === 0) {
        return this.$t("unavailable");
      } else if (state === 1) {
        this.tagtype = "success";
        return this.$t("normal");
      } else {
        return this.$t("unavailable");
      }
    },
    formatdirection(value) {
      switch (value) {
        case 0:
          return this.$t("positive");
          break;
        case 1:
          return this.$t("reverse");
          break;
      }
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  background-color: #f7f8fa;
  min-height: calc(100vh - 50px);
  padding: 10px;
  border-radius: 8px;
}

.box-content {
  border-radius: 8px;
  overflow: hidden;
}

.fa {
  color: #25a4bb;
}

.dropdown-menu-option-active-color {
  color: #25a4bb;
}

.itemright {
  float: right;
  padding-right: 10px;
}
</style>
