<template>
  <div>
    <van-nav-bar
      :title="$t('Menu.commissionTotal')"
      left-arrow
      @click-left="$router.go(-1)"
      :placeholder="true"
      fixed
    />
    <!-- <NavBar :title="$t('Report.commissionTotal')"></NavBar> -->
    <div class="box">
      <div class="box-content">
        <van-dropdown-menu active-color="#25a4bb">
          <van-dropdown-item
            v-model="value"
            :options="option"
            @change="fliter"
          />
          <van-dropdown-item :title="$t('sort')" ref="item">
            <!-- <van-cell center :title="$t('sortByTime')" @click="Sort('CLOSE_TIME')">
                        <template #right-icon>
                            <i :class="{fa:true, 'fa-long-arrow-down':(OrderType=='desc'),'fa-long-arrow-up':(OrderType=='asc')}" :style="{display:OrderName=='CLOSE_TIME'?'block':'none'}" aria-hidden="true"></i>
                        </template>
                    </van-cell> -->
            <van-cell
              center
              :title="$t('sortByAmount')"
              @click="Sort('COMVALUE1')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  aria-hidden="true"
                  :style="{ display: OrderName == 'COMVALUE1' ? 'block' : 'none' }"
                ></i>
              </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>

        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item, index) in records" :key="index">
            <template #title>
              <div>
                <strong>{{ item.LOGIN_COM }}</strong
                >,{{ item.NAME }}
              </div>
              <!--<div>{{ item.NAME}}</div>-->
            </template>
            <template #right-icon>
              <div>
                <!--<div style="color: darkgray;">{{ item.CLOSE_TIME.replace(/T/g,' ').replace(/\.[\d]{3}Z/,'')  }}</div>-->
                <div style="float: right">
                  <span :style="{ color: formatColor(item.COMVALUE4) }">{{
                    formatPrice(item.COMVALUE4, 2)
                  }}</span>
                </div>
              </div>
            </template>
            <van-row>
              <!--<van-col span="24">{{item.OPEN_TIME.replace(/T/g,' ').replace(/\.[\d]{3}Z/,'')}}----{{item.CLOSE_TIME.replace(/T/g,' ').replace(/\.[\d]{3}Z/,'')}}</van-col>-->
              <van-col span="12"
                >{{ $t("Report.volume") }}:<span class="itemright">{{
                  formatPrice(item.VOLUME, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.balance") }}:<span class="itemright">{{
                  formatPrice(item.BALANCE, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.commission") }}:<span class="itemright">{{
                  formatPrice(item.COMVALUE1, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.shareProfit") }}:<span class="itemright">{{
                  formatPrice(item.COMVALUE2, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.signalCommission") }}:<span class="itemright">{{
                  formatPrice(item.COMVALUE3, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.totalCommission") }}:<span class="itemright">{{
                  formatPrice(item.COMVALUE4, 2)
                }}</span></van-col
              >
            </van-row>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div style="margin-bottom: 0px; text-align: center; padding: 25px 0px">
        <span v-if="records.length < total" @click="nextpage()">{{
          $t("app.clickLoadMore")
        }}</span>
        <span v-else>{{ $t("app.noMoreData") }}</span>
      </div>
    </div>
    <div class="van-safe-area-bottom"></div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { getUserLogins, getTotalCommission } from "@/api";
    import { formatType, forPrice } from "@/utils/commons";
    import { Notify, Dialog } from 'vant';
export default {
  name: "Bonus",
  components: { NavBar },
  data() {
    return {
      pageIndex: 1,
      pageSize: 12,
      records: [],
      total: 0,
      value: 0,
      color: "green",
      option: [
        //{ text: '请选择', value: 0 },
      ],
      activeNames: [],
      OrderName: "TICKET",
      OrderType: "desc",
      Search: "",
      HistoryType: 3, //出入金
    };
  },
  mounted() {
    this.loadLogins();
  },
  watch: {},
  created() {},
  methods: {
    loadLogins() {
      getUserLogins({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            this.option.push({ text: element, value: element });
          });
          this.Search = res.msg[0];
          this.value = res.msg[0];
          this.loadPageData();
        }
      });
    },
    loadPageData() {
      let param = {
        offset: this.pageIndex,
        pageSize: this.pageSize,
        dateTime: "1970-01-01—9999-12-12",
        orderName: this.OrderName,
        order: this.OrderType,
        search: this.Search,
      };
      if (param.search.length == 0) {
        this.$toast(this.$t("Report.pleaseChooseLogin"));
        return;
      }
      getTotalCommission(param).then((res) => {
        if ((res.code = 200)) {
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    nextpage() {
      this.pageIndex++;
      this.loadPageData();
    },
    Sort(value) {
      //ebugger
      this.$refs.item.toggle();
      switch (value) {
        case "CLOSE_TIME":
          this.OrderName = "CLOSE_TIME";
          break;
          case "COMVALUE1":
              this.OrderName = "COMVALUE1";
          break;
      }
      if (this.OrderType == "desc") {
        this.OrderType = "asc";
      } else {
        this.OrderType = "desc";
      }
      this.pageIndex = 1;
      this.loadPageData();
    },
    fliter(value) {
      this.Search = value;
      this.pageIndex = 1;
      this.loadPageData();
    },
    formattype(value) {
      return formatType(value);
    },
    formatVolume(value) {
      return (value * 0.01).toFixed(2);
    },
    formatColor(value) {
      if (parseFloat(value) > 0) {
        return "blue";
      }
      return "red";
    },
    formatPrice(value, len) {
      return forPrice(value, len);
    },
    formatSlTp(value) {
      if (value <= 0) {
        return "-";
      }
      return this.formatPrice(value, 5);
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  padding: 10px;
  background-color: #f7f8fa;
  min-height: calc(100vh - 50px);
}
.box-content {
  border-radius: 8px;
  overflow: hidden;
}
.van-nav-bar {
  background-color: #13b5b1;
}
.van-nav-bar >>> .van-nav-bar__title {
  color: #fff;
}
.van-nav-bar >>> .van-icon {
  color: #fff !important;
}
.fa {
  color: #25a4bb;
}

.dropdown-menu-option-active-color {
  color: #25a4bb;
}

.itemright {
  float: right;
  padding-right: 10px;
}
</style>
