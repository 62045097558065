<template>
  <div>
    <NavBar :title="$t('referralLink')"></NavBar>
    <div class="container">
      <div class="box">
        <div class="customerspace"></div>
        <van-cell-group title="" class="cblock">
          <van-form>
            <van-field
              v-model="username"
              name="ibaccount"
              :label="$t('NewAccount.agentAccount')"
              :placeholder="$t('NewAccount.agentAccount')"
              @click="showibPicker = true"
              :rules="[{ required: true, message: '' }]"
            />
            <van-field
              v-model="refrencelink"
              readonly
              disabled
              autosize
              type="textarea"
              :name="$t('referralLink')"
              :placeholder="$t('referralLink')"
              :rules="[{ required: true, message: '' }]"
            />
            <input type="hidden" id="testing-code" :value="refrencelink" />
            <div style="margin: 16px">
              <van-button
                round
                block
                type="info"
                @click="copyUrl()"
                color="#13b5b1"
                >{{ $t("clickToCopy") }}</van-button
              >
            </div>
          </van-form>
        </van-cell-group>
        <div class="customerspace"></div>
        <div style="margin-bottom: 150px"></div>
      </div>
    </div>
    <van-popup v-model="showibPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="this.ibs"
        :confirm-button-text="$t('ok')"
        :cancel-button-text="$t('Menu.cancel')"
        @confirm="onConfirm"
        @cancel="showibPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
    import { getibs, getrefrencelink } from "@/api";
    import { Notify, Dialog } from 'vant';
export default {
  name: "Terminal",
  components: { NavBar },
  data() {
    return {
      showibPicker: false,
      ibs: [],
      username: "",
      refrencelink: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.loadData();
  },
  beforeDestroy() {},
  methods: {
    loadData() {
      getibs({}).then((res) => {
        if (res.code == 200) {
          if (res.msg == "noibaccount") {
            this.username = "No IB account";
          } else {
            this.ibs = res.msg;
            this.username = res.msg[0];
            this.generateurl(this.username);
          }
        } else {
            Dialog.alert({
                message: this.$t("networkerrorpleasetryagainlater"),
            }).then(() => {
                // on close
            });
        }
      });
    },
    generateurl(login) {
      getrefrencelink({ login: login }).then((res) => {
        if (res.code == 200) {
          this.refrencelink = `${window.location.host}/#/Register?uid=${res.msg}`;
        }
      });
    },
    onConfirm(value) {
      this.username = value;
      this.generateurl(this.username);
      this.showibPicker = false;
    },
    copyUrl() {
      //1234
      try {
        const el = document.createElement("textarea");
        el.value = this.refrencelink;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        const selected =
          document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        if (selected) {
          document.getSelection().removeAllRanges();
          document.getSelection().addRange(selected);
        }
          Notify({ type: 'success', message: this.$t("success") });
      } catch (error) {
          Notify({ type: 'danger', message: this.$t("copyfailurepleaerefreshpageandtryagain") });

      }
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  margin: 0 10px;
}
.button {
  margin: 15px;
  padding-bottom: 15px;
}
.container {
  background-color: #f7f7f7;
  height: 100%;
}

.space {
  margin-top: 15px;
}

.van-cell-group__title {
  background-color: white;
}

.van-uploader {
  margin: 10px;
}

.cblock {
  text-align: center;
  font-size: 16px;
  padding: 12px 0;
  border-radius: 8px;
}

.imgPreview {
  width: 100%;
}

.customerspace {
  height: 10px;
}

.van-uploader__preview-image {
  height: unset;
  width: unset;
}
.fa {
  font-size: 130px;
  margin: 15px auto;
}
</style>