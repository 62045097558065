var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":_vm.$t('Menu.summaryReport'),"left-arrow":"","placeholder":true,"fixed":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-content"},[_c('van-dropdown-menu',{attrs:{"active-color":"#25a4bb"}},[_c('van-dropdown-item',{attrs:{"options":_vm.option},on:{"change":_vm.fliter},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('van-dropdown-item',{ref:"item",attrs:{"title":_vm.$t('sort')}},[_c('van-cell',{attrs:{"center":"","title":_vm.$t('sortByDeposit')},on:{"click":function($event){return _vm.Sort('Deposit')}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('i',{class:{
                  fa: true,
                  'fa-long-arrow-down': _vm.OrderType == 'desc',
                  'fa-long-arrow-up': _vm.OrderType == 'asc',
                },style:({
                  display: _vm.OrderName == 'Deposit' ? 'block' : 'none',
                }),attrs:{"aria-hidden":"true"}})]},proxy:true}])}),_c('van-cell',{attrs:{"center":"","title":_vm.$t('Report.volume')},on:{"click":function($event){return _vm.Sort('Volume')}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('i',{class:{
                  fa: true,
                  'fa-long-arrow-down': _vm.OrderType == 'desc',
                  'fa-long-arrow-up': _vm.OrderType == 'asc',
                },style:({ display: _vm.OrderName == 'Volume' ? 'block' : 'none' }),attrs:{"aria-hidden":"true"}})]},proxy:true}])})],1)],1),_c('van-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.records),function(item,index){return _c('van-collapse-item',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_c('strong',[_vm._v(_vm._s(item.Login))]),_vm._v(","+_vm._s(item.Name)+" ")])]},proxy:true},{key:"right-icon",fn:function(){return [_c('div',[_c('div',{staticStyle:{"float":"right"}},[_c('span',{style:({ color: _vm.formatColor(item.Balance) })},[_vm._v(_vm._s(_vm.formatPrice(item.Balance, 2)))])])])]},proxy:true}],null,true)},[_c('van-row',[_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(_vm.$t("Report.volume"))+":"),_c('span',{staticClass:"itemright"},[_vm._v(_vm._s(_vm.formatPrice(item.Volume, 2)))])]),_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(_vm.$t("Report.charges"))+":"),_c('span',{staticClass:"itemright"},[_vm._v(_vm._s(_vm.formatPrice(item.Commission, 2)))])]),_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(_vm.$t("Report.depossitTotal"))+":"),_c('span',{staticClass:"itemright"},[_vm._v(_vm._s(_vm.formatPrice(item.Deposit, 2)))])]),_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(_vm.$t("Report.withdrawalTotal"))+":"),_c('span',{staticClass:"itemright"},[_vm._v(_vm._s(_vm.formatPrice(item.Withdrawal, 2)))])]),_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(_vm.$t("Report.credit"))+":"),_c('span',{staticClass:"itemright"},[_vm._v(_vm._s(_vm.formatPrice(item.Credit, 2)))])])],1)],1)}),1)],1),_c('div',{staticStyle:{"margin-bottom":"0px","text-align":"center","padding":"25px 0px"}},[(_vm.records.length < _vm.total)?_c('span',{on:{"click":function($event){return _vm.nextpage()}}},[_vm._v(_vm._s(_vm.$t("app.clickLoadMore")))]):_c('span',[_vm._v(_vm._s(_vm.$t("app.noMoreData")))])])]),_c('div',{staticClass:"van-safe-area-bottom"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }