<template>
  <div class="k-line-chart-container">
    <h3 class="k-line-chart-title">EUR/USD</h3>
    <div id="basic-k-line" class="k-line-chart"></div>
  </div>
</template>

<script>
import { dispose, init } from "klinecharts";
import generatedKLineDataList from "@/assets/js/generatedKLineDataList";
export default {
  name: "BasicKLineChart",
  mounted: function () {
    // this.$store.commit("toggleTabbarShow", false);
    const kLineChart = init("basic-k-line");
    kLineChart.applyNewData([
      {
        close: 4976.16,
        high: 4977.99,
        low: 4970.12,
        open: 4972.89,
        timestamp: 1587660000000,
        volume: 204,
      },
      {
        close: 4977.33,
        high: 4979.94,
        low: 4971.34,
        open: 4973.2,
        timestamp: 1587660060000,
        volume: 194,
      },
      {
        close: 4977.93,
        high: 4977.93,
        low: 4974.2,
        open: 4976.53,
        timestamp: 1587660120000,
        volume: 197,
      },
      {
        close: 4966.77,
        high: 4968.53,
        low: 4962.2,
        open: 4963.88,
        timestamp: 1587660180000,
        volume: 28,
      },
      {
        close: 4961.56,
        high: 4972.61,
        low: 4961.28,
        open: 4961.28,
        timestamp: 1587660240000,
        volume: 184,
      },
      {
        close: 4964.19,
        high: 4964.74,
        low: 4961.42,
        open: 4961.64,
        timestamp: 1587660300000,
        volume: 191,
      },
      {
        close: 4968.93,
        high: 4972.7,
        low: 4964.55,
        open: 4966.96,
        timestamp: 1587660360000,
        volume: 105,
      },
      {
        close: 4979.31,
        high: 4979.61,
        low: 4973.99,
        open: 4977.06,
        timestamp: 1587660420000,
        volume: 35,
      },
      {
        close: 4977.02,
        high: 4981.66,
        low: 4975.14,
        open: 4981.66,
        timestamp: 1587660480000,
        volume: 135,
      },
      {
        close: 4985.09,
        high: 4988.62,
        low: 4980.3,
        open: 4986.72,
        timestamp: 1587660540000,
        volume: 76,
      },
      {
        close: 4976.16,
        high: 4977.99,
        low: 4970.12,
        open: 4972.89,
        timestamp: 1587660000000,
        volume: 204,
      },
      {
        close: 4977.33,
        high: 4979.94,
        low: 4971.34,
        open: 4973.2,
        timestamp: 1587660060000,
        volume: 194,
      },
      {
        close: 4977.93,
        high: 4977.93,
        low: 4974.2,
        open: 4976.53,
        timestamp: 1587660120000,
        volume: 197,
      },
      {
        close: 4966.77,
        high: 4968.53,
        low: 4962.2,
        open: 4963.88,
        timestamp: 1587660180000,
        volume: 28,
      },
      {
        close: 4961.56,
        high: 4972.61,
        low: 4961.28,
        open: 4961.28,
        timestamp: 1587660240000,
        volume: 184,
      },
      {
        close: 4964.19,
        high: 4964.74,
        low: 4961.42,
        open: 4961.64,
        timestamp: 1587660300000,
        volume: 191,
      },
      {
        close: 4968.93,
        high: 4972.7,
        low: 4964.55,
        open: 4966.96,
        timestamp: 1587660360000,
        volume: 105,
      },
      {
        close: 4979.31,
        high: 4979.61,
        low: 4973.99,
        open: 4977.06,
        timestamp: 1587660420000,
        volume: 35,
      },
      {
        close: 4977.02,
        high: 4981.66,
        low: 4975.14,
        open: 4981.66,
        timestamp: 1587660480000,
        volume: 135,
      },
      {
        close: 4985.09,
        high: 4988.62,
        low: 4980.3,
        open: 4986.72,
        timestamp: 1587660540000,
        volume: 76,
      },
    ]);

    //start
    const updateData = () => {
      setTimeout(() => {
        const dataList = kLineChart.getDataList();
        const lastData = dataList[dataList.length - 1];
        const newData = generatedKLineDataList(
          lastData.timestamp,
          lastData.close,
          1
        )[0];
        newData.timestamp += 60 * 1000;
        kLineChart.updateData(newData);
        updateData(kLineChart);
      }, 1000);
    };
    kLineChart.applyNewData(generatedKLineDataList());
    updateData();
    //end
  },
  destroyed: function () {
    dispose("basic-k-line");
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #191919;
}

div,
p {
  box-sizing: border-box;
}

p {
  margin: 0;
}

.app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px;
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-color: #1f2126;
  width: 100%;
  height: 100%;
  padding: 16px 6px 16px 16px;
}

.k-line-chart-title {
  margin: 0;
  color: #e6e8ea;
  padding-bottom: 10px;
}

.k-line-chart {
  display: flex;
  flex: 1;
}
.k-line-chart-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #929aa5;
}
.k-line-chart-menu-container button {
  cursor: pointer;
  background-color: #2196f3;
  border-radius: 2px;
  margin-right: 8px;
  height: 24px;
  line-height: 26px;
  padding: 0 6px;
  font-size: 12px;
  color: #fff;
  border: none;
  outline: none;
}
</style>
