<template>
    <div class="deposit">
        <!-- <NavBar :title="$t('Funds.deposit')"></NavBar> -->
        <van-nav-bar :title="$t('Funds.deposit')"
                     left-arrow
                     @click-left="$router.go(-1)" />
        <section style="padding: 10px; display: flex; flex-flow: column nowrap">
            <div>
                <van-form @submit="onDepositSubmit">
                    <van-cell-group :border="false">
                        <van-field readonly
                                   v-model="model.login"
                                   clickable
                                   :label="$t('Funds.login')"
                                   :placeholder="$t('Funds.chooseLogin')"
                                   @click="showUserLoginListPopup" />
                        <van-popup v-model="UserLoginList.show"
                                   bind:close="onClose"
                                   position="bottom">
                            <van-picker :title="$t('Funds.login')"
                                        show-toolbar
                                        :columns="UserLoginList.columns"
                                        @confirm="onConfirmLogin"
                                        @cancel="onCancel"
                                        @change="onChange"
                                        :default-index="UserLoginList.defaultIndex" />
                        </van-popup>

                        <van-field readonly
                                   v-model="model.currency"
                                   clickable
                                   :label="$t('Funds.currency')"
                                   :placeholder="$t('Funds.pleaseChooseCurrency')"
                                   @click="showCurrencyPopup"
                                   :rules="[{ required: true }]" />
                        <van-popup v-model="Currency.show"
                                   bind:close="onClose"
                                   position="bottom">
                            <van-picker :title="$t('Funds.currency')"
                                        show-toolbar
                                        :columns="Currency.columns"
                                        @confirm="onConfirmCurrency"
                                        @cancel="onCancel"
                                        @change="onChange"
                                        :default-index="Currency.defaultIndex" />
                        </van-popup>

                        <van-field v-model="model.money"
                                   center
                                   clearable
                                   ref="money"
                                   type="number"
                                   :label="$t('Funds.depositMoney')"
                                   :placeholder="$t('Funds.pleaseInputDepositMoney')"
                                   :rules="[{ required: true }]">
                        </van-field>

                        <van-field v-model="model.moneyUsd"
                                   center
                                   readonly
                                   clearable
                                   type="number"
                                   :label="$t('Funds.depositMoneyUsd')"
                                   :placeholder="$t('Funds.pleaseInputDepositMoneyUsd')">
                            <!-- <template #right-icon>
                                  <van-tag color="rgb(19, 181, 177)" size="medium" plain>{{$t("app.not")}}</van-tag>
                                </template> -->
                            
                        </van-field>

                        <van-field readonly
                                   v-model="model.payType.displayName"
                                   clickable
                                   :label="$t('Funds.payChannel')"
                                   :placeholder="$t('Funds.pleaseInputPayChannel')"
                                   @click="showPayChannelPopup"
                                   :rules="[{ required: true }]" />
                        <van-popup v-model="PayChannel.show"
                                   bind:close="onClose"
                                   position="bottom">
                            <van-picker :title="$t('Funds.payChannel')"
                                        show-toolbar
                                        :columns="PayChannel.columns"
                                        @confirm="onConfirmPayChannel"
                                        @cancel="onCancel"
                                        @change="onChange"
                                        :default-index="PayChannel.defaultIndex" />
                        </van-popup>

                        <van-field class="code"
                                   readonly
                                   v-model="model.bankWire.displayName"
                                   clickable
                                   :label="$t('Funds.bankAccount')"
                                   :placeholder="$t('Funds.pleaseInputPayChannel')"
                                   @click="showBankWirePopup"
                                   :style="{display: model.showBankWire == true ? 'inline-flex' : 'none',}"
                                   :rules="[{required: model.showBankWire == true ? true : false,},]" />

                        <van-popup v-model="BankWire.show"
                                   bind:close="onClose"
                                   position="bottom">
                            <van-picker :title="$t('Funds.payChannel')"
                                        show-toolbar
                                        :columns="BankWire.columns"
                                        @confirm="onConfirmBankWire"
                                        @cancel="onCancel"
                                        @change="onChange"
                                        :default-index="PayChannel.defaultIndex" />
                        </van-popup>
                    </van-cell-group>

                    <div class="button">
                        <van-button color="#13b5b1"
                                    :type="type"
                                    native-type="submit"
                                    :loading="isLoading"
                                    :loading-text="$t('loading')"
                                    block
                                    :disabled="depositDisabled">{{ $t("Funds.submitApplication") }}</van-button>
                    </div>
                </van-form>
            </div>
        </section>
    </div>
</template>

<script>
    import NavBar from "@/components/NavBar";
    import { Toast, Dialog, Notify } from "vant";
    import {
        getUserLogins,
        getBankWire,
        getRate,
        getPayMethod,
        bankWireUsdtBtcDeposit,
        onlineDeposit,
    } from "@/api";
    export default {
        name: "Deposit",
        components: { NavBar },
        data() {
            return {
                type: "default",
                depositDisabled: true,
                userinfo: this.$store.state.userinfo,
                UserLoginList: {
                    show: false,
                    columns: [],
                    defaultIndex: 0,
                },
                Currency: {
                    show: false,
                    columns: [
                        { id: "USD", text: "USD" },
                        { id: "CNY", text: "CNY" },
                        { id: "USDT", text: "USDT" },
                        { id: "BTC", text: "BTC" },
                        
                    ],
                    defaultIndex: 0,
                },
                PayChannel: {
                    show: false,
                    columns: [],
                    defaultIndex: 0,
                },
                BankWire: {
                    show: false,
                    columns: [],
                    defaultIndex: 0,
                },
                isLoading: false,
                model: {
                    showBankWire: false,
                    login: "",
                    moneyUsd: "",
                    money: "",
                    currency: "",
                    rate: 0,
                    payType: {
                        displayName: "",
                        id: "",
                        text: "",
                    },
                    bankWire: {
                        id: "",
                        text: "",
                        displayName: "",
                    },
                },
            };
        },
        mounted() {
            // this.$store.commit("toggleTabbarShow", false);
        },
        watch: {
            "model.money"(newVal) {
                if (parseFloat(newVal) > 0) {
                    this.depositDisabled = false;
                    this.type = "info";
                    this.exchangeUsd();
                } else {
                    this.depositDisabled = true;
                    this.type = "default";
                }
            },
        },
        created() {
            this.loadLogins();
            this.model.currency = "USD";
            this.Currency.defaultIndex = 0;
            this.loadPayChannel();
            this.loadRate();
        },
        methods: {
            onConfirmLogin(value, index) {
                this.UserLoginList.show = false;
                this.model.login = value.id;
            },
            onConfirmCurrency(value, index) {
                this.Currency.show = false;
                this.model.currency = value.id;
                this.loadRate();
                this.loadPayChannel();
                this.showBankWire();
            },
            onConfirmPayChannel(value, index) {
                this.PayChannel.show = false;
                this.model.payType.id = value.id;
                this.model.payType.name = value.name;
                this.model.payType.displayName = value.text;

                this.showBankWire();
            },
            onConfirmBankWire(value, index) {
                this.BankWire.show = false;
                this.model.bankWire.id = value.id;
                this.model.bankWire.text = value.text;
                this.model.bankWire.displayName = value.name;

                console.log(value);
            },
            onChange(picker, value, index) { },
            onCancel() {
                this.UserLoginList.show = false;
                this.Currency.show = false;
                this.PayChannel.show = false;
            },
            showUserLoginListPopup() {
                this.UserLoginList.show = true;
            },
            showCurrencyPopup() {
                this.Currency.show = true;
            },
            showPayChannelPopup() {
                this.PayChannel.show = true;
            },
            showBankWirePopup() {
                this.BankWire.show = true;
            },
            onDepositSubmit() {
                this.isLoading = true;
                this.depositDisabled = true;
                let params = {
                    login: this.model.login,
                    currency: this.model.currency,
                    money: this.model.money,
                    payTypeId: this.model.payType.id,
                    payTypeName: this.model.payType.name,
                };
                //console.log(params)
                switch (params.payTypeId) {
                    case 1: //demo
                        onlineDeposit(params).then((res) => {
                            if (res.code !== 200) {
                                if (res.code === 202) {
                                    Dialog.alert({
                                        message: this.$t(res.msg.msg) + "" + res.msg.amount,
                                        /*theme: "round-button",*/
                                    }).then(() => {
                                        // on close
                                    });
                                    // Dialog.alert(this.$t(res.msg.msg) + "" + res.msg.amount);
                                } else {
                                    Notify({ type: 'danger', message: this.$t(res.msg) });
                                }
                            } else {
                                this.$router.push({
                                    path: "/funds/history",
                                });
                            }
                        });
                        break;
                    case 2: //BankWire
                        params.bankWireId = this.model.bankWire.id;
                    case 3: //USDT
                    case 4: //BTC
                        bankWireUsdtBtcDeposit(params).then((res) => {
                            if (res.code !== 200) {
                                if (res.code === 202) {
                                    Dialog.alert({
                                        message: this.$t(res.msg.msg) + "" + res.msg.amount,
                                        /*theme: "round-button",*/
                                    }).then(() => {
                                        // on close
                                    });
                                } else {
                                    Notify({ type: 'danger', message: this.$t(res.msg) });
                                }
                            } else {
                                var json = res.msg;
                                this.$router.push({
                                    path: "/funds/Payment",
                                    query: {
                                        bankwireId: json.TransferinfoId,
                                        money: json.Money,
                                        moneyUsd: json.MoneyUSD,
                                        currency: json.ImgUrl,
                                        orderNo: json.OrderNumber,
                                    },
                                });
                            }
                        });
                        break;
                    default:
                        Dialog.alert({
                            message: $t("Funds.pleaseChoosePaymentChannel"),
                            /*theme: "round-button",*/
                        }).then(() => {
                            // on close
                        });
                        break;
                }
                this.isLoading = false;
                this.depositDisabled = false;
            },
            loadLogins() {
                let params = { loginType: 2 };
                getUserLogins(params).then((res) => {
                    if (res.code !== 200) {
                        Dialog.alert({
                            message: this.$t(res.msg),
                        }).then(() => {
                            // on close
                        });
                    } else {
                        var json = res.msg;
                        if (json.length > 0) {
                            for (var i = 0; i < json.length; i++) {
                                this.UserLoginList.columns.push({ id: json[i], text: json[i] });
                            }
                            this.model.login = json[0];
                        } else {
                            this.$router.push("/member/newaccount?id=0");
                        }
                    }
                });
            },
            loadPayChannel() {
                let data = { currency: this.model.currency };
                getPayMethod(data).then((res) => {
                    if (res.code != 200) {
                        Dialog.alert({
                            message: this.$t(res.msg),
                        }).then(() => {
                            // on close
                        });
                        this.depositDisabled = true;
                    } else {
                        var json = res.msg;
                        this.PayChannel.columns = [];
                        for (var i = 0; i < json.length; i++) {
                            this.PayChannel.columns.push({
                                id: json[i].ValueId,
                                text: json[i].DisplayName,
                                name: json[i].Name,
                            });
                        }
                        this.model.payType.id = "";
                        this.model.payType.name = "";
                        this.model.payType.displayName = "";
                    }
                });
            },
            loadRate() {
                let data = { currency: this.model.currency, isDeposit: true };
                getRate(data).then((res) => {
                    if (res.code != 200) {
                        Dialog.alert({
                            message: this.$t(res.msg),
                        }).then(() => {
                            // on close
                        });
                        this.depositDisabled = true;
                    } else {
                        this.model.rate = res.msg;
                        this.exchangeUsd();
                        //console.log(`当前汇率:${data.currency},${res.msg}`);
                    }
                });
            },
            loadBankWire() {
                let data = {};
                getBankWire(data).then((res) => {
                    if (res.code != 200) {
                        Dialog.alert({
                            message: this.$t(res.msg),
                        }).then(() => {
                            // on close
                        });
                        this.depositDisabled = true;
                    } else {
                        var json = res.msg;
                        this.BankWire.columns = [];
                        for (var i = 0; i < json.length; i++) {
                            this.BankWire.columns.push({
                                id: json[i].Id,
                                text: json[i].BankName + json[i].BankAccount,
                                name: json[i].BankName,
                            });
                        }
                    }
                });
            },
            showBankWire() {
                var cry = this.model.currency;
                var payChannelId = this.model.payType.id;
                //console.log(payChannelId);
                if ((cry == "CNY" || cry == "USD") && payChannelId == 2) {
                    this.model.showBankWire = true;
                    this.loadBankWire();
                } else {
                    this.model.bankWire.id = "";
                    this.model.bankWire.text = "";
                    this.model.bankWire.displayName = "";
                    this.model.showBankWire = false;
                }
            },
            exchangeUsd() {
                if (parseFloat(this.model.money) > 0) {
                    this.model.moneyUsd = (this.model.money / this.model.rate).toFixed(2);
                    console.log("model.currency" + this.model.moneyUsd);
                }
            },
        },
        beforeDestroy() {
            this.$store.commit("toggleTabbarShow", true);
        },
    };</script>

<style scoped>
    .deposit {
        background-color: #f7f8fa;
        height: 100vh;
    }

    .van-cell-group {
        border-radius: 8px;
        overflow: hidden;
    }

    .space-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .van-loading span.van-loading__spinner {
        margin-top: 14px !important;
    }

    .deposit .van-popup {
        margin-bottom: 0;
    }

    .van-button {
        border-radius: 5px;
        overflow: hidden;
    }

    .button {
        margin-top: 20px;
    }
</style>
