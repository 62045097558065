<template>
  <div>
    <navlangbar />
    <section
      style="padding: 30px 30px; display: flex; flex-flow: column nowrap"
    >
      <div style="font-size: 20px; font-weight: bold; text-align: center">
        {{ $t("app.registernow") }}
      </div>
      <van-form>
        <van-cell-group>
          <van-field
            v-model="model.userEmail"
            clearable
            :placeholder="$t('Register.userEmail')"
            name="pattern"
            :rules="[
              {
                pattern:
                  /^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
                message: $t('app.email'),
              },
            ]"
            ref="userEmail"
            style="background-color: WhiteSmoke"
          >
          </van-field>
          <van-field
            v-model="model.userName"
            clearable
            :placeholder="$t('Register.userName')"
            style="background-color: WhiteSmoke"
          >
          </van-field>
          <van-field
            v-model="model.pwd"
            center
            clearable
            :placeholder="$t('Register.userPassword')"
            name="pattern"
            :rules="[
              {
                pattern: /^\S{6,}$/,
                message: '*' + $t('ChangePwd.pleaseInputPassword'),
              },
            ]"
            ref="pwd"
            style="background-color: WhiteSmoke"
            type="password"
          >
          </van-field>
          <van-field
            v-model="model.repwd"
            center
            clearable
            :placeholder="$t('Register.userPasswordAgain')"
            name="pattern"
            :rules="[
              {
                pattern: /^\S{6,}$/,
                message: '*' + $t('ChangePwd.pleaseInputPassword'),
              },
            ]"
            style="background-color: WhiteSmoke"
            ref="repwd"
            type="password"
          >
          </van-field>
          <van-field
            v-model="model.agent"
            clearable
            :placeholder="$t('Register.userAgent')"
            type="number"
            ref="agent"
            style="background-color: WhiteSmoke"
          >
          </van-field>
        </van-cell-group>
      </van-form>
      <div>
        <van-button
          color="#13b5b1"
          block
          :disabled="loginDisabled"
          @click="showVCode"
          >{{ $t("Register.register") }}</van-button
        >
      </div>
      <div style="text-align: center">
        <span
          >{{ $t("Register.hasAccount") }}&nbsp;<router-link to="/">{{
            $t("Register.loginNow")
          }}</router-link></span
        >
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>
<script>
import Vcode from "vue-puzzle-vcode";
import navlangbar from "@/components/NavlangBar";
import { register, active, decrypt } from "@/api";
import { isEmail } from "@/utils/commons";
    import { Toast, Notify, Dialog } from "vant";
export default {
  name: "Register",
  components: { navlangbar, Vcode },
  data() {
    return {
      type: "default",
      loginDisabled: true,
      vcodeShow: false,
      model: {
        userEmail: "",
        userName: "",
        repwd: "",
        pwd: "",
        agent: "",
      },
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", false);
    this.Active();
    this.loaddata();
  },
  watch: {
    // 当对象中的属性发生增变化时触发
    model: {
      handler: function (val, oldVal) {
        console.log(val);
        if (
          this.emailIs(val.userEmail) &&
          val.userName.length &&
          val.repwd.length >= 6 &&
          val.pwd.length >= 6
        ) {
          this.loginDisabled = false;
        } else {
          this.loginDisabled = true;
        }
      },
      deep: true,
    },
  },
  // watch: {
  //   // "model.pwd"(newVal) {
  //   //   if (
  //   //     newVal.length >= 6 &&
  //   //     this.model.repwd.length == newVal.length &&
  //   //     this.model.repwd == newVal
  //   //   ) {
  //   //     this.loginDisabled = false;
  //   //     this.type = "info";
  //   //   } else {
  //   //     this.loginDisabled = true;
  //   //     this.type = "info";
  //   //   }
  //   //   this.chedkNull();
  //   // },
  //   "model.repwd"(newVal) {
  //     if (
  //       newVal.length >= 6 &&
  //       this.model.pwd.length == newVal.length &&
  //       this.model.pwd == newVal
  //     ) {
  //       this.loginDisabled = false;
  //       this.type = "info";
  //     } else {
  //       this.loginDisabled = true;
  //       this.type = "default";
  //     }
  //     this.chedkNull();
  //   },
  //   // "model.userEmail"(newVal) {
  //   //   if (isEmail(newVal)) {
  //   //     this.loginDisabled = false;
  //   //     this.type = "info";
  //   //   } else {
  //   //     this.loginDisabled = true;
  //   //     this.type = "default";
  //   //   }
  //   //   this.chedkNull();
  //   // },
  // },
  methods: {
    emailIs(val) {
      const reg = /^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      return reg.test(val);
    },
    chedkNull() {
      let verify = true;
      let params = {
        email: this.model.userEmail,
        name: this.model.userName,
        agent: this.model.agent,
        pwd: this.model.pwd,
        pwdre: this.model.repwd,
      };
      if (!isEmail(params.email)) {
        verify = false;
      }
      if (params.name.length === 0) {
        verify = false;
      }
      if (
        params.pwd.length >= 6 &&
        params.pwd.length <= 18 &&
        params.pwd == params.pwdre
      ) {
        verify = true;
      } else {
        verify = false;
      }
      if (verify) {
        this.loginDisabled = false;
        this.type = "info";
      } else {
        this.loginDisabled = true;
        this.type = "default";
      }
    },
    toLogin() {
      this.$router.push("/");
    },
    close() {
      this.isShow = false;
    },
    loaddata() {
      // debugger
      let uid = this.$route.query.uid;
      if (uid) {
        decrypt({ uid: uid }).then((res) => {
          this.model.agent = res.msg;
        });
      }
    },
    Active() {
      var email = this.$route.query.email;
      var ticket = this.$route.query.ticket;
      var uid = this.$route.query.uid;
      if (uid != undefined) {
        this.agent = uid;
      }
      if (
        email &&
        email.trim().length > 0 &&
        ticket &&
        ticket.trim().length > 0
      ) {
        this.loginDisabled = "disabled";
        //console.log("email:" + email + ",ticket:" + ticket + ",uid:" + uid);
        let params = {
          email: email,
          ticket: ticket,
        };
        active(params)
          .then((res) => {
            if (res.code !== 200) {
                Notify({ type: 'danger', message: this.$t(res.msg) });
            } else {
              Toast({
                type: "success",
                message: this.$t(res.msg),
                onClose: () => {
                  this.$router.push("/");
                },
              });
            }
            this.loginDisabled = false;
          })
          .catch(() => {
            this.loginDisabled = false;
          });
      }
    },
    toRegister() {
      let params = {
        email: this.model.userEmail,
        name: this.model.userName,
        agent: this.model.agent,
        pwd: this.model.pwd,
        pwdre: this.model.repwd,
      };
      if (params.agent.length == 0) {
        params.agent = 0;
      }
      this.loginDisabled = true;
      register(params)
        .then((res) => {
          console.log(res);
          if (res.code !== 200) {
              Notify({ type: 'danger', message: this.$t(res.msg) });
          } else {
              Notify({ type: 'success', message: this.$t(res.msg) });
          }
          this.loginDisabled = false;
        })
        .catch(() => {
          this.loginDisabled = false;
        });
    },
    showVCode() {
      this.loginDisabled = "disabled";
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      this.toRegister();
    },
  },
};
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}
.van-button {
  border-radius: 8px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.router-link-active {
  color: #13b5b1;
}
</style>
