var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":_vm.$t('Menu.fundsHistory'),"left-arrow":"","placeholder":true,"fixed":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-content"},[_c('van-dropdown-menu',{attrs:{"active-color":"#25a4bb"}},[_c('van-dropdown-item',{attrs:{"options":_vm.option},on:{"change":_vm.fliter},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('van-dropdown-item',{ref:"item",attrs:{"title":_vm.$t('sort')}},[_c('van-cell',{attrs:{"center":"","title":_vm.$t('sortByTime')},on:{"click":function($event){return _vm.Sort('OrderDate')}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('i',{class:{
                  fa: true,
                  'fa-long-arrow-down': _vm.OrderType == 'desc',
                  'fa-long-arrow-up': _vm.OrderType == 'asc',
                },style:({
                  display: _vm.OrderName == 'OrderDate' ? 'block' : 'none',
                }),attrs:{"aria-hidden":"true"}})]},proxy:true}])}),_c('van-cell',{attrs:{"center":"","title":_vm.$t('sortByAmount')},on:{"click":function($event){return _vm.Sort('MoneyUSD')}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('i',{class:{
                  fa: true,
                  'fa-long-arrow-down': _vm.OrderType == 'desc',
                  'fa-long-arrow-up': _vm.OrderType == 'asc',
                },style:({
                  display: _vm.OrderName == 'MoneyUSD' ? 'block' : 'none',
                }),attrs:{"aria-hidden":"true"}})]},proxy:true}])})],1)],1),_c('van-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('van-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.records.length == 0),expression:"records.length == 0"}],attrs:{"type":"spinner"}}),_vm._l((_vm.records),function(item,index){return _c('van-collapse-item',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_c('strong',[_vm._v(_vm._s(item.Login))]),_vm._v(","),_c('span',{style:({ color: _vm.formatColor(item.DepositType) })},[_vm._v(_vm._s(_vm.formattype(item.DepositType)))])]),_c('div',{staticStyle:{"display":"none"}},[_vm._v(" 1.232332  "),_c('i',{staticClass:"fa fa-long-arrow-right",attrs:{"aria-hidden":"true"}}),_vm._v("  1.23233 ")])]},proxy:true},{key:"right-icon",fn:function(){return [_c('div',[_c('div',{staticStyle:{"color":"darkgray"}},[_vm._v(" "+_vm._s(item.OrderDate.replace(/T/g, " ").replace(/\.[\d]{3}Z/, ""))+" ")]),_c('div',{staticStyle:{"float":"right"}},[_c('span',{style:({ color: _vm.formatColor(item.DepositType) })},[_vm._v(_vm._s(item.MoneyUSD))])])])]},proxy:true}],null,true)},[_c('van-row',[_c('van-col',{attrs:{"span":"24"}},[_vm._v(_vm._s(_vm.$t("ordernumber"))+": "+_vm._s(item.OrderNumber))]),_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(_vm.$t("Status"))+":"+_vm._s(_vm.formatStatus(item.IsSymbol)))]),_c('van-col',{attrs:{"span":"12"}},[(item.ImgUrl != '')?_c('span',{staticStyle:{"float":"right"},on:{"click":function($event){return _vm.viewCert(item.ImgUrl)}}},[_vm._v(_vm._s(_vm.$t("Funds.viewCert")))]):_vm._e()])],1)],1)})],2)],1),_c('div',{staticStyle:{"margin-bottom":"100px","text-align":"center","padding":"25px 0px"}},[(_vm.records.length < _vm.total)?_c('span',{on:{"click":function($event){return _vm.nextpage()}}},[_vm._v(_vm._s(_vm.$t("app.clickLoadMore")))]):_c('span',[_vm._v(_vm._s(_vm.$t("app.noMoreData")))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }