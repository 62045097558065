<template>
  <div>
    <van-nav-bar
      :title="$t('Menu.summaryReport')"
      left-arrow
      @click-left="$router.go(-1)"
      :placeholder="true"
      fixed
    />
    <!-- <NavBar :title="$t('Menu.summaryReport')"></NavBar> -->
    <div class="box">
      <div class="box-content">
        <van-dropdown-menu active-color="#25a4bb">
          <van-dropdown-item
            v-model="value"
            :options="option"
            @change="fliter"
          />
          <van-dropdown-item :title="$t('sort')" ref="item">
            <van-cell
              center
              :title="$t('sortByDeposit')"
              @click="Sort('Deposit')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  :style="{
                    display: OrderName == 'Deposit' ? 'block' : 'none',
                  }"
                  aria-hidden="true"
                ></i>
              </template>
            </van-cell>
            <van-cell
              center
              :title="$t('Report.volume')"
              @click="Sort('Volume')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  aria-hidden="true"
                  :style="{ display: OrderName == 'Volume' ? 'block' : 'none' }"
                ></i>
              </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>

        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item, index) in records" :key="index">
            <template #title>
              <div>
                <strong>{{ item.Login }}</strong
                >,{{ item.Name }}
              </div>
              <!--<div>{{ item.NAME}}</div>-->
            </template>
            <template #right-icon>
              <div>
                <!--<div style="color: darkgray;">{{ item.CLOSE_TIME.replace(/T/g,' ').replace(/\.[\d]{3}Z/,'')  }}</div>-->
                <div style="float: right">
                  <span :style="{ color: formatColor(item.Balance) }">{{
                    formatPrice(item.Balance, 2)
                  }}</span>
                </div>
              </div>
            </template>
            <van-row>
              <van-col span="12"
                >{{ $t("Report.volume") }}:<span class="itemright">{{
                  formatPrice(item.Volume, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.charges") }}:<span class="itemright">{{
                  formatPrice(item.Commission, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.depossitTotal") }}:<span class="itemright">{{
                  formatPrice(item.Deposit, 2)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("Report.withdrawalTotal") }}:<span class="itemright">{{
                  formatPrice(item.Withdrawal, 2)
                }}</span></van-col
              >
              <!--<van-col span="12"
                >{{ $t("Report.commissionAgent") }}:<span class="itemright">{{
                  formatPrice(item.CommissionAgent, 2)
                }}</span></van-col
              >-->
              <van-col span="12"
                >{{ $t("Report.credit") }}:<span class="itemright">{{
                  formatPrice(item.Credit, 2)
                }}</span></van-col
              >
            </van-row>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div style="margin-bottom: 0px; text-align: center; padding: 25px 0px">
        <span v-if="records.length < total" @click="nextpage()">{{
          $t("app.clickLoadMore")
        }}</span>
        <span v-else>{{ $t("app.noMoreData") }}</span>
      </div>
    </div>
    <div class="van-safe-area-bottom"></div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { getUserLogins, getSummary } from "@/api";
    import { forPrice } from "@/utils/commons";
    import { Notify, Dialog } from 'vant';
export default {
  name: "Summary",
  components: { NavBar },
  data() {
    return {
      pageIndex: 1,
      pageSize: 12,
      records: [],
      total: 0,
      value: 0,
      color: "green",
      option: [
        //{ text: '请选择', value: 0 },
      ],
      activeNames: [],
      OrderName: "Login",
      OrderType: "desc",
      Search: "",
    };
  },
  mounted() {
    this.loadLogins();
  },
  watch: {},
  created() {},
  methods: {
    loadLogins() {
      getUserLogins({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            this.option.push({ text: element, value: element });
          });
          this.Search = res.msg[0];
          this.value = res.msg[0];
          this.loadPageData();
        }
      });
    },
    loadPageData() {
      let param = {
        offset: this.pageIndex,
        pageSize: this.pageSize,
        dateTime: "1970-01-01—9999-12-12",
        orderName: this.OrderName,
        order: this.OrderType,
        search: this.Search,
      };
      if (param.search.length == 0) {
        this.$toast(this.$$t("Report.pleaseChooseLogin"));
        return;
      }
      getSummary(param).then((res) => {
        if ((res.code = 200)) {
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    nextpage() {
      this.pageIndex++;
      this.loadPageData();
    },
    Sort(value) {
      //ebugger
      this.$refs.item.toggle();
      switch (value) {
        case "Deposit":
          this.OrderName = "Deposit";
          break;
        case "Volume":
          this.OrderName = "Volume";
          break;
      }
      if (this.OrderType == "desc") {
        this.OrderType = "asc";
      } else {
        this.OrderType = "desc";
      }
      this.pageIndex = 1;
      this.loadPageData();
    },
    fliter(value) {
      this.Search = value;
      this.pageIndex = 1;
      this.loadPageData();
    },
    formatColor(value) {
      if (value > 0) {
        return "blue";
      }
      return "red";
    },
    formatPrice(value, len) {
      return forPrice(value, len);
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  padding: 10px;
  background-color: #f7f8fa;
  min-height: calc(100vh - 50px);
}
.box-content {
  border-radius: 8px;
  overflow: hidden;
}
.van-nav-bar {
  background-color: #13b5b1;
}
.van-nav-bar >>> .van-nav-bar__title {
  color: #fff;
}
.van-nav-bar >>> .van-icon {
  color: #fff !important;
}
.fa {
  color: #25a4bb;
}

.dropdown-menu-option-active-color {
  color: #25a4bb;
}

.itemright {
  float: right;
  padding-right: 10px;
}
</style>
