<template>
  <div class="mt4pwd">
    <!-- <NavBar></NavBar> -->
    <van-nav-bar
      :title="$t('ChangePwd.changeMt4Pwd')"
      left-arrow
      @click-left="$router.back()"
    />
    <section style="padding: 10px; display: flex; flex-flow: column nowrap">
      <!-- <div style="font-size: 20px; font-weight: bold; text-align: center">
        {{ $t("ChangePwd.changeMt4Pwd") }}
      </div> -->
      <div>
        <van-form @submit="onSumbmit">
          <van-cell-group :border="false" class="mt4pwd-group">
            <van-field
              readonly
              v-model="model.login"
              clickable
              :label="$t('Funds.login') + '：'"
              :placeholder="$t('Funds.chooseLogin')"
              @click="showUserLoginListPopup"
              :border="false"
            />
            <van-popup
              v-model="UserLoginList.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.login')"
                show-toolbar
                :columns="UserLoginList.columns"
                @confirm="onConfirmLogin"
                @cancel="onCancel"
                @change="onChange"
                :default-index="UserLoginList.defaultIndex"
              />
            </van-popup>

            <van-field
              readonly
              v-model="PwdType.pwdTypeText"
              clickable
              :label="$t('ChangePwd.passwordType') + '：'"
              :placeholder="$t('ChangePwd.pleaseChoosePasswordType')"
              @click="showPwdTypePopup"
              :rules="[{ required: true }]"
              :border="false"
            />
            <van-popup
              v-model="PwdType.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('ChangePwd.passwordType')"
                show-toolbar
                :columns="PwdType.columns"
                @confirm="onConfirmPwdType"
                @cancel="onCancel"
                @change="onChange"
                :default-index="PwdType.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="model.password"
              clickable
              type="password"
              ref="password"
              :label="$t('ChangePwd.password') + '：'"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^\S{8,}$/,
                  message: '*' + $t('ChangePwd.pleaseInputPassword'),
                },
              ]"
              :border="false"
            />

            <van-field
              v-model="model.passwordAgain"
              clickable
              type="password"
              ref="passwordAgain"
              :label="$t('ChangePwd.passwordAgain') + '：'"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^\S{8,}$/,
                  message: '*' + $t('ChangePwd.pleaseInputPassword'),
                },
              ]"
              :border="false"
            />

            <van-field
              class="code"
              v-model="model.code"
              center
              clearable
              ref="code"
              :label="$t('verificationCode') + '：'"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message: '*' + $t('pleaseInputVerificationCode'),
                },
              ]"
            >
              <template #button>
                <!-- <van-button
                  size="small"
                  type="info"
                  :disabled="sendCodeDisabled"
                  @click="showVCode"
                  color="#13b5b1"
                  >{{ $t("sendVerificationCode") }}</van-button
                > -->
                <CountDown @click.native="showVCode"></CountDown>
              </template>
            </van-field>
          </van-cell-group>

          <div class="button">
            <van-button
              :type="type"
              native-type="submit"
              :loading="isLoading"
              :loading-text="$t('loading')"
              block
              color="#13b5b1"
              :disabled="changeMt4PwdDisabled"
              >{{ $t("Funds.submitApplication") }}</van-button
            >
          </div>
        </van-form>
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
    import { Toast, Dialog, Notify } from "vant";
import { getUserLogins, changeMt4Pwd, sendVerifyCode } from "@/api";
export default {
  name: "CrmPwd",
  components: {
    NavBar,
    Vcode,
    CountDown: () => import("@/components/CounDown.vue"),
  },
  data() {
    return {
      type: "default",
      changeMt4PwdDisabled: true,
      sendCodeDisabled: false,
      vcodeShow: false,
      userinfo: this.$store.state.userinfo,
      isLoading: false,
      UserLoginList: {
        show: false,
        columns: [],
        defaultIndex: 0,
      },
      PwdType: {
        show: false,
        columns: [
          { id: 0, text: this.$t("ChangePwd.mainPassword") },
          { id: 1, text: this.$t("ChangePwd.investorPassword") },
        ],
        defaultIndex: 0,
        pwdTypeText: "",
      },
      model: {
        login: "",
        code: "",
        password: "",
        passwordAgain: "",
        pwdType: "",
      },
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
  },

  // watch: {
  //   "model.code"(newVal) {
  //     if (newVal.length == 8) {
  //       this.changeMt4PwdDisabled = false;
  //       this.type = "info";
  //     } else {
  //       this.changeMt4PwdDisabled = true;
  //       this.type = "default";
  //     }
  //   },
  //   "model.passwordAgain"(newVal) {
  //     let pwd = this.model.password;
  //     if (newVal.length >= 6 && pwd.length >= 6 && newVal == pwd) {
  //       this.changeMt4PwdDisabled = false;
  //       this.type = "info";
  //     } else {
  //       this.changeMt4PwdDisabled = true;
  //       this.type = "default";
  //     }
  //   },

  //   "model.password"(newVal) {
  //     let pwd = this.model.passwordAgain;
  //     if (newVal.length >= 6 && pwd.length >= 6 && newVal == pwd) {
  //       this.changeMt4PwdDisabled = false;
  //       this.type = "info";
  //     } else {
  //       this.changeMt4PwdDisabled = true;
  //       this.type = "default";
  //     }
  //   },
  // },
  created() {
    this.loadLogins();
  },
  watch: {
    model: {
      handler: function (val) {
        console.log(val);
        if (
          val.password.length >= 8 &&
          val.passwordAgain.length >= 8 &&
          val.code.length == 8
        ) {
          this.changeMt4PwdDisabled = false;
        } else {
          this.changeMt4PwdDisabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    onConfirmLogin(value, index) {
      this.UserLoginList.show = false;
      this.model.login = value.id;
    },
    onConfirmPwdType(value, index) {
      this.PwdType.show = false;
      this.PwdType.pwdTypeText = value.text;
      this.model.pwdType = value.id;
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.UserLoginList.show = false;
      this.PwdType.show = false;
    },
    showUserLoginListPopup() {
      this.UserLoginList.show = true;
    },
    showPwdTypePopup() {
      this.PwdType.show = true;
    },
    showVCode() {
      this.sendCodeDisabled = true;
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 9,
      };
      sendVerifyCode(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    onSumbmit() {
      this.isLoading = true;
      this.changeMt4PwdDisabled = true;
      let { password, passwordAgain } = this.model;
      let params = {
        login: this.model.login,
        password: this.model.password,
        passwordAgain: this.model.passwordAgain,
        code: this.model.code,
        pwdType: this.model.pwdType,
      };
      if (password === passwordAgain) {
        changeMt4Pwd(params).then((res) => {
          this.isLoading = false;
          this.changeMt4PwdDisabled = false;
          if (res.code !== 200) {
              Dialog.alert({
                  message: this.$t(res.msg),
              }).then(() => {
                  // on close
              });
          } else {
              Notify({ type: 'success', message: this.$t(res.msg) });
          }
        });
      } else {
        Dialog.alert({
          message: "The two passwords you typed do not match.",
          /*theme: "round-button",*/
        }).then(() => {
          // on close
        });
        this.isLoading = false;
      }
    },
    loadLogins() {
      let params = { loginType: 2 };
      getUserLogins(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
          var json = res.msg;
          if (json.length > 0) {
            for (var i = 0; i < json.length; i++) {
              this.UserLoginList.columns.push({ id: json[i], text: json[i] });
            }
            this.model.login = json[0];
            // this.getUserLeverage();
          } else {
            this.$router.push("/member/newaccount?id=0");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.mt4pwd {
  background-color: #f7f8fa;
}
.mt4pwd-group {
  border-radius: 8px;
  overflow: auto;
}
.mt4pwd .van-field {
  display: flex;
  flex-direction: column;
}
.mt4pwd .van-field:last-child {
  flex-direction: row;
}
.van-field >>> .van-field__label {
  width: 100%;
}
.van-field >>> .van-field__body {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
}
.code >>> .van-field__label {
  margin-right: 0;
}
.code >>> .van-field__body {
  border: none;
}
.code >>> .van-field__control {
  border-bottom: 1px solid #eee;
}
.button {
  margin-top: 20px;
}
.van-button {
  border-radius: 5px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.van-popup {
  margin-bottom: 0;
}
.mt4pwd .van-field:last-child >>> .van-cell__title {
  width: 4em;
}
</style>
