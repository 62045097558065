<template>
  <van-nav-bar :title="title" >
    <van-icon name="arrow-left" slot="left" color="#000" size=".46rem" @click="back"></van-icon>
  </van-nav-bar>
</template>

<script>
  export default {
    name: "NavBar",
    props:{
       title:{
         type:String,
         default:""
       }
    },
    data(){
       return{
       }
    },
    methods: {
      back() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped>

</style>
