<template>
  <div>
    <NavBar :title="$t('signalapply')"></NavBar>
    <div class="box">
      <div class="box-content">
        <van-form>
          <van-field
            v-model="login"
            name="Login"
            :label="$t('login')"
            label-width="1.5rem"
            :placeholder="$t('pleaseselect')"
            @click="logins.show = true"
            readonly
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="sharelogin"
            type="number"
            name="sharelogin"
            :label="$t('profitShareLogin')"
            label-width="1.5rem"
            :placeholder="$t('profitShareLogin')"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="signalname"
            name="SignalName"
            :label="$t('signalname')"
            label-width="1.5rem"
            :placeholder="$t('pleaseinputsinglaname')"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="singalcomm"
            type="number"
            name="SignalCom"
            :label="$t('SignalCom')"
            label-width="1.5rem"
            :placeholder="$t('pleaseinputpercentagesignalcom')"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="refercomm"
            type="number"
            name="ParentCom"
            :label="$t('ParentCom')"
            label-width="1.5rem"
            :placeholder="$t('pleaseinputpercentageparentcom')"
          />
        </van-form>
      </div>
      <div style="margin: 16px">
        <van-button
          block
          type="info"
          native-type="submit"
          color="#13b5b1"
          @click="onSubmit"
          >{{ $t("ok") }}</van-button
        >
      </div>
    </div>
    <van-action-sheet
      v-model="logins.show"
      :actions="logins.actions"
      @select="LoginOnSelect"
      :cancel-text="$t('cancel')"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { GetLoginList, SignalApplyAction } from "@/api";
    import { Dialog, Notify } from "vant";
export default {
  name: "applysignal",
  components: { NavBar },
  data() {
    return {
      login: "",
      logins: {
        show: false,
        actions: [],
      },
      sharelogin: "",
      signalname: "",
      singalcomm: "",
      refercomm: "",
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {},
  created() {},
  methods: {
    loadData() {
      GetLoginList({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            this.logins.actions.push({ id: element, name: element });
          });
        }
      });
    },
    LoginOnSelect(value) {
      this.logins.show = false;
      this.login = value.name;
    },
    onSubmit(value) {
      console.log(123);
      // debugger
        if (this.login == "") {
            Dialog.alert({
                message: this.$t("loginnoeempty"),
            }).then(() => {
                // on close
            });
        return ;
      }
        if (this.signalname == "") {
            Dialog.alert({
                message: this.$t("signalnamenotempty"),
            }).then(() => {
                // on close
            });
        return ;
      }
      if (
        parseFloat(this.singalcomm) > 100 ||
        parseFloat(this.singalcomm) < 0
      ) {
          Dialog.alert({
              message: this.$t("signalcommduringzerotoonehundred"),
          }).then(() => {
              // on close
          });
        return;
      }
        if (parseFloat(this.refercomm) > 100 || parseFloat(this.refercomm) < 0) {
            Dialog.alert({
                message: this.$t("parentcommduringzerotoonehundred"),
            }).then(() => {
                // on close
            });
        return ;
      }
        if (parseFloat(this.refercomm) + parseFloat(this.singalcomm) > 100) {
            Dialog.alert({
                message: this.$t("thesumcommmusetlessonehundred"),
            }).then(() => {
                // on close
            });
        return ;
      }
      SignalApplyAction({
        Login: this.login,
        ShareLogin: this.sharelogin,
        SignalName: this.signalname,
        SignalCom: parseFloat(this.singalcomm),
        ParentCom: parseFloat(this.refercomm),
      }).then((res) => {
        if (res.code == 200) {
            Notify({ type: 'success', message: this.$t(res.msg) });
          this.$router.push("/applyingsignal");
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        }
      });
    },
    onCancel() {},
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  background-color: #f7f8fa;
  padding: 10px;
  min-height: calc(100vh - 50px);
}
.box-content {
  border-radius: 8px;
  overflow: hidden;
}
.fa {
  color: #25a4bb;
}
.dropdown-menu-option-active-color {
  color: #25a4bb;
}
.van-button {
  border-radius: 8px;
}
</style>
