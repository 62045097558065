<template>
  <div>
    <navlangbar />
    <section
      style="padding: 30px 30px; display: flex; flex-flow: column nowrap"
    >
      <div style="font-size: 20px; font-weight: bold">
        {{ $t("retrievepwd") }}
      </div>
      <div>{{ $t("resetpwdlinksendtoyouremail") }}</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="email"
            clearable
            :placeholder="$t('pleaseinputemail')"
            style="background-color: WhiteSmoke"
            ref="email"
          >
          </van-field>
        </van-cell-group>
      </div>
      <div>
        <van-button
          color="#13b5b1"
          block
          :disabled="loginDisabled"
          @click="submit"
          >{{ $t("ok") }}</van-button
        >
      </div>
    </section>
    <Vcode
      :show="isShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import navlangbar from "@/components/NavlangBar";
import { retrievePassword } from "@/api";
import { isEmail } from "@/utils/commons";
    import { Toast, Notify, Dialog } from "vant";

export default {
  name: "RetrievePwd",
  components: { navlangbar, Vcode },
  mounted() {
    this.$store.commit("toggleTabbarShow", false);
    this.$refs.email.focus();
  },
  mounted() {
    //if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ) {
    //    window.location.href="http://trader.mt4ea.io";
    //}
  },
  watch: {
    email(newVal, oldVal) {
      if (newVal.length > 0 && isEmail(newVal)) {
        this.type = "info";
        this.loginDisabled = false;
      } else {
        this.type = "default";
        this.loginDisabled = true;
      }
    },
  },
  data() {
    return {
      loginDisabled: "disabled",
      type: "default",
      email: this.$store.state.email,
      disabled: "disabled",
      text: "",
      isShow: false,
    };
  },
  methods: {
    submit() {
      this.isShow = true;
    },
    success(msg) {
      this.isShow = false;
      let params = {
        email: this.email,
      };
      retrievePassword(params).then((res) => {
        if (res.code !== 200) {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.isShow = false;
    },
  },
  created() {
    if (this.email.length > 0) {
      this.type = "info";
      this.loginDisabled = false;
    } else {
      this.type = "default";
      this.loginDisabled = "disabled";
        Notify({ type: 'danger', message: this.$t('notrightformatemail') });
    }
  },
};
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}
.van-button {
  border-radius: 8px;
}
</style>
