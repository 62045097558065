<template>
  <div class="page page-profile">
    <van-nav-bar
      :title="$t('personalinformation')"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="container margin">
      <van-cell-group>
        <van-cell
          :title="$t('avater')"
          @click="show = true"
          center
          is-link
          class="page-profile-avatar"
        >
          <van-image
            class="avatar"
            :src="avatar"
            width=".8rem"
            height=".8rem"
            fit="cover"
          ></van-image>
        </van-cell>
        <van-action-sheet
          v-model="show"
          :cancel-text="$t('cancel')"
          close-on-click-action
          @cancel="onCancel"
        >
          <template #default>
            <div class="content-parent">
              <div class="content">
                <van-uploader
                  :max-size="3 * 1024 * 1024"
                  :max-count="1"
                  :after-read="afterRead"
                  capture="camera"
                  :upload-text="$t('app.photograph')"
                  upload-icon=""
                />
              </div>
              <div class="content">
                <van-uploader
                  :max-size="3 * 1024 * 1024"
                  :max-count="1"
                  :after-read="afterRead"
                  capture="file"
                  :upload-text="$t('app.photo')"
                  upload-icon=""
                />
              </div>
            </div>
          </template>
        </van-action-sheet>
      </van-cell-group>

      <van-cell-group class="margin">
        <van-cell :title="$t('username')" :value="userinfo.Name" is-link />
        <van-cell :title="$t('email')" :value="userinfo.Email" is-link />
        <van-cell
          :title="$t('areacode')"
          is-link
          :value="userinfo.PhoneCode"
          @click="showPhonecodePicker = true"
        />
        <van-cell
          :title="$t('phone')"
          is-link
          :value="ifEmpty(userinfo.Phone, 'unbound')"
          @click="setField('Phone', 'pleaseinputphone')"
        />
      </van-cell-group>

      <van-cell-group class="margin">
        <van-cell
          :title="$t('country')"
          is-link
          :value="userinfo.Country"
          @click="showCountryPicker = true"
        />
        <van-cell
          :title="$t('province')"
          is-link
          :style="{ display: ischina ? 'inline-flex' : 'none' }"
          :value="userinfo.Province"
          @click="showProvincePicker = true"
        />
        <van-cell
          :title="$t('city')"
          is-link
          :style="{ display: ischina ? 'inline-flex' : 'none' }"
          :value="userinfo.City"
          @click="showCityPicker = true"
        />
        <van-cell
          :title="$t('province')"
          is-link
          :style="{ display: ischina ? 'none' : 'inline-flex' }"
          :value="userinfo.Province"
          @click="setField('Province', 'pleaseselectprovince')"
        />
        <van-cell
          :title="$t('city')"
          is-link
          :style="{ display: ischina ? 'none' : 'inline-flex' }"
          :value="userinfo.City"
          @click="setField('City', 'pleaseselectcity')"
        />
        <van-cell
          :title="$t('address')"
          is-link
          :value="userinfo.Address"
          @click="setField('Address', 'pleaseinputaddress')"
        />
      </van-cell-group>

      <van-cell-group class="margin" style="display: none">
        <van-cell
          title="登录密码"
          value="修改密码"
          is-link
          to="/member/password"
        />
      </van-cell-group>
    </div>
    <van-popup v-model="showPhonecodePicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="this.phonecolumns"
        :confirm-button-text="$t('ok')"
        :cancel-button-text="$t('cancel')"
        @confirm="onPhonecodeConfirm"
        @cancel="showPhonecodePicker = false"
      />
    </van-popup>
    <van-popup v-model="showCountryPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="this.countries"
        :confirm-button-text="$t('ok')"
        :cancel-button-text="$t('cancel')"
        @confirm="onCountryConfirm"
        @cancel="showCountryPicker = false"
      />
    </van-popup>
    <van-popup v-model="showProvincePicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="this.provinces"
        :confirm-button-text="$t('ok')"
        :cancel-button-text="$t('cancel')"
        @confirm="onProvinceConfirm"
        @cancel="showProvincePicker = false"
      />
    </van-popup>
    <van-popup v-model="showCityPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="this.cities"
        :confirm-button-text="$t('ok')"
        :cancel-button-text="$t('cancel')"
        @confirm="onCityConfirm"
        @cancel="showCityPicker = false"
      />
    </van-popup>
    <van-popup v-model="showAvatarPicker" round>
      <van-uploader
        v-model="images"
        accept="image/*"
        :max-count="1"
        :max-size="20 * 1024 * 1024"
        :after-read="afterRead"
        :before-read="beforeRead"
      >
      </van-uploader>
    </van-popup>
    <van-dialog
      v-model="promptShow"
      :title="fieldTitle"
      show-cancel-button
      @confirm="updateField"
    >
      <van-cell-group>
        <van-field class="prompt-input" ref="fieldInput" v-model="fieldValue" />
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script>
import { sortByKey } from "@/assets/js/phone.code.js";
import { sortCountry } from "@/assets/js/country.js";
import { getProvince, getCities } from "@/assets/js/mapchina.js";
import { getinitInfo, EditPersonInfo, changeavater } from "@/api";
import http from "@/utils/http";
    import { Notify, Dialog } from 'vant';
export default {
  data() {
    return {
      avatar: this.$store.state.userinfo.avatar,
      userinfo: Object,
      phone: "",
      phoneCode: "",
      ischina: false,
      email: "",
      addressLoading: true,
      showAddressPicker: false,
      promptShow: false,
      fieldName: "",
      fieldTitle: "",
      fieldValue: "",
      phonecolumns: [],
      showPhonecodePicker: false,
      showCountryPicker: false,
      showProvincePicker: false,
      showCityPicker: false,
      showAvatarPicker: false,
      countries: [],
      provinces: [],
      images: [],
      cities: [],
      show: false,
    };
  },
  computed: {},
  mounted() {
    // this.$store.commit("toggleTabbarShow", false);
    this.localData();
  },
  destroyed() {},
  methods: {
    onCancel() {
      console.log(123);
    },
    onSelect(name, index) {
      localStorage.setItem("isSex", name);
      data.user.isSex = name;
      data.user.Sex = index;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let objEditInfo = {
        NickName: "",
        Sex: index,
        LastName: "",
        FirstName: "",
        Country: "",
        State: "",
        City: "",
        Address: "",
      };
      PUT_EDITINFO(objEditInfo);
    },
    localData() {
      this.phonecolumns = sortByKey();
      this.countries = sortCountry();
      this.provinces = getProvince();
      getinitInfo({}).then((res) => {
        if (res.code == 200) {
          this.userinfo = res.msg.user;
          if (this.userinfo.Country.toLowerCase() == "china") {
            this.ischina = true;
            this.cities = getCities(this.userinfo.Province);
          }
        } else {
          this.$router.push("/member");
        }
      });
    },
    onConfirm(value) {},
    canChange() {},
    onClickLeft() {
      this.$router.back();
    },
    ifEmpty(val, dft = "unbound") {
      return val ? val : this.$t(dft);
    },
    setField(field, title) {
      this.promptShow = true;
      this.fieldName = field;
      this.fieldTitle = this.$t(title);
      this.fieldValue = this.userinfo[field];
      this.$refs.fieldInput && this.$refs.fieldInput.focus();
    },
    updateField() {
      this.updateInfo(this.fieldName, this.fieldValue);
    },
    updateInfo(Name, Value) {
      let params = {
        fieldName: Name,
        fieldValue: Value,
      };
      let cur = this;
      EditPersonInfo(params).then((res) => {
        if (res.code == 200) {
          //  debugger
          switch (Name.toLowerCase()) {
            case "phonecode":
              this.showPhonecodePicker = false;
              this.userinfo.PhoneCode = Value;
              break;
            case "phone":
              this.promptShow = false;
              this.userinfo.Phone = Value;
              break;
            case "address":
              this.promptShow = false;
              this.userinfo.Address = Value;
              break;
            case "country":
              this.userinfo.Country = Value;
              break;
            case "province":
              if (this.ischina) {
                cur.showProvincePicker = false;
              } else {
                this.promptShow = false;
              }
              this.userinfo.Province = Value;
              break;
            case "city":
              if (this.ischina) {
                cur.showCityPicker = false;
              } else {
                this.promptShow = false;
              }
              this.userinfo.City = Value;
              break;
          }
            Notify({ type: 'success', message: this.$t("success") });
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
        this.$toast.clear();
      });
    },
    onPhonecodeConfirm(value) {
      this.updateInfo("Phonecode", value);
    },
    onCountryConfirm(value) {
      this.ischina = value.toLowerCase() == "china";
      this.showCountryPicker = false;
      this.updateInfo("Country", value);
    },
    onProvinceConfirm(value) {
      // debugger
      this.cities = getCities(value);
      this.userinfo.City = this.cities[0];
      this.updateInfo("Province", value);
      this.updateInfo("City", this.userinfo.City);
    },
    onCityConfirm(value) {
      this.updateInfo("City", value);
    },
    beforeRead(file) {
        if (!file.type.includes("image")) {
            Dialog.alert({
                message: this.$t("pleaseuploadpicture"),
            }).then(() => {
                // on close
            });

        return false;
      }
        if (file.size > 1024 * 1024 * 3) {
            Dialog.alert({
                message: this.$t("uploadFileRangeSize"),
            }).then(() => {
                // on close
            });
        return false;
      }
      return true;
    },
    afterRead(file) {
      this.$toast.loading(this.$t("uploading"));
      http.upload(http.imageurl, file).then((res) => {
        if (res.IsSuccess) {
          this.images = [];
          this.avatar = res.FilePath;
          this.updatedoc(res.FilePath);
          this.show = false;
        } else {
            this.images = [];
            Dialog.alert({
                message: res.Message,
            }).then(() => {
                // on close
            });
           
          this.$toast.clear();
        }
      });
    },
    updatedoc(url) {
      let params = {
        url: url,
      };
      changeavater(params).then((res) => {
        if (res.code == 200) {
          this.showAvatarPicker = false;
            Notify({ type: 'success', message: this.$t("success") });
          let userinfo = this.$store.state.userinfo;
          this.avatar = url;
          userinfo.avatar = url;
          this.$store.commit("updateActive", userinfo);
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        }
        setInterval(() => {
          this.$toast.clear();
        }, 2000);
      });
    },
  },
};
</script>
<style scoped>
.page {
  margin: 0;
}
.page-profile {
  background-color: #f7f8fa;
  min-height: 100vh;
}
.page-profile .prompt-input {
  border: 1px #ddd solid;
  margin: 20px;
  width: calc(100% - 40px);
}
.page-profile .submit-btn .van-button {
  width: 100%;
}
.content {
  text-align: center;
  font-size: 14px;
  padding: 10px;
}
.content div {
  width: 100%;
}
.content:nth-child(1) {
  border-bottom: 1px solid #eee;
}
.content >>> .van-uploader__upload {
  display: block;
  padding: 5px;
  width: 100%;
  height: 100%;
  background: #fff;
}
.content >>> .van-uploader__upload-text {
  font-size: 14px;
  margin-top: 0;
}
.van-image {
  border: 2px solid rgba(116, 113, 113, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
.page-profile-avatar {
  padding: 8px 16px;
}
.page-profile-avatar >>> .van-cell__value {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.margin {
  margin-top: 10px;
}
/* .margin >>> .van-cell__value {
  white-space: nowrap;
  overflow: initial;
} */
</style>