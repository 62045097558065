<template>
    <div class="payment">
        <!--<NavBar :title="title"></NavBar>-->
        <van-nav-bar :title="$t('Funds.deposit')"
                     left-arrow
                     @click-left="$router.go(-1)" />
        <section style="padding: 10px; display: flex; flex-flow: column nowrap">
            <van-cell-group :border="false">
                <van-cell title="OrderNo" :value="model.orderNo" />
                <van-cell title="Currency" :value="model.currency" />
                <van-cell title="Amount" :value="model.money" />
            </van-cell-group>
            <van-cell-group v-if="model.bankwireId <= 0" v-for="(item,i) in usdtBtc" :key="item.Id">
                <div v-if="model.currency == 'USDT'">
                    <van-cell :title="item.TypeName" :value="item.PayUrl" />
                </div>
                <div v-else-if="model.currency == 'BTC'">
                    <van-cell :title="item.TypeName" :value="item.PayUrl" />
                </div>
                <div v-else>
                </div>
            </van-cell-group>
            <van-cell-group v-if="model.bankwireId > 0">
                <van-cell title="Account" :value="bankWire.BankAccount" />
                <van-cell title="Swift Code" :value="bankWire.SwiftCode" />
                <van-cell title="Payee" :value="bankWire.RecipientName" />
                <van-cell title="Bank Name" :value="bankWire.BankName" />
                <van-cell title="Address" :value="bankWire.BankAddress" />
            </van-cell-group>

            <div style="text-align: center; margin-top: 16px">
                <van-uploader :before-read="beforeRead"
                              :after-read="afterRead"
                              :max-size="5 * 1024 * 1024"
                              accept="image/*">
                    <van-button icon="plus" color="#13b5b1">
                        {{
            $t("Funds.uploadCert")
                        }}
                    </van-button>
                </van-uploader>
                <!--<van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" />-->
            </div>
        </section>
    </div>
</template>

<script>
    import NavBar from "@/components/NavBar";
    import { Toast, Notify, Dialog } from "vant";
    import { getBankWireInfo, uploadCert } from "@/api";
    import http from "@/utils/http";
    export default {
        name: "Payment",
        components: { NavBar },
        data() {
            return {
                title: "Payment",
                model: {
                    bankwireId: 0,
                    money: 0,
                    moneyUsd: 0,
                    currency: "",
                    orderNo: "",
                    image: "",
                },
                bankWire: {
                    BankName: "",
                    BankAddress: "",
                    SwiftCode: "",
                    BankAccount: "",
                    RecipientName: "",
                },
                usdtBtc: []
            };
        },
        mounted() {
            this.$store.commit("toggleTabbarShow", true);
        },
        watch: {},
        created() {
            this.model.bankwireId = this.$route.query.bankwireId;
            this.model.money = this.$route.query.money;
            this.model.moneyUsd = this.$route.query.moneyUsd;
            this.model.currency = this.$route.query.currency;
            this.model.orderNo = this.$route.query.orderNo;
            this.getBank();
        },
        methods: {
            getBank() {
                let data = {
                    bankWireId: this.model.bankwireId,
                    currency: this.model.currency,
                };
                getBankWireInfo(data).then((res) => {
                    if (res.code != 200) {
                        Dialog.alert({
                            message: this.$t(res.msg),
                        }).then(() => {
                            // on close
                        });
                    } else {
                        var json = res.msg;
                        if (data.bankWireId == 0) {
                            this.usdtBtc.push(...json);
                            //console.log(this.usdtBtc);
                        }
                        else {
                            this.bankWire.BankName = json.BankName;
                            this.bankWire.BankAddress = json.BankAddress;
                            this.bankWire.SwiftCode = json.SwiftCode;
                            this.bankWire.BankAccount = json.BankAccount;
                            this.bankWire.RecipientName = json.RecipientName;
                        }
                    }
                });
            },
            afterRead(file) {
                http.upload(http.imageurl, file).then((res) => {
                    if (res.IsSuccess) {
                        this.model.image = res.FilePath;
                        this.updatedoc(this.model.orderNo, res.FilePath);
                    } else {
                        Notify({ type: 'danger', message: res.Message });                       
                    }
                });
            },
            beforeRead(file) {
                console.log(file);
                if (!file.type.includes("image")) {
                    Notify({ type: 'danger', message: this.$t("Funds.errorImageFormat") });
                  
                    return false;
                }
                if (file.size > 1024 * 1024 * 3) {
                    Notify({ type: 'danger', message: this.$t("uploadFileRangeSize") });
                    return false;
                }
                return true;
            },
            updatedoc(orderNo, url) {
                let params = {
                    orderNo: orderNo,
                    url: url,
                };
                uploadCert(params).then((res) => {
                    if (res.code == 200) {
                        const toast = Toast.loading({
                            type: "success",
                            duration: 0,
                            forbidClick: true,
                            message: "success",
                        });
                        let second = 3;
                        const timer = setInterval(() => {
                            second--;
                            if (second) {                               
                                toast.message = this.$t("success");
                            } else {
                                clearInterval(timer);
                                Toast.clear();
                                this.$router.push("/funds/history");
                            }
                        }, 1000);
                    } else {
                        Dialog.alert({
                            message: this.$t(res.msg),
                        }).then(() => {
                            // on close
                        });
                    }
                });
            },
            onOversize(file) {
                console.log(file);
                //Toast('文件大小不能超过 500kb');
            },
        },
    };
</script>

<style scoped>
    .payment {
        background-color: #f7f8fa;
    }

        .payment > > > .van-cell__value {
            white-space: nowrap;
            overflow: initial;
        }

    .van-cell-group {
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 10px;
    }

    .van-button {
        border-radius: 5px;
    }
</style>
