<template>
  <div class="transfer">
    <NavBar :title="$t('Funds.transfer')"></NavBar>
    <section style="padding: 10px; display: flex; flex-flow: column nowrap">
      <div>
        <van-form @submit="transfer">
          <van-cell-group :border="false">
            <van-field
              readonly
              v-model="model.login"
              clickable
              :label="$t('Funds.login')"
              :placeholder="$t('Funds.chooseLogin')"
              @click="showUserLoginListPopup"
            />
            <van-popup
              v-model="UserLoginList.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.login')"
                show-toolbar
                :columns="UserLoginList.columns"
                @confirm="onConfirmLogin"
                @cancel="onCancel"
                @change="onChange"
                :default-index="UserLoginList.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="model.freeBalance"
              center
              readonly
              clearable
              type="number"
              ref="freeBalance"
              :label="$t('Funds.freeBalance')"
              :placeholder="$t('Funds.freeBalance')"
            >
            </van-field>

            <van-field
              v-if="sameNameOnly == false"
              v-model="model.transLogin"
              center
              clearable
              type="number"
              ref="freeBalance"
              :label="$t('Funds.transLogin')"
              :placeholder="$t('Funds.pleaseInputTransLogin')"
              :rules="[{ required: true }]"
            >
            </van-field>
            <div v-else>
              <van-field
                readonly
                v-model="model.transLogin"
                clickable
                :label="$t('Funds.transLogin')"
                :placeholder="$t('Funds.pleaseInputTransLogin')"
                @click="showUserTransLoginListPopup"
              />
              <van-popup
                v-model="UserTransLoginList.show"
                bind:close="onClose"
                position="bottom"
              >
                <van-picker
                  :title="$t('Funds.login')"
                  show-toolbar
                  :columns="UserTransLoginList.columns"
                  @confirm="onConfirmTransLogin"
                  @cancel="onCancel"
                  @change="onChange"
                  :default-index="UserTransLoginList.defaultIndex"
                />
              </van-popup>
            </div>
            <van-field
              v-model="model.moneyUsd"
              center
              clearable
              ref="moneyUsd"
              type="number"
              :label="$t('Funds.transAmount')"
              :placeholder="$t('Funds.pleaseInputTransAmount')"
              :rules="[{ required: true }]"
            >
            </van-field>

            <van-field
              v-model="model.code"
              center
              clearable
              ref="code"
              :label="$t('verificationCode')"
              :rules="[
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message: '*' + $t('pleaseInputVerificationCode'),
                },
              ]"
            >
              <template #button>
                <!-- <van-button
                  color="#13b5b1"
                  size="small"
                  type="info"
                  :disabled="sendCodeDisabled"
                  @click="showVCode"
                  >{{ $t("sendVerificationCode") }}</van-button
                > -->
                <CountDown @click.native="showVCode"></CountDown>
              </template>
            </van-field>
          </van-cell-group>

          <van-cell-group class="button">
            <van-button
              color="#13b5b1"
              :type="type"
              native-type="submit"
              :loading="isLoading"
              :loading-text="$t('loading')"
              block
              :disabled="transferDisabled"
              >{{ $t("Funds.submitApplication") }}</van-button
            >
          </van-cell-group>
        </van-form>
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
    import { Toast, Notify, Dialog } from "vant";
import {
  getUserLogins,
  getFreeBalanceByLogin,
  addTransfer,
  sendVerifyCode,
  transSameNameOnly,
} from "@/api";

export default {
  name: "Transfer",
  components: { NavBar, Vcode,
    CountDown: () => import("@/components/CounDown.vue"),
  },
  data() {
    return {
      type: "default",
      transferDisabled: true,
      sendCodeDisabled: false,

      vcodeShow: false,
      userinfo: this.$store.state.userinfo,

      UserLoginList: {
        show: false,
        columns: [],
        defaultIndex: 0,
      },
      UserTransLoginList: {
        show: false,
        columns: [],
        defaultIndex: 0,
      },
      isLoading: false,
      model: {
        login: "",
        code: "",
        moneyUsd: "",
        freeBalance: 0,
        transLogin: "",
      },
      sameNameOnly: false,
    };
  },
  mounted() {
    // this.$store.commit("toggleTabbarShow", false);
  },
  watch: {
    "model.code"(newVal) {
      if (
        newVal.length == 8 &&
        parseFloat(this.model.moneyUsd) > 0 &&
        parseFloat(this.model.moneyUsd) <= parseFloat(this.model.freeBalance)
      ) {
        this.transferDisabled = false;
        this.type = "info";
      } else {
        this.transferDisabled = true;
        this.type = "default";
      }
    },
    "model.moneyUsd"(newVal) {
      if (
        parseFloat(newVal) > 0 &&
        parseFloat(newVal) <= parseFloat(this.model.freeBalance) &&
        this.model.code.length == 8
      ) {
        this.transferDisabled = false;
        this.type = "info";
      } else {
        this.transferDisabled = true;
        this.type = "default";
      }
    },
  },
  created() {
    this.loadSettings();
    this.loadLogins();
  },
  methods: {
    loadSettings() {
      let params = {};
      transSameNameOnly(params).then((res) => {
        if (res.code !== 200) {
          this.sameNameOnly = false;
        } else {
          if (parseInt(res.msg) === 1) {
            this.sameNameOnly = true;
          } else {
            this.sameNameOnly = false;
          }
        }
      });
    },
    onConfirmLogin(value, index) {
      //console.log(`当前值：${value.id}, 当前索引：${index}`);
      this.UserLoginList.show = false;
      this.model.login = value.id;
      this.loadTransLogins();
      this.getUserBalance();
    },
    onConfirmTransLogin(value, index) {
      this.UserTransLoginList.show = false;
      this.model.transLogin = value.id;
    },
    onConfirmCurrency(value, index) {
      this.model.currency = value.id;
    },

    onChange(picker, value, index) {},
    onCancel() {
      this.UserLoginList.show = false;
      this.UserTransLoginList.show = false;
    },
    showUserLoginListPopup() {
      this.UserLoginList.show = true;
    },
    showUserTransLoginListPopup() {
      this.UserTransLoginList.show = true;
    },
    showVCode() {
      this.sendCodeDisabled = true;
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 1,
      };
      sendVerifyCode(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    transfer() {
      this.isLoading = true;
      this.transferDisabled = true;
      let params = {
        login: this.model.login,
        code: this.model.code,
        moneyUsd: this.model.moneyUsd,
        transLogin: this.model.transLogin,
      };
      addTransfer(params).then((res) => {
        this.isLoading = false;
        this.transferDisabled = false;
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    loadLogins() {
      let params = { loginType: 2 };
      getUserLogins(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
          var json = res.msg;
          if (json.length > 0) {
            for (var i = 0; i < json.length; i++) {
              this.UserLoginList.columns.push({ id: json[i], text: json[i] });
            }
            this.model.login = json[0];
            this.getUserBalance();
            this.loadTransLogins();
          } else {
            this.$router.push("/member/newaccount?id=0");
          }
        }
      });
    },
    loadTransLogins() {
      this.UserTransLoginList.columns = [];
      var findIndex;
      this.UserTransLoginList.columns.push(...this.UserLoginList.columns);
      this.UserTransLoginList.columns.forEach((x) => {
        if (x.id == this.model.login) {
          findIndex = x;
        }
      });
      var index = this.UserTransLoginList.columns.indexOf(findIndex);
      this.UserTransLoginList.columns.splice(index, 1);
      if (this.model.login == this.model.transLogin) {
        this.model.transLogin = this.UserTransLoginList.columns[0].id;
      }
    },
    getUserBalance() {
      let data = { login: this.model.login };
      getFreeBalanceByLogin(data).then((res) => {
        if (res.code != 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
          this.model.freeBalance = res.msg.MarginFree.toFixed(2);
        }
      });
    },
  },
  beforeDestroy() {
    // this.$store.commit("toggleTabbarShow", true);
  },
};
</script>

<style scoped>
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transfer {
  background-color: #f7f8fa;
  height: 100vh;
}
.van-cell-group {
  border-radius: 8px;
  overflow: hidden;
}
.van-popup {
  margin-bottom: 0;
}
.button {
  margin-top: 20px;
}
</style>
