var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navlangbar'),_c('section',{staticStyle:{"padding":"30px 30px","display":"flex","flex-flow":"column nowrap"}},[_c('div',{staticStyle:{"font-size":"20px","font-weight":"bold","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("app.registernow"))+" ")]),_c('van-form',[_c('van-cell-group',[_c('van-field',{ref:"userEmail",staticStyle:{"background-color":"WhiteSmoke"},attrs:{"clearable":"","placeholder":_vm.$t('Register.userEmail'),"name":"pattern","rules":[
            {
              pattern:
                /^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
              message: _vm.$t('app.email'),
            } ]},model:{value:(_vm.model.userEmail),callback:function ($$v) {_vm.$set(_vm.model, "userEmail", $$v)},expression:"model.userEmail"}}),_c('van-field',{staticStyle:{"background-color":"WhiteSmoke"},attrs:{"clearable":"","placeholder":_vm.$t('Register.userName')},model:{value:(_vm.model.userName),callback:function ($$v) {_vm.$set(_vm.model, "userName", $$v)},expression:"model.userName"}}),_c('van-field',{ref:"pwd",staticStyle:{"background-color":"WhiteSmoke"},attrs:{"center":"","clearable":"","placeholder":_vm.$t('Register.userPassword'),"name":"pattern","rules":[
            {
              pattern: /^\S{6,}$/,
              message: '*' + _vm.$t('ChangePwd.pleaseInputPassword'),
            } ],"type":"password"},model:{value:(_vm.model.pwd),callback:function ($$v) {_vm.$set(_vm.model, "pwd", $$v)},expression:"model.pwd"}}),_c('van-field',{ref:"repwd",staticStyle:{"background-color":"WhiteSmoke"},attrs:{"center":"","clearable":"","placeholder":_vm.$t('Register.userPasswordAgain'),"name":"pattern","rules":[
            {
              pattern: /^\S{6,}$/,
              message: '*' + _vm.$t('ChangePwd.pleaseInputPassword'),
            } ],"type":"password"},model:{value:(_vm.model.repwd),callback:function ($$v) {_vm.$set(_vm.model, "repwd", $$v)},expression:"model.repwd"}}),_c('van-field',{ref:"agent",staticStyle:{"background-color":"WhiteSmoke"},attrs:{"clearable":"","placeholder":_vm.$t('Register.userAgent'),"type":"number"},model:{value:(_vm.model.agent),callback:function ($$v) {_vm.$set(_vm.model, "agent", $$v)},expression:"model.agent"}})],1)],1),_c('div',[_c('van-button',{attrs:{"color":"#13b5b1","block":"","disabled":_vm.loginDisabled},on:{"click":_vm.showVCode}},[_vm._v(_vm._s(_vm.$t("Register.register")))])],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(_vm.$t("Register.hasAccount"))+" "),_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("Register.loginNow")))])],1)])],1),_c('Vcode',{attrs:{"show":_vm.vcodeShow,"successText":_vm.$t('app.sildesuccesstext'),"failText":_vm.$t('app.sildefailtext'),"sliderText":_vm.$t('app.slidertext')},on:{"success":_vm.success,"close":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }