<template>
  <div class="withdrawals">
    <NavBar :title="$t('Funds.withdrawals')"></NavBar>
    <section style="padding: 10px; display: flex; flex-flow: column nowrap">
      <div>
        <van-form @submit="withdrawals">
          <van-cell-group :border="false">
            <van-field
              readonly
              v-model="model.login"
              clickable
              :label="$t('Funds.login')"
              :placeholder="$t('Funds.chooseLogin')"
              @click="showUserLoginListPopup"
            />
            <van-popup
              v-model="UserLoginList.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.login')"
                show-toolbar
                :columns="UserLoginList.columns"
                @confirm="onConfirmLogin"
                @cancel="onCancel"
                @change="onChange"
                :default-index="UserLoginList.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="model.freeBalance"
              center
              readonly
              clearable
              type="number"
              ref="freeBalance"
              :label="$t('Funds.freeBalance')"
              :placeholder="$t('Funds.freeBalance')"
            >
            </van-field>

            <van-field
              readonly
              v-model="model.currency"
              clickable
              :label="$t('Funds.currency')"
              :placeholder="$t('Funds.pleaseChooseCurrency')"
              @click="showCurrencyPopup"
              :rules="[{ required: true }]"
            />
            <van-popup
              v-model="Currency.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.currency')"
                show-toolbar
                :columns="Currency.columns"
                @confirm="onConfirmCurrency"
                @cancel="onCancel"
                @change="onChange"
                :default-index="Currency.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="model.moneyUsd"
              center
              clearable
              ref="moneyUsd"
              type="number"
              :label="$t('Funds.withdrawalsMoneyUsd')"
              :placeholder="$t('Funds.pleaseInputWithdrawalsMoneyUsd')"
              :rules="[{ required: true }]"
            >
            </van-field>

            <van-field
              readonly
              v-model="model.bankInfoText"
              clickable
              :label="$t('Funds.bankInfo')"
              :placeholder="$t('Funds.pleaseChooseBankInfo')"
              @click="showBankinfoPopup"
              :rules="[{ required: true }]"
            />
            <van-popup
              v-model="Bankinfo.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.bankInfo')"
                show-toolbar
                :columns="Bankinfo.columns"
                @confirm="onConfirmBankinfo"
                @cancel="onCancel"
                @change="onChange"
                :default-index="Bankinfo.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="model.code"
              center
              clearable
              ref="code"
              :label="$t('verificationCode')"
              name="pattern"
              :rules="[
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message: '*' + $t('pleaseInputVerificationCode'),
                },
              ]"
            >
              <template #button>
                <CountDown @click.native="showVCode"></CountDown>
                <!-- <van-button
                  color="#13b5b1"
                  size="small"
                  type="info"
                  :disabled="sendCodeDisabled"
                  @click="showVCode"
                  >{{ $t("sendVerificationCode") }}</van-button
                > -->
              </template>
            </van-field>
          </van-cell-group>
         

          <div class="button">
            <van-button
              color="#13b5b1"
              :type="type"
              native-type="submit"
              :loading="isLoading"
              :loading-text="$t('loading')"
              block
              :disabled="withdrawalsDisabled"
              >{{ $t("Funds.submitApplication") }}</van-button
            >
          </div>
        </van-form>
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
import { Toast, Dialog } from "vant";
import { Notify } from "vant";
import {
  getUserLogins,
  getFreeBalanceByLogin,
  getBank,
  addWithdrawals,
  sendVerifyCode,
  GetBankByType,
} from "@/api";
export default {
  name: "Withdrawals",
  components: { 
    NavBar, 
    Vcode,
    CountDown: () => import("@/components/CounDown"),
  },
  data() {
    return {
      type: "default",
      withdrawalsDisabled: true,
      sendCodeDisabled: false,

      vcodeShow: false,
      userinfo: this.$store.state.userinfo,
      UserLoginList: {
        show: false,
        columns: [],
        defaultIndex: 0,
      },
      Currency: {
        show: false,
          columns: [
              { id: "USD", text: "USD" },
          { id: "CNY", text: "CNY" },
          { id: "USDT", text: "USDT" },
          { id: "BTC", text: "BTC" },
          
        ],
        defaultIndex: 0,
      },
      Bankinfo: {
        show: false,
        columns: [],
        columBtc: [],
        columWire: [],
        columUsdt: [],
        defaultIndex: 0,
      },
      isLoading: false,
      model: {
        login: "",
        code: "",
        moneyUsd: "",
        freeBalance: 0,
        currency: "",
        bankInfo: "",
        bankInfoText: "",
        rate: 0,
      },
    };
  },
  mounted() {
    // this.$store.commit("toggleTabbarShow", false);
  },
  watch: {
    "model.code"(newVal) {
      if (
        newVal.length == 8 &&
        parseFloat(this.model.moneyUsd) > 0 &&
        parseFloat(this.model.moneyUsd) <= parseFloat(this.model.freeBalance)
      ) {
        this.withdrawalsDisabled = false;
        this.type = "info";
      } else {
        this.withdrawalsDisabled = true;
        this.type = "default";
      }
    },
    // "model.moneyUsd"(newVal) {
    //   if (
    //     parseFloat(newVal) > 0 &&
    //     parseFloat(newVal) <= parseFloat(this.model.freeBalance) &&
    //     this.model.code.length == 8
    //   ) {
    //     this.withdrawalsDisabled = false;
    //     this.type = "info";
    //   } else {
    //     this.withdrawalsDisabled = true;
    //     this.type = "default";
    //   }
    // },
  },
  created() {
    this.loadLogins();
    this.getUserBank();
  },
  methods: {
    onConfirmLogin(value, index) {
      //console.log(`当前值：${value.id}, 当前索引：${index}`);
      this.UserLoginList.show = false;
      this.model.login = value.id;
      this.getUserBalance();
    },
    onConfirmCurrency(value, index) {
      this.Bankinfo.columns = [];
      this.Currency.show = false;
      this.model.currency = value.id;
      switch (value.id) {
        case "USDT":
          this.Bankinfo.columns = this.Bankinfo.columUsdt;
          break;
        case "BTC":
          this.Bankinfo.columns = this.Bankinfo.columBtc;
          break;
        default:
          this.Bankinfo.columns = this.Bankinfo.columWire;
          break;
      }
      this.model.bankInfoText = "";
    },
    onConfirmBankinfo(value, index) {
      this.Bankinfo.show = false;
      this.model.bankInfo = value.id;
      this.model.bankInfoText = value.text;
      console.log(this.model.bankInfo);
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.UserLoginList.show = false;
      this.Currency.show = false;
      this.Bankinfo.show = false;
    },
    showUserLoginListPopup() {
      this.UserLoginList.show = true;
    },
    showCurrencyPopup() {
      this.Currency.show = true;
    },
    showBankinfoPopup() {
      this.Bankinfo.show = true;
    },
    showVCode() {
      this.sendCodeDisabled = true;
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 0,
      };
      sendVerifyCode(params).then((res) => {
        if (res.code !== 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    withdrawals() {
      this.isLoading = true;
      this.withdrawalsDisabled = true;
      let params = {
        login: this.model.login,
        currency: this.model.currency,
        code: this.model.code,
        moneyUsd: this.model.moneyUsd,
        bankId: this.model.bankInfo,
      };
      addWithdrawals(params).then((res) => {
        this.isLoading = false;
        this.withdrawalsDisabled = false;
        if (res.code !== 200) {
            if (res.code === 202) {
                var mssg = this.$t(res.msg.msg) + "$" + res.msg.amount;
                Dialog.alert({
                    message: mssg,
                }).then(() => {
                    // on close
                });
          } else {
                Notify({ type: 'danger', message: this.$t(res.msg) });
          }
        } else {
            Notify({ type: 'success', message: this.$t(res.msg) });
          this.$router.push({
            path: "/funds/history",
          });
        }
      });
    },
    loadLogins() {
      let params = { loginType: 2 };
      getUserLogins(params).then((res) => {
          if (res.code !== 200) {
              Dialog.alert({
                  message: this.$t(res.msg),
              }).then(() => {
                  // on close
              });
        } else {
          var json = res.msg;
          if (json.length > 0) {
            for (var i = 0; i < json.length; i++) {
              this.UserLoginList.columns.push({ id: json[i], text: json[i] });
            }
            this.model.login = json[0];
            this.getUserBalance();
          } else {
            this.$router.push("/member/newaccount?id=0");
          }
        }
      });
    },
    getUserBalance() {
      let data = { login: this.model.login };
      getFreeBalanceByLogin(data).then((res) => {
        if (res.code != 200) {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        } else {
          this.model.freeBalance = res.msg.MarginFree.toFixed(2);
        }
      });
    },
    getUserBank() {
      let data = { type: 0 };
      getBank(data).then((res) => {
        if (res.code != 200) {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                this.$router.push("/funds/bankinfo");
            });
          
        } else {
          var json = res.msg;
          console.log(json);
          json.BankWire.forEach((item) => {
            let cardId = item.CardID;
            this.Bankinfo.columWire.push({
              id: item.BankId,
              text:
                item.BankName +
                " " +
                item.BranchName +
                "****" +
                cardId.substr(cardId.length - 4),
            });
          });

          json.Btc.forEach((item) => {
            let cardId = item.CardID;
            this.Bankinfo.columBtc.push({
              id: item.BankId,
              text:
                item.BankName +
                " " +
                item.BranchName +
                "****" +
                cardId.substr(cardId.length - 4),
            });
          });

          json.Usdt.forEach((item) => {
            let cardId = item.CardID;
            this.Bankinfo.columUsdt.push({
              id: item.BankId,
              text:
                item.BankName +
                " " +
                item.BranchName +
                "****" +
                cardId.substr(cardId.length - 4),
            });
          });

          // this.model.bankInfo = json[0].BankId;
        }
      });
    },
  },
  // beforeDestroy() {
  //   this.$store.commit("toggleTabbarShow", true);
  // },
};
</script>

<style scoped>
.withdrawals {
  background-color: #f7f8fa;
  min-height: 100vh;
}
.van-cell-group {
  border-radius: 8px;
  overflow: hidden;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.van-popup {
  margin-bottom: 0;
}
.van-button {
  border-radius: 5px;
}
.button {
  margin-top: 10px;
}
</style>
