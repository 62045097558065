<template>
  <div class="bankinfo">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="$router.go(-1)"
      :border="false"
      fixed
      :placeholder="true"
    />
    <div class="bankinfo-content">
      <div class="bankinfo-head"></div>
      <div class="bankinfo-ellipse"></div>
      <div class="bankinfo-my-card">
        <div class="card">
          <div class="my-card">
            <!-- <van-cell
              title="CNY"
              :value="`${$t('Mt4ApiTrade.showAll')}`"
              :border="false"
              is-link
              to="/member/bankinfo/card"
            /> -->
            <div class="bankinfo-my-card-card">
              <van-cell
                v-for="item in 1"
                :key="item"
                :title="$t('Funds.bankCard')"
                center
                class="china-bank china-USD"
                is-link
                to="/member/bankinfo/card/1"
              >
                <template #icon>
                  <van-image
                    round
                    fit="cover"
                    width=".8rem"
                    height=".8rem"
                    :src="USD"
                  />
                </template>
              </van-cell>
            </div>
          </div>
          <div class="my-card">
            <!-- <van-cell
              title="USDT"
              :value="`${$t('Mt4ApiTrade.showAll')}`"
              :border="false"
              is-link
              to="/member/bankinfo/card"
            /> -->
            <div class="bankinfo-my-card-card">
              <van-cell
                v-for="item in 1"
                :key="item"
                title="USDT"
                center
                class="china-bank china-USDT"
                is-link
                to="/member/bankinfo/card/3"
              >
                <template #icon>
                  <van-image
                    round
                    fit="cover"
                    width=".8rem"
                    height=".8rem"
                    :src="USDT"
                  />
                </template>
              </van-cell>
            </div>
          </div>
          <div class="my-card">
            <!-- <van-cell
              title="BTC"
              :value="`${$t('Mt4ApiTrade.showAll')}`"
              :border="false"
              is-link
              to="/member/bankinfo/card"
            /> -->
            <div class="bankinfo-my-card-card">
              <van-cell
                v-for="item in 1"
                :key="item"
                title="BTC"
                center
                class="china-bank china-BTC"
                is-link
                to="/member/bankinfo/card/2"
              >
                <template #icon>
                  <van-image
                    round
                    fit="cover"
                    width=".8rem"
                    height=".8rem"
                    :src="BTC"
                  />
                </template>
              </van-cell>
            </div>
          </div>

          <div class="recomend">
            <van-cell :title="$t('app.recomment')" :border="false" />
            <div class="recoment-bank">
              <van-cell
                title="BTC"
                :value="$t('app.binding')"
                is-link
                to="/funds/bankadd?type=2"
              >
                <template #icon>
                  <van-icon
                    class="iconfont"
                    class-prefix="icon"
                    name="bitebi"
                    color="orange"
                  />
                </template>
              </van-cell>
              <van-cell
                title="USDT"
                :value="$t('app.binding')"
                icon="location-o"
                is-link
                to="/funds/bankadd?type=3"
              >
                <template #icon>
                  <van-icon
                    class="iconfont"
                    class-prefix="icon"
                    name="USDT"
                    color="#13b5b1"
                  />
                </template>
              </van-cell>
              <div class="bind">
                <van-button @click="onAdd" type="primary" block color="#13b5b1"
                  ><span class="add"
                    ><van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="add"
                      color="#13b5b1" /></span
                  >{{ $t("app.bind") }}</van-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#13b5b1">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <img v-lazy="image" />
          </van-swipe-item>
        </van-swipe> -->
      </div>
    </div>
    <div class="van-safe-area-bottom"></div>
    <!-- <van-action-sheet
      v-model="CardAdd.show"
      :actions="CardAdd.actions"
      @select="cardAddOnSelect"
      :cancel-text="$t('Menu.cancel')"
      @cancel="onCancel"
    /> -->
    <!-- <section
      style="padding: 30px 30px; display: flex; flex-flow: column nowrap"
    >
      <div>
        <van-cell-group>
          <van-row v-for="(item, i) in BankCard" v-bind:key="i" class="cards">
            <van-col span="20">
              <div class="card_title">
                {{ item.BankName
                }}<span
                  v-if="item.Type == 3"
                  style="padding-left: 20px; font-size: 14px"
                  >{{ item.BranchName }}</span
                >
              </div>

              <div class="card_text">{{ item.UserName }}</div>
              <div class="cardsubtitle">{{ item.CardID }}</div>
            </van-col>
            <van-col span="4">
              <div class="card_edit">
                <van-icon name="edit" />{{ $t("Funds.edit") }}
              </div>
            </van-col>
          </van-row>

          <van-contact-card
            type="add"
            @click="onAdd"
            :add-text="$t('Funds.walletAddress')"
          />
          <van-action-sheet
            v-model="CardAdd.show"
            :actions="CardAdd.actions"
            @select="cardAddOnSelect"
            :cancel-text="$t('Menu.cancel')"
            @cancel="onCancel"
          />
        </van-cell-group>
      </div>
    </section> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { getBank } from "@/api";
export default {
  name: "BankInfo",
  components: {},
  filters: {
    getBankCode(val) {
      if (!val) return "";
      val = val.toString().replaceAll(" ", "");
      return val.substring(val.length - 4);
    },
  },
  data() {
    return {
      USD: require('@/assets/image/all-bank.png'),
      USDT: require('@/assets/image/crm/USDT.svg'),
      BTC: require('@/assets/image/crm/BTC.svg'),
      title: "",
      BankCard: [],
      CardAdd: {
        show: false,
        actions: [
          { id: 1, name: this.$t("Funds.bankCard") },
          { id: 2, name: "BTC" },
          { id: 3, name: "USDT" },
        ],
      },
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
  },
  watch: {},
  created() {
    // this.getUserBank();
    this.title = this.$t("Funds.bankCardUsdtBtc");
  },
  methods: {
    onAdd() {
      let url = "/funds/bankadd?type=" + 1;
      this.$router.push({
        path: url,
      });
    },
    funds() {
      this.Funds.show = true;
    },
    cardAddOnSelect(item) {
      this.CardAdd.show = false;
      let url = "/funds/bankadd?type=" + item.id;
      this.$router.push({
        path: url,
      });
    },
    onCancel() {},
    onEdit(id, type) {
      let url = "/funds/bankedit?bid=" + id + "&type=" + type;
      this.$router.push({
        path: url,
      });
    },
    getUserBank() {
      let data = { type: 0 };
      getBank(data).then((res) => {
        if (res.code != 200) {
          //this.$router.push('/funds/bankinfo');
        } else {
          var json = res.msg;
          console.log(json);
          for (var i = 0; i < json.length; i++) {
            this.BankCard.push({
              BankAddress: json[i].BankAddress,
              BankId: json[i].BankId,
              BankName: json[i].BankName,
              BranchName: json[i].BranchName,
              UserName: json[i].UserName,
              CardID: json[i].CardID,
              Type: json[i].Type,
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.van-loading span.van-loading__spinner {
  margin-top: 14px !important;
}
.my-card .van-icon {
  color: #fff;
}
.van-contact-card--add .van-contact-card__value,
.van-popup {
  margin-bottom: 0 !important;
}

.cards {
  padding: 15px 10px 0 15px;
  border-radius: 4px;
  border: 1px dashed gray;
}
.my-card {
  padding-top: 14px;
}
.card_title {
  font-weight: 300;
  font-size: larger;
}

.card_text {
  overflow-x: hidden;
}

.cardsubtitle {
  overflow-x: hidden;
  margin-bottom: 0;
}

.card_edit {
  vertical-align: middle;
  text-align: center;
}
.van-nav-bar {
  background-color: #13b5b1;
  color: #fff;
}
.van-nav-bar >>> .van-icon {
  color: #fff !important;
}
.van-nav-bar >>> .van-nav-bar__title {
  color: #fff;
}
.bankinfo {
  background-color: #f2f3f5;
  overflow-y: auto;
}
.bankinfo-content {
  position: relative;
}
.bankinfo-head {
  width: 100%;
  height: 140px;
  background: #13b5b1;
  position: absolute;
}
.bankinfo-ellipse {
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  height: 50px;
  margin: auto;
  background-color: #13b5b1;
  border-radius: 50% / 100% 100% 0 0;
  transform: rotateX(180deg);
}
.bankinfo-my-card {
  padding: 0.25rem;
}
.bankinfo-my-card-card {
  position: relative;
  padding: 0 10px;
  background-color: #fff;
}
.bankinfo-my-card-card .van-cell {
  background-color: #eee;
  border-radius: 8px;
  padding: 16px;
}
.bankinfo-my-card-card .van-cell ~ .van-cell {
  transform: translateY(-9px);
  background-image: url("../../assets/image/visa-bank.png");
  background-position: -20px -101px;
  /* background-image: linear-gradient(to right, pink 10%, rgb(179, 110, 150) 50%); */
}
.bankinfo-my-card-card .van-cell .van-image {
  margin-right: 0.2rem;
  padding: 8px;
  background-color: #fff;
}
.card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 12px;
  background-color: #fff;
}
.recoment-bank {
  padding: 0 10px;
  background-color: #fff;
}
.recoment-bank .van-cell {
  background-color: #f7f8fa;
  border-radius: 5px;
  padding: 16px;
}
.recoment-bank .van-cell ~ .van-cell {
  margin-top: 10px;
}
.bind {
  margin-top: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.add {
  margin-right: 8px;
}
.add .icon-add {
  color: #fff !important;
}
.my-swipe {
  border-radius: 8px;
  margin-top: 15px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  height: 80px;
  text-align: center;
  overflow: hidden;
}
.my-swipe .van-swipe-item img {
  width: 100%;
}
.my-swipe >>> .van-swipe__indicator {
  width: 6px;
  height: 6px;
  background-color: #eee;
  border-radius: 30%;
  transition: opacity 0.2s, background-color 0.2s;
}
.my-swipe >>> .van-swipe__indicator.van-swipe__indicator--active {
  width: 16px;
}
.bankinfo-my-card-card >>> .van-cell__label {
  color: #fff;
  white-space: nowrap;
}
.bankinfo-my-card-card >>> .van-cell__value {
  color: #fff;
}
.bankinfo-my-card-card >>> .van-cell__title {
  color: #fff;
}
.bankeddit-value-start {
  position: absolute;
  top: 3px;
  right: 34px;
}
.china-USD {
  background-image: linear-gradient(to right, #4fb0e0 10%, #1485ee 50%);
  color: rgb(244, 245, 247);
}
.china-USDT {
  background-image: linear-gradient(to right, #6fcf3e 10%, #1fb668 50%);
  color: rgb(244, 245, 247);
}
.china-BTC {
  background-image: linear-gradient(to right, #ffc300 10%, #c87d2f 50%);
  color: rgb(244, 245, 247);
}
.icon-bianji {
  margin-left: 2px;
}
.icon-bitebi,
.icon-USDT {
  margin-right: 12px;
}
/* .county-bank {
  background-image: linear-gradient(to right, skyblue 10%, #489dd4 50%) !important;
}
.another-bank {
  background-image: linear-gradient(to right, skyblue 10%, #489dd4 50%) !important;
} */
</style>
