var map = [
    {
        "Key": 100000,
        "Value": [
            {
                "Key": 110000,
                "Value": "北京市"
            },
            {
                "Key": 120000,
                "Value": "天津市"
            },
            {
                "Key": 130000,
                "Value": "河北省"
            },
            {
                "Key": 140000,
                "Value": "山西省"
            },
            {
                "Key": 150000,
                "Value": "内蒙古自治区"
            },
            {
                "Key": 210000,
                "Value": "辽宁省"
            },
            {
                "Key": 220000,
                "Value": "吉林省"
            },
            {
                "Key": 230000,
                "Value": "黑龙江省"
            },
            {
                "Key": 310000,
                "Value": "上海市"
            },
            {
                "Key": 320000,
                "Value": "江苏省"
            },
            {
                "Key": 330000,
                "Value": "浙江省"
            },
            {
                "Key": 340000,
                "Value": "安徽省"
            },
            {
                "Key": 350000,
                "Value": "福建省"
            },
            {
                "Key": 360000,
                "Value": "江西省"
            },
            {
                "Key": 370000,
                "Value": "山东省"
            },
            {
                "Key": 410000,
                "Value": "河南省"
            },
            {
                "Key": 420000,
                "Value": "湖北省"
            },
            {
                "Key": 430000,
                "Value": "湖南省"
            },
            {
                "Key": 440000,
                "Value": "广东省"
            },
            {
                "Key": 450000,
                "Value": "广西壮族自治区"
            },
            {
                "Key": 460000,
                "Value": "海南省"
            },
            {
                "Key": 500000,
                "Value": "重庆市"
            },
            {
                "Key": 510000,
                "Value": "四川省"
            },
            {
                "Key": 520000,
                "Value": "贵州省"
            },
            {
                "Key": 530000,
                "Value": "云南省"
            },
            {
                "Key": 540000,
                "Value": "西藏自治区"
            },
            {
                "Key": 610000,
                "Value": "陕西省"
            },
            {
                "Key": 620000,
                "Value": "甘肃省"
            },
            {
                "Key": 630000,
                "Value": "青海省"
            },
            {
                "Key": 640000,
                "Value": "宁夏回族自治区"
            },
            {
                "Key": 650000,
                "Value": "新疆维吾尔自治区"
            },
            {
                "Key": 710000,
                "Value": "台湾省"
            },
            {
                "Key": 810000,
                "Value": "香港特别行政区"
            },
            {
                "Key": 820000,
                "Value": "澳门特别行政区"
            }
        ]
    },
    {
        "Key": 110100,
        "Value": [
            {
                "Key": 110101,
                "Value": "东城区"
            },
            {
                "Key": 110102,
                "Value": "西城区"
            },
            {
                "Key": 110105,
                "Value": "朝阳区"
            },
            {
                "Key": 110106,
                "Value": "丰台区"
            },
            {
                "Key": 110107,
                "Value": "石景山区"
            },
            {
                "Key": 110108,
                "Value": "海淀区"
            },
            {
                "Key": 110109,
                "Value": "门头沟区"
            },
            {
                "Key": 110111,
                "Value": "房山区"
            },
            {
                "Key": 110112,
                "Value": "通州区"
            },
            {
                "Key": 110113,
                "Value": "顺义区"
            },
            {
                "Key": 110114,
                "Value": "昌平区"
            },
            {
                "Key": 110115,
                "Value": "大兴区"
            },
            {
                "Key": 110116,
                "Value": "怀柔区"
            },
            {
                "Key": 110117,
                "Value": "平谷区"
            },
            {
                "Key": 110118,
                "Value": "密云区"
            },
            {
                "Key": 110119,
                "Value": "延庆区"
            }
        ]
    },
    {
        "Key": 120100,
        "Value": [
            {
                "Key": 120101,
                "Value": "和平区"
            },
            {
                "Key": 120102,
                "Value": "河东区"
            },
            {
                "Key": 120103,
                "Value": "河西区"
            },
            {
                "Key": 120104,
                "Value": "南开区"
            },
            {
                "Key": 120105,
                "Value": "河北区"
            },
            {
                "Key": 120106,
                "Value": "红桥区"
            },
            {
                "Key": 120110,
                "Value": "东丽区"
            },
            {
                "Key": 120111,
                "Value": "西青区"
            },
            {
                "Key": 120112,
                "Value": "津南区"
            },
            {
                "Key": 120113,
                "Value": "北辰区"
            },
            {
                "Key": 120114,
                "Value": "武清区"
            },
            {
                "Key": 120115,
                "Value": "宝坻区"
            },
            {
                "Key": 120116,
                "Value": "滨海新区"
            },
            {
                "Key": 120117,
                "Value": "宁河区"
            },
            {
                "Key": 120118,
                "Value": "静海区"
            },
            {
                "Key": 120119,
                "Value": "蓟州区"
            }
        ]
    },
    {
        "Key": 130000,
        "Value": [
            {
                "Key": 130100,
                "Value": "石家庄市"
            },
            {
                "Key": 130200,
                "Value": "唐山市"
            },
            {
                "Key": 130300,
                "Value": "秦皇岛市"
            },
            {
                "Key": 130400,
                "Value": "邯郸市"
            },
            {
                "Key": 130500,
                "Value": "邢台市"
            },
            {
                "Key": 130600,
                "Value": "保定市"
            },
            {
                "Key": 130700,
                "Value": "张家口市"
            },
            {
                "Key": 130800,
                "Value": "承德市"
            },
            {
                "Key": 130900,
                "Value": "沧州市"
            },
            {
                "Key": 131000,
                "Value": "廊坊市"
            },
            {
                "Key": 131100,
                "Value": "衡水市"
            },
            {
                "Key": 139000,
                "Value": "省直辖县级行政区划"
            }
        ]
    },
    {
        "Key": 130100,
        "Value": [
            {
                "Key": 130102,
                "Value": "长安区"
            },
            {
                "Key": 130104,
                "Value": "桥西区"
            },
            {
                "Key": 130105,
                "Value": "新华区"
            },
            {
                "Key": 130107,
                "Value": "井陉矿区"
            },
            {
                "Key": 130108,
                "Value": "裕华区"
            },
            {
                "Key": 130109,
                "Value": "藁城区"
            },
            {
                "Key": 130110,
                "Value": "鹿泉区"
            },
            {
                "Key": 130111,
                "Value": "栾城区"
            },
            {
                "Key": 130121,
                "Value": "井陉县"
            },
            {
                "Key": 130123,
                "Value": "正定县"
            },
            {
                "Key": 130125,
                "Value": "行唐县"
            },
            {
                "Key": 130126,
                "Value": "灵寿县"
            },
            {
                "Key": 130127,
                "Value": "高邑县"
            },
            {
                "Key": 130128,
                "Value": "深泽县"
            },
            {
                "Key": 130129,
                "Value": "赞皇县"
            },
            {
                "Key": 130130,
                "Value": "无极县"
            },
            {
                "Key": 130131,
                "Value": "平山县"
            },
            {
                "Key": 130132,
                "Value": "元氏县"
            },
            {
                "Key": 130133,
                "Value": "赵县"
            },
            {
                "Key": 130183,
                "Value": "晋州市"
            },
            {
                "Key": 130184,
                "Value": "新乐市"
            }
        ]
    },
    {
        "Key": 130200,
        "Value": [
            {
                "Key": 130202,
                "Value": "路南区"
            },
            {
                "Key": 130203,
                "Value": "路北区"
            },
            {
                "Key": 130204,
                "Value": "古冶区"
            },
            {
                "Key": 130205,
                "Value": "开平区"
            },
            {
                "Key": 130207,
                "Value": "丰南区"
            },
            {
                "Key": 130208,
                "Value": "丰润区"
            },
            {
                "Key": 130209,
                "Value": "曹妃甸区"
            },
            {
                "Key": 130223,
                "Value": "滦县"
            },
            {
                "Key": 130224,
                "Value": "滦南县"
            },
            {
                "Key": 130225,
                "Value": "乐亭县"
            },
            {
                "Key": 130227,
                "Value": "迁西县"
            },
            {
                "Key": 130229,
                "Value": "玉田县"
            },
            {
                "Key": 130281,
                "Value": "遵化市"
            },
            {
                "Key": 130283,
                "Value": "迁安市"
            }
        ]
    },
    {
        "Key": 130300,
        "Value": [
            {
                "Key": 130302,
                "Value": "海港区"
            },
            {
                "Key": 130303,
                "Value": "山海关区"
            },
            {
                "Key": 130304,
                "Value": "北戴河区"
            },
            {
                "Key": 130306,
                "Value": "抚宁区"
            },
            {
                "Key": 130321,
                "Value": "青龙满族自治县"
            },
            {
                "Key": 130322,
                "Value": "昌黎县"
            },
            {
                "Key": 130324,
                "Value": "卢龙县"
            }
        ]
    },
    {
        "Key": 130400,
        "Value": [
            {
                "Key": 130402,
                "Value": "邯山区"
            },
            {
                "Key": 130403,
                "Value": "丛台区"
            },
            {
                "Key": 130404,
                "Value": "复兴区"
            },
            {
                "Key": 130406,
                "Value": "峰峰矿区"
            },
            {
                "Key": 130421,
                "Value": "邯郸县"
            },
            {
                "Key": 130423,
                "Value": "临漳县"
            },
            {
                "Key": 130424,
                "Value": "成安县"
            },
            {
                "Key": 130425,
                "Value": "大名县"
            },
            {
                "Key": 130426,
                "Value": "涉县"
            },
            {
                "Key": 130427,
                "Value": "磁县"
            },
            {
                "Key": 130428,
                "Value": "肥乡县"
            },
            {
                "Key": 130429,
                "Value": "永年县"
            },
            {
                "Key": 130430,
                "Value": "邱县"
            },
            {
                "Key": 130431,
                "Value": "鸡泽县"
            },
            {
                "Key": 130432,
                "Value": "广平县"
            },
            {
                "Key": 130433,
                "Value": "馆陶县"
            },
            {
                "Key": 130434,
                "Value": "魏县"
            },
            {
                "Key": 130435,
                "Value": "曲周县"
            },
            {
                "Key": 130481,
                "Value": "武安市"
            }
        ]
    },
    {
        "Key": 130500,
        "Value": [
            {
                "Key": 130502,
                "Value": "桥东区"
            },
            {
                "Key": 130503,
                "Value": "桥西区"
            },
            {
                "Key": 130521,
                "Value": "邢台县"
            },
            {
                "Key": 130522,
                "Value": "临城县"
            },
            {
                "Key": 130523,
                "Value": "内丘县"
            },
            {
                "Key": 130524,
                "Value": "柏乡县"
            },
            {
                "Key": 130525,
                "Value": "隆尧县"
            },
            {
                "Key": 130526,
                "Value": "任县"
            },
            {
                "Key": 130527,
                "Value": "南和县"
            },
            {
                "Key": 130528,
                "Value": "宁晋县"
            },
            {
                "Key": 130529,
                "Value": "巨鹿县"
            },
            {
                "Key": 130530,
                "Value": "新河县"
            },
            {
                "Key": 130531,
                "Value": "广宗县"
            },
            {
                "Key": 130532,
                "Value": "平乡县"
            },
            {
                "Key": 130533,
                "Value": "威县"
            },
            {
                "Key": 130534,
                "Value": "清河县"
            },
            {
                "Key": 130535,
                "Value": "临西县"
            },
            {
                "Key": 130581,
                "Value": "南宫市"
            },
            {
                "Key": 130582,
                "Value": "沙河市"
            }
        ]
    },
    {
        "Key": 130600,
        "Value": [
            {
                "Key": 130602,
                "Value": "竞秀区"
            },
            {
                "Key": 130606,
                "Value": "莲池区"
            },
            {
                "Key": 130607,
                "Value": "满城区"
            },
            {
                "Key": 130608,
                "Value": "清苑区"
            },
            {
                "Key": 130609,
                "Value": "徐水区"
            },
            {
                "Key": 130623,
                "Value": "涞水县"
            },
            {
                "Key": 130624,
                "Value": "阜平县"
            },
            {
                "Key": 130626,
                "Value": "定兴县"
            },
            {
                "Key": 130627,
                "Value": "唐县"
            },
            {
                "Key": 130628,
                "Value": "高阳县"
            },
            {
                "Key": 130629,
                "Value": "容城县"
            },
            {
                "Key": 130630,
                "Value": "涞源县"
            },
            {
                "Key": 130631,
                "Value": "望都县"
            },
            {
                "Key": 130632,
                "Value": "安新县"
            },
            {
                "Key": 130633,
                "Value": "易县"
            },
            {
                "Key": 130634,
                "Value": "曲阳县"
            },
            {
                "Key": 130635,
                "Value": "蠡县"
            },
            {
                "Key": 130636,
                "Value": "顺平县"
            },
            {
                "Key": 130637,
                "Value": "博野县"
            },
            {
                "Key": 130638,
                "Value": "雄县"
            },
            {
                "Key": 130681,
                "Value": "涿州市"
            },
            {
                "Key": 130683,
                "Value": "安国市"
            },
            {
                "Key": 130684,
                "Value": "高碑店市"
            }
        ]
    },
    {
        "Key": 130700,
        "Value": [
            {
                "Key": 130702,
                "Value": "桥东区"
            },
            {
                "Key": 130703,
                "Value": "桥西区"
            },
            {
                "Key": 130705,
                "Value": "宣化区"
            },
            {
                "Key": 130706,
                "Value": "下花园区"
            },
            {
                "Key": 130708,
                "Value": "万全区"
            },
            {
                "Key": 130709,
                "Value": "崇礼区"
            },
            {
                "Key": 130722,
                "Value": "张北县"
            },
            {
                "Key": 130723,
                "Value": "康保县"
            },
            {
                "Key": 130724,
                "Value": "沽源县"
            },
            {
                "Key": 130725,
                "Value": "尚义县"
            },
            {
                "Key": 130726,
                "Value": "蔚县"
            },
            {
                "Key": 130727,
                "Value": "阳原县"
            },
            {
                "Key": 130728,
                "Value": "怀安县"
            },
            {
                "Key": 130730,
                "Value": "怀来县"
            },
            {
                "Key": 130731,
                "Value": "涿鹿县"
            },
            {
                "Key": 130732,
                "Value": "赤城县"
            }
        ]
    },
    {
        "Key": 130800,
        "Value": [
            {
                "Key": 130802,
                "Value": "双桥区"
            },
            {
                "Key": 130803,
                "Value": "双滦区"
            },
            {
                "Key": 130804,
                "Value": "鹰手营子矿区"
            },
            {
                "Key": 130821,
                "Value": "承德县"
            },
            {
                "Key": 130822,
                "Value": "兴隆县"
            },
            {
                "Key": 130823,
                "Value": "平泉县"
            },
            {
                "Key": 130824,
                "Value": "滦平县"
            },
            {
                "Key": 130825,
                "Value": "隆化县"
            },
            {
                "Key": 130826,
                "Value": "丰宁满族自治县"
            },
            {
                "Key": 130827,
                "Value": "宽城满族自治县"
            },
            {
                "Key": 130828,
                "Value": "围场满族蒙古族自治县"
            }
        ]
    },
    {
        "Key": 130900,
        "Value": [
            {
                "Key": 130902,
                "Value": "新华区"
            },
            {
                "Key": 130903,
                "Value": "运河区"
            },
            {
                "Key": 130921,
                "Value": "沧县"
            },
            {
                "Key": 130922,
                "Value": "青县"
            },
            {
                "Key": 130923,
                "Value": "东光县"
            },
            {
                "Key": 130924,
                "Value": "海兴县"
            },
            {
                "Key": 130925,
                "Value": "盐山县"
            },
            {
                "Key": 130926,
                "Value": "肃宁县"
            },
            {
                "Key": 130927,
                "Value": "南皮县"
            },
            {
                "Key": 130928,
                "Value": "吴桥县"
            },
            {
                "Key": 130929,
                "Value": "献县"
            },
            {
                "Key": 130930,
                "Value": "孟村回族自治县"
            },
            {
                "Key": 130981,
                "Value": "泊头市"
            },
            {
                "Key": 130982,
                "Value": "任丘市"
            },
            {
                "Key": 130983,
                "Value": "黄骅市"
            },
            {
                "Key": 130984,
                "Value": "河间市"
            }
        ]
    },
    {
        "Key": 131000,
        "Value": [
            {
                "Key": 131002,
                "Value": "安次区"
            },
            {
                "Key": 131003,
                "Value": "广阳区"
            },
            {
                "Key": 131022,
                "Value": "固安县"
            },
            {
                "Key": 131023,
                "Value": "永清县"
            },
            {
                "Key": 131024,
                "Value": "香河县"
            },
            {
                "Key": 131025,
                "Value": "大城县"
            },
            {
                "Key": 131026,
                "Value": "文安县"
            },
            {
                "Key": 131028,
                "Value": "大厂回族自治县"
            },
            {
                "Key": 131081,
                "Value": "霸州市"
            },
            {
                "Key": 131082,
                "Value": "三河市"
            }
        ]
    },
    {
        "Key": 131100,
        "Value": [
            {
                "Key": 131102,
                "Value": "桃城区"
            },
            {
                "Key": 131103,
                "Value": "冀州区"
            },
            {
                "Key": 131121,
                "Value": "枣强县"
            },
            {
                "Key": 131122,
                "Value": "武邑县"
            },
            {
                "Key": 131123,
                "Value": "武强县"
            },
            {
                "Key": 131124,
                "Value": "饶阳县"
            },
            {
                "Key": 131125,
                "Value": "安平县"
            },
            {
                "Key": 131126,
                "Value": "故城县"
            },
            {
                "Key": 131127,
                "Value": "景县"
            },
            {
                "Key": 131128,
                "Value": "阜城县"
            },
            {
                "Key": 131182,
                "Value": "深州市"
            }
        ]
    },
    {
        "Key": 139000,
        "Value": [
            {
                "Key": 139001,
                "Value": "定州市"
            },
            {
                "Key": 139002,
                "Value": "辛集市"
            }
        ]
    },
    {
        "Key": 140000,
        "Value": [
            {
                "Key": 140100,
                "Value": "太原市"
            },
            {
                "Key": 140200,
                "Value": "大同市"
            },
            {
                "Key": 140300,
                "Value": "阳泉市"
            },
            {
                "Key": 140400,
                "Value": "长治市"
            },
            {
                "Key": 140500,
                "Value": "晋城市"
            },
            {
                "Key": 140600,
                "Value": "朔州市"
            },
            {
                "Key": 140700,
                "Value": "晋中市"
            },
            {
                "Key": 140800,
                "Value": "运城市"
            },
            {
                "Key": 140900,
                "Value": "忻州市"
            },
            {
                "Key": 141000,
                "Value": "临汾市"
            },
            {
                "Key": 141100,
                "Value": "吕梁市"
            }
        ]
    },
    {
        "Key": 140100,
        "Value": [
            {
                "Key": 140105,
                "Value": "小店区"
            },
            {
                "Key": 140106,
                "Value": "迎泽区"
            },
            {
                "Key": 140107,
                "Value": "杏花岭区"
            },
            {
                "Key": 140108,
                "Value": "尖草坪区"
            },
            {
                "Key": 140109,
                "Value": "万柏林区"
            },
            {
                "Key": 140110,
                "Value": "晋源区"
            },
            {
                "Key": 140121,
                "Value": "清徐县"
            },
            {
                "Key": 140122,
                "Value": "阳曲县"
            },
            {
                "Key": 140123,
                "Value": "娄烦县"
            },
            {
                "Key": 140181,
                "Value": "古交市"
            }
        ]
    },
    {
        "Key": 140200,
        "Value": [
            {
                "Key": 140202,
                "Value": "城区"
            },
            {
                "Key": 140203,
                "Value": "矿区"
            },
            {
                "Key": 140211,
                "Value": "南郊区"
            },
            {
                "Key": 140212,
                "Value": "新荣区"
            },
            {
                "Key": 140221,
                "Value": "阳高县"
            },
            {
                "Key": 140222,
                "Value": "天镇县"
            },
            {
                "Key": 140223,
                "Value": "广灵县"
            },
            {
                "Key": 140224,
                "Value": "灵丘县"
            },
            {
                "Key": 140225,
                "Value": "浑源县"
            },
            {
                "Key": 140226,
                "Value": "左云县"
            },
            {
                "Key": 140227,
                "Value": "大同县"
            }
        ]
    },
    {
        "Key": 140300,
        "Value": [
            {
                "Key": 140302,
                "Value": "城区"
            },
            {
                "Key": 140303,
                "Value": "矿区"
            },
            {
                "Key": 140311,
                "Value": "郊区"
            },
            {
                "Key": 140321,
                "Value": "平定县"
            },
            {
                "Key": 140322,
                "Value": "盂县"
            }
        ]
    },
    {
        "Key": 140400,
        "Value": [
            {
                "Key": 140402,
                "Value": "城区"
            },
            {
                "Key": 140411,
                "Value": "郊区"
            },
            {
                "Key": 140421,
                "Value": "长治县"
            },
            {
                "Key": 140423,
                "Value": "襄垣县"
            },
            {
                "Key": 140424,
                "Value": "屯留县"
            },
            {
                "Key": 140425,
                "Value": "平顺县"
            },
            {
                "Key": 140426,
                "Value": "黎城县"
            },
            {
                "Key": 140427,
                "Value": "壶关县"
            },
            {
                "Key": 140428,
                "Value": "长子县"
            },
            {
                "Key": 140429,
                "Value": "武乡县"
            },
            {
                "Key": 140430,
                "Value": "沁县"
            },
            {
                "Key": 140431,
                "Value": "沁源县"
            },
            {
                "Key": 140481,
                "Value": "潞城市"
            }
        ]
    },
    {
        "Key": 140500,
        "Value": [
            {
                "Key": 140502,
                "Value": "城区"
            },
            {
                "Key": 140521,
                "Value": "沁水县"
            },
            {
                "Key": 140522,
                "Value": "阳城县"
            },
            {
                "Key": 140524,
                "Value": "陵川县"
            },
            {
                "Key": 140525,
                "Value": "泽州县"
            },
            {
                "Key": 140581,
                "Value": "高平市"
            }
        ]
    },
    {
        "Key": 140600,
        "Value": [
            {
                "Key": 140602,
                "Value": "朔城区"
            },
            {
                "Key": 140603,
                "Value": "平鲁区"
            },
            {
                "Key": 140621,
                "Value": "山阴县"
            },
            {
                "Key": 140622,
                "Value": "应县"
            },
            {
                "Key": 140623,
                "Value": "右玉县"
            },
            {
                "Key": 140624,
                "Value": "怀仁县"
            }
        ]
    },
    {
        "Key": 140700,
        "Value": [
            {
                "Key": 140702,
                "Value": "榆次区"
            },
            {
                "Key": 140721,
                "Value": "榆社县"
            },
            {
                "Key": 140722,
                "Value": "左权县"
            },
            {
                "Key": 140723,
                "Value": "和顺县"
            },
            {
                "Key": 140724,
                "Value": "昔阳县"
            },
            {
                "Key": 140725,
                "Value": "寿阳县"
            },
            {
                "Key": 140726,
                "Value": "太谷县"
            },
            {
                "Key": 140727,
                "Value": "祁县"
            },
            {
                "Key": 140728,
                "Value": "平遥县"
            },
            {
                "Key": 140729,
                "Value": "灵石县"
            },
            {
                "Key": 140781,
                "Value": "介休市"
            }
        ]
    },
    {
        "Key": 140800,
        "Value": [
            {
                "Key": 140802,
                "Value": "盐湖区"
            },
            {
                "Key": 140821,
                "Value": "临猗县"
            },
            {
                "Key": 140822,
                "Value": "万荣县"
            },
            {
                "Key": 140823,
                "Value": "闻喜县"
            },
            {
                "Key": 140824,
                "Value": "稷山县"
            },
            {
                "Key": 140825,
                "Value": "新绛县"
            },
            {
                "Key": 140826,
                "Value": "绛县"
            },
            {
                "Key": 140827,
                "Value": "垣曲县"
            },
            {
                "Key": 140828,
                "Value": "夏县"
            },
            {
                "Key": 140829,
                "Value": "平陆县"
            },
            {
                "Key": 140830,
                "Value": "芮城县"
            },
            {
                "Key": 140881,
                "Value": "永济市"
            },
            {
                "Key": 140882,
                "Value": "河津市"
            }
        ]
    },
    {
        "Key": 140900,
        "Value": [
            {
                "Key": 140902,
                "Value": "忻府区"
            },
            {
                "Key": 140921,
                "Value": "定襄县"
            },
            {
                "Key": 140922,
                "Value": "五台县"
            },
            {
                "Key": 140923,
                "Value": "代县"
            },
            {
                "Key": 140924,
                "Value": "繁峙县"
            },
            {
                "Key": 140925,
                "Value": "宁武县"
            },
            {
                "Key": 140926,
                "Value": "静乐县"
            },
            {
                "Key": 140927,
                "Value": "神池县"
            },
            {
                "Key": 140928,
                "Value": "五寨县"
            },
            {
                "Key": 140929,
                "Value": "岢岚县"
            },
            {
                "Key": 140930,
                "Value": "河曲县"
            },
            {
                "Key": 140931,
                "Value": "保德县"
            },
            {
                "Key": 140932,
                "Value": "偏关县"
            },
            {
                "Key": 140981,
                "Value": "原平市"
            }
        ]
    },
    {
        "Key": 141000,
        "Value": [
            {
                "Key": 141002,
                "Value": "尧都区"
            },
            {
                "Key": 141021,
                "Value": "曲沃县"
            },
            {
                "Key": 141022,
                "Value": "翼城县"
            },
            {
                "Key": 141023,
                "Value": "襄汾县"
            },
            {
                "Key": 141024,
                "Value": "洪洞县"
            },
            {
                "Key": 141025,
                "Value": "古县"
            },
            {
                "Key": 141026,
                "Value": "安泽县"
            },
            {
                "Key": 141027,
                "Value": "浮山县"
            },
            {
                "Key": 141028,
                "Value": "吉县"
            },
            {
                "Key": 141029,
                "Value": "乡宁县"
            },
            {
                "Key": 141030,
                "Value": "大宁县"
            },
            {
                "Key": 141031,
                "Value": "隰县"
            },
            {
                "Key": 141032,
                "Value": "永和县"
            },
            {
                "Key": 141033,
                "Value": "蒲县"
            },
            {
                "Key": 141034,
                "Value": "汾西县"
            },
            {
                "Key": 141081,
                "Value": "侯马市"
            },
            {
                "Key": 141082,
                "Value": "霍州市"
            }
        ]
    },
    {
        "Key": 141100,
        "Value": [
            {
                "Key": 141102,
                "Value": "离石区"
            },
            {
                "Key": 141121,
                "Value": "文水县"
            },
            {
                "Key": 141122,
                "Value": "交城县"
            },
            {
                "Key": 141123,
                "Value": "兴县"
            },
            {
                "Key": 141124,
                "Value": "临县"
            },
            {
                "Key": 141125,
                "Value": "柳林县"
            },
            {
                "Key": 141126,
                "Value": "石楼县"
            },
            {
                "Key": 141127,
                "Value": "岚县"
            },
            {
                "Key": 141128,
                "Value": "方山县"
            },
            {
                "Key": 141129,
                "Value": "中阳县"
            },
            {
                "Key": 141130,
                "Value": "交口县"
            },
            {
                "Key": 141181,
                "Value": "孝义市"
            },
            {
                "Key": 141182,
                "Value": "汾阳市"
            }
        ]
    },
    {
        "Key": 150000,
        "Value": [
            {
                "Key": 150100,
                "Value": "呼和浩特市"
            },
            {
                "Key": 150200,
                "Value": "包头市"
            },
            {
                "Key": 150300,
                "Value": "乌海市"
            },
            {
                "Key": 150400,
                "Value": "赤峰市"
            },
            {
                "Key": 150500,
                "Value": "通辽市"
            },
            {
                "Key": 150600,
                "Value": "鄂尔多斯市"
            },
            {
                "Key": 150700,
                "Value": "呼伦贝尔市"
            },
            {
                "Key": 150800,
                "Value": "巴彦淖尔市"
            },
            {
                "Key": 150900,
                "Value": "乌兰察布市"
            },
            {
                "Key": 152200,
                "Value": "兴安盟"
            },
            {
                "Key": 152500,
                "Value": "锡林郭勒盟"
            },
            {
                "Key": 152900,
                "Value": "阿拉善盟"
            }
        ]
    },
    {
        "Key": 150100,
        "Value": [
            {
                "Key": 150102,
                "Value": "新城区"
            },
            {
                "Key": 150103,
                "Value": "回民区"
            },
            {
                "Key": 150104,
                "Value": "玉泉区"
            },
            {
                "Key": 150105,
                "Value": "赛罕区"
            },
            {
                "Key": 150121,
                "Value": "土默特左旗"
            },
            {
                "Key": 150122,
                "Value": "托克托县"
            },
            {
                "Key": 150123,
                "Value": "和林格尔县"
            },
            {
                "Key": 150124,
                "Value": "清水河县"
            },
            {
                "Key": 150125,
                "Value": "武川县"
            }
        ]
    },
    {
        "Key": 150200,
        "Value": [
            {
                "Key": 150202,
                "Value": "东河区"
            },
            {
                "Key": 150203,
                "Value": "昆都仑区"
            },
            {
                "Key": 150204,
                "Value": "青山区"
            },
            {
                "Key": 150205,
                "Value": "石拐区"
            },
            {
                "Key": 150206,
                "Value": "白云鄂博矿区"
            },
            {
                "Key": 150207,
                "Value": "九原区"
            },
            {
                "Key": 150221,
                "Value": "土默特右旗"
            },
            {
                "Key": 150222,
                "Value": "固阳县"
            },
            {
                "Key": 150223,
                "Value": "达尔罕茂明安联合旗"
            }
        ]
    },
    {
        "Key": 150300,
        "Value": [
            {
                "Key": 150302,
                "Value": "海勃湾区"
            },
            {
                "Key": 150303,
                "Value": "海南区"
            },
            {
                "Key": 150304,
                "Value": "乌达区"
            }
        ]
    },
    {
        "Key": 150400,
        "Value": [
            {
                "Key": 150402,
                "Value": "红山区"
            },
            {
                "Key": 150403,
                "Value": "元宝山区"
            },
            {
                "Key": 150404,
                "Value": "松山区"
            },
            {
                "Key": 150421,
                "Value": "阿鲁科尔沁旗"
            },
            {
                "Key": 150422,
                "Value": "巴林左旗"
            },
            {
                "Key": 150423,
                "Value": "巴林右旗"
            },
            {
                "Key": 150424,
                "Value": "林西县"
            },
            {
                "Key": 150425,
                "Value": "克什克腾旗"
            },
            {
                "Key": 150426,
                "Value": "翁牛特旗"
            },
            {
                "Key": 150428,
                "Value": "喀喇沁旗"
            },
            {
                "Key": 150429,
                "Value": "宁城县"
            },
            {
                "Key": 150430,
                "Value": "敖汉旗"
            }
        ]
    },
    {
        "Key": 150500,
        "Value": [
            {
                "Key": 150502,
                "Value": "科尔沁区"
            },
            {
                "Key": 150521,
                "Value": "科尔沁左翼中旗"
            },
            {
                "Key": 150522,
                "Value": "科尔沁左翼后旗"
            },
            {
                "Key": 150523,
                "Value": "开鲁县"
            },
            {
                "Key": 150524,
                "Value": "库伦旗"
            },
            {
                "Key": 150525,
                "Value": "奈曼旗"
            },
            {
                "Key": 150526,
                "Value": "扎鲁特旗"
            },
            {
                "Key": 150581,
                "Value": "霍林郭勒市"
            }
        ]
    },
    {
        "Key": 150600,
        "Value": [
            {
                "Key": 150602,
                "Value": "东胜区"
            },
            {
                "Key": 150603,
                "Value": "康巴什区"
            },
            {
                "Key": 150621,
                "Value": "达拉特旗"
            },
            {
                "Key": 150622,
                "Value": "准格尔旗"
            },
            {
                "Key": 150623,
                "Value": "鄂托克前旗"
            },
            {
                "Key": 150624,
                "Value": "鄂托克旗"
            },
            {
                "Key": 150625,
                "Value": "杭锦旗"
            },
            {
                "Key": 150626,
                "Value": "乌审旗"
            },
            {
                "Key": 150627,
                "Value": "伊金霍洛旗"
            }
        ]
    },
    {
        "Key": 150700,
        "Value": [
            {
                "Key": 150702,
                "Value": "海拉尔区"
            },
            {
                "Key": 150703,
                "Value": "扎赉诺尔区"
            },
            {
                "Key": 150721,
                "Value": "阿荣旗"
            },
            {
                "Key": 150722,
                "Value": "莫力达瓦达斡尔族自治旗"
            },
            {
                "Key": 150723,
                "Value": "鄂伦春自治旗"
            },
            {
                "Key": 150724,
                "Value": "鄂温克族自治旗"
            },
            {
                "Key": 150725,
                "Value": "陈巴尔虎旗"
            },
            {
                "Key": 150726,
                "Value": "新巴尔虎左旗"
            },
            {
                "Key": 150727,
                "Value": "新巴尔虎右旗"
            },
            {
                "Key": 150781,
                "Value": "满洲里市"
            },
            {
                "Key": 150782,
                "Value": "牙克石市"
            },
            {
                "Key": 150783,
                "Value": "扎兰屯市"
            },
            {
                "Key": 150784,
                "Value": "额尔古纳市"
            },
            {
                "Key": 150785,
                "Value": "根河市"
            }
        ]
    },
    {
        "Key": 150800,
        "Value": [
            {
                "Key": 150802,
                "Value": "临河区"
            },
            {
                "Key": 150821,
                "Value": "五原县"
            },
            {
                "Key": 150822,
                "Value": "磴口县"
            },
            {
                "Key": 150823,
                "Value": "乌拉特前旗"
            },
            {
                "Key": 150824,
                "Value": "乌拉特中旗"
            },
            {
                "Key": 150825,
                "Value": "乌拉特后旗"
            },
            {
                "Key": 150826,
                "Value": "杭锦后旗"
            }
        ]
    },
    {
        "Key": 150900,
        "Value": [
            {
                "Key": 150902,
                "Value": "集宁区"
            },
            {
                "Key": 150921,
                "Value": "卓资县"
            },
            {
                "Key": 150922,
                "Value": "化德县"
            },
            {
                "Key": 150923,
                "Value": "商都县"
            },
            {
                "Key": 150924,
                "Value": "兴和县"
            },
            {
                "Key": 150925,
                "Value": "凉城县"
            },
            {
                "Key": 150926,
                "Value": "察哈尔右翼前旗"
            },
            {
                "Key": 150927,
                "Value": "察哈尔右翼中旗"
            },
            {
                "Key": 150928,
                "Value": "察哈尔右翼后旗"
            },
            {
                "Key": 150929,
                "Value": "四子王旗"
            },
            {
                "Key": 150981,
                "Value": "丰镇市"
            }
        ]
    },
    {
        "Key": 152200,
        "Value": [
            {
                "Key": 152201,
                "Value": "乌兰浩特市"
            },
            {
                "Key": 152202,
                "Value": "阿尔山市"
            },
            {
                "Key": 152221,
                "Value": "科尔沁右翼前旗"
            },
            {
                "Key": 152222,
                "Value": "科尔沁右翼中旗"
            },
            {
                "Key": 152223,
                "Value": "扎赉特旗"
            },
            {
                "Key": 152224,
                "Value": "突泉县"
            }
        ]
    },
    {
        "Key": 152500,
        "Value": [
            {
                "Key": 152501,
                "Value": "二连浩特市"
            },
            {
                "Key": 152502,
                "Value": "锡林浩特市"
            },
            {
                "Key": 152522,
                "Value": "阿巴嘎旗"
            },
            {
                "Key": 152523,
                "Value": "苏尼特左旗"
            },
            {
                "Key": 152524,
                "Value": "苏尼特右旗"
            },
            {
                "Key": 152525,
                "Value": "东乌珠穆沁旗"
            },
            {
                "Key": 152526,
                "Value": "西乌珠穆沁旗"
            },
            {
                "Key": 152527,
                "Value": "太仆寺旗"
            },
            {
                "Key": 152528,
                "Value": "镶黄旗"
            },
            {
                "Key": 152529,
                "Value": "正镶白旗"
            },
            {
                "Key": 152530,
                "Value": "正蓝旗"
            },
            {
                "Key": 152531,
                "Value": "多伦县"
            }
        ]
    },
    {
        "Key": 152900,
        "Value": [
            {
                "Key": 152921,
                "Value": "阿拉善左旗"
            },
            {
                "Key": 152922,
                "Value": "阿拉善右旗"
            },
            {
                "Key": 152923,
                "Value": "额济纳旗"
            }
        ]
    },
    {
        "Key": 210000,
        "Value": [
            {
                "Key": 210100,
                "Value": "沈阳市"
            },
            {
                "Key": 210200,
                "Value": "大连市"
            },
            {
                "Key": 210300,
                "Value": "鞍山市"
            },
            {
                "Key": 210400,
                "Value": "抚顺市"
            },
            {
                "Key": 210500,
                "Value": "本溪市"
            },
            {
                "Key": 210600,
                "Value": "丹东市"
            },
            {
                "Key": 210700,
                "Value": "锦州市"
            },
            {
                "Key": 210800,
                "Value": "营口市"
            },
            {
                "Key": 210900,
                "Value": "阜新市"
            },
            {
                "Key": 211000,
                "Value": "辽阳市"
            },
            {
                "Key": 211100,
                "Value": "盘锦市"
            },
            {
                "Key": 211200,
                "Value": "铁岭市"
            },
            {
                "Key": 211300,
                "Value": "朝阳市"
            },
            {
                "Key": 211400,
                "Value": "葫芦岛市"
            }
        ]
    },
    {
        "Key": 210100,
        "Value": [
            {
                "Key": 210102,
                "Value": "和平区"
            },
            {
                "Key": 210103,
                "Value": "沈河区"
            },
            {
                "Key": 210104,
                "Value": "大东区"
            },
            {
                "Key": 210105,
                "Value": "皇姑区"
            },
            {
                "Key": 210106,
                "Value": "铁西区"
            },
            {
                "Key": 210111,
                "Value": "苏家屯区"
            },
            {
                "Key": 210112,
                "Value": "浑南区"
            },
            {
                "Key": 210113,
                "Value": "沈北新区"
            },
            {
                "Key": 210114,
                "Value": "于洪区"
            },
            {
                "Key": 210115,
                "Value": "辽中区"
            },
            {
                "Key": 210123,
                "Value": "康平县"
            },
            {
                "Key": 210124,
                "Value": "法库县"
            },
            {
                "Key": 210181,
                "Value": "新民市"
            }
        ]
    },
    {
        "Key": 210200,
        "Value": [
            {
                "Key": 210202,
                "Value": "中山区"
            },
            {
                "Key": 210203,
                "Value": "西岗区"
            },
            {
                "Key": 210204,
                "Value": "沙河口区"
            },
            {
                "Key": 210211,
                "Value": "甘井子区"
            },
            {
                "Key": 210212,
                "Value": "旅顺口区"
            },
            {
                "Key": 210213,
                "Value": "金州区"
            },
            {
                "Key": 210214,
                "Value": "普兰店区"
            },
            {
                "Key": 210224,
                "Value": "长海县"
            },
            {
                "Key": 210281,
                "Value": "瓦房店市"
            },
            {
                "Key": 210283,
                "Value": "庄河市"
            }
        ]
    },
    {
        "Key": 210300,
        "Value": [
            {
                "Key": 210302,
                "Value": "铁东区"
            },
            {
                "Key": 210303,
                "Value": "铁西区"
            },
            {
                "Key": 210304,
                "Value": "立山区"
            },
            {
                "Key": 210311,
                "Value": "千山区"
            },
            {
                "Key": 210321,
                "Value": "台安县"
            },
            {
                "Key": 210323,
                "Value": "岫岩满族自治县"
            },
            {
                "Key": 210381,
                "Value": "海城市"
            }
        ]
    },
    {
        "Key": 210400,
        "Value": [
            {
                "Key": 210402,
                "Value": "新抚区"
            },
            {
                "Key": 210403,
                "Value": "东洲区"
            },
            {
                "Key": 210404,
                "Value": "望花区"
            },
            {
                "Key": 210411,
                "Value": "顺城区"
            },
            {
                "Key": 210421,
                "Value": "抚顺县"
            },
            {
                "Key": 210422,
                "Value": "新宾满族自治县"
            },
            {
                "Key": 210423,
                "Value": "清原满族自治县"
            }
        ]
    },
    {
        "Key": 210500,
        "Value": [
            {
                "Key": 210502,
                "Value": "平山区"
            },
            {
                "Key": 210503,
                "Value": "溪湖区"
            },
            {
                "Key": 210504,
                "Value": "明山区"
            },
            {
                "Key": 210505,
                "Value": "南芬区"
            },
            {
                "Key": 210521,
                "Value": "本溪满族自治县"
            },
            {
                "Key": 210522,
                "Value": "桓仁满族自治县"
            }
        ]
    },
    {
        "Key": 210600,
        "Value": [
            {
                "Key": 210602,
                "Value": "元宝区"
            },
            {
                "Key": 210603,
                "Value": "振兴区"
            },
            {
                "Key": 210604,
                "Value": "振安区"
            },
            {
                "Key": 210624,
                "Value": "宽甸满族自治县"
            },
            {
                "Key": 210681,
                "Value": "东港市"
            },
            {
                "Key": 210682,
                "Value": "凤城市"
            }
        ]
    },
    {
        "Key": 210700,
        "Value": [
            {
                "Key": 210702,
                "Value": "古塔区"
            },
            {
                "Key": 210703,
                "Value": "凌河区"
            },
            {
                "Key": 210711,
                "Value": "太和区"
            },
            {
                "Key": 210726,
                "Value": "黑山县"
            },
            {
                "Key": 210727,
                "Value": "义县"
            },
            {
                "Key": 210781,
                "Value": "凌海市"
            },
            {
                "Key": 210782,
                "Value": "北镇市"
            }
        ]
    },
    {
        "Key": 210800,
        "Value": [
            {
                "Key": 210802,
                "Value": "站前区"
            },
            {
                "Key": 210803,
                "Value": "西市区"
            },
            {
                "Key": 210804,
                "Value": "鲅鱼圈区"
            },
            {
                "Key": 210811,
                "Value": "老边区"
            },
            {
                "Key": 210881,
                "Value": "盖州市"
            },
            {
                "Key": 210882,
                "Value": "大石桥市"
            }
        ]
    },
    {
        "Key": 210900,
        "Value": [
            {
                "Key": 210902,
                "Value": "海州区"
            },
            {
                "Key": 210903,
                "Value": "新邱区"
            },
            {
                "Key": 210904,
                "Value": "太平区"
            },
            {
                "Key": 210905,
                "Value": "清河门区"
            },
            {
                "Key": 210911,
                "Value": "细河区"
            },
            {
                "Key": 210921,
                "Value": "阜新蒙古族自治县"
            },
            {
                "Key": 210922,
                "Value": "彰武县"
            }
        ]
    },
    {
        "Key": 211000,
        "Value": [
            {
                "Key": 211002,
                "Value": "白塔区"
            },
            {
                "Key": 211003,
                "Value": "文圣区"
            },
            {
                "Key": 211004,
                "Value": "宏伟区"
            },
            {
                "Key": 211005,
                "Value": "弓长岭区"
            },
            {
                "Key": 211011,
                "Value": "太子河区"
            },
            {
                "Key": 211021,
                "Value": "辽阳县"
            },
            {
                "Key": 211081,
                "Value": "灯塔市"
            }
        ]
    },
    {
        "Key": 211100,
        "Value": [
            {
                "Key": 211102,
                "Value": "双台子区"
            },
            {
                "Key": 211103,
                "Value": "兴隆台区"
            },
            {
                "Key": 211104,
                "Value": "大洼区"
            },
            {
                "Key": 211122,
                "Value": "盘山县"
            }
        ]
    },
    {
        "Key": 211200,
        "Value": [
            {
                "Key": 211202,
                "Value": "银州区"
            },
            {
                "Key": 211204,
                "Value": "清河区"
            },
            {
                "Key": 211221,
                "Value": "铁岭县"
            },
            {
                "Key": 211223,
                "Value": "西丰县"
            },
            {
                "Key": 211224,
                "Value": "昌图县"
            },
            {
                "Key": 211281,
                "Value": "调兵山市"
            },
            {
                "Key": 211282,
                "Value": "开原市"
            }
        ]
    },
    {
        "Key": 211300,
        "Value": [
            {
                "Key": 211302,
                "Value": "双塔区"
            },
            {
                "Key": 211303,
                "Value": "龙城区"
            },
            {
                "Key": 211321,
                "Value": "朝阳县"
            },
            {
                "Key": 211322,
                "Value": "建平县"
            },
            {
                "Key": 211324,
                "Value": "喀喇沁左翼蒙古族自治县"
            },
            {
                "Key": 211381,
                "Value": "北票市"
            },
            {
                "Key": 211382,
                "Value": "凌源市"
            }
        ]
    },
    {
        "Key": 211400,
        "Value": [
            {
                "Key": 211402,
                "Value": "连山区"
            },
            {
                "Key": 211403,
                "Value": "龙港区"
            },
            {
                "Key": 211404,
                "Value": "南票区"
            },
            {
                "Key": 211421,
                "Value": "绥中县"
            },
            {
                "Key": 211422,
                "Value": "建昌县"
            },
            {
                "Key": 211481,
                "Value": "兴城市"
            }
        ]
    },
    {
        "Key": 220000,
        "Value": [
            {
                "Key": 220100,
                "Value": "长春市"
            },
            {
                "Key": 220200,
                "Value": "吉林市"
            },
            {
                "Key": 220300,
                "Value": "四平市"
            },
            {
                "Key": 220400,
                "Value": "辽源市"
            },
            {
                "Key": 220500,
                "Value": "通化市"
            },
            {
                "Key": 220600,
                "Value": "白山市"
            },
            {
                "Key": 220700,
                "Value": "松原市"
            },
            {
                "Key": 220800,
                "Value": "白城市"
            },
            {
                "Key": 222400,
                "Value": "延边朝鲜族自治州"
            }
        ]
    },
    {
        "Key": 220100,
        "Value": [
            {
                "Key": 220102,
                "Value": "南关区"
            },
            {
                "Key": 220103,
                "Value": "宽城区"
            },
            {
                "Key": 220104,
                "Value": "朝阳区"
            },
            {
                "Key": 220105,
                "Value": "二道区"
            },
            {
                "Key": 220106,
                "Value": "绿园区"
            },
            {
                "Key": 220112,
                "Value": "双阳区"
            },
            {
                "Key": 220113,
                "Value": "九台区"
            },
            {
                "Key": 220122,
                "Value": "农安县"
            },
            {
                "Key": 220182,
                "Value": "榆树市"
            },
            {
                "Key": 220183,
                "Value": "德惠市"
            }
        ]
    },
    {
        "Key": 220200,
        "Value": [
            {
                "Key": 220202,
                "Value": "昌邑区"
            },
            {
                "Key": 220203,
                "Value": "龙潭区"
            },
            {
                "Key": 220204,
                "Value": "船营区"
            },
            {
                "Key": 220211,
                "Value": "丰满区"
            },
            {
                "Key": 220221,
                "Value": "永吉县"
            },
            {
                "Key": 220281,
                "Value": "蛟河市"
            },
            {
                "Key": 220282,
                "Value": "桦甸市"
            },
            {
                "Key": 220283,
                "Value": "舒兰市"
            },
            {
                "Key": 220284,
                "Value": "磐石市"
            }
        ]
    },
    {
        "Key": 220300,
        "Value": [
            {
                "Key": 220302,
                "Value": "铁西区"
            },
            {
                "Key": 220303,
                "Value": "铁东区"
            },
            {
                "Key": 220322,
                "Value": "梨树县"
            },
            {
                "Key": 220323,
                "Value": "伊通满族自治县"
            },
            {
                "Key": 220381,
                "Value": "公主岭市"
            },
            {
                "Key": 220382,
                "Value": "双辽市"
            }
        ]
    },
    {
        "Key": 220400,
        "Value": [
            {
                "Key": 220402,
                "Value": "龙山区"
            },
            {
                "Key": 220403,
                "Value": "西安区"
            },
            {
                "Key": 220421,
                "Value": "东丰县"
            },
            {
                "Key": 220422,
                "Value": "东辽县"
            }
        ]
    },
    {
        "Key": 220500,
        "Value": [
            {
                "Key": 220502,
                "Value": "东昌区"
            },
            {
                "Key": 220503,
                "Value": "二道江区"
            },
            {
                "Key": 220521,
                "Value": "通化县"
            },
            {
                "Key": 220523,
                "Value": "辉南县"
            },
            {
                "Key": 220524,
                "Value": "柳河县"
            },
            {
                "Key": 220581,
                "Value": "梅河口市"
            },
            {
                "Key": 220582,
                "Value": "集安市"
            }
        ]
    },
    {
        "Key": 220600,
        "Value": [
            {
                "Key": 220602,
                "Value": "浑江区"
            },
            {
                "Key": 220605,
                "Value": "江源区"
            },
            {
                "Key": 220621,
                "Value": "抚松县"
            },
            {
                "Key": 220622,
                "Value": "靖宇县"
            },
            {
                "Key": 220623,
                "Value": "长白朝鲜族自治县"
            },
            {
                "Key": 220681,
                "Value": "临江市"
            }
        ]
    },
    {
        "Key": 220700,
        "Value": [
            {
                "Key": 220702,
                "Value": "宁江区"
            },
            {
                "Key": 220721,
                "Value": "前郭尔罗斯蒙古族自治县"
            },
            {
                "Key": 220722,
                "Value": "长岭县"
            },
            {
                "Key": 220723,
                "Value": "乾安县"
            },
            {
                "Key": 220781,
                "Value": "扶余市"
            }
        ]
    },
    {
        "Key": 220800,
        "Value": [
            {
                "Key": 220802,
                "Value": "洮北区"
            },
            {
                "Key": 220821,
                "Value": "镇赉县"
            },
            {
                "Key": 220822,
                "Value": "通榆县"
            },
            {
                "Key": 220881,
                "Value": "洮南市"
            },
            {
                "Key": 220882,
                "Value": "大安市"
            }
        ]
    },
    {
        "Key": 222400,
        "Value": [
            {
                "Key": 222401,
                "Value": "延吉市"
            },
            {
                "Key": 222402,
                "Value": "图们市"
            },
            {
                "Key": 222403,
                "Value": "敦化市"
            },
            {
                "Key": 222404,
                "Value": "珲春市"
            },
            {
                "Key": 222405,
                "Value": "龙井市"
            },
            {
                "Key": 222406,
                "Value": "和龙市"
            },
            {
                "Key": 222424,
                "Value": "汪清县"
            },
            {
                "Key": 222426,
                "Value": "安图县"
            }
        ]
    },
    {
        "Key": 230000,
        "Value": [
            {
                "Key": 230100,
                "Value": "哈尔滨市"
            },
            {
                "Key": 230200,
                "Value": "齐齐哈尔市"
            },
            {
                "Key": 230300,
                "Value": "鸡西市"
            },
            {
                "Key": 230400,
                "Value": "鹤岗市"
            },
            {
                "Key": 230500,
                "Value": "双鸭山市"
            },
            {
                "Key": 230600,
                "Value": "大庆市"
            },
            {
                "Key": 230700,
                "Value": "伊春市"
            },
            {
                "Key": 230800,
                "Value": "佳木斯市"
            },
            {
                "Key": 230900,
                "Value": "七台河市"
            },
            {
                "Key": 231000,
                "Value": "牡丹江市"
            },
            {
                "Key": 231100,
                "Value": "黑河市"
            },
            {
                "Key": 231200,
                "Value": "绥化市"
            },
            {
                "Key": 232700,
                "Value": "大兴安岭地区"
            }
        ]
    },
    {
        "Key": 230100,
        "Value": [
            {
                "Key": 230102,
                "Value": "道里区"
            },
            {
                "Key": 230103,
                "Value": "南岗区"
            },
            {
                "Key": 230104,
                "Value": "道外区"
            },
            {
                "Key": 230108,
                "Value": "平房区"
            },
            {
                "Key": 230109,
                "Value": "松北区"
            },
            {
                "Key": 230110,
                "Value": "香坊区"
            },
            {
                "Key": 230111,
                "Value": "呼兰区"
            },
            {
                "Key": 230112,
                "Value": "阿城区"
            },
            {
                "Key": 230113,
                "Value": "双城区"
            },
            {
                "Key": 230123,
                "Value": "依兰县"
            },
            {
                "Key": 230124,
                "Value": "方正县"
            },
            {
                "Key": 230125,
                "Value": "宾县"
            },
            {
                "Key": 230126,
                "Value": "巴彦县"
            },
            {
                "Key": 230127,
                "Value": "木兰县"
            },
            {
                "Key": 230128,
                "Value": "通河县"
            },
            {
                "Key": 230129,
                "Value": "延寿县"
            },
            {
                "Key": 230183,
                "Value": "尚志市"
            },
            {
                "Key": 230184,
                "Value": "五常市"
            }
        ]
    },
    {
        "Key": 230200,
        "Value": [
            {
                "Key": 230202,
                "Value": "龙沙区"
            },
            {
                "Key": 230203,
                "Value": "建华区"
            },
            {
                "Key": 230204,
                "Value": "铁锋区"
            },
            {
                "Key": 230205,
                "Value": "昂昂溪区"
            },
            {
                "Key": 230206,
                "Value": "富拉尔基区"
            },
            {
                "Key": 230207,
                "Value": "碾子山区"
            },
            {
                "Key": 230208,
                "Value": "梅里斯达斡尔族区"
            },
            {
                "Key": 230221,
                "Value": "龙江县"
            },
            {
                "Key": 230223,
                "Value": "依安县"
            },
            {
                "Key": 230224,
                "Value": "泰来县"
            },
            {
                "Key": 230225,
                "Value": "甘南县"
            },
            {
                "Key": 230227,
                "Value": "富裕县"
            },
            {
                "Key": 230229,
                "Value": "克山县"
            },
            {
                "Key": 230230,
                "Value": "克东县"
            },
            {
                "Key": 230231,
                "Value": "拜泉县"
            },
            {
                "Key": 230281,
                "Value": "讷河市"
            }
        ]
    },
    {
        "Key": 230300,
        "Value": [
            {
                "Key": 230302,
                "Value": "鸡冠区"
            },
            {
                "Key": 230303,
                "Value": "恒山区"
            },
            {
                "Key": 230304,
                "Value": "滴道区"
            },
            {
                "Key": 230305,
                "Value": "梨树区"
            },
            {
                "Key": 230306,
                "Value": "城子河区"
            },
            {
                "Key": 230307,
                "Value": "麻山区"
            },
            {
                "Key": 230321,
                "Value": "鸡东县"
            },
            {
                "Key": 230381,
                "Value": "虎林市"
            },
            {
                "Key": 230382,
                "Value": "密山市"
            }
        ]
    },
    {
        "Key": 230400,
        "Value": [
            {
                "Key": 230402,
                "Value": "向阳区"
            },
            {
                "Key": 230403,
                "Value": "工农区"
            },
            {
                "Key": 230404,
                "Value": "南山区"
            },
            {
                "Key": 230405,
                "Value": "兴安区"
            },
            {
                "Key": 230406,
                "Value": "东山区"
            },
            {
                "Key": 230407,
                "Value": "兴山区"
            },
            {
                "Key": 230421,
                "Value": "萝北县"
            },
            {
                "Key": 230422,
                "Value": "绥滨县"
            }
        ]
    },
    {
        "Key": 230500,
        "Value": [
            {
                "Key": 230502,
                "Value": "尖山区"
            },
            {
                "Key": 230503,
                "Value": "岭东区"
            },
            {
                "Key": 230505,
                "Value": "四方台区"
            },
            {
                "Key": 230506,
                "Value": "宝山区"
            },
            {
                "Key": 230521,
                "Value": "集贤县"
            },
            {
                "Key": 230522,
                "Value": "友谊县"
            },
            {
                "Key": 230523,
                "Value": "宝清县"
            },
            {
                "Key": 230524,
                "Value": "饶河县"
            }
        ]
    },
    {
        "Key": 230600,
        "Value": [
            {
                "Key": 230602,
                "Value": "萨尔图区"
            },
            {
                "Key": 230603,
                "Value": "龙凤区"
            },
            {
                "Key": 230604,
                "Value": "让胡路区"
            },
            {
                "Key": 230605,
                "Value": "红岗区"
            },
            {
                "Key": 230606,
                "Value": "大同区"
            },
            {
                "Key": 230621,
                "Value": "肇州县"
            },
            {
                "Key": 230622,
                "Value": "肇源县"
            },
            {
                "Key": 230623,
                "Value": "林甸县"
            },
            {
                "Key": 230624,
                "Value": "杜尔伯特蒙古族自治县"
            }
        ]
    },
    {
        "Key": 230700,
        "Value": [
            {
                "Key": 230702,
                "Value": "伊春区"
            },
            {
                "Key": 230703,
                "Value": "南岔区"
            },
            {
                "Key": 230704,
                "Value": "友好区"
            },
            {
                "Key": 230705,
                "Value": "西林区"
            },
            {
                "Key": 230706,
                "Value": "翠峦区"
            },
            {
                "Key": 230707,
                "Value": "新青区"
            },
            {
                "Key": 230708,
                "Value": "美溪区"
            },
            {
                "Key": 230709,
                "Value": "金山屯区"
            },
            {
                "Key": 230710,
                "Value": "五营区"
            },
            {
                "Key": 230711,
                "Value": "乌马河区"
            },
            {
                "Key": 230712,
                "Value": "汤旺河区"
            },
            {
                "Key": 230713,
                "Value": "带岭区"
            },
            {
                "Key": 230714,
                "Value": "乌伊岭区"
            },
            {
                "Key": 230715,
                "Value": "红星区"
            },
            {
                "Key": 230716,
                "Value": "上甘岭区"
            },
            {
                "Key": 230722,
                "Value": "嘉荫县"
            },
            {
                "Key": 230781,
                "Value": "铁力市"
            }
        ]
    },
    {
        "Key": 230800,
        "Value": [
            {
                "Key": 230803,
                "Value": "向阳区"
            },
            {
                "Key": 230804,
                "Value": "前进区"
            },
            {
                "Key": 230805,
                "Value": "东风区"
            },
            {
                "Key": 230811,
                "Value": "郊区"
            },
            {
                "Key": 230822,
                "Value": "桦南县"
            },
            {
                "Key": 230826,
                "Value": "桦川县"
            },
            {
                "Key": 230828,
                "Value": "汤原县"
            },
            {
                "Key": 230881,
                "Value": "同江市"
            },
            {
                "Key": 230882,
                "Value": "富锦市"
            },
            {
                "Key": 230883,
                "Value": "抚远市"
            }
        ]
    },
    {
        "Key": 230900,
        "Value": [
            {
                "Key": 230902,
                "Value": "新兴区"
            },
            {
                "Key": 230903,
                "Value": "桃山区"
            },
            {
                "Key": 230904,
                "Value": "茄子河区"
            },
            {
                "Key": 230921,
                "Value": "勃利县"
            }
        ]
    },
    {
        "Key": 231000,
        "Value": [
            {
                "Key": 231002,
                "Value": "东安区"
            },
            {
                "Key": 231003,
                "Value": "阳明区"
            },
            {
                "Key": 231004,
                "Value": "爱民区"
            },
            {
                "Key": 231005,
                "Value": "西安区"
            },
            {
                "Key": 231025,
                "Value": "林口县"
            },
            {
                "Key": 231081,
                "Value": "绥芬河市"
            },
            {
                "Key": 231083,
                "Value": "海林市"
            },
            {
                "Key": 231084,
                "Value": "宁安市"
            },
            {
                "Key": 231085,
                "Value": "穆棱市"
            },
            {
                "Key": 231086,
                "Value": "东宁市"
            }
        ]
    },
    {
        "Key": 231100,
        "Value": [
            {
                "Key": 231102,
                "Value": "爱辉区"
            },
            {
                "Key": 231121,
                "Value": "嫩江县"
            },
            {
                "Key": 231123,
                "Value": "逊克县"
            },
            {
                "Key": 231124,
                "Value": "孙吴县"
            },
            {
                "Key": 231181,
                "Value": "北安市"
            },
            {
                "Key": 231182,
                "Value": "五大连池市"
            }
        ]
    },
    {
        "Key": 231200,
        "Value": [
            {
                "Key": 231202,
                "Value": "北林区"
            },
            {
                "Key": 231221,
                "Value": "望奎县"
            },
            {
                "Key": 231222,
                "Value": "兰西县"
            },
            {
                "Key": 231223,
                "Value": "青冈县"
            },
            {
                "Key": 231224,
                "Value": "庆安县"
            },
            {
                "Key": 231225,
                "Value": "明水县"
            },
            {
                "Key": 231226,
                "Value": "绥棱县"
            },
            {
                "Key": 231281,
                "Value": "安达市"
            },
            {
                "Key": 231282,
                "Value": "肇东市"
            },
            {
                "Key": 231283,
                "Value": "海伦市"
            }
        ]
    },
    {
        "Key": 232700,
        "Value": [
            {
                "Key": 232721,
                "Value": "呼玛县"
            },
            {
                "Key": 232722,
                "Value": "塔河县"
            },
            {
                "Key": 232723,
                "Value": "漠河县"
            }
        ]
    },
    {
        "Key": 310100,
        "Value": [
            {
                "Key": 310101,
                "Value": "黄浦区"
            },
            {
                "Key": 310104,
                "Value": "徐汇区"
            },
            {
                "Key": 310105,
                "Value": "长宁区"
            },
            {
                "Key": 310106,
                "Value": "静安区"
            },
            {
                "Key": 310107,
                "Value": "普陀区"
            },
            {
                "Key": 310109,
                "Value": "虹口区"
            },
            {
                "Key": 310110,
                "Value": "杨浦区"
            },
            {
                "Key": 310112,
                "Value": "闵行区"
            },
            {
                "Key": 310113,
                "Value": "宝山区"
            },
            {
                "Key": 310114,
                "Value": "嘉定区"
            },
            {
                "Key": 310115,
                "Value": "浦东新区"
            },
            {
                "Key": 310116,
                "Value": "金山区"
            },
            {
                "Key": 310117,
                "Value": "松江区"
            },
            {
                "Key": 310118,
                "Value": "青浦区"
            },
            {
                "Key": 310120,
                "Value": "奉贤区"
            },
            {
                "Key": 310151,
                "Value": "崇明区"
            }
        ]
    },
    {
        "Key": 320000,
        "Value": [
            {
                "Key": 320100,
                "Value": "南京市"
            },
            {
                "Key": 320200,
                "Value": "无锡市"
            },
            {
                "Key": 320300,
                "Value": "徐州市"
            },
            {
                "Key": 320400,
                "Value": "常州市"
            },
            {
                "Key": 320500,
                "Value": "苏州市"
            },
            {
                "Key": 320600,
                "Value": "南通市"
            },
            {
                "Key": 320700,
                "Value": "连云港市"
            },
            {
                "Key": 320800,
                "Value": "淮安市"
            },
            {
                "Key": 320900,
                "Value": "盐城市"
            },
            {
                "Key": 321000,
                "Value": "扬州市"
            },
            {
                "Key": 321100,
                "Value": "镇江市"
            },
            {
                "Key": 321200,
                "Value": "泰州市"
            },
            {
                "Key": 321300,
                "Value": "宿迁市"
            }
        ]
    },
    {
        "Key": 320100,
        "Value": [
            {
                "Key": 320102,
                "Value": "玄武区"
            },
            {
                "Key": 320104,
                "Value": "秦淮区"
            },
            {
                "Key": 320105,
                "Value": "建邺区"
            },
            {
                "Key": 320106,
                "Value": "鼓楼区"
            },
            {
                "Key": 320111,
                "Value": "浦口区"
            },
            {
                "Key": 320113,
                "Value": "栖霞区"
            },
            {
                "Key": 320114,
                "Value": "雨花台区"
            },
            {
                "Key": 320115,
                "Value": "江宁区"
            },
            {
                "Key": 320116,
                "Value": "六合区"
            },
            {
                "Key": 320117,
                "Value": "溧水区"
            },
            {
                "Key": 320118,
                "Value": "高淳区"
            }
        ]
    },
    {
        "Key": 320200,
        "Value": [
            {
                "Key": 320205,
                "Value": "锡山区"
            },
            {
                "Key": 320206,
                "Value": "惠山区"
            },
            {
                "Key": 320211,
                "Value": "滨湖区"
            },
            {
                "Key": 320213,
                "Value": "梁溪区"
            },
            {
                "Key": 320214,
                "Value": "新吴区"
            },
            {
                "Key": 320281,
                "Value": "江阴市"
            },
            {
                "Key": 320282,
                "Value": "宜兴市"
            }
        ]
    },
    {
        "Key": 320300,
        "Value": [
            {
                "Key": 320302,
                "Value": "鼓楼区"
            },
            {
                "Key": 320303,
                "Value": "云龙区"
            },
            {
                "Key": 320305,
                "Value": "贾汪区"
            },
            {
                "Key": 320311,
                "Value": "泉山区"
            },
            {
                "Key": 320312,
                "Value": "铜山区"
            },
            {
                "Key": 320321,
                "Value": "丰县"
            },
            {
                "Key": 320322,
                "Value": "沛县"
            },
            {
                "Key": 320324,
                "Value": "睢宁县"
            },
            {
                "Key": 320381,
                "Value": "新沂市"
            },
            {
                "Key": 320382,
                "Value": "邳州市"
            }
        ]
    },
    {
        "Key": 320400,
        "Value": [
            {
                "Key": 320402,
                "Value": "天宁区"
            },
            {
                "Key": 320404,
                "Value": "钟楼区"
            },
            {
                "Key": 320411,
                "Value": "新北区"
            },
            {
                "Key": 320412,
                "Value": "武进区"
            },
            {
                "Key": 320413,
                "Value": "金坛区"
            },
            {
                "Key": 320481,
                "Value": "溧阳市"
            }
        ]
    },
    {
        "Key": 320500,
        "Value": [
            {
                "Key": 320505,
                "Value": "虎丘区"
            },
            {
                "Key": 320506,
                "Value": "吴中区"
            },
            {
                "Key": 320507,
                "Value": "相城区"
            },
            {
                "Key": 320508,
                "Value": "姑苏区"
            },
            {
                "Key": 320509,
                "Value": "吴江区"
            },
            {
                "Key": 320581,
                "Value": "常熟市"
            },
            {
                "Key": 320582,
                "Value": "张家港市"
            },
            {
                "Key": 320583,
                "Value": "昆山市"
            },
            {
                "Key": 320585,
                "Value": "太仓市"
            }
        ]
    },
    {
        "Key": 320600,
        "Value": [
            {
                "Key": 320602,
                "Value": "崇川区"
            },
            {
                "Key": 320611,
                "Value": "港闸区"
            },
            {
                "Key": 320612,
                "Value": "通州区"
            },
            {
                "Key": 320621,
                "Value": "海安县"
            },
            {
                "Key": 320623,
                "Value": "如东县"
            },
            {
                "Key": 320681,
                "Value": "启东市"
            },
            {
                "Key": 320682,
                "Value": "如皋市"
            },
            {
                "Key": 320684,
                "Value": "海门市"
            }
        ]
    },
    {
        "Key": 320700,
        "Value": [
            {
                "Key": 320703,
                "Value": "连云区"
            },
            {
                "Key": 320706,
                "Value": "海州区"
            },
            {
                "Key": 320707,
                "Value": "赣榆区"
            },
            {
                "Key": 320722,
                "Value": "东海县"
            },
            {
                "Key": 320723,
                "Value": "灌云县"
            },
            {
                "Key": 320724,
                "Value": "灌南县"
            }
        ]
    },
    {
        "Key": 320800,
        "Value": [
            {
                "Key": 320803,
                "Value": "淮安区"
            },
            {
                "Key": 320804,
                "Value": "淮阴区"
            },
            {
                "Key": 320812,
                "Value": "清江浦区"
            },
            {
                "Key": 320813,
                "Value": "洪泽区"
            },
            {
                "Key": 320826,
                "Value": "涟水县"
            },
            {
                "Key": 320830,
                "Value": "盱眙县"
            },
            {
                "Key": 320831,
                "Value": "金湖县"
            }
        ]
    },
    {
        "Key": 320900,
        "Value": [
            {
                "Key": 320902,
                "Value": "亭湖区"
            },
            {
                "Key": 320903,
                "Value": "盐都区"
            },
            {
                "Key": 320904,
                "Value": "大丰区"
            },
            {
                "Key": 320921,
                "Value": "响水县"
            },
            {
                "Key": 320922,
                "Value": "滨海县"
            },
            {
                "Key": 320923,
                "Value": "阜宁县"
            },
            {
                "Key": 320924,
                "Value": "射阳县"
            },
            {
                "Key": 320925,
                "Value": "建湖县"
            },
            {
                "Key": 320981,
                "Value": "东台市"
            }
        ]
    },
    {
        "Key": 321000,
        "Value": [
            {
                "Key": 321002,
                "Value": "广陵区"
            },
            {
                "Key": 321003,
                "Value": "邗江区"
            },
            {
                "Key": 321012,
                "Value": "江都区"
            },
            {
                "Key": 321023,
                "Value": "宝应县"
            },
            {
                "Key": 321081,
                "Value": "仪征市"
            },
            {
                "Key": 321084,
                "Value": "高邮市"
            }
        ]
    },
    {
        "Key": 321100,
        "Value": [
            {
                "Key": 321102,
                "Value": "京口区"
            },
            {
                "Key": 321111,
                "Value": "润州区"
            },
            {
                "Key": 321112,
                "Value": "丹徒区"
            },
            {
                "Key": 321181,
                "Value": "丹阳市"
            },
            {
                "Key": 321182,
                "Value": "扬中市"
            },
            {
                "Key": 321183,
                "Value": "句容市"
            }
        ]
    },
    {
        "Key": 321200,
        "Value": [
            {
                "Key": 321202,
                "Value": "海陵区"
            },
            {
                "Key": 321203,
                "Value": "高港区"
            },
            {
                "Key": 321204,
                "Value": "姜堰区"
            },
            {
                "Key": 321281,
                "Value": "兴化市"
            },
            {
                "Key": 321282,
                "Value": "靖江市"
            },
            {
                "Key": 321283,
                "Value": "泰兴市"
            }
        ]
    },
    {
        "Key": 321300,
        "Value": [
            {
                "Key": 321302,
                "Value": "宿城区"
            },
            {
                "Key": 321311,
                "Value": "宿豫区"
            },
            {
                "Key": 321322,
                "Value": "沭阳县"
            },
            {
                "Key": 321323,
                "Value": "泗阳县"
            },
            {
                "Key": 321324,
                "Value": "泗洪县"
            }
        ]
    },
    {
        "Key": 330000,
        "Value": [
            {
                "Key": 330100,
                "Value": "杭州市"
            },
            {
                "Key": 330200,
                "Value": "宁波市"
            },
            {
                "Key": 330300,
                "Value": "温州市"
            },
            {
                "Key": 330400,
                "Value": "嘉兴市"
            },
            {
                "Key": 330500,
                "Value": "湖州市"
            },
            {
                "Key": 330600,
                "Value": "绍兴市"
            },
            {
                "Key": 330700,
                "Value": "金华市"
            },
            {
                "Key": 330800,
                "Value": "衢州市"
            },
            {
                "Key": 330900,
                "Value": "舟山市"
            },
            {
                "Key": 331000,
                "Value": "台州市"
            },
            {
                "Key": 331100,
                "Value": "丽水市"
            }
        ]
    },
    {
        "Key": 330100,
        "Value": [
            {
                "Key": 330102,
                "Value": "上城区"
            },
            {
                "Key": 330103,
                "Value": "下城区"
            },
            {
                "Key": 330104,
                "Value": "江干区"
            },
            {
                "Key": 330105,
                "Value": "拱墅区"
            },
            {
                "Key": 330106,
                "Value": "西湖区"
            },
            {
                "Key": 330108,
                "Value": "滨江区"
            },
            {
                "Key": 330109,
                "Value": "萧山区"
            },
            {
                "Key": 330110,
                "Value": "余杭区"
            },
            {
                "Key": 330111,
                "Value": "富阳区"
            },
            {
                "Key": 330122,
                "Value": "桐庐县"
            },
            {
                "Key": 330127,
                "Value": "淳安县"
            },
            {
                "Key": 330182,
                "Value": "建德市"
            },
            {
                "Key": 330185,
                "Value": "临安市"
            }
        ]
    },
    {
        "Key": 330200,
        "Value": [
            {
                "Key": 330203,
                "Value": "海曙区"
            },
            {
                "Key": 330204,
                "Value": "江东区"
            },
            {
                "Key": 330205,
                "Value": "江北区"
            },
            {
                "Key": 330206,
                "Value": "北仑区"
            },
            {
                "Key": 330211,
                "Value": "镇海区"
            },
            {
                "Key": 330212,
                "Value": "鄞州区"
            },
            {
                "Key": 330225,
                "Value": "象山县"
            },
            {
                "Key": 330226,
                "Value": "宁海县"
            },
            {
                "Key": 330281,
                "Value": "余姚市"
            },
            {
                "Key": 330282,
                "Value": "慈溪市"
            },
            {
                "Key": 330283,
                "Value": "奉化市"
            }
        ]
    },
    {
        "Key": 330300,
        "Value": [
            {
                "Key": 330302,
                "Value": "鹿城区"
            },
            {
                "Key": 330303,
                "Value": "龙湾区"
            },
            {
                "Key": 330304,
                "Value": "瓯海区"
            },
            {
                "Key": 330305,
                "Value": "洞头区"
            },
            {
                "Key": 330324,
                "Value": "永嘉县"
            },
            {
                "Key": 330326,
                "Value": "平阳县"
            },
            {
                "Key": 330327,
                "Value": "苍南县"
            },
            {
                "Key": 330328,
                "Value": "文成县"
            },
            {
                "Key": 330329,
                "Value": "泰顺县"
            },
            {
                "Key": 330381,
                "Value": "瑞安市"
            },
            {
                "Key": 330382,
                "Value": "乐清市"
            }
        ]
    },
    {
        "Key": 330400,
        "Value": [
            {
                "Key": 330402,
                "Value": "南湖区"
            },
            {
                "Key": 330411,
                "Value": "秀洲区"
            },
            {
                "Key": 330421,
                "Value": "嘉善县"
            },
            {
                "Key": 330424,
                "Value": "海盐县"
            },
            {
                "Key": 330481,
                "Value": "海宁市"
            },
            {
                "Key": 330482,
                "Value": "平湖市"
            },
            {
                "Key": 330483,
                "Value": "桐乡市"
            }
        ]
    },
    {
        "Key": 330500,
        "Value": [
            {
                "Key": 330502,
                "Value": "吴兴区"
            },
            {
                "Key": 330503,
                "Value": "南浔区"
            },
            {
                "Key": 330521,
                "Value": "德清县"
            },
            {
                "Key": 330522,
                "Value": "长兴县"
            },
            {
                "Key": 330523,
                "Value": "安吉县"
            }
        ]
    },
    {
        "Key": 330600,
        "Value": [
            {
                "Key": 330602,
                "Value": "越城区"
            },
            {
                "Key": 330603,
                "Value": "柯桥区"
            },
            {
                "Key": 330604,
                "Value": "上虞区"
            },
            {
                "Key": 330624,
                "Value": "新昌县"
            },
            {
                "Key": 330681,
                "Value": "诸暨市"
            },
            {
                "Key": 330683,
                "Value": "嵊州市"
            }
        ]
    },
    {
        "Key": 330700,
        "Value": [
            {
                "Key": 330702,
                "Value": "婺城区"
            },
            {
                "Key": 330703,
                "Value": "金东区"
            },
            {
                "Key": 330723,
                "Value": "武义县"
            },
            {
                "Key": 330726,
                "Value": "浦江县"
            },
            {
                "Key": 330727,
                "Value": "磐安县"
            },
            {
                "Key": 330781,
                "Value": "兰溪市"
            },
            {
                "Key": 330782,
                "Value": "义乌市"
            },
            {
                "Key": 330783,
                "Value": "东阳市"
            },
            {
                "Key": 330784,
                "Value": "永康市"
            }
        ]
    },
    {
        "Key": 330800,
        "Value": [
            {
                "Key": 330802,
                "Value": "柯城区"
            },
            {
                "Key": 330803,
                "Value": "衢江区"
            },
            {
                "Key": 330822,
                "Value": "常山县"
            },
            {
                "Key": 330824,
                "Value": "开化县"
            },
            {
                "Key": 330825,
                "Value": "龙游县"
            },
            {
                "Key": 330881,
                "Value": "江山市"
            }
        ]
    },
    {
        "Key": 330900,
        "Value": [
            {
                "Key": 330902,
                "Value": "定海区"
            },
            {
                "Key": 330903,
                "Value": "普陀区"
            },
            {
                "Key": 330921,
                "Value": "岱山县"
            },
            {
                "Key": 330922,
                "Value": "嵊泗县"
            }
        ]
    },
    {
        "Key": 331000,
        "Value": [
            {
                "Key": 331002,
                "Value": "椒江区"
            },
            {
                "Key": 331003,
                "Value": "黄岩区"
            },
            {
                "Key": 331004,
                "Value": "路桥区"
            },
            {
                "Key": 331021,
                "Value": "玉环县"
            },
            {
                "Key": 331022,
                "Value": "三门县"
            },
            {
                "Key": 331023,
                "Value": "天台县"
            },
            {
                "Key": 331024,
                "Value": "仙居县"
            },
            {
                "Key": 331081,
                "Value": "温岭市"
            },
            {
                "Key": 331082,
                "Value": "临海市"
            }
        ]
    },
    {
        "Key": 331100,
        "Value": [
            {
                "Key": 331102,
                "Value": "莲都区"
            },
            {
                "Key": 331121,
                "Value": "青田县"
            },
            {
                "Key": 331122,
                "Value": "缙云县"
            },
            {
                "Key": 331123,
                "Value": "遂昌县"
            },
            {
                "Key": 331124,
                "Value": "松阳县"
            },
            {
                "Key": 331125,
                "Value": "云和县"
            },
            {
                "Key": 331126,
                "Value": "庆元县"
            },
            {
                "Key": 331127,
                "Value": "景宁畲族自治县"
            },
            {
                "Key": 331181,
                "Value": "龙泉市"
            }
        ]
    },
    {
        "Key": 340000,
        "Value": [
            {
                "Key": 340100,
                "Value": "合肥市"
            },
            {
                "Key": 340200,
                "Value": "芜湖市"
            },
            {
                "Key": 340300,
                "Value": "蚌埠市"
            },
            {
                "Key": 340400,
                "Value": "淮南市"
            },
            {
                "Key": 340500,
                "Value": "马鞍山市"
            },
            {
                "Key": 340600,
                "Value": "淮北市"
            },
            {
                "Key": 340700,
                "Value": "铜陵市"
            },
            {
                "Key": 340800,
                "Value": "安庆市"
            },
            {
                "Key": 341000,
                "Value": "黄山市"
            },
            {
                "Key": 341100,
                "Value": "滁州市"
            },
            {
                "Key": 341200,
                "Value": "阜阳市"
            },
            {
                "Key": 341300,
                "Value": "宿州市"
            },
            {
                "Key": 341500,
                "Value": "六安市"
            },
            {
                "Key": 341600,
                "Value": "亳州市"
            },
            {
                "Key": 341700,
                "Value": "池州市"
            },
            {
                "Key": 341800,
                "Value": "宣城市"
            }
        ]
    },
    {
        "Key": 340100,
        "Value": [
            {
                "Key": 340102,
                "Value": "瑶海区"
            },
            {
                "Key": 340103,
                "Value": "庐阳区"
            },
            {
                "Key": 340104,
                "Value": "蜀山区"
            },
            {
                "Key": 340111,
                "Value": "包河区"
            },
            {
                "Key": 340121,
                "Value": "长丰县"
            },
            {
                "Key": 340122,
                "Value": "肥东县"
            },
            {
                "Key": 340123,
                "Value": "肥西县"
            },
            {
                "Key": 340124,
                "Value": "庐江县"
            },
            {
                "Key": 340181,
                "Value": "巢湖市"
            }
        ]
    },
    {
        "Key": 340200,
        "Value": [
            {
                "Key": 340202,
                "Value": "镜湖区"
            },
            {
                "Key": 340203,
                "Value": "弋江区"
            },
            {
                "Key": 340207,
                "Value": "鸠江区"
            },
            {
                "Key": 340208,
                "Value": "三山区"
            },
            {
                "Key": 340221,
                "Value": "芜湖县"
            },
            {
                "Key": 340222,
                "Value": "繁昌县"
            },
            {
                "Key": 340223,
                "Value": "南陵县"
            },
            {
                "Key": 340225,
                "Value": "无为县"
            }
        ]
    },
    {
        "Key": 340300,
        "Value": [
            {
                "Key": 340302,
                "Value": "龙子湖区"
            },
            {
                "Key": 340303,
                "Value": "蚌山区"
            },
            {
                "Key": 340304,
                "Value": "禹会区"
            },
            {
                "Key": 340311,
                "Value": "淮上区"
            },
            {
                "Key": 340321,
                "Value": "怀远县"
            },
            {
                "Key": 340322,
                "Value": "五河县"
            },
            {
                "Key": 340323,
                "Value": "固镇县"
            }
        ]
    },
    {
        "Key": 340400,
        "Value": [
            {
                "Key": 340402,
                "Value": "大通区"
            },
            {
                "Key": 340403,
                "Value": "田家庵区"
            },
            {
                "Key": 340404,
                "Value": "谢家集区"
            },
            {
                "Key": 340405,
                "Value": "八公山区"
            },
            {
                "Key": 340406,
                "Value": "潘集区"
            },
            {
                "Key": 340421,
                "Value": "凤台县"
            },
            {
                "Key": 340422,
                "Value": "寿县"
            }
        ]
    },
    {
        "Key": 340500,
        "Value": [
            {
                "Key": 340503,
                "Value": "花山区"
            },
            {
                "Key": 340504,
                "Value": "雨山区"
            },
            {
                "Key": 340506,
                "Value": "博望区"
            },
            {
                "Key": 340521,
                "Value": "当涂县"
            },
            {
                "Key": 340522,
                "Value": "含山县"
            },
            {
                "Key": 340523,
                "Value": "和县"
            }
        ]
    },
    {
        "Key": 340600,
        "Value": [
            {
                "Key": 340602,
                "Value": "杜集区"
            },
            {
                "Key": 340603,
                "Value": "相山区"
            },
            {
                "Key": 340604,
                "Value": "烈山区"
            },
            {
                "Key": 340621,
                "Value": "濉溪县"
            }
        ]
    },
    {
        "Key": 340700,
        "Value": [
            {
                "Key": 340705,
                "Value": "铜官区"
            },
            {
                "Key": 340706,
                "Value": "义安区"
            },
            {
                "Key": 340711,
                "Value": "郊区"
            },
            {
                "Key": 340722,
                "Value": "枞阳县"
            }
        ]
    },
    {
        "Key": 340800,
        "Value": [
            {
                "Key": 340802,
                "Value": "迎江区"
            },
            {
                "Key": 340803,
                "Value": "大观区"
            },
            {
                "Key": 340811,
                "Value": "宜秀区"
            },
            {
                "Key": 340822,
                "Value": "怀宁县"
            },
            {
                "Key": 340824,
                "Value": "潜山县"
            },
            {
                "Key": 340825,
                "Value": "太湖县"
            },
            {
                "Key": 340826,
                "Value": "宿松县"
            },
            {
                "Key": 340827,
                "Value": "望江县"
            },
            {
                "Key": 340828,
                "Value": "岳西县"
            },
            {
                "Key": 340881,
                "Value": "桐城市"
            }
        ]
    },
    {
        "Key": 341000,
        "Value": [
            {
                "Key": 341002,
                "Value": "屯溪区"
            },
            {
                "Key": 341003,
                "Value": "黄山区"
            },
            {
                "Key": 341004,
                "Value": "徽州区"
            },
            {
                "Key": 341021,
                "Value": "歙县"
            },
            {
                "Key": 341022,
                "Value": "休宁县"
            },
            {
                "Key": 341023,
                "Value": "黟县"
            },
            {
                "Key": 341024,
                "Value": "祁门县"
            }
        ]
    },
    {
        "Key": 341100,
        "Value": [
            {
                "Key": 341102,
                "Value": "琅琊区"
            },
            {
                "Key": 341103,
                "Value": "南谯区"
            },
            {
                "Key": 341122,
                "Value": "来安县"
            },
            {
                "Key": 341124,
                "Value": "全椒县"
            },
            {
                "Key": 341125,
                "Value": "定远县"
            },
            {
                "Key": 341126,
                "Value": "凤阳县"
            },
            {
                "Key": 341181,
                "Value": "天长市"
            },
            {
                "Key": 341182,
                "Value": "明光市"
            }
        ]
    },
    {
        "Key": 341200,
        "Value": [
            {
                "Key": 341202,
                "Value": "颍州区"
            },
            {
                "Key": 341203,
                "Value": "颍东区"
            },
            {
                "Key": 341204,
                "Value": "颍泉区"
            },
            {
                "Key": 341221,
                "Value": "临泉县"
            },
            {
                "Key": 341222,
                "Value": "太和县"
            },
            {
                "Key": 341225,
                "Value": "阜南县"
            },
            {
                "Key": 341226,
                "Value": "颍上县"
            },
            {
                "Key": 341282,
                "Value": "界首市"
            }
        ]
    },
    {
        "Key": 341300,
        "Value": [
            {
                "Key": 341302,
                "Value": "埇桥区"
            },
            {
                "Key": 341321,
                "Value": "砀山县"
            },
            {
                "Key": 341322,
                "Value": "萧县"
            },
            {
                "Key": 341323,
                "Value": "灵璧县"
            },
            {
                "Key": 341324,
                "Value": "泗县"
            }
        ]
    },
    {
        "Key": 341500,
        "Value": [
            {
                "Key": 341502,
                "Value": "金安区"
            },
            {
                "Key": 341503,
                "Value": "裕安区"
            },
            {
                "Key": 341504,
                "Value": "叶集区"
            },
            {
                "Key": 341522,
                "Value": "霍邱县"
            },
            {
                "Key": 341523,
                "Value": "舒城县"
            },
            {
                "Key": 341524,
                "Value": "金寨县"
            },
            {
                "Key": 341525,
                "Value": "霍山县"
            }
        ]
    },
    {
        "Key": 341600,
        "Value": [
            {
                "Key": 341602,
                "Value": "谯城区"
            },
            {
                "Key": 341621,
                "Value": "涡阳县"
            },
            {
                "Key": 341622,
                "Value": "蒙城县"
            },
            {
                "Key": 341623,
                "Value": "利辛县"
            }
        ]
    },
    {
        "Key": 341700,
        "Value": [
            {
                "Key": 341702,
                "Value": "贵池区"
            },
            {
                "Key": 341721,
                "Value": "东至县"
            },
            {
                "Key": 341722,
                "Value": "石台县"
            },
            {
                "Key": 341723,
                "Value": "青阳县"
            }
        ]
    },
    {
        "Key": 341800,
        "Value": [
            {
                "Key": 341802,
                "Value": "宣州区"
            },
            {
                "Key": 341821,
                "Value": "郎溪县"
            },
            {
                "Key": 341822,
                "Value": "广德县"
            },
            {
                "Key": 341823,
                "Value": "泾县"
            },
            {
                "Key": 341824,
                "Value": "绩溪县"
            },
            {
                "Key": 341825,
                "Value": "旌德县"
            },
            {
                "Key": 341881,
                "Value": "宁国市"
            }
        ]
    },
    {
        "Key": 350000,
        "Value": [
            {
                "Key": 350100,
                "Value": "福州市"
            },
            {
                "Key": 350200,
                "Value": "厦门市"
            },
            {
                "Key": 350300,
                "Value": "莆田市"
            },
            {
                "Key": 350400,
                "Value": "三明市"
            },
            {
                "Key": 350500,
                "Value": "泉州市"
            },
            {
                "Key": 350600,
                "Value": "漳州市"
            },
            {
                "Key": 350700,
                "Value": "南平市"
            },
            {
                "Key": 350800,
                "Value": "龙岩市"
            },
            {
                "Key": 350900,
                "Value": "宁德市"
            }
        ]
    },
    {
        "Key": 350100,
        "Value": [
            {
                "Key": 350102,
                "Value": "鼓楼区"
            },
            {
                "Key": 350103,
                "Value": "台江区"
            },
            {
                "Key": 350104,
                "Value": "仓山区"
            },
            {
                "Key": 350105,
                "Value": "马尾区"
            },
            {
                "Key": 350111,
                "Value": "晋安区"
            },
            {
                "Key": 350121,
                "Value": "闽侯县"
            },
            {
                "Key": 350122,
                "Value": "连江县"
            },
            {
                "Key": 350123,
                "Value": "罗源县"
            },
            {
                "Key": 350124,
                "Value": "闽清县"
            },
            {
                "Key": 350125,
                "Value": "永泰县"
            },
            {
                "Key": 350128,
                "Value": "平潭县"
            },
            {
                "Key": 350181,
                "Value": "福清市"
            },
            {
                "Key": 350182,
                "Value": "长乐市"
            }
        ]
    },
    {
        "Key": 350200,
        "Value": [
            {
                "Key": 350203,
                "Value": "思明区"
            },
            {
                "Key": 350205,
                "Value": "海沧区"
            },
            {
                "Key": 350206,
                "Value": "湖里区"
            },
            {
                "Key": 350211,
                "Value": "集美区"
            },
            {
                "Key": 350212,
                "Value": "同安区"
            },
            {
                "Key": 350213,
                "Value": "翔安区"
            }
        ]
    },
    {
        "Key": 350300,
        "Value": [
            {
                "Key": 350302,
                "Value": "城厢区"
            },
            {
                "Key": 350303,
                "Value": "涵江区"
            },
            {
                "Key": 350304,
                "Value": "荔城区"
            },
            {
                "Key": 350305,
                "Value": "秀屿区"
            },
            {
                "Key": 350322,
                "Value": "仙游县"
            }
        ]
    },
    {
        "Key": 350400,
        "Value": [
            {
                "Key": 350402,
                "Value": "梅列区"
            },
            {
                "Key": 350403,
                "Value": "三元区"
            },
            {
                "Key": 350421,
                "Value": "明溪县"
            },
            {
                "Key": 350423,
                "Value": "清流县"
            },
            {
                "Key": 350424,
                "Value": "宁化县"
            },
            {
                "Key": 350425,
                "Value": "大田县"
            },
            {
                "Key": 350426,
                "Value": "尤溪县"
            },
            {
                "Key": 350427,
                "Value": "沙县"
            },
            {
                "Key": 350428,
                "Value": "将乐县"
            },
            {
                "Key": 350429,
                "Value": "泰宁县"
            },
            {
                "Key": 350430,
                "Value": "建宁县"
            },
            {
                "Key": 350481,
                "Value": "永安市"
            }
        ]
    },
    {
        "Key": 350500,
        "Value": [
            {
                "Key": 350502,
                "Value": "鲤城区"
            },
            {
                "Key": 350503,
                "Value": "丰泽区"
            },
            {
                "Key": 350504,
                "Value": "洛江区"
            },
            {
                "Key": 350505,
                "Value": "泉港区"
            },
            {
                "Key": 350521,
                "Value": "惠安县"
            },
            {
                "Key": 350524,
                "Value": "安溪县"
            },
            {
                "Key": 350525,
                "Value": "永春县"
            },
            {
                "Key": 350526,
                "Value": "德化县"
            },
            {
                "Key": 350527,
                "Value": "金门县"
            },
            {
                "Key": 350581,
                "Value": "石狮市"
            },
            {
                "Key": 350582,
                "Value": "晋江市"
            },
            {
                "Key": 350583,
                "Value": "南安市"
            }
        ]
    },
    {
        "Key": 350600,
        "Value": [
            {
                "Key": 350602,
                "Value": "芗城区"
            },
            {
                "Key": 350603,
                "Value": "龙文区"
            },
            {
                "Key": 350622,
                "Value": "云霄县"
            },
            {
                "Key": 350623,
                "Value": "漳浦县"
            },
            {
                "Key": 350624,
                "Value": "诏安县"
            },
            {
                "Key": 350625,
                "Value": "长泰县"
            },
            {
                "Key": 350626,
                "Value": "东山县"
            },
            {
                "Key": 350627,
                "Value": "南靖县"
            },
            {
                "Key": 350628,
                "Value": "平和县"
            },
            {
                "Key": 350629,
                "Value": "华安县"
            },
            {
                "Key": 350681,
                "Value": "龙海市"
            }
        ]
    },
    {
        "Key": 350700,
        "Value": [
            {
                "Key": 350702,
                "Value": "延平区"
            },
            {
                "Key": 350703,
                "Value": "建阳区"
            },
            {
                "Key": 350721,
                "Value": "顺昌县"
            },
            {
                "Key": 350722,
                "Value": "浦城县"
            },
            {
                "Key": 350723,
                "Value": "光泽县"
            },
            {
                "Key": 350724,
                "Value": "松溪县"
            },
            {
                "Key": 350725,
                "Value": "政和县"
            },
            {
                "Key": 350781,
                "Value": "邵武市"
            },
            {
                "Key": 350782,
                "Value": "武夷山市"
            },
            {
                "Key": 350783,
                "Value": "建瓯市"
            }
        ]
    },
    {
        "Key": 350800,
        "Value": [
            {
                "Key": 350802,
                "Value": "新罗区"
            },
            {
                "Key": 350803,
                "Value": "永定区"
            },
            {
                "Key": 350821,
                "Value": "长汀县"
            },
            {
                "Key": 350823,
                "Value": "上杭县"
            },
            {
                "Key": 350824,
                "Value": "武平县"
            },
            {
                "Key": 350825,
                "Value": "连城县"
            },
            {
                "Key": 350881,
                "Value": "漳平市"
            }
        ]
    },
    {
        "Key": 350900,
        "Value": [
            {
                "Key": 350902,
                "Value": "蕉城区"
            },
            {
                "Key": 350921,
                "Value": "霞浦县"
            },
            {
                "Key": 350922,
                "Value": "古田县"
            },
            {
                "Key": 350923,
                "Value": "屏南县"
            },
            {
                "Key": 350924,
                "Value": "寿宁县"
            },
            {
                "Key": 350925,
                "Value": "周宁县"
            },
            {
                "Key": 350926,
                "Value": "柘荣县"
            },
            {
                "Key": 350981,
                "Value": "福安市"
            },
            {
                "Key": 350982,
                "Value": "福鼎市"
            }
        ]
    },
    {
        "Key": 360000,
        "Value": [
            {
                "Key": 360100,
                "Value": "南昌市"
            },
            {
                "Key": 360200,
                "Value": "景德镇市"
            },
            {
                "Key": 360300,
                "Value": "萍乡市"
            },
            {
                "Key": 360400,
                "Value": "九江市"
            },
            {
                "Key": 360500,
                "Value": "新余市"
            },
            {
                "Key": 360600,
                "Value": "鹰潭市"
            },
            {
                "Key": 360700,
                "Value": "赣州市"
            },
            {
                "Key": 360800,
                "Value": "吉安市"
            },
            {
                "Key": 360900,
                "Value": "宜春市"
            },
            {
                "Key": 361000,
                "Value": "抚州市"
            },
            {
                "Key": 361100,
                "Value": "上饶市"
            }
        ]
    },
    {
        "Key": 360100,
        "Value": [
            {
                "Key": 360102,
                "Value": "东湖区"
            },
            {
                "Key": 360103,
                "Value": "西湖区"
            },
            {
                "Key": 360104,
                "Value": "青云谱区"
            },
            {
                "Key": 360105,
                "Value": "湾里区"
            },
            {
                "Key": 360111,
                "Value": "青山湖区"
            },
            {
                "Key": 360112,
                "Value": "新建区"
            },
            {
                "Key": 360121,
                "Value": "南昌县"
            },
            {
                "Key": 360123,
                "Value": "安义县"
            },
            {
                "Key": 360124,
                "Value": "进贤县"
            }
        ]
    },
    {
        "Key": 360200,
        "Value": [
            {
                "Key": 360202,
                "Value": "昌江区"
            },
            {
                "Key": 360203,
                "Value": "珠山区"
            },
            {
                "Key": 360222,
                "Value": "浮梁县"
            },
            {
                "Key": 360281,
                "Value": "乐平市"
            }
        ]
    },
    {
        "Key": 360300,
        "Value": [
            {
                "Key": 360302,
                "Value": "安源区"
            },
            {
                "Key": 360313,
                "Value": "湘东区"
            },
            {
                "Key": 360321,
                "Value": "莲花县"
            },
            {
                "Key": 360322,
                "Value": "上栗县"
            },
            {
                "Key": 360323,
                "Value": "芦溪县"
            }
        ]
    },
    {
        "Key": 360400,
        "Value": [
            {
                "Key": 360402,
                "Value": "濂溪区"
            },
            {
                "Key": 360403,
                "Value": "浔阳区"
            },
            {
                "Key": 360421,
                "Value": "九江县"
            },
            {
                "Key": 360423,
                "Value": "武宁县"
            },
            {
                "Key": 360424,
                "Value": "修水县"
            },
            {
                "Key": 360425,
                "Value": "永修县"
            },
            {
                "Key": 360426,
                "Value": "德安县"
            },
            {
                "Key": 360428,
                "Value": "都昌县"
            },
            {
                "Key": 360429,
                "Value": "湖口县"
            },
            {
                "Key": 360430,
                "Value": "彭泽县"
            },
            {
                "Key": 360481,
                "Value": "瑞昌市"
            },
            {
                "Key": 360482,
                "Value": "共青城市"
            },
            {
                "Key": 360483,
                "Value": "庐山市"
            }
        ]
    },
    {
        "Key": 360500,
        "Value": [
            {
                "Key": 360502,
                "Value": "渝水区"
            },
            {
                "Key": 360521,
                "Value": "分宜县"
            }
        ]
    },
    {
        "Key": 360600,
        "Value": [
            {
                "Key": 360602,
                "Value": "月湖区"
            },
            {
                "Key": 360622,
                "Value": "余江县"
            },
            {
                "Key": 360681,
                "Value": "贵溪市"
            }
        ]
    },
    {
        "Key": 360700,
        "Value": [
            {
                "Key": 360702,
                "Value": "章贡区"
            },
            {
                "Key": 360703,
                "Value": "南康区"
            },
            {
                "Key": 360721,
                "Value": "赣县"
            },
            {
                "Key": 360722,
                "Value": "信丰县"
            },
            {
                "Key": 360723,
                "Value": "大余县"
            },
            {
                "Key": 360724,
                "Value": "上犹县"
            },
            {
                "Key": 360725,
                "Value": "崇义县"
            },
            {
                "Key": 360726,
                "Value": "安远县"
            },
            {
                "Key": 360727,
                "Value": "龙南县"
            },
            {
                "Key": 360728,
                "Value": "定南县"
            },
            {
                "Key": 360729,
                "Value": "全南县"
            },
            {
                "Key": 360730,
                "Value": "宁都县"
            },
            {
                "Key": 360731,
                "Value": "于都县"
            },
            {
                "Key": 360732,
                "Value": "兴国县"
            },
            {
                "Key": 360733,
                "Value": "会昌县"
            },
            {
                "Key": 360734,
                "Value": "寻乌县"
            },
            {
                "Key": 360735,
                "Value": "石城县"
            },
            {
                "Key": 360781,
                "Value": "瑞金市"
            }
        ]
    },
    {
        "Key": 360800,
        "Value": [
            {
                "Key": 360802,
                "Value": "吉州区"
            },
            {
                "Key": 360803,
                "Value": "青原区"
            },
            {
                "Key": 360821,
                "Value": "吉安县"
            },
            {
                "Key": 360822,
                "Value": "吉水县"
            },
            {
                "Key": 360823,
                "Value": "峡江县"
            },
            {
                "Key": 360824,
                "Value": "新干县"
            },
            {
                "Key": 360825,
                "Value": "永丰县"
            },
            {
                "Key": 360826,
                "Value": "泰和县"
            },
            {
                "Key": 360827,
                "Value": "遂川县"
            },
            {
                "Key": 360828,
                "Value": "万安县"
            },
            {
                "Key": 360829,
                "Value": "安福县"
            },
            {
                "Key": 360830,
                "Value": "永新县"
            },
            {
                "Key": 360881,
                "Value": "井冈山市"
            }
        ]
    },
    {
        "Key": 360900,
        "Value": [
            {
                "Key": 360902,
                "Value": "袁州区"
            },
            {
                "Key": 360921,
                "Value": "奉新县"
            },
            {
                "Key": 360922,
                "Value": "万载县"
            },
            {
                "Key": 360923,
                "Value": "上高县"
            },
            {
                "Key": 360924,
                "Value": "宜丰县"
            },
            {
                "Key": 360925,
                "Value": "靖安县"
            },
            {
                "Key": 360926,
                "Value": "铜鼓县"
            },
            {
                "Key": 360981,
                "Value": "丰城市"
            },
            {
                "Key": 360982,
                "Value": "樟树市"
            },
            {
                "Key": 360983,
                "Value": "高安市"
            }
        ]
    },
    {
        "Key": 361000,
        "Value": [
            {
                "Key": 361002,
                "Value": "临川区"
            },
            {
                "Key": 361021,
                "Value": "南城县"
            },
            {
                "Key": 361022,
                "Value": "黎川县"
            },
            {
                "Key": 361023,
                "Value": "南丰县"
            },
            {
                "Key": 361024,
                "Value": "崇仁县"
            },
            {
                "Key": 361025,
                "Value": "乐安县"
            },
            {
                "Key": 361026,
                "Value": "宜黄县"
            },
            {
                "Key": 361027,
                "Value": "金溪县"
            },
            {
                "Key": 361028,
                "Value": "资溪县"
            },
            {
                "Key": 361029,
                "Value": "东乡县"
            },
            {
                "Key": 361030,
                "Value": "广昌县"
            }
        ]
    },
    {
        "Key": 361100,
        "Value": [
            {
                "Key": 361102,
                "Value": "信州区"
            },
            {
                "Key": 361103,
                "Value": "广丰区"
            },
            {
                "Key": 361121,
                "Value": "上饶县"
            },
            {
                "Key": 361123,
                "Value": "玉山县"
            },
            {
                "Key": 361124,
                "Value": "铅山县"
            },
            {
                "Key": 361125,
                "Value": "横峰县"
            },
            {
                "Key": 361126,
                "Value": "弋阳县"
            },
            {
                "Key": 361127,
                "Value": "余干县"
            },
            {
                "Key": 361128,
                "Value": "鄱阳县"
            },
            {
                "Key": 361129,
                "Value": "万年县"
            },
            {
                "Key": 361130,
                "Value": "婺源县"
            },
            {
                "Key": 361181,
                "Value": "德兴市"
            }
        ]
    },
    {
        "Key": 370000,
        "Value": [
            {
                "Key": 370100,
                "Value": "济南市"
            },
            {
                "Key": 370200,
                "Value": "青岛市"
            },
            {
                "Key": 370300,
                "Value": "淄博市"
            },
            {
                "Key": 370400,
                "Value": "枣庄市"
            },
            {
                "Key": 370500,
                "Value": "东营市"
            },
            {
                "Key": 370600,
                "Value": "烟台市"
            },
            {
                "Key": 370700,
                "Value": "潍坊市"
            },
            {
                "Key": 370800,
                "Value": "济宁市"
            },
            {
                "Key": 370900,
                "Value": "泰安市"
            },
            {
                "Key": 371000,
                "Value": "威海市"
            },
            {
                "Key": 371100,
                "Value": "日照市"
            },
            {
                "Key": 371200,
                "Value": "莱芜市"
            },
            {
                "Key": 371300,
                "Value": "临沂市"
            },
            {
                "Key": 371400,
                "Value": "德州市"
            },
            {
                "Key": 371500,
                "Value": "聊城市"
            },
            {
                "Key": 371600,
                "Value": "滨州市"
            },
            {
                "Key": 371700,
                "Value": "菏泽市"
            }
        ]
    },
    {
        "Key": 370100,
        "Value": [
            {
                "Key": 370102,
                "Value": "历下区"
            },
            {
                "Key": 370103,
                "Value": "市中区"
            },
            {
                "Key": 370104,
                "Value": "槐荫区"
            },
            {
                "Key": 370105,
                "Value": "天桥区"
            },
            {
                "Key": 370112,
                "Value": "历城区"
            },
            {
                "Key": 370113,
                "Value": "长清区"
            },
            {
                "Key": 370124,
                "Value": "平阴县"
            },
            {
                "Key": 370125,
                "Value": "济阳县"
            },
            {
                "Key": 370126,
                "Value": "商河县"
            },
            {
                "Key": 370181,
                "Value": "章丘市"
            }
        ]
    },
    {
        "Key": 370200,
        "Value": [
            {
                "Key": 370202,
                "Value": "市南区"
            },
            {
                "Key": 370203,
                "Value": "市北区"
            },
            {
                "Key": 370211,
                "Value": "黄岛区"
            },
            {
                "Key": 370212,
                "Value": "崂山区"
            },
            {
                "Key": 370213,
                "Value": "李沧区"
            },
            {
                "Key": 370214,
                "Value": "城阳区"
            },
            {
                "Key": 370281,
                "Value": "胶州市"
            },
            {
                "Key": 370282,
                "Value": "即墨市"
            },
            {
                "Key": 370283,
                "Value": "平度市"
            },
            {
                "Key": 370285,
                "Value": "莱西市"
            }
        ]
    },
    {
        "Key": 370300,
        "Value": [
            {
                "Key": 370302,
                "Value": "淄川区"
            },
            {
                "Key": 370303,
                "Value": "张店区"
            },
            {
                "Key": 370304,
                "Value": "博山区"
            },
            {
                "Key": 370305,
                "Value": "临淄区"
            },
            {
                "Key": 370306,
                "Value": "周村区"
            },
            {
                "Key": 370321,
                "Value": "桓台县"
            },
            {
                "Key": 370322,
                "Value": "高青县"
            },
            {
                "Key": 370323,
                "Value": "沂源县"
            }
        ]
    },
    {
        "Key": 370400,
        "Value": [
            {
                "Key": 370402,
                "Value": "市中区"
            },
            {
                "Key": 370403,
                "Value": "薛城区"
            },
            {
                "Key": 370404,
                "Value": "峄城区"
            },
            {
                "Key": 370405,
                "Value": "台儿庄区"
            },
            {
                "Key": 370406,
                "Value": "山亭区"
            },
            {
                "Key": 370481,
                "Value": "滕州市"
            }
        ]
    },
    {
        "Key": 370500,
        "Value": [
            {
                "Key": 370502,
                "Value": "东营区"
            },
            {
                "Key": 370503,
                "Value": "河口区"
            },
            {
                "Key": 370505,
                "Value": "垦利区"
            },
            {
                "Key": 370522,
                "Value": "利津县"
            },
            {
                "Key": 370523,
                "Value": "广饶县"
            }
        ]
    },
    {
        "Key": 370600,
        "Value": [
            {
                "Key": 370602,
                "Value": "芝罘区"
            },
            {
                "Key": 370611,
                "Value": "福山区"
            },
            {
                "Key": 370612,
                "Value": "牟平区"
            },
            {
                "Key": 370613,
                "Value": "莱山区"
            },
            {
                "Key": 370634,
                "Value": "长岛县"
            },
            {
                "Key": 370681,
                "Value": "龙口市"
            },
            {
                "Key": 370682,
                "Value": "莱阳市"
            },
            {
                "Key": 370683,
                "Value": "莱州市"
            },
            {
                "Key": 370684,
                "Value": "蓬莱市"
            },
            {
                "Key": 370685,
                "Value": "招远市"
            },
            {
                "Key": 370686,
                "Value": "栖霞市"
            },
            {
                "Key": 370687,
                "Value": "海阳市"
            }
        ]
    },
    {
        "Key": 370700,
        "Value": [
            {
                "Key": 370702,
                "Value": "潍城区"
            },
            {
                "Key": 370703,
                "Value": "寒亭区"
            },
            {
                "Key": 370704,
                "Value": "坊子区"
            },
            {
                "Key": 370705,
                "Value": "奎文区"
            },
            {
                "Key": 370724,
                "Value": "临朐县"
            },
            {
                "Key": 370725,
                "Value": "昌乐县"
            },
            {
                "Key": 370781,
                "Value": "青州市"
            },
            {
                "Key": 370782,
                "Value": "诸城市"
            },
            {
                "Key": 370783,
                "Value": "寿光市"
            },
            {
                "Key": 370784,
                "Value": "安丘市"
            },
            {
                "Key": 370785,
                "Value": "高密市"
            },
            {
                "Key": 370786,
                "Value": "昌邑市"
            }
        ]
    },
    {
        "Key": 370800,
        "Value": [
            {
                "Key": 370811,
                "Value": "任城区"
            },
            {
                "Key": 370812,
                "Value": "兖州区"
            },
            {
                "Key": 370826,
                "Value": "微山县"
            },
            {
                "Key": 370827,
                "Value": "鱼台县"
            },
            {
                "Key": 370828,
                "Value": "金乡县"
            },
            {
                "Key": 370829,
                "Value": "嘉祥县"
            },
            {
                "Key": 370830,
                "Value": "汶上县"
            },
            {
                "Key": 370831,
                "Value": "泗水县"
            },
            {
                "Key": 370832,
                "Value": "梁山县"
            },
            {
                "Key": 370881,
                "Value": "曲阜市"
            },
            {
                "Key": 370883,
                "Value": "邹城市"
            }
        ]
    },
    {
        "Key": 370900,
        "Value": [
            {
                "Key": 370902,
                "Value": "泰山区"
            },
            {
                "Key": 370911,
                "Value": "岱岳区"
            },
            {
                "Key": 370921,
                "Value": "宁阳县"
            },
            {
                "Key": 370923,
                "Value": "东平县"
            },
            {
                "Key": 370982,
                "Value": "新泰市"
            },
            {
                "Key": 370983,
                "Value": "肥城市"
            }
        ]
    },
    {
        "Key": 371000,
        "Value": [
            {
                "Key": 371002,
                "Value": "环翠区"
            },
            {
                "Key": 371003,
                "Value": "文登区"
            },
            {
                "Key": 371082,
                "Value": "荣成市"
            },
            {
                "Key": 371083,
                "Value": "乳山市"
            }
        ]
    },
    {
        "Key": 371100,
        "Value": [
            {
                "Key": 371102,
                "Value": "东港区"
            },
            {
                "Key": 371103,
                "Value": "岚山区"
            },
            {
                "Key": 371121,
                "Value": "五莲县"
            },
            {
                "Key": 371122,
                "Value": "莒县"
            }
        ]
    },
    {
        "Key": 371200,
        "Value": [
            {
                "Key": 371202,
                "Value": "莱城区"
            },
            {
                "Key": 371203,
                "Value": "钢城区"
            }
        ]
    },
    {
        "Key": 371300,
        "Value": [
            {
                "Key": 371302,
                "Value": "兰山区"
            },
            {
                "Key": 371311,
                "Value": "罗庄区"
            },
            {
                "Key": 371312,
                "Value": "河东区"
            },
            {
                "Key": 371321,
                "Value": "沂南县"
            },
            {
                "Key": 371322,
                "Value": "郯城县"
            },
            {
                "Key": 371323,
                "Value": "沂水县"
            },
            {
                "Key": 371324,
                "Value": "兰陵县"
            },
            {
                "Key": 371325,
                "Value": "费县"
            },
            {
                "Key": 371326,
                "Value": "平邑县"
            },
            {
                "Key": 371327,
                "Value": "莒南县"
            },
            {
                "Key": 371328,
                "Value": "蒙阴县"
            },
            {
                "Key": 371329,
                "Value": "临沭县"
            }
        ]
    },
    {
        "Key": 371400,
        "Value": [
            {
                "Key": 371402,
                "Value": "德城区"
            },
            {
                "Key": 371403,
                "Value": "陵城区"
            },
            {
                "Key": 371422,
                "Value": "宁津县"
            },
            {
                "Key": 371423,
                "Value": "庆云县"
            },
            {
                "Key": 371424,
                "Value": "临邑县"
            },
            {
                "Key": 371425,
                "Value": "齐河县"
            },
            {
                "Key": 371426,
                "Value": "平原县"
            },
            {
                "Key": 371427,
                "Value": "夏津县"
            },
            {
                "Key": 371428,
                "Value": "武城县"
            },
            {
                "Key": 371481,
                "Value": "乐陵市"
            },
            {
                "Key": 371482,
                "Value": "禹城市"
            }
        ]
    },
    {
        "Key": 371500,
        "Value": [
            {
                "Key": 371502,
                "Value": "东昌府区"
            },
            {
                "Key": 371521,
                "Value": "阳谷县"
            },
            {
                "Key": 371522,
                "Value": "莘县"
            },
            {
                "Key": 371523,
                "Value": "茌平县"
            },
            {
                "Key": 371524,
                "Value": "东阿县"
            },
            {
                "Key": 371525,
                "Value": "冠县"
            },
            {
                "Key": 371526,
                "Value": "高唐县"
            },
            {
                "Key": 371581,
                "Value": "临清市"
            }
        ]
    },
    {
        "Key": 371600,
        "Value": [
            {
                "Key": 371602,
                "Value": "滨城区"
            },
            {
                "Key": 371603,
                "Value": "沾化区"
            },
            {
                "Key": 371621,
                "Value": "惠民县"
            },
            {
                "Key": 371622,
                "Value": "阳信县"
            },
            {
                "Key": 371623,
                "Value": "无棣县"
            },
            {
                "Key": 371625,
                "Value": "博兴县"
            },
            {
                "Key": 371626,
                "Value": "邹平县"
            }
        ]
    },
    {
        "Key": 371700,
        "Value": [
            {
                "Key": 371702,
                "Value": "牡丹区"
            },
            {
                "Key": 371703,
                "Value": "定陶区"
            },
            {
                "Key": 371721,
                "Value": "曹县"
            },
            {
                "Key": 371722,
                "Value": "单县"
            },
            {
                "Key": 371723,
                "Value": "成武县"
            },
            {
                "Key": 371724,
                "Value": "巨野县"
            },
            {
                "Key": 371725,
                "Value": "郓城县"
            },
            {
                "Key": 371726,
                "Value": "鄄城县"
            },
            {
                "Key": 371728,
                "Value": "东明县"
            }
        ]
    },
    {
        "Key": 410000,
        "Value": [
            {
                "Key": 410100,
                "Value": "郑州市"
            },
            {
                "Key": 410200,
                "Value": "开封市"
            },
            {
                "Key": 410300,
                "Value": "洛阳市"
            },
            {
                "Key": 410400,
                "Value": "平顶山市"
            },
            {
                "Key": 410500,
                "Value": "安阳市"
            },
            {
                "Key": 410600,
                "Value": "鹤壁市"
            },
            {
                "Key": 410700,
                "Value": "新乡市"
            },
            {
                "Key": 410800,
                "Value": "焦作市"
            },
            {
                "Key": 410900,
                "Value": "濮阳市"
            },
            {
                "Key": 411000,
                "Value": "许昌市"
            },
            {
                "Key": 411100,
                "Value": "漯河市"
            },
            {
                "Key": 411200,
                "Value": "三门峡市"
            },
            {
                "Key": 411300,
                "Value": "南阳市"
            },
            {
                "Key": 411400,
                "Value": "商丘市"
            },
            {
                "Key": 411500,
                "Value": "信阳市"
            },
            {
                "Key": 411600,
                "Value": "周口市"
            },
            {
                "Key": 411700,
                "Value": "驻马店市"
            },
            {
                "Key": 419000,
                "Value": "省直辖县级行政区划"
            }
        ]
    },
    {
        "Key": 410100,
        "Value": [
            {
                "Key": 410102,
                "Value": "中原区"
            },
            {
                "Key": 410103,
                "Value": "二七区"
            },
            {
                "Key": 410104,
                "Value": "管城回族区"
            },
            {
                "Key": 410105,
                "Value": "金水区"
            },
            {
                "Key": 410106,
                "Value": "上街区"
            },
            {
                "Key": 410108,
                "Value": "惠济区"
            },
            {
                "Key": 410122,
                "Value": "中牟县"
            },
            {
                "Key": 410181,
                "Value": "巩义市"
            },
            {
                "Key": 410182,
                "Value": "荥阳市"
            },
            {
                "Key": 410183,
                "Value": "新密市"
            },
            {
                "Key": 410184,
                "Value": "新郑市"
            },
            {
                "Key": 410185,
                "Value": "登封市"
            }
        ]
    },
    {
        "Key": 410200,
        "Value": [
            {
                "Key": 410202,
                "Value": "龙亭区"
            },
            {
                "Key": 410203,
                "Value": "顺河回族区"
            },
            {
                "Key": 410204,
                "Value": "鼓楼区"
            },
            {
                "Key": 410205,
                "Value": "禹王台区"
            },
            {
                "Key": 410211,
                "Value": "金明区"
            },
            {
                "Key": 410212,
                "Value": "祥符区"
            },
            {
                "Key": 410221,
                "Value": "杞县"
            },
            {
                "Key": 410222,
                "Value": "通许县"
            },
            {
                "Key": 410223,
                "Value": "尉氏县"
            },
            {
                "Key": 410225,
                "Value": "兰考县"
            }
        ]
    },
    {
        "Key": 410300,
        "Value": [
            {
                "Key": 410302,
                "Value": "老城区"
            },
            {
                "Key": 410303,
                "Value": "西工区"
            },
            {
                "Key": 410304,
                "Value": "瀍河回族区"
            },
            {
                "Key": 410305,
                "Value": "涧西区"
            },
            {
                "Key": 410306,
                "Value": "吉利区"
            },
            {
                "Key": 410311,
                "Value": "洛龙区"
            },
            {
                "Key": 410322,
                "Value": "孟津县"
            },
            {
                "Key": 410323,
                "Value": "新安县"
            },
            {
                "Key": 410324,
                "Value": "栾川县"
            },
            {
                "Key": 410325,
                "Value": "嵩县"
            },
            {
                "Key": 410326,
                "Value": "汝阳县"
            },
            {
                "Key": 410327,
                "Value": "宜阳县"
            },
            {
                "Key": 410328,
                "Value": "洛宁县"
            },
            {
                "Key": 410329,
                "Value": "伊川县"
            },
            {
                "Key": 410381,
                "Value": "偃师市"
            }
        ]
    },
    {
        "Key": 410400,
        "Value": [
            {
                "Key": 410402,
                "Value": "新华区"
            },
            {
                "Key": 410403,
                "Value": "卫东区"
            },
            {
                "Key": 410404,
                "Value": "石龙区"
            },
            {
                "Key": 410411,
                "Value": "湛河区"
            },
            {
                "Key": 410421,
                "Value": "宝丰县"
            },
            {
                "Key": 410422,
                "Value": "叶县"
            },
            {
                "Key": 410423,
                "Value": "鲁山县"
            },
            {
                "Key": 410425,
                "Value": "郏县"
            },
            {
                "Key": 410481,
                "Value": "舞钢市"
            },
            {
                "Key": 410482,
                "Value": "汝州市"
            }
        ]
    },
    {
        "Key": 410500,
        "Value": [
            {
                "Key": 410502,
                "Value": "文峰区"
            },
            {
                "Key": 410503,
                "Value": "北关区"
            },
            {
                "Key": 410505,
                "Value": "殷都区"
            },
            {
                "Key": 410506,
                "Value": "龙安区"
            },
            {
                "Key": 410522,
                "Value": "安阳县"
            },
            {
                "Key": 410523,
                "Value": "汤阴县"
            },
            {
                "Key": 410526,
                "Value": "滑县"
            },
            {
                "Key": 410527,
                "Value": "内黄县"
            },
            {
                "Key": 410581,
                "Value": "林州市"
            }
        ]
    },
    {
        "Key": 410600,
        "Value": [
            {
                "Key": 410602,
                "Value": "鹤山区"
            },
            {
                "Key": 410603,
                "Value": "山城区"
            },
            {
                "Key": 410611,
                "Value": "淇滨区"
            },
            {
                "Key": 410621,
                "Value": "浚县"
            },
            {
                "Key": 410622,
                "Value": "淇县"
            }
        ]
    },
    {
        "Key": 410700,
        "Value": [
            {
                "Key": 410702,
                "Value": "红旗区"
            },
            {
                "Key": 410703,
                "Value": "卫滨区"
            },
            {
                "Key": 410704,
                "Value": "凤泉区"
            },
            {
                "Key": 410711,
                "Value": "牧野区"
            },
            {
                "Key": 410721,
                "Value": "新乡县"
            },
            {
                "Key": 410724,
                "Value": "获嘉县"
            },
            {
                "Key": 410725,
                "Value": "原阳县"
            },
            {
                "Key": 410726,
                "Value": "延津县"
            },
            {
                "Key": 410727,
                "Value": "封丘县"
            },
            {
                "Key": 410728,
                "Value": "长垣县"
            },
            {
                "Key": 410781,
                "Value": "卫辉市"
            },
            {
                "Key": 410782,
                "Value": "辉县市"
            }
        ]
    },
    {
        "Key": 410800,
        "Value": [
            {
                "Key": 410802,
                "Value": "解放区"
            },
            {
                "Key": 410803,
                "Value": "中站区"
            },
            {
                "Key": 410804,
                "Value": "马村区"
            },
            {
                "Key": 410811,
                "Value": "山阳区"
            },
            {
                "Key": 410821,
                "Value": "修武县"
            },
            {
                "Key": 410822,
                "Value": "博爱县"
            },
            {
                "Key": 410823,
                "Value": "武陟县"
            },
            {
                "Key": 410825,
                "Value": "温县"
            },
            {
                "Key": 410882,
                "Value": "沁阳市"
            },
            {
                "Key": 410883,
                "Value": "孟州市"
            }
        ]
    },
    {
        "Key": 410900,
        "Value": [
            {
                "Key": 410902,
                "Value": "华龙区"
            },
            {
                "Key": 410922,
                "Value": "清丰县"
            },
            {
                "Key": 410923,
                "Value": "南乐县"
            },
            {
                "Key": 410926,
                "Value": "范县"
            },
            {
                "Key": 410927,
                "Value": "台前县"
            },
            {
                "Key": 410928,
                "Value": "濮阳县"
            }
        ]
    },
    {
        "Key": 411000,
        "Value": [
            {
                "Key": 411002,
                "Value": "魏都区"
            },
            {
                "Key": 411023,
                "Value": "许昌县"
            },
            {
                "Key": 411024,
                "Value": "鄢陵县"
            },
            {
                "Key": 411025,
                "Value": "襄城县"
            },
            {
                "Key": 411081,
                "Value": "禹州市"
            },
            {
                "Key": 411082,
                "Value": "长葛市"
            }
        ]
    },
    {
        "Key": 411100,
        "Value": [
            {
                "Key": 411102,
                "Value": "源汇区"
            },
            {
                "Key": 411103,
                "Value": "郾城区"
            },
            {
                "Key": 411104,
                "Value": "召陵区"
            },
            {
                "Key": 411121,
                "Value": "舞阳县"
            },
            {
                "Key": 411122,
                "Value": "临颍县"
            }
        ]
    },
    {
        "Key": 411200,
        "Value": [
            {
                "Key": 411202,
                "Value": "湖滨区"
            },
            {
                "Key": 411203,
                "Value": "陕州区"
            },
            {
                "Key": 411221,
                "Value": "渑池县"
            },
            {
                "Key": 411224,
                "Value": "卢氏县"
            },
            {
                "Key": 411281,
                "Value": "义马市"
            },
            {
                "Key": 411282,
                "Value": "灵宝市"
            }
        ]
    },
    {
        "Key": 411300,
        "Value": [
            {
                "Key": 411302,
                "Value": "宛城区"
            },
            {
                "Key": 411303,
                "Value": "卧龙区"
            },
            {
                "Key": 411321,
                "Value": "南召县"
            },
            {
                "Key": 411322,
                "Value": "方城县"
            },
            {
                "Key": 411323,
                "Value": "西峡县"
            },
            {
                "Key": 411324,
                "Value": "镇平县"
            },
            {
                "Key": 411325,
                "Value": "内乡县"
            },
            {
                "Key": 411326,
                "Value": "淅川县"
            },
            {
                "Key": 411327,
                "Value": "社旗县"
            },
            {
                "Key": 411328,
                "Value": "唐河县"
            },
            {
                "Key": 411329,
                "Value": "新野县"
            },
            {
                "Key": 411330,
                "Value": "桐柏县"
            },
            {
                "Key": 411381,
                "Value": "邓州市"
            }
        ]
    },
    {
        "Key": 411400,
        "Value": [
            {
                "Key": 411402,
                "Value": "梁园区"
            },
            {
                "Key": 411403,
                "Value": "睢阳区"
            },
            {
                "Key": 411421,
                "Value": "民权县"
            },
            {
                "Key": 411422,
                "Value": "睢县"
            },
            {
                "Key": 411423,
                "Value": "宁陵县"
            },
            {
                "Key": 411424,
                "Value": "柘城县"
            },
            {
                "Key": 411425,
                "Value": "虞城县"
            },
            {
                "Key": 411426,
                "Value": "夏邑县"
            },
            {
                "Key": 411481,
                "Value": "永城市"
            }
        ]
    },
    {
        "Key": 411500,
        "Value": [
            {
                "Key": 411502,
                "Value": "浉河区"
            },
            {
                "Key": 411503,
                "Value": "平桥区"
            },
            {
                "Key": 411521,
                "Value": "罗山县"
            },
            {
                "Key": 411522,
                "Value": "光山县"
            },
            {
                "Key": 411523,
                "Value": "新县"
            },
            {
                "Key": 411524,
                "Value": "商城县"
            },
            {
                "Key": 411525,
                "Value": "固始县"
            },
            {
                "Key": 411526,
                "Value": "潢川县"
            },
            {
                "Key": 411527,
                "Value": "淮滨县"
            },
            {
                "Key": 411528,
                "Value": "息县"
            }
        ]
    },
    {
        "Key": 411600,
        "Value": [
            {
                "Key": 411602,
                "Value": "川汇区"
            },
            {
                "Key": 411621,
                "Value": "扶沟县"
            },
            {
                "Key": 411622,
                "Value": "西华县"
            },
            {
                "Key": 411623,
                "Value": "商水县"
            },
            {
                "Key": 411624,
                "Value": "沈丘县"
            },
            {
                "Key": 411625,
                "Value": "郸城县"
            },
            {
                "Key": 411626,
                "Value": "淮阳县"
            },
            {
                "Key": 411627,
                "Value": "太康县"
            },
            {
                "Key": 411628,
                "Value": "鹿邑县"
            },
            {
                "Key": 411681,
                "Value": "项城市"
            }
        ]
    },
    {
        "Key": 411700,
        "Value": [
            {
                "Key": 411702,
                "Value": "驿城区"
            },
            {
                "Key": 411721,
                "Value": "西平县"
            },
            {
                "Key": 411722,
                "Value": "上蔡县"
            },
            {
                "Key": 411723,
                "Value": "平舆县"
            },
            {
                "Key": 411724,
                "Value": "正阳县"
            },
            {
                "Key": 411725,
                "Value": "确山县"
            },
            {
                "Key": 411726,
                "Value": "泌阳县"
            },
            {
                "Key": 411727,
                "Value": "汝南县"
            },
            {
                "Key": 411728,
                "Value": "遂平县"
            },
            {
                "Key": 411729,
                "Value": "新蔡县"
            }
        ]
    },
    {
        "Key": 419000,
        "Value": [
            {
                "Key": 419001,
                "Value": "济源市"
            }
        ]
    },
    {
        "Key": 420000,
        "Value": [
            {
                "Key": 420100,
                "Value": "武汉市"
            },
            {
                "Key": 420200,
                "Value": "黄石市"
            },
            {
                "Key": 420300,
                "Value": "十堰市"
            },
            {
                "Key": 420500,
                "Value": "宜昌市"
            },
            {
                "Key": 420600,
                "Value": "襄阳市"
            },
            {
                "Key": 420700,
                "Value": "鄂州市"
            },
            {
                "Key": 420800,
                "Value": "荆门市"
            },
            {
                "Key": 420900,
                "Value": "孝感市"
            },
            {
                "Key": 421000,
                "Value": "荆州市"
            },
            {
                "Key": 421100,
                "Value": "黄冈市"
            },
            {
                "Key": 421200,
                "Value": "咸宁市"
            },
            {
                "Key": 421300,
                "Value": "随州市"
            },
            {
                "Key": 422800,
                "Value": "恩施土家族苗族自治州"
            },
            {
                "Key": 429000,
                "Value": "省直辖县级行政区划"
            }
        ]
    },
    {
        "Key": 420100,
        "Value": [
            {
                "Key": 420102,
                "Value": "江岸区"
            },
            {
                "Key": 420103,
                "Value": "江汉区"
            },
            {
                "Key": 420104,
                "Value": "硚口区"
            },
            {
                "Key": 420105,
                "Value": "汉阳区"
            },
            {
                "Key": 420106,
                "Value": "武昌区"
            },
            {
                "Key": 420107,
                "Value": "青山区"
            },
            {
                "Key": 420111,
                "Value": "洪山区"
            },
            {
                "Key": 420112,
                "Value": "东西湖区"
            },
            {
                "Key": 420113,
                "Value": "汉南区"
            },
            {
                "Key": 420114,
                "Value": "蔡甸区"
            },
            {
                "Key": 420115,
                "Value": "江夏区"
            },
            {
                "Key": 420116,
                "Value": "黄陂区"
            },
            {
                "Key": 420117,
                "Value": "新洲区"
            }
        ]
    },
    {
        "Key": 420200,
        "Value": [
            {
                "Key": 420202,
                "Value": "黄石港区"
            },
            {
                "Key": 420203,
                "Value": "西塞山区"
            },
            {
                "Key": 420204,
                "Value": "下陆区"
            },
            {
                "Key": 420205,
                "Value": "铁山区"
            },
            {
                "Key": 420222,
                "Value": "阳新县"
            },
            {
                "Key": 420281,
                "Value": "大冶市"
            }
        ]
    },
    {
        "Key": 420300,
        "Value": [
            {
                "Key": 420302,
                "Value": "茅箭区"
            },
            {
                "Key": 420303,
                "Value": "张湾区"
            },
            {
                "Key": 420304,
                "Value": "郧阳区"
            },
            {
                "Key": 420322,
                "Value": "郧西县"
            },
            {
                "Key": 420323,
                "Value": "竹山县"
            },
            {
                "Key": 420324,
                "Value": "竹溪县"
            },
            {
                "Key": 420325,
                "Value": "房县"
            },
            {
                "Key": 420381,
                "Value": "丹江口市"
            }
        ]
    },
    {
        "Key": 420500,
        "Value": [
            {
                "Key": 420502,
                "Value": "西陵区"
            },
            {
                "Key": 420503,
                "Value": "伍家岗区"
            },
            {
                "Key": 420504,
                "Value": "点军区"
            },
            {
                "Key": 420505,
                "Value": "猇亭区"
            },
            {
                "Key": 420506,
                "Value": "夷陵区"
            },
            {
                "Key": 420525,
                "Value": "远安县"
            },
            {
                "Key": 420526,
                "Value": "兴山县"
            },
            {
                "Key": 420527,
                "Value": "秭归县"
            },
            {
                "Key": 420528,
                "Value": "长阳土家族自治县"
            },
            {
                "Key": 420529,
                "Value": "五峰土家族自治县"
            },
            {
                "Key": 420581,
                "Value": "宜都市"
            },
            {
                "Key": 420582,
                "Value": "当阳市"
            },
            {
                "Key": 420583,
                "Value": "枝江市"
            }
        ]
    },
    {
        "Key": 420600,
        "Value": [
            {
                "Key": 420602,
                "Value": "襄城区"
            },
            {
                "Key": 420606,
                "Value": "樊城区"
            },
            {
                "Key": 420607,
                "Value": "襄州区"
            },
            {
                "Key": 420624,
                "Value": "南漳县"
            },
            {
                "Key": 420625,
                "Value": "谷城县"
            },
            {
                "Key": 420626,
                "Value": "保康县"
            },
            {
                "Key": 420682,
                "Value": "老河口市"
            },
            {
                "Key": 420683,
                "Value": "枣阳市"
            },
            {
                "Key": 420684,
                "Value": "宜城市"
            }
        ]
    },
    {
        "Key": 420700,
        "Value": [
            {
                "Key": 420702,
                "Value": "梁子湖区"
            },
            {
                "Key": 420703,
                "Value": "华容区"
            },
            {
                "Key": 420704,
                "Value": "鄂城区"
            }
        ]
    },
    {
        "Key": 420800,
        "Value": [
            {
                "Key": 420802,
                "Value": "东宝区"
            },
            {
                "Key": 420804,
                "Value": "掇刀区"
            },
            {
                "Key": 420821,
                "Value": "京山县"
            },
            {
                "Key": 420822,
                "Value": "沙洋县"
            },
            {
                "Key": 420881,
                "Value": "钟祥市"
            }
        ]
    },
    {
        "Key": 420900,
        "Value": [
            {
                "Key": 420902,
                "Value": "孝南区"
            },
            {
                "Key": 420921,
                "Value": "孝昌县"
            },
            {
                "Key": 420922,
                "Value": "大悟县"
            },
            {
                "Key": 420923,
                "Value": "云梦县"
            },
            {
                "Key": 420981,
                "Value": "应城市"
            },
            {
                "Key": 420982,
                "Value": "安陆市"
            },
            {
                "Key": 420984,
                "Value": "汉川市"
            }
        ]
    },
    {
        "Key": 421000,
        "Value": [
            {
                "Key": 421002,
                "Value": "沙市区"
            },
            {
                "Key": 421003,
                "Value": "荆州区"
            },
            {
                "Key": 421022,
                "Value": "公安县"
            },
            {
                "Key": 421023,
                "Value": "监利县"
            },
            {
                "Key": 421024,
                "Value": "江陵县"
            },
            {
                "Key": 421081,
                "Value": "石首市"
            },
            {
                "Key": 421083,
                "Value": "洪湖市"
            },
            {
                "Key": 421087,
                "Value": "松滋市"
            }
        ]
    },
    {
        "Key": 421100,
        "Value": [
            {
                "Key": 421102,
                "Value": "黄州区"
            },
            {
                "Key": 421121,
                "Value": "团风县"
            },
            {
                "Key": 421122,
                "Value": "红安县"
            },
            {
                "Key": 421123,
                "Value": "罗田县"
            },
            {
                "Key": 421124,
                "Value": "英山县"
            },
            {
                "Key": 421125,
                "Value": "浠水县"
            },
            {
                "Key": 421126,
                "Value": "蕲春县"
            },
            {
                "Key": 421127,
                "Value": "黄梅县"
            },
            {
                "Key": 421181,
                "Value": "麻城市"
            },
            {
                "Key": 421182,
                "Value": "武穴市"
            }
        ]
    },
    {
        "Key": 421200,
        "Value": [
            {
                "Key": 421202,
                "Value": "咸安区"
            },
            {
                "Key": 421221,
                "Value": "嘉鱼县"
            },
            {
                "Key": 421222,
                "Value": "通城县"
            },
            {
                "Key": 421223,
                "Value": "崇阳县"
            },
            {
                "Key": 421224,
                "Value": "通山县"
            },
            {
                "Key": 421281,
                "Value": "赤壁市"
            }
        ]
    },
    {
        "Key": 421300,
        "Value": [
            {
                "Key": 421303,
                "Value": "曾都区"
            },
            {
                "Key": 421321,
                "Value": "随县"
            },
            {
                "Key": 421381,
                "Value": "广水市"
            }
        ]
    },
    {
        "Key": 422800,
        "Value": [
            {
                "Key": 422801,
                "Value": "恩施市"
            },
            {
                "Key": 422802,
                "Value": "利川市"
            },
            {
                "Key": 422822,
                "Value": "建始县"
            },
            {
                "Key": 422823,
                "Value": "巴东县"
            },
            {
                "Key": 422825,
                "Value": "宣恩县"
            },
            {
                "Key": 422826,
                "Value": "咸丰县"
            },
            {
                "Key": 422827,
                "Value": "来凤县"
            },
            {
                "Key": 422828,
                "Value": "鹤峰县"
            }
        ]
    },
    {
        "Key": 429000,
        "Value": [
            {
                "Key": 429004,
                "Value": "仙桃市"
            },
            {
                "Key": 429005,
                "Value": "潜江市"
            },
            {
                "Key": 429006,
                "Value": "天门市"
            },
            {
                "Key": 429021,
                "Value": "神农架林区"
            }
        ]
    },
    {
        "Key": 430000,
        "Value": [
            {
                "Key": 430100,
                "Value": "长沙市"
            },
            {
                "Key": 430200,
                "Value": "株洲市"
            },
            {
                "Key": 430300,
                "Value": "湘潭市"
            },
            {
                "Key": 430400,
                "Value": "衡阳市"
            },
            {
                "Key": 430500,
                "Value": "邵阳市"
            },
            {
                "Key": 430600,
                "Value": "岳阳市"
            },
            {
                "Key": 430700,
                "Value": "常德市"
            },
            {
                "Key": 430800,
                "Value": "张家界市"
            },
            {
                "Key": 430900,
                "Value": "益阳市"
            },
            {
                "Key": 431000,
                "Value": "郴州市"
            },
            {
                "Key": 431100,
                "Value": "永州市"
            },
            {
                "Key": 431200,
                "Value": "怀化市"
            },
            {
                "Key": 431300,
                "Value": "娄底市"
            },
            {
                "Key": 433100,
                "Value": "湘西土家族苗族自治州"
            }
        ]
    },
    {
        "Key": 430100,
        "Value": [
            {
                "Key": 430102,
                "Value": "芙蓉区"
            },
            {
                "Key": 430103,
                "Value": "天心区"
            },
            {
                "Key": 430104,
                "Value": "岳麓区"
            },
            {
                "Key": 430105,
                "Value": "开福区"
            },
            {
                "Key": 430111,
                "Value": "雨花区"
            },
            {
                "Key": 430112,
                "Value": "望城区"
            },
            {
                "Key": 430121,
                "Value": "长沙县"
            },
            {
                "Key": 430124,
                "Value": "宁乡县"
            },
            {
                "Key": 430181,
                "Value": "浏阳市"
            }
        ]
    },
    {
        "Key": 430200,
        "Value": [
            {
                "Key": 430202,
                "Value": "荷塘区"
            },
            {
                "Key": 430203,
                "Value": "芦淞区"
            },
            {
                "Key": 430204,
                "Value": "石峰区"
            },
            {
                "Key": 430211,
                "Value": "天元区"
            },
            {
                "Key": 430221,
                "Value": "株洲县"
            },
            {
                "Key": 430223,
                "Value": "攸县"
            },
            {
                "Key": 430224,
                "Value": "茶陵县"
            },
            {
                "Key": 430225,
                "Value": "炎陵县"
            },
            {
                "Key": 430281,
                "Value": "醴陵市"
            }
        ]
    },
    {
        "Key": 430300,
        "Value": [
            {
                "Key": 430302,
                "Value": "雨湖区"
            },
            {
                "Key": 430304,
                "Value": "岳塘区"
            },
            {
                "Key": 430321,
                "Value": "湘潭县"
            },
            {
                "Key": 430381,
                "Value": "湘乡市"
            },
            {
                "Key": 430382,
                "Value": "韶山市"
            }
        ]
    },
    {
        "Key": 430400,
        "Value": [
            {
                "Key": 430405,
                "Value": "珠晖区"
            },
            {
                "Key": 430406,
                "Value": "雁峰区"
            },
            {
                "Key": 430407,
                "Value": "石鼓区"
            },
            {
                "Key": 430408,
                "Value": "蒸湘区"
            },
            {
                "Key": 430412,
                "Value": "南岳区"
            },
            {
                "Key": 430421,
                "Value": "衡阳县"
            },
            {
                "Key": 430422,
                "Value": "衡南县"
            },
            {
                "Key": 430423,
                "Value": "衡山县"
            },
            {
                "Key": 430424,
                "Value": "衡东县"
            },
            {
                "Key": 430426,
                "Value": "祁东县"
            },
            {
                "Key": 430481,
                "Value": "耒阳市"
            },
            {
                "Key": 430482,
                "Value": "常宁市"
            }
        ]
    },
    {
        "Key": 430500,
        "Value": [
            {
                "Key": 430502,
                "Value": "双清区"
            },
            {
                "Key": 430503,
                "Value": "大祥区"
            },
            {
                "Key": 430511,
                "Value": "北塔区"
            },
            {
                "Key": 430521,
                "Value": "邵东县"
            },
            {
                "Key": 430522,
                "Value": "新邵县"
            },
            {
                "Key": 430523,
                "Value": "邵阳县"
            },
            {
                "Key": 430524,
                "Value": "隆回县"
            },
            {
                "Key": 430525,
                "Value": "洞口县"
            },
            {
                "Key": 430527,
                "Value": "绥宁县"
            },
            {
                "Key": 430528,
                "Value": "新宁县"
            },
            {
                "Key": 430529,
                "Value": "城步苗族自治县"
            },
            {
                "Key": 430581,
                "Value": "武冈市"
            }
        ]
    },
    {
        "Key": 430600,
        "Value": [
            {
                "Key": 430602,
                "Value": "岳阳楼区"
            },
            {
                "Key": 430603,
                "Value": "云溪区"
            },
            {
                "Key": 430611,
                "Value": "君山区"
            },
            {
                "Key": 430621,
                "Value": "岳阳县"
            },
            {
                "Key": 430623,
                "Value": "华容县"
            },
            {
                "Key": 430624,
                "Value": "湘阴县"
            },
            {
                "Key": 430626,
                "Value": "平江县"
            },
            {
                "Key": 430681,
                "Value": "汨罗市"
            },
            {
                "Key": 430682,
                "Value": "临湘市"
            }
        ]
    },
    {
        "Key": 430700,
        "Value": [
            {
                "Key": 430702,
                "Value": "武陵区"
            },
            {
                "Key": 430703,
                "Value": "鼎城区"
            },
            {
                "Key": 430721,
                "Value": "安乡县"
            },
            {
                "Key": 430722,
                "Value": "汉寿县"
            },
            {
                "Key": 430723,
                "Value": "澧县"
            },
            {
                "Key": 430724,
                "Value": "临澧县"
            },
            {
                "Key": 430725,
                "Value": "桃源县"
            },
            {
                "Key": 430726,
                "Value": "石门县"
            },
            {
                "Key": 430781,
                "Value": "津市市"
            }
        ]
    },
    {
        "Key": 430800,
        "Value": [
            {
                "Key": 430802,
                "Value": "永定区"
            },
            {
                "Key": 430811,
                "Value": "武陵源区"
            },
            {
                "Key": 430821,
                "Value": "慈利县"
            },
            {
                "Key": 430822,
                "Value": "桑植县"
            }
        ]
    },
    {
        "Key": 430900,
        "Value": [
            {
                "Key": 430902,
                "Value": "资阳区"
            },
            {
                "Key": 430903,
                "Value": "赫山区"
            },
            {
                "Key": 430921,
                "Value": "南县"
            },
            {
                "Key": 430922,
                "Value": "桃江县"
            },
            {
                "Key": 430923,
                "Value": "安化县"
            },
            {
                "Key": 430981,
                "Value": "沅江市"
            }
        ]
    },
    {
        "Key": 431000,
        "Value": [
            {
                "Key": 431002,
                "Value": "北湖区"
            },
            {
                "Key": 431003,
                "Value": "苏仙区"
            },
            {
                "Key": 431021,
                "Value": "桂阳县"
            },
            {
                "Key": 431022,
                "Value": "宜章县"
            },
            {
                "Key": 431023,
                "Value": "永兴县"
            },
            {
                "Key": 431024,
                "Value": "嘉禾县"
            },
            {
                "Key": 431025,
                "Value": "临武县"
            },
            {
                "Key": 431026,
                "Value": "汝城县"
            },
            {
                "Key": 431027,
                "Value": "桂东县"
            },
            {
                "Key": 431028,
                "Value": "安仁县"
            },
            {
                "Key": 431081,
                "Value": "资兴市"
            }
        ]
    },
    {
        "Key": 431100,
        "Value": [
            {
                "Key": 431102,
                "Value": "零陵区"
            },
            {
                "Key": 431103,
                "Value": "冷水滩区"
            },
            {
                "Key": 431121,
                "Value": "祁阳县"
            },
            {
                "Key": 431122,
                "Value": "东安县"
            },
            {
                "Key": 431123,
                "Value": "双牌县"
            },
            {
                "Key": 431124,
                "Value": "道县"
            },
            {
                "Key": 431125,
                "Value": "江永县"
            },
            {
                "Key": 431126,
                "Value": "宁远县"
            },
            {
                "Key": 431127,
                "Value": "蓝山县"
            },
            {
                "Key": 431128,
                "Value": "新田县"
            },
            {
                "Key": 431129,
                "Value": "江华瑶族自治县"
            }
        ]
    },
    {
        "Key": 431200,
        "Value": [
            {
                "Key": 431202,
                "Value": "鹤城区"
            },
            {
                "Key": 431221,
                "Value": "中方县"
            },
            {
                "Key": 431222,
                "Value": "沅陵县"
            },
            {
                "Key": 431223,
                "Value": "辰溪县"
            },
            {
                "Key": 431224,
                "Value": "溆浦县"
            },
            {
                "Key": 431225,
                "Value": "会同县"
            },
            {
                "Key": 431226,
                "Value": "麻阳苗族自治县"
            },
            {
                "Key": 431227,
                "Value": "新晃侗族自治县"
            },
            {
                "Key": 431228,
                "Value": "芷江侗族自治县"
            },
            {
                "Key": 431229,
                "Value": "靖州苗族侗族自治县"
            },
            {
                "Key": 431230,
                "Value": "通道侗族自治县"
            },
            {
                "Key": 431281,
                "Value": "洪江市"
            }
        ]
    },
    {
        "Key": 431300,
        "Value": [
            {
                "Key": 431302,
                "Value": "娄星区"
            },
            {
                "Key": 431321,
                "Value": "双峰县"
            },
            {
                "Key": 431322,
                "Value": "新化县"
            },
            {
                "Key": 431381,
                "Value": "冷水江市"
            },
            {
                "Key": 431382,
                "Value": "涟源市"
            }
        ]
    },
    {
        "Key": 433100,
        "Value": [
            {
                "Key": 433101,
                "Value": "吉首市"
            },
            {
                "Key": 433122,
                "Value": "泸溪县"
            },
            {
                "Key": 433123,
                "Value": "凤凰县"
            },
            {
                "Key": 433124,
                "Value": "花垣县"
            },
            {
                "Key": 433125,
                "Value": "保靖县"
            },
            {
                "Key": 433126,
                "Value": "古丈县"
            },
            {
                "Key": 433127,
                "Value": "永顺县"
            },
            {
                "Key": 433130,
                "Value": "龙山县"
            }
        ]
    },
    {
        "Key": 440000,
        "Value": [
            {
                "Key": 440100,
                "Value": "广州市"
            },
            {
                "Key": 440200,
                "Value": "韶关市"
            },
            {
                "Key": 440300,
                "Value": "深圳市"
            },
            {
                "Key": 440400,
                "Value": "珠海市"
            },
            {
                "Key": 440500,
                "Value": "汕头市"
            },
            {
                "Key": 440600,
                "Value": "佛山市"
            },
            {
                "Key": 440700,
                "Value": "江门市"
            },
            {
                "Key": 440800,
                "Value": "湛江市"
            },
            {
                "Key": 440900,
                "Value": "茂名市"
            },
            {
                "Key": 441200,
                "Value": "肇庆市"
            },
            {
                "Key": 441300,
                "Value": "惠州市"
            },
            {
                "Key": 441400,
                "Value": "梅州市"
            },
            {
                "Key": 441500,
                "Value": "汕尾市"
            },
            {
                "Key": 441600,
                "Value": "河源市"
            },
            {
                "Key": 441700,
                "Value": "阳江市"
            },
            {
                "Key": 441800,
                "Value": "清远市"
            },
            {
                "Key": 441900,
                "Value": "东莞市"
            },
            {
                "Key": 442000,
                "Value": "中山市"
            },
            {
                "Key": 445100,
                "Value": "潮州市"
            },
            {
                "Key": 445200,
                "Value": "揭阳市"
            },
            {
                "Key": 445300,
                "Value": "云浮市"
            }
        ]
    },
    {
        "Key": 440100,
        "Value": [
            {
                "Key": 440103,
                "Value": "荔湾区"
            },
            {
                "Key": 440104,
                "Value": "越秀区"
            },
            {
                "Key": 440105,
                "Value": "海珠区"
            },
            {
                "Key": 440106,
                "Value": "天河区"
            },
            {
                "Key": 440111,
                "Value": "白云区"
            },
            {
                "Key": 440112,
                "Value": "黄埔区"
            },
            {
                "Key": 440113,
                "Value": "番禺区"
            },
            {
                "Key": 440114,
                "Value": "花都区"
            },
            {
                "Key": 440115,
                "Value": "南沙区"
            },
            {
                "Key": 440117,
                "Value": "从化区"
            },
            {
                "Key": 440118,
                "Value": "增城区"
            }
        ]
    },
    {
        "Key": 440200,
        "Value": [
            {
                "Key": 440203,
                "Value": "武江区"
            },
            {
                "Key": 440204,
                "Value": "浈江区"
            },
            {
                "Key": 440205,
                "Value": "曲江区"
            },
            {
                "Key": 440222,
                "Value": "始兴县"
            },
            {
                "Key": 440224,
                "Value": "仁化县"
            },
            {
                "Key": 440229,
                "Value": "翁源县"
            },
            {
                "Key": 440232,
                "Value": "乳源瑶族自治县"
            },
            {
                "Key": 440233,
                "Value": "新丰县"
            },
            {
                "Key": 440281,
                "Value": "乐昌市"
            },
            {
                "Key": 440282,
                "Value": "南雄市"
            }
        ]
    },
    {
        "Key": 440300,
        "Value": [
            {
                "Key": 440303,
                "Value": "罗湖区"
            },
            {
                "Key": 440304,
                "Value": "福田区"
            },
            {
                "Key": 440305,
                "Value": "南山区"
            },
            {
                "Key": 440306,
                "Value": "宝安区"
            },
            {
                "Key": 440307,
                "Value": "龙岗区"
            },
            {
                "Key": 440308,
                "Value": "盐田区"
            }
        ]
    },
    {
        "Key": 440400,
        "Value": [
            {
                "Key": 440402,
                "Value": "香洲区"
            },
            {
                "Key": 440403,
                "Value": "斗门区"
            },
            {
                "Key": 440404,
                "Value": "金湾区"
            }
        ]
    },
    {
        "Key": 440500,
        "Value": [
            {
                "Key": 440507,
                "Value": "龙湖区"
            },
            {
                "Key": 440511,
                "Value": "金平区"
            },
            {
                "Key": 440512,
                "Value": "濠江区"
            },
            {
                "Key": 440513,
                "Value": "潮阳区"
            },
            {
                "Key": 440514,
                "Value": "潮南区"
            },
            {
                "Key": 440515,
                "Value": "澄海区"
            },
            {
                "Key": 440523,
                "Value": "南澳县"
            }
        ]
    },
    {
        "Key": 440600,
        "Value": [
            {
                "Key": 440604,
                "Value": "禅城区"
            },
            {
                "Key": 440605,
                "Value": "南海区"
            },
            {
                "Key": 440606,
                "Value": "顺德区"
            },
            {
                "Key": 440607,
                "Value": "三水区"
            },
            {
                "Key": 440608,
                "Value": "高明区"
            }
        ]
    },
    {
        "Key": 440700,
        "Value": [
            {
                "Key": 440703,
                "Value": "蓬江区"
            },
            {
                "Key": 440704,
                "Value": "江海区"
            },
            {
                "Key": 440705,
                "Value": "新会区"
            },
            {
                "Key": 440781,
                "Value": "台山市"
            },
            {
                "Key": 440783,
                "Value": "开平市"
            },
            {
                "Key": 440784,
                "Value": "鹤山市"
            },
            {
                "Key": 440785,
                "Value": "恩平市"
            }
        ]
    },
    {
        "Key": 440800,
        "Value": [
            {
                "Key": 440802,
                "Value": "赤坎区"
            },
            {
                "Key": 440803,
                "Value": "霞山区"
            },
            {
                "Key": 440804,
                "Value": "坡头区"
            },
            {
                "Key": 440811,
                "Value": "麻章区"
            },
            {
                "Key": 440823,
                "Value": "遂溪县"
            },
            {
                "Key": 440825,
                "Value": "徐闻县"
            },
            {
                "Key": 440881,
                "Value": "廉江市"
            },
            {
                "Key": 440882,
                "Value": "雷州市"
            },
            {
                "Key": 440883,
                "Value": "吴川市"
            }
        ]
    },
    {
        "Key": 440900,
        "Value": [
            {
                "Key": 440902,
                "Value": "茂南区"
            },
            {
                "Key": 440904,
                "Value": "电白区"
            },
            {
                "Key": 440981,
                "Value": "高州市"
            },
            {
                "Key": 440982,
                "Value": "化州市"
            },
            {
                "Key": 440983,
                "Value": "信宜市"
            }
        ]
    },
    {
        "Key": 441200,
        "Value": [
            {
                "Key": 441202,
                "Value": "端州区"
            },
            {
                "Key": 441203,
                "Value": "鼎湖区"
            },
            {
                "Key": 441204,
                "Value": "高要区"
            },
            {
                "Key": 441223,
                "Value": "广宁县"
            },
            {
                "Key": 441224,
                "Value": "怀集县"
            },
            {
                "Key": 441225,
                "Value": "封开县"
            },
            {
                "Key": 441226,
                "Value": "德庆县"
            },
            {
                "Key": 441284,
                "Value": "四会市"
            }
        ]
    },
    {
        "Key": 441300,
        "Value": [
            {
                "Key": 441302,
                "Value": "惠城区"
            },
            {
                "Key": 441303,
                "Value": "惠阳区"
            },
            {
                "Key": 441322,
                "Value": "博罗县"
            },
            {
                "Key": 441323,
                "Value": "惠东县"
            },
            {
                "Key": 441324,
                "Value": "龙门县"
            }
        ]
    },
    {
        "Key": 441400,
        "Value": [
            {
                "Key": 441402,
                "Value": "梅江区"
            },
            {
                "Key": 441403,
                "Value": "梅县区"
            },
            {
                "Key": 441422,
                "Value": "大埔县"
            },
            {
                "Key": 441423,
                "Value": "丰顺县"
            },
            {
                "Key": 441424,
                "Value": "五华县"
            },
            {
                "Key": 441426,
                "Value": "平远县"
            },
            {
                "Key": 441427,
                "Value": "蕉岭县"
            },
            {
                "Key": 441481,
                "Value": "兴宁市"
            }
        ]
    },
    {
        "Key": 441500,
        "Value": [
            {
                "Key": 441502,
                "Value": "城区"
            },
            {
                "Key": 441521,
                "Value": "海丰县"
            },
            {
                "Key": 441523,
                "Value": "陆河县"
            },
            {
                "Key": 441581,
                "Value": "陆丰市"
            }
        ]
    },
    {
        "Key": 441600,
        "Value": [
            {
                "Key": 441602,
                "Value": "源城区"
            },
            {
                "Key": 441621,
                "Value": "紫金县"
            },
            {
                "Key": 441622,
                "Value": "龙川县"
            },
            {
                "Key": 441623,
                "Value": "连平县"
            },
            {
                "Key": 441624,
                "Value": "和平县"
            },
            {
                "Key": 441625,
                "Value": "东源县"
            }
        ]
    },
    {
        "Key": 441700,
        "Value": [
            {
                "Key": 441702,
                "Value": "江城区"
            },
            {
                "Key": 441704,
                "Value": "阳东区"
            },
            {
                "Key": 441721,
                "Value": "阳西县"
            },
            {
                "Key": 441781,
                "Value": "阳春市"
            }
        ]
    },
    {
        "Key": 441800,
        "Value": [
            {
                "Key": 441802,
                "Value": "清城区"
            },
            {
                "Key": 441803,
                "Value": "清新区"
            },
            {
                "Key": 441821,
                "Value": "佛冈县"
            },
            {
                "Key": 441823,
                "Value": "阳山县"
            },
            {
                "Key": 441825,
                "Value": "连山壮族瑶族自治县"
            },
            {
                "Key": 441826,
                "Value": "连南瑶族自治县"
            },
            {
                "Key": 441881,
                "Value": "英德市"
            },
            {
                "Key": 441882,
                "Value": "连州市"
            }
        ]
    },
    {
        "Key": 445100,
        "Value": [
            {
                "Key": 445102,
                "Value": "湘桥区"
            },
            {
                "Key": 445103,
                "Value": "潮安区"
            },
            {
                "Key": 445122,
                "Value": "饶平县"
            }
        ]
    },
    {
        "Key": 445200,
        "Value": [
            {
                "Key": 445202,
                "Value": "榕城区"
            },
            {
                "Key": 445203,
                "Value": "揭东区"
            },
            {
                "Key": 445222,
                "Value": "揭西县"
            },
            {
                "Key": 445224,
                "Value": "惠来县"
            },
            {
                "Key": 445281,
                "Value": "普宁市"
            }
        ]
    },
    {
        "Key": 445300,
        "Value": [
            {
                "Key": 445302,
                "Value": "云城区"
            },
            {
                "Key": 445303,
                "Value": "云安区"
            },
            {
                "Key": 445321,
                "Value": "新兴县"
            },
            {
                "Key": 445322,
                "Value": "郁南县"
            },
            {
                "Key": 445381,
                "Value": "罗定市"
            }
        ]
    },
    {
        "Key": 450000,
        "Value": [
            {
                "Key": 450100,
                "Value": "南宁市"
            },
            {
                "Key": 450200,
                "Value": "柳州市"
            },
            {
                "Key": 450300,
                "Value": "桂林市"
            },
            {
                "Key": 450400,
                "Value": "梧州市"
            },
            {
                "Key": 450500,
                "Value": "北海市"
            },
            {
                "Key": 450600,
                "Value": "防城港市"
            },
            {
                "Key": 450700,
                "Value": "钦州市"
            },
            {
                "Key": 450800,
                "Value": "贵港市"
            },
            {
                "Key": 450900,
                "Value": "玉林市"
            },
            {
                "Key": 451000,
                "Value": "百色市"
            },
            {
                "Key": 451100,
                "Value": "贺州市"
            },
            {
                "Key": 451200,
                "Value": "河池市"
            },
            {
                "Key": 451300,
                "Value": "来宾市"
            },
            {
                "Key": 451400,
                "Value": "崇左市"
            }
        ]
    },
    {
        "Key": 450100,
        "Value": [
            {
                "Key": 450102,
                "Value": "兴宁区"
            },
            {
                "Key": 450103,
                "Value": "青秀区"
            },
            {
                "Key": 450105,
                "Value": "江南区"
            },
            {
                "Key": 450107,
                "Value": "西乡塘区"
            },
            {
                "Key": 450108,
                "Value": "良庆区"
            },
            {
                "Key": 450109,
                "Value": "邕宁区"
            },
            {
                "Key": 450110,
                "Value": "武鸣区"
            },
            {
                "Key": 450123,
                "Value": "隆安县"
            },
            {
                "Key": 450124,
                "Value": "马山县"
            },
            {
                "Key": 450125,
                "Value": "上林县"
            },
            {
                "Key": 450126,
                "Value": "宾阳县"
            },
            {
                "Key": 450127,
                "Value": "横县"
            }
        ]
    },
    {
        "Key": 450200,
        "Value": [
            {
                "Key": 450202,
                "Value": "城中区"
            },
            {
                "Key": 450203,
                "Value": "鱼峰区"
            },
            {
                "Key": 450204,
                "Value": "柳南区"
            },
            {
                "Key": 450205,
                "Value": "柳北区"
            },
            {
                "Key": 450206,
                "Value": "柳江区"
            },
            {
                "Key": 450222,
                "Value": "柳城县"
            },
            {
                "Key": 450223,
                "Value": "鹿寨县"
            },
            {
                "Key": 450224,
                "Value": "融安县"
            },
            {
                "Key": 450225,
                "Value": "融水苗族自治县"
            },
            {
                "Key": 450226,
                "Value": "三江侗族自治县"
            }
        ]
    },
    {
        "Key": 450300,
        "Value": [
            {
                "Key": 450302,
                "Value": "秀峰区"
            },
            {
                "Key": 450303,
                "Value": "叠彩区"
            },
            {
                "Key": 450304,
                "Value": "象山区"
            },
            {
                "Key": 450305,
                "Value": "七星区"
            },
            {
                "Key": 450311,
                "Value": "雁山区"
            },
            {
                "Key": 450312,
                "Value": "临桂区"
            },
            {
                "Key": 450321,
                "Value": "阳朔县"
            },
            {
                "Key": 450323,
                "Value": "灵川县"
            },
            {
                "Key": 450324,
                "Value": "全州县"
            },
            {
                "Key": 450325,
                "Value": "兴安县"
            },
            {
                "Key": 450326,
                "Value": "永福县"
            },
            {
                "Key": 450327,
                "Value": "灌阳县"
            },
            {
                "Key": 450328,
                "Value": "龙胜各族自治县"
            },
            {
                "Key": 450329,
                "Value": "资源县"
            },
            {
                "Key": 450330,
                "Value": "平乐县"
            },
            {
                "Key": 450331,
                "Value": "荔浦县"
            },
            {
                "Key": 450332,
                "Value": "恭城瑶族自治县"
            }
        ]
    },
    {
        "Key": 450400,
        "Value": [
            {
                "Key": 450403,
                "Value": "万秀区"
            },
            {
                "Key": 450405,
                "Value": "长洲区"
            },
            {
                "Key": 450406,
                "Value": "龙圩区"
            },
            {
                "Key": 450421,
                "Value": "苍梧县"
            },
            {
                "Key": 450422,
                "Value": "藤县"
            },
            {
                "Key": 450423,
                "Value": "蒙山县"
            },
            {
                "Key": 450481,
                "Value": "岑溪市"
            }
        ]
    },
    {
        "Key": 450500,
        "Value": [
            {
                "Key": 450502,
                "Value": "海城区"
            },
            {
                "Key": 450503,
                "Value": "银海区"
            },
            {
                "Key": 450512,
                "Value": "铁山港区"
            },
            {
                "Key": 450521,
                "Value": "合浦县"
            }
        ]
    },
    {
        "Key": 450600,
        "Value": [
            {
                "Key": 450602,
                "Value": "港口区"
            },
            {
                "Key": 450603,
                "Value": "防城区"
            },
            {
                "Key": 450621,
                "Value": "上思县"
            },
            {
                "Key": 450681,
                "Value": "东兴市"
            }
        ]
    },
    {
        "Key": 450700,
        "Value": [
            {
                "Key": 450702,
                "Value": "钦南区"
            },
            {
                "Key": 450703,
                "Value": "钦北区"
            },
            {
                "Key": 450721,
                "Value": "灵山县"
            },
            {
                "Key": 450722,
                "Value": "浦北县"
            }
        ]
    },
    {
        "Key": 450800,
        "Value": [
            {
                "Key": 450802,
                "Value": "港北区"
            },
            {
                "Key": 450803,
                "Value": "港南区"
            },
            {
                "Key": 450804,
                "Value": "覃塘区"
            },
            {
                "Key": 450821,
                "Value": "平南县"
            },
            {
                "Key": 450881,
                "Value": "桂平市"
            }
        ]
    },
    {
        "Key": 450900,
        "Value": [
            {
                "Key": 450902,
                "Value": "玉州区"
            },
            {
                "Key": 450903,
                "Value": "福绵区"
            },
            {
                "Key": 450921,
                "Value": "容县"
            },
            {
                "Key": 450922,
                "Value": "陆川县"
            },
            {
                "Key": 450923,
                "Value": "博白县"
            },
            {
                "Key": 450924,
                "Value": "兴业县"
            },
            {
                "Key": 450981,
                "Value": "北流市"
            }
        ]
    },
    {
        "Key": 451000,
        "Value": [
            {
                "Key": 451002,
                "Value": "右江区"
            },
            {
                "Key": 451021,
                "Value": "田阳县"
            },
            {
                "Key": 451022,
                "Value": "田东县"
            },
            {
                "Key": 451023,
                "Value": "平果县"
            },
            {
                "Key": 451024,
                "Value": "德保县"
            },
            {
                "Key": 451026,
                "Value": "那坡县"
            },
            {
                "Key": 451027,
                "Value": "凌云县"
            },
            {
                "Key": 451028,
                "Value": "乐业县"
            },
            {
                "Key": 451029,
                "Value": "田林县"
            },
            {
                "Key": 451030,
                "Value": "西林县"
            },
            {
                "Key": 451031,
                "Value": "隆林各族自治县"
            },
            {
                "Key": 451081,
                "Value": "靖西市"
            }
        ]
    },
    {
        "Key": 451100,
        "Value": [
            {
                "Key": 451102,
                "Value": "八步区"
            },
            {
                "Key": 451103,
                "Value": "平桂区"
            },
            {
                "Key": 451121,
                "Value": "昭平县"
            },
            {
                "Key": 451122,
                "Value": "钟山县"
            },
            {
                "Key": 451123,
                "Value": "富川瑶族自治县"
            }
        ]
    },
    {
        "Key": 451200,
        "Value": [
            {
                "Key": 451202,
                "Value": "金城江区"
            },
            {
                "Key": 451221,
                "Value": "南丹县"
            },
            {
                "Key": 451222,
                "Value": "天峨县"
            },
            {
                "Key": 451223,
                "Value": "凤山县"
            },
            {
                "Key": 451224,
                "Value": "东兰县"
            },
            {
                "Key": 451225,
                "Value": "罗城仫佬族自治县"
            },
            {
                "Key": 451226,
                "Value": "环江毛南族自治县"
            },
            {
                "Key": 451227,
                "Value": "巴马瑶族自治县"
            },
            {
                "Key": 451228,
                "Value": "都安瑶族自治县"
            },
            {
                "Key": 451229,
                "Value": "大化瑶族自治县"
            },
            {
                "Key": 451281,
                "Value": "宜州市"
            }
        ]
    },
    {
        "Key": 451300,
        "Value": [
            {
                "Key": 451302,
                "Value": "兴宾区"
            },
            {
                "Key": 451321,
                "Value": "忻城县"
            },
            {
                "Key": 451322,
                "Value": "象州县"
            },
            {
                "Key": 451323,
                "Value": "武宣县"
            },
            {
                "Key": 451324,
                "Value": "金秀瑶族自治县"
            },
            {
                "Key": 451381,
                "Value": "合山市"
            }
        ]
    },
    {
        "Key": 451400,
        "Value": [
            {
                "Key": 451402,
                "Value": "江州区"
            },
            {
                "Key": 451421,
                "Value": "扶绥县"
            },
            {
                "Key": 451422,
                "Value": "宁明县"
            },
            {
                "Key": 451423,
                "Value": "龙州县"
            },
            {
                "Key": 451424,
                "Value": "大新县"
            },
            {
                "Key": 451425,
                "Value": "天等县"
            },
            {
                "Key": 451481,
                "Value": "凭祥市"
            }
        ]
    },
    {
        "Key": 460000,
        "Value": [
            {
                "Key": 460100,
                "Value": "海口市"
            },
            {
                "Key": 460200,
                "Value": "三亚市"
            },
            {
                "Key": 460300,
                "Value": "三沙市"
            },
            {
                "Key": 460400,
                "Value": "儋州市"
            },
            {
                "Key": 469000,
                "Value": "省直辖县级行政区划"
            }
        ]
    },
    {
        "Key": 460100,
        "Value": [
            {
                "Key": 460105,
                "Value": "秀英区"
            },
            {
                "Key": 460106,
                "Value": "龙华区"
            },
            {
                "Key": 460107,
                "Value": "琼山区"
            },
            {
                "Key": 460108,
                "Value": "美兰区"
            }
        ]
    },
    {
        "Key": 460200,
        "Value": [
            {
                "Key": 460202,
                "Value": "海棠区"
            },
            {
                "Key": 460203,
                "Value": "吉阳区"
            },
            {
                "Key": 460204,
                "Value": "天涯区"
            },
            {
                "Key": 460205,
                "Value": "崖州区"
            }
        ]
    },
    {
        "Key": 469000,
        "Value": [
            {
                "Key": 469001,
                "Value": "五指山市"
            },
            {
                "Key": 469002,
                "Value": "琼海市"
            },
            {
                "Key": 469005,
                "Value": "文昌市"
            },
            {
                "Key": 469006,
                "Value": "万宁市"
            },
            {
                "Key": 469007,
                "Value": "东方市"
            },
            {
                "Key": 469021,
                "Value": "定安县"
            },
            {
                "Key": 469022,
                "Value": "屯昌县"
            },
            {
                "Key": 469023,
                "Value": "澄迈县"
            },
            {
                "Key": 469024,
                "Value": "临高县"
            },
            {
                "Key": 469025,
                "Value": "白沙黎族自治县"
            },
            {
                "Key": 469026,
                "Value": "昌江黎族自治县"
            },
            {
                "Key": 469027,
                "Value": "乐东黎族自治县"
            },
            {
                "Key": 469028,
                "Value": "陵水黎族自治县"
            },
            {
                "Key": 469029,
                "Value": "保亭黎族苗族自治县"
            },
            {
                "Key": 469030,
                "Value": "琼中黎族苗族自治县"
            }
        ]
    },
    {
        "Key": 500000,
        "Value": [
            {
                "Key": 500200,
                "Value": "重庆市"
            }
        ]
    },
    {
        "Key": 500100,
        "Value": [
            {
                "Key": 500101,
                "Value": "万州区"
            },
            {
                "Key": 500102,
                "Value": "涪陵区"
            },
            {
                "Key": 500103,
                "Value": "渝中区"
            },
            {
                "Key": 500104,
                "Value": "大渡口区"
            },
            {
                "Key": 500105,
                "Value": "江北区"
            },
            {
                "Key": 500106,
                "Value": "沙坪坝区"
            },
            {
                "Key": 500107,
                "Value": "九龙坡区"
            },
            {
                "Key": 500108,
                "Value": "南岸区"
            },
            {
                "Key": 500109,
                "Value": "北碚区"
            },
            {
                "Key": 500110,
                "Value": "綦江区"
            },
            {
                "Key": 500111,
                "Value": "大足区"
            },
            {
                "Key": 500112,
                "Value": "渝北区"
            },
            {
                "Key": 500113,
                "Value": "巴南区"
            },
            {
                "Key": 500114,
                "Value": "黔江区"
            },
            {
                "Key": 500115,
                "Value": "长寿区"
            },
            {
                "Key": 500116,
                "Value": "江津区"
            },
            {
                "Key": 500117,
                "Value": "合川区"
            },
            {
                "Key": 500118,
                "Value": "永川区"
            },
            {
                "Key": 500119,
                "Value": "南川区"
            },
            {
                "Key": 500120,
                "Value": "璧山区"
            },
            {
                "Key": 500151,
                "Value": "铜梁区"
            },
            {
                "Key": 500152,
                "Value": "潼南区"
            },
            {
                "Key": 500153,
                "Value": "荣昌区"
            },
            {
                "Key": 500154,
                "Value": "开州区"
            }
        ]
    },
    {
        "Key": 500200,
        "Value": [
            {
                "Key": 500228,
                "Value": "梁平县"
            },
            {
                "Key": 500229,
                "Value": "城口县"
            },
            {
                "Key": 500230,
                "Value": "丰都县"
            },
            {
                "Key": 500231,
                "Value": "垫江县"
            },
            {
                "Key": 500232,
                "Value": "武隆县"
            },
            {
                "Key": 500233,
                "Value": "忠县"
            },
            {
                "Key": 500235,
                "Value": "云阳县"
            },
            {
                "Key": 500236,
                "Value": "奉节县"
            },
            {
                "Key": 500237,
                "Value": "巫山县"
            },
            {
                "Key": 500238,
                "Value": "巫溪县"
            },
            {
                "Key": 500240,
                "Value": "石柱土家族自治县"
            },
            {
                "Key": 500241,
                "Value": "秀山土家族苗族自治县"
            },
            {
                "Key": 500242,
                "Value": "酉阳土家族苗族自治县"
            },
            {
                "Key": 500243,
                "Value": "彭水苗族土家族自治县"
            }
        ]
    },
    {
        "Key": 510000,
        "Value": [
            {
                "Key": 510100,
                "Value": "成都市"
            },
            {
                "Key": 510300,
                "Value": "自贡市"
            },
            {
                "Key": 510400,
                "Value": "攀枝花市"
            },
            {
                "Key": 510500,
                "Value": "泸州市"
            },
            {
                "Key": 510600,
                "Value": "德阳市"
            },
            {
                "Key": 510700,
                "Value": "绵阳市"
            },
            {
                "Key": 510800,
                "Value": "广元市"
            },
            {
                "Key": 510900,
                "Value": "遂宁市"
            },
            {
                "Key": 511000,
                "Value": "内江市"
            },
            {
                "Key": 511100,
                "Value": "乐山市"
            },
            {
                "Key": 511300,
                "Value": "南充市"
            },
            {
                "Key": 511400,
                "Value": "眉山市"
            },
            {
                "Key": 511500,
                "Value": "宜宾市"
            },
            {
                "Key": 511600,
                "Value": "广安市"
            },
            {
                "Key": 511700,
                "Value": "达州市"
            },
            {
                "Key": 511800,
                "Value": "雅安市"
            },
            {
                "Key": 511900,
                "Value": "巴中市"
            },
            {
                "Key": 512000,
                "Value": "资阳市"
            },
            {
                "Key": 513200,
                "Value": "阿坝藏族羌族自治州"
            },
            {
                "Key": 513300,
                "Value": "甘孜藏族自治州"
            },
            {
                "Key": 513400,
                "Value": "凉山彝族自治州"
            }
        ]
    },
    {
        "Key": 510100,
        "Value": [
            {
                "Key": 510104,
                "Value": "锦江区"
            },
            {
                "Key": 510105,
                "Value": "青羊区"
            },
            {
                "Key": 510106,
                "Value": "金牛区"
            },
            {
                "Key": 510107,
                "Value": "武侯区"
            },
            {
                "Key": 510108,
                "Value": "成华区"
            },
            {
                "Key": 510112,
                "Value": "龙泉驿区"
            },
            {
                "Key": 510113,
                "Value": "青白江区"
            },
            {
                "Key": 510114,
                "Value": "新都区"
            },
            {
                "Key": 510115,
                "Value": "温江区"
            },
            {
                "Key": 510116,
                "Value": "双流区"
            },
            {
                "Key": 510121,
                "Value": "金堂县"
            },
            {
                "Key": 510124,
                "Value": "郫县"
            },
            {
                "Key": 510129,
                "Value": "大邑县"
            },
            {
                "Key": 510131,
                "Value": "蒲江县"
            },
            {
                "Key": 510132,
                "Value": "新津县"
            },
            {
                "Key": 510181,
                "Value": "都江堰市"
            },
            {
                "Key": 510182,
                "Value": "彭州市"
            },
            {
                "Key": 510183,
                "Value": "邛崃市"
            },
            {
                "Key": 510184,
                "Value": "崇州市"
            },
            {
                "Key": 510185,
                "Value": "简阳市"
            }
        ]
    },
    {
        "Key": 510300,
        "Value": [
            {
                "Key": 510302,
                "Value": "自流井区"
            },
            {
                "Key": 510303,
                "Value": "贡井区"
            },
            {
                "Key": 510304,
                "Value": "大安区"
            },
            {
                "Key": 510311,
                "Value": "沿滩区"
            },
            {
                "Key": 510321,
                "Value": "荣县"
            },
            {
                "Key": 510322,
                "Value": "富顺县"
            }
        ]
    },
    {
        "Key": 510400,
        "Value": [
            {
                "Key": 510402,
                "Value": "东区"
            },
            {
                "Key": 510403,
                "Value": "西区"
            },
            {
                "Key": 510411,
                "Value": "仁和区"
            },
            {
                "Key": 510421,
                "Value": "米易县"
            },
            {
                "Key": 510422,
                "Value": "盐边县"
            }
        ]
    },
    {
        "Key": 510500,
        "Value": [
            {
                "Key": 510502,
                "Value": "江阳区"
            },
            {
                "Key": 510503,
                "Value": "纳溪区"
            },
            {
                "Key": 510504,
                "Value": "龙马潭区"
            },
            {
                "Key": 510521,
                "Value": "泸县"
            },
            {
                "Key": 510522,
                "Value": "合江县"
            },
            {
                "Key": 510524,
                "Value": "叙永县"
            },
            {
                "Key": 510525,
                "Value": "古蔺县"
            }
        ]
    },
    {
        "Key": 510600,
        "Value": [
            {
                "Key": 510603,
                "Value": "旌阳区"
            },
            {
                "Key": 510623,
                "Value": "中江县"
            },
            {
                "Key": 510626,
                "Value": "罗江县"
            },
            {
                "Key": 510681,
                "Value": "广汉市"
            },
            {
                "Key": 510682,
                "Value": "什邡市"
            },
            {
                "Key": 510683,
                "Value": "绵竹市"
            }
        ]
    },
    {
        "Key": 510700,
        "Value": [
            {
                "Key": 510703,
                "Value": "涪城区"
            },
            {
                "Key": 510704,
                "Value": "游仙区"
            },
            {
                "Key": 510705,
                "Value": "安州区"
            },
            {
                "Key": 510722,
                "Value": "三台县"
            },
            {
                "Key": 510723,
                "Value": "盐亭县"
            },
            {
                "Key": 510725,
                "Value": "梓潼县"
            },
            {
                "Key": 510726,
                "Value": "北川羌族自治县"
            },
            {
                "Key": 510727,
                "Value": "平武县"
            },
            {
                "Key": 510781,
                "Value": "江油市"
            }
        ]
    },
    {
        "Key": 510800,
        "Value": [
            {
                "Key": 510802,
                "Value": "利州区"
            },
            {
                "Key": 510811,
                "Value": "昭化区"
            },
            {
                "Key": 510812,
                "Value": "朝天区"
            },
            {
                "Key": 510821,
                "Value": "旺苍县"
            },
            {
                "Key": 510822,
                "Value": "青川县"
            },
            {
                "Key": 510823,
                "Value": "剑阁县"
            },
            {
                "Key": 510824,
                "Value": "苍溪县"
            }
        ]
    },
    {
        "Key": 510900,
        "Value": [
            {
                "Key": 510903,
                "Value": "船山区"
            },
            {
                "Key": 510904,
                "Value": "安居区"
            },
            {
                "Key": 510921,
                "Value": "蓬溪县"
            },
            {
                "Key": 510922,
                "Value": "射洪县"
            },
            {
                "Key": 510923,
                "Value": "大英县"
            }
        ]
    },
    {
        "Key": 511000,
        "Value": [
            {
                "Key": 511002,
                "Value": "市中区"
            },
            {
                "Key": 511011,
                "Value": "东兴区"
            },
            {
                "Key": 511024,
                "Value": "威远县"
            },
            {
                "Key": 511025,
                "Value": "资中县"
            },
            {
                "Key": 511028,
                "Value": "隆昌县"
            }
        ]
    },
    {
        "Key": 511100,
        "Value": [
            {
                "Key": 511102,
                "Value": "市中区"
            },
            {
                "Key": 511111,
                "Value": "沙湾区"
            },
            {
                "Key": 511112,
                "Value": "五通桥区"
            },
            {
                "Key": 511113,
                "Value": "金口河区"
            },
            {
                "Key": 511123,
                "Value": "犍为县"
            },
            {
                "Key": 511124,
                "Value": "井研县"
            },
            {
                "Key": 511126,
                "Value": "夹江县"
            },
            {
                "Key": 511129,
                "Value": "沐川县"
            },
            {
                "Key": 511132,
                "Value": "峨边彝族自治县"
            },
            {
                "Key": 511133,
                "Value": "马边彝族自治县"
            },
            {
                "Key": 511181,
                "Value": "峨眉山市"
            }
        ]
    },
    {
        "Key": 511300,
        "Value": [
            {
                "Key": 511302,
                "Value": "顺庆区"
            },
            {
                "Key": 511303,
                "Value": "高坪区"
            },
            {
                "Key": 511304,
                "Value": "嘉陵区"
            },
            {
                "Key": 511321,
                "Value": "南部县"
            },
            {
                "Key": 511322,
                "Value": "营山县"
            },
            {
                "Key": 511323,
                "Value": "蓬安县"
            },
            {
                "Key": 511324,
                "Value": "仪陇县"
            },
            {
                "Key": 511325,
                "Value": "西充县"
            },
            {
                "Key": 511381,
                "Value": "阆中市"
            }
        ]
    },
    {
        "Key": 511400,
        "Value": [
            {
                "Key": 511402,
                "Value": "东坡区"
            },
            {
                "Key": 511403,
                "Value": "彭山区"
            },
            {
                "Key": 511421,
                "Value": "仁寿县"
            },
            {
                "Key": 511423,
                "Value": "洪雅县"
            },
            {
                "Key": 511424,
                "Value": "丹棱县"
            },
            {
                "Key": 511425,
                "Value": "青神县"
            }
        ]
    },
    {
        "Key": 511500,
        "Value": [
            {
                "Key": 511502,
                "Value": "翠屏区"
            },
            {
                "Key": 511503,
                "Value": "南溪区"
            },
            {
                "Key": 511521,
                "Value": "宜宾县"
            },
            {
                "Key": 511523,
                "Value": "江安县"
            },
            {
                "Key": 511524,
                "Value": "长宁县"
            },
            {
                "Key": 511525,
                "Value": "高县"
            },
            {
                "Key": 511526,
                "Value": "珙县"
            },
            {
                "Key": 511527,
                "Value": "筠连县"
            },
            {
                "Key": 511528,
                "Value": "兴文县"
            },
            {
                "Key": 511529,
                "Value": "屏山县"
            }
        ]
    },
    {
        "Key": 511600,
        "Value": [
            {
                "Key": 511602,
                "Value": "广安区"
            },
            {
                "Key": 511603,
                "Value": "前锋区"
            },
            {
                "Key": 511621,
                "Value": "岳池县"
            },
            {
                "Key": 511622,
                "Value": "武胜县"
            },
            {
                "Key": 511623,
                "Value": "邻水县"
            },
            {
                "Key": 511681,
                "Value": "华蓥市"
            }
        ]
    },
    {
        "Key": 511700,
        "Value": [
            {
                "Key": 511702,
                "Value": "通川区"
            },
            {
                "Key": 511703,
                "Value": "达川区"
            },
            {
                "Key": 511722,
                "Value": "宣汉县"
            },
            {
                "Key": 511723,
                "Value": "开江县"
            },
            {
                "Key": 511724,
                "Value": "大竹县"
            },
            {
                "Key": 511725,
                "Value": "渠县"
            },
            {
                "Key": 511781,
                "Value": "万源市"
            }
        ]
    },
    {
        "Key": 511800,
        "Value": [
            {
                "Key": 511802,
                "Value": "雨城区"
            },
            {
                "Key": 511803,
                "Value": "名山区"
            },
            {
                "Key": 511822,
                "Value": "荥经县"
            },
            {
                "Key": 511823,
                "Value": "汉源县"
            },
            {
                "Key": 511824,
                "Value": "石棉县"
            },
            {
                "Key": 511825,
                "Value": "天全县"
            },
            {
                "Key": 511826,
                "Value": "芦山县"
            },
            {
                "Key": 511827,
                "Value": "宝兴县"
            }
        ]
    },
    {
        "Key": 511900,
        "Value": [
            {
                "Key": 511902,
                "Value": "巴州区"
            },
            {
                "Key": 511903,
                "Value": "恩阳区"
            },
            {
                "Key": 511921,
                "Value": "通江县"
            },
            {
                "Key": 511922,
                "Value": "南江县"
            },
            {
                "Key": 511923,
                "Value": "平昌县"
            }
        ]
    },
    {
        "Key": 512000,
        "Value": [
            {
                "Key": 512002,
                "Value": "雁江区"
            },
            {
                "Key": 512021,
                "Value": "安岳县"
            },
            {
                "Key": 512022,
                "Value": "乐至县"
            }
        ]
    },
    {
        "Key": 513200,
        "Value": [
            {
                "Key": 513201,
                "Value": "马尔康市"
            },
            {
                "Key": 513221,
                "Value": "汶川县"
            },
            {
                "Key": 513222,
                "Value": "理县"
            },
            {
                "Key": 513223,
                "Value": "茂县"
            },
            {
                "Key": 513224,
                "Value": "松潘县"
            },
            {
                "Key": 513225,
                "Value": "九寨沟县"
            },
            {
                "Key": 513226,
                "Value": "金川县"
            },
            {
                "Key": 513227,
                "Value": "小金县"
            },
            {
                "Key": 513228,
                "Value": "黑水县"
            },
            {
                "Key": 513230,
                "Value": "壤塘县"
            },
            {
                "Key": 513231,
                "Value": "阿坝县"
            },
            {
                "Key": 513232,
                "Value": "若尔盖县"
            },
            {
                "Key": 513233,
                "Value": "红原县"
            }
        ]
    },
    {
        "Key": 513300,
        "Value": [
            {
                "Key": 513301,
                "Value": "康定市"
            },
            {
                "Key": 513322,
                "Value": "泸定县"
            },
            {
                "Key": 513323,
                "Value": "丹巴县"
            },
            {
                "Key": 513324,
                "Value": "九龙县"
            },
            {
                "Key": 513325,
                "Value": "雅江县"
            },
            {
                "Key": 513326,
                "Value": "道孚县"
            },
            {
                "Key": 513327,
                "Value": "炉霍县"
            },
            {
                "Key": 513328,
                "Value": "甘孜县"
            },
            {
                "Key": 513329,
                "Value": "新龙县"
            },
            {
                "Key": 513330,
                "Value": "德格县"
            },
            {
                "Key": 513331,
                "Value": "白玉县"
            },
            {
                "Key": 513332,
                "Value": "石渠县"
            },
            {
                "Key": 513333,
                "Value": "色达县"
            },
            {
                "Key": 513334,
                "Value": "理塘县"
            },
            {
                "Key": 513335,
                "Value": "巴塘县"
            },
            {
                "Key": 513336,
                "Value": "乡城县"
            },
            {
                "Key": 513337,
                "Value": "稻城县"
            },
            {
                "Key": 513338,
                "Value": "得荣县"
            }
        ]
    },
    {
        "Key": 513400,
        "Value": [
            {
                "Key": 513401,
                "Value": "西昌市"
            },
            {
                "Key": 513422,
                "Value": "木里藏族自治县"
            },
            {
                "Key": 513423,
                "Value": "盐源县"
            },
            {
                "Key": 513424,
                "Value": "德昌县"
            },
            {
                "Key": 513425,
                "Value": "会理县"
            },
            {
                "Key": 513426,
                "Value": "会东县"
            },
            {
                "Key": 513427,
                "Value": "宁南县"
            },
            {
                "Key": 513428,
                "Value": "普格县"
            },
            {
                "Key": 513429,
                "Value": "布拖县"
            },
            {
                "Key": 513430,
                "Value": "金阳县"
            },
            {
                "Key": 513431,
                "Value": "昭觉县"
            },
            {
                "Key": 513432,
                "Value": "喜德县"
            },
            {
                "Key": 513433,
                "Value": "冕宁县"
            },
            {
                "Key": 513434,
                "Value": "越西县"
            },
            {
                "Key": 513435,
                "Value": "甘洛县"
            },
            {
                "Key": 513436,
                "Value": "美姑县"
            },
            {
                "Key": 513437,
                "Value": "雷波县"
            }
        ]
    },
    {
        "Key": 520000,
        "Value": [
            {
                "Key": 520100,
                "Value": "贵阳市"
            },
            {
                "Key": 520200,
                "Value": "六盘水市"
            },
            {
                "Key": 520300,
                "Value": "遵义市"
            },
            {
                "Key": 520400,
                "Value": "安顺市"
            },
            {
                "Key": 520500,
                "Value": "毕节市"
            },
            {
                "Key": 520600,
                "Value": "铜仁市"
            },
            {
                "Key": 522300,
                "Value": "黔西南布依族苗族自治州"
            },
            {
                "Key": 522600,
                "Value": "黔东南苗族侗族自治州"
            },
            {
                "Key": 522700,
                "Value": "黔南布依族苗族自治州"
            }
        ]
    },
    {
        "Key": 520100,
        "Value": [
            {
                "Key": 520102,
                "Value": "南明区"
            },
            {
                "Key": 520103,
                "Value": "云岩区"
            },
            {
                "Key": 520111,
                "Value": "花溪区"
            },
            {
                "Key": 520112,
                "Value": "乌当区"
            },
            {
                "Key": 520113,
                "Value": "白云区"
            },
            {
                "Key": 520115,
                "Value": "观山湖区"
            },
            {
                "Key": 520121,
                "Value": "开阳县"
            },
            {
                "Key": 520122,
                "Value": "息烽县"
            },
            {
                "Key": 520123,
                "Value": "修文县"
            },
            {
                "Key": 520181,
                "Value": "清镇市"
            }
        ]
    },
    {
        "Key": 520200,
        "Value": [
            {
                "Key": 520201,
                "Value": "钟山区"
            },
            {
                "Key": 520203,
                "Value": "六枝特区"
            },
            {
                "Key": 520221,
                "Value": "水城县"
            },
            {
                "Key": 520222,
                "Value": "盘县"
            }
        ]
    },
    {
        "Key": 520300,
        "Value": [
            {
                "Key": 520302,
                "Value": "红花岗区"
            },
            {
                "Key": 520303,
                "Value": "汇川区"
            },
            {
                "Key": 520304,
                "Value": "播州区"
            },
            {
                "Key": 520322,
                "Value": "桐梓县"
            },
            {
                "Key": 520323,
                "Value": "绥阳县"
            },
            {
                "Key": 520324,
                "Value": "正安县"
            },
            {
                "Key": 520325,
                "Value": "道真仡佬族苗族自治县"
            },
            {
                "Key": 520326,
                "Value": "务川仡佬族苗族自治县"
            },
            {
                "Key": 520327,
                "Value": "凤冈县"
            },
            {
                "Key": 520328,
                "Value": "湄潭县"
            },
            {
                "Key": 520329,
                "Value": "余庆县"
            },
            {
                "Key": 520330,
                "Value": "习水县"
            },
            {
                "Key": 520381,
                "Value": "赤水市"
            },
            {
                "Key": 520382,
                "Value": "仁怀市"
            }
        ]
    },
    {
        "Key": 520400,
        "Value": [
            {
                "Key": 520402,
                "Value": "西秀区"
            },
            {
                "Key": 520403,
                "Value": "平坝区"
            },
            {
                "Key": 520422,
                "Value": "普定县"
            },
            {
                "Key": 520423,
                "Value": "镇宁布依族苗族自治县"
            },
            {
                "Key": 520424,
                "Value": "关岭布依族苗族自治县"
            },
            {
                "Key": 520425,
                "Value": "紫云苗族布依族自治县"
            }
        ]
    },
    {
        "Key": 520500,
        "Value": [
            {
                "Key": 520502,
                "Value": "七星关区"
            },
            {
                "Key": 520521,
                "Value": "大方县"
            },
            {
                "Key": 520522,
                "Value": "黔西县"
            },
            {
                "Key": 520523,
                "Value": "金沙县"
            },
            {
                "Key": 520524,
                "Value": "织金县"
            },
            {
                "Key": 520525,
                "Value": "纳雍县"
            },
            {
                "Key": 520526,
                "Value": "威宁彝族回族苗族自治县"
            },
            {
                "Key": 520527,
                "Value": "赫章县"
            }
        ]
    },
    {
        "Key": 520600,
        "Value": [
            {
                "Key": 520602,
                "Value": "碧江区"
            },
            {
                "Key": 520603,
                "Value": "万山区"
            },
            {
                "Key": 520621,
                "Value": "江口县"
            },
            {
                "Key": 520622,
                "Value": "玉屏侗族自治县"
            },
            {
                "Key": 520623,
                "Value": "石阡县"
            },
            {
                "Key": 520624,
                "Value": "思南县"
            },
            {
                "Key": 520625,
                "Value": "印江土家族苗族自治县"
            },
            {
                "Key": 520626,
                "Value": "德江县"
            },
            {
                "Key": 520627,
                "Value": "沿河土家族自治县"
            },
            {
                "Key": 520628,
                "Value": "松桃苗族自治县"
            }
        ]
    },
    {
        "Key": 522300,
        "Value": [
            {
                "Key": 522301,
                "Value": "兴义市"
            },
            {
                "Key": 522322,
                "Value": "兴仁县"
            },
            {
                "Key": 522323,
                "Value": "普安县"
            },
            {
                "Key": 522324,
                "Value": "晴隆县"
            },
            {
                "Key": 522325,
                "Value": "贞丰县"
            },
            {
                "Key": 522326,
                "Value": "望谟县"
            },
            {
                "Key": 522327,
                "Value": "册亨县"
            },
            {
                "Key": 522328,
                "Value": "安龙县"
            }
        ]
    },
    {
        "Key": 522600,
        "Value": [
            {
                "Key": 522601,
                "Value": "凯里市"
            },
            {
                "Key": 522622,
                "Value": "黄平县"
            },
            {
                "Key": 522623,
                "Value": "施秉县"
            },
            {
                "Key": 522624,
                "Value": "三穗县"
            },
            {
                "Key": 522625,
                "Value": "镇远县"
            },
            {
                "Key": 522626,
                "Value": "岑巩县"
            },
            {
                "Key": 522627,
                "Value": "天柱县"
            },
            {
                "Key": 522628,
                "Value": "锦屏县"
            },
            {
                "Key": 522629,
                "Value": "剑河县"
            },
            {
                "Key": 522630,
                "Value": "台江县"
            },
            {
                "Key": 522631,
                "Value": "黎平县"
            },
            {
                "Key": 522632,
                "Value": "榕江县"
            },
            {
                "Key": 522633,
                "Value": "从江县"
            },
            {
                "Key": 522634,
                "Value": "雷山县"
            },
            {
                "Key": 522635,
                "Value": "麻江县"
            },
            {
                "Key": 522636,
                "Value": "丹寨县"
            }
        ]
    },
    {
        "Key": 522700,
        "Value": [
            {
                "Key": 522701,
                "Value": "都匀市"
            },
            {
                "Key": 522702,
                "Value": "福泉市"
            },
            {
                "Key": 522722,
                "Value": "荔波县"
            },
            {
                "Key": 522723,
                "Value": "贵定县"
            },
            {
                "Key": 522725,
                "Value": "瓮安县"
            },
            {
                "Key": 522726,
                "Value": "独山县"
            },
            {
                "Key": 522727,
                "Value": "平塘县"
            },
            {
                "Key": 522728,
                "Value": "罗甸县"
            },
            {
                "Key": 522729,
                "Value": "长顺县"
            },
            {
                "Key": 522730,
                "Value": "龙里县"
            },
            {
                "Key": 522731,
                "Value": "惠水县"
            },
            {
                "Key": 522732,
                "Value": "三都水族自治县"
            }
        ]
    },
    {
        "Key": 530000,
        "Value": [
            {
                "Key": 530100,
                "Value": "昆明市"
            },
            {
                "Key": 530300,
                "Value": "曲靖市"
            },
            {
                "Key": 530400,
                "Value": "玉溪市"
            },
            {
                "Key": 530500,
                "Value": "保山市"
            },
            {
                "Key": 530600,
                "Value": "昭通市"
            },
            {
                "Key": 530700,
                "Value": "丽江市"
            },
            {
                "Key": 530800,
                "Value": "普洱市"
            },
            {
                "Key": 530900,
                "Value": "临沧市"
            },
            {
                "Key": 532300,
                "Value": "楚雄彝族自治州"
            },
            {
                "Key": 532500,
                "Value": "红河哈尼族彝族自治州"
            },
            {
                "Key": 532600,
                "Value": "文山壮族苗族自治州"
            },
            {
                "Key": 532800,
                "Value": "西双版纳傣族自治州"
            },
            {
                "Key": 532900,
                "Value": "大理白族自治州"
            },
            {
                "Key": 533100,
                "Value": "德宏傣族景颇族自治州"
            },
            {
                "Key": 533300,
                "Value": "怒江傈僳族自治州"
            },
            {
                "Key": 533400,
                "Value": "迪庆藏族自治州"
            }
        ]
    },
    {
        "Key": 530100,
        "Value": [
            {
                "Key": 530102,
                "Value": "五华区"
            },
            {
                "Key": 530103,
                "Value": "盘龙区"
            },
            {
                "Key": 530111,
                "Value": "官渡区"
            },
            {
                "Key": 530112,
                "Value": "西山区"
            },
            {
                "Key": 530113,
                "Value": "东川区"
            },
            {
                "Key": 530114,
                "Value": "呈贡区"
            },
            {
                "Key": 530122,
                "Value": "晋宁县"
            },
            {
                "Key": 530124,
                "Value": "富民县"
            },
            {
                "Key": 530125,
                "Value": "宜良县"
            },
            {
                "Key": 530126,
                "Value": "石林彝族自治县"
            },
            {
                "Key": 530127,
                "Value": "嵩明县"
            },
            {
                "Key": 530128,
                "Value": "禄劝彝族苗族自治县"
            },
            {
                "Key": 530129,
                "Value": "寻甸回族彝族自治县"
            },
            {
                "Key": 530181,
                "Value": "安宁市"
            }
        ]
    },
    {
        "Key": 530300,
        "Value": [
            {
                "Key": 530302,
                "Value": "麒麟区"
            },
            {
                "Key": 530303,
                "Value": "沾益区"
            },
            {
                "Key": 530321,
                "Value": "马龙县"
            },
            {
                "Key": 530322,
                "Value": "陆良县"
            },
            {
                "Key": 530323,
                "Value": "师宗县"
            },
            {
                "Key": 530324,
                "Value": "罗平县"
            },
            {
                "Key": 530325,
                "Value": "富源县"
            },
            {
                "Key": 530326,
                "Value": "会泽县"
            },
            {
                "Key": 530381,
                "Value": "宣威市"
            }
        ]
    },
    {
        "Key": 530400,
        "Value": [
            {
                "Key": 530402,
                "Value": "红塔区"
            },
            {
                "Key": 530403,
                "Value": "江川区"
            },
            {
                "Key": 530422,
                "Value": "澄江县"
            },
            {
                "Key": 530423,
                "Value": "通海县"
            },
            {
                "Key": 530424,
                "Value": "华宁县"
            },
            {
                "Key": 530425,
                "Value": "易门县"
            },
            {
                "Key": 530426,
                "Value": "峨山彝族自治县"
            },
            {
                "Key": 530427,
                "Value": "新平彝族傣族自治县"
            },
            {
                "Key": 530428,
                "Value": "元江哈尼族彝族傣族自治县"
            }
        ]
    },
    {
        "Key": 530500,
        "Value": [
            {
                "Key": 530502,
                "Value": "隆阳区"
            },
            {
                "Key": 530521,
                "Value": "施甸县"
            },
            {
                "Key": 530523,
                "Value": "龙陵县"
            },
            {
                "Key": 530524,
                "Value": "昌宁县"
            },
            {
                "Key": 530581,
                "Value": "腾冲市"
            }
        ]
    },
    {
        "Key": 530600,
        "Value": [
            {
                "Key": 530602,
                "Value": "昭阳区"
            },
            {
                "Key": 530621,
                "Value": "鲁甸县"
            },
            {
                "Key": 530622,
                "Value": "巧家县"
            },
            {
                "Key": 530623,
                "Value": "盐津县"
            },
            {
                "Key": 530624,
                "Value": "大关县"
            },
            {
                "Key": 530625,
                "Value": "永善县"
            },
            {
                "Key": 530626,
                "Value": "绥江县"
            },
            {
                "Key": 530627,
                "Value": "镇雄县"
            },
            {
                "Key": 530628,
                "Value": "彝良县"
            },
            {
                "Key": 530629,
                "Value": "威信县"
            },
            {
                "Key": 530630,
                "Value": "水富县"
            }
        ]
    },
    {
        "Key": 530700,
        "Value": [
            {
                "Key": 530702,
                "Value": "古城区"
            },
            {
                "Key": 530721,
                "Value": "玉龙纳西族自治县"
            },
            {
                "Key": 530722,
                "Value": "永胜县"
            },
            {
                "Key": 530723,
                "Value": "华坪县"
            },
            {
                "Key": 530724,
                "Value": "宁蒗彝族自治县"
            }
        ]
    },
    {
        "Key": 530800,
        "Value": [
            {
                "Key": 530802,
                "Value": "思茅区"
            },
            {
                "Key": 530821,
                "Value": "宁洱哈尼族彝族自治县"
            },
            {
                "Key": 530822,
                "Value": "墨江哈尼族自治县"
            },
            {
                "Key": 530823,
                "Value": "景东彝族自治县"
            },
            {
                "Key": 530824,
                "Value": "景谷傣族彝族自治县"
            },
            {
                "Key": 530825,
                "Value": "镇沅彝族哈尼族拉祜族自治县"
            },
            {
                "Key": 530826,
                "Value": "江城哈尼族彝族自治县"
            },
            {
                "Key": 530827,
                "Value": "孟连傣族拉祜族佤族自治县"
            },
            {
                "Key": 530828,
                "Value": "澜沧拉祜族自治县"
            },
            {
                "Key": 530829,
                "Value": "西盟佤族自治县"
            }
        ]
    },
    {
        "Key": 530900,
        "Value": [
            {
                "Key": 530902,
                "Value": "临翔区"
            },
            {
                "Key": 530921,
                "Value": "凤庆县"
            },
            {
                "Key": 530922,
                "Value": "云县"
            },
            {
                "Key": 530923,
                "Value": "永德县"
            },
            {
                "Key": 530924,
                "Value": "镇康县"
            },
            {
                "Key": 530925,
                "Value": "双江拉祜族佤族布朗族傣族自治县"
            },
            {
                "Key": 530926,
                "Value": "耿马傣族佤族自治县"
            },
            {
                "Key": 530927,
                "Value": "沧源佤族自治县"
            }
        ]
    },
    {
        "Key": 532300,
        "Value": [
            {
                "Key": 532301,
                "Value": "楚雄市"
            },
            {
                "Key": 532322,
                "Value": "双柏县"
            },
            {
                "Key": 532323,
                "Value": "牟定县"
            },
            {
                "Key": 532324,
                "Value": "南华县"
            },
            {
                "Key": 532325,
                "Value": "姚安县"
            },
            {
                "Key": 532326,
                "Value": "大姚县"
            },
            {
                "Key": 532327,
                "Value": "永仁县"
            },
            {
                "Key": 532328,
                "Value": "元谋县"
            },
            {
                "Key": 532329,
                "Value": "武定县"
            },
            {
                "Key": 532331,
                "Value": "禄丰县"
            }
        ]
    },
    {
        "Key": 532500,
        "Value": [
            {
                "Key": 532501,
                "Value": "个旧市"
            },
            {
                "Key": 532502,
                "Value": "开远市"
            },
            {
                "Key": 532503,
                "Value": "蒙自市"
            },
            {
                "Key": 532504,
                "Value": "弥勒市"
            },
            {
                "Key": 532523,
                "Value": "屏边苗族自治县"
            },
            {
                "Key": 532524,
                "Value": "建水县"
            },
            {
                "Key": 532525,
                "Value": "石屏县"
            },
            {
                "Key": 532527,
                "Value": "泸西县"
            },
            {
                "Key": 532528,
                "Value": "元阳县"
            },
            {
                "Key": 532529,
                "Value": "红河县"
            },
            {
                "Key": 532530,
                "Value": "金平苗族瑶族傣族自治县"
            },
            {
                "Key": 532531,
                "Value": "绿春县"
            },
            {
                "Key": 532532,
                "Value": "河口瑶族自治县"
            }
        ]
    },
    {
        "Key": 532600,
        "Value": [
            {
                "Key": 532601,
                "Value": "文山市"
            },
            {
                "Key": 532622,
                "Value": "砚山县"
            },
            {
                "Key": 532623,
                "Value": "西畴县"
            },
            {
                "Key": 532624,
                "Value": "麻栗坡县"
            },
            {
                "Key": 532625,
                "Value": "马关县"
            },
            {
                "Key": 532626,
                "Value": "丘北县"
            },
            {
                "Key": 532627,
                "Value": "广南县"
            },
            {
                "Key": 532628,
                "Value": "富宁县"
            }
        ]
    },
    {
        "Key": 532800,
        "Value": [
            {
                "Key": 532801,
                "Value": "景洪市"
            },
            {
                "Key": 532822,
                "Value": "勐海县"
            },
            {
                "Key": 532823,
                "Value": "勐腊县"
            }
        ]
    },
    {
        "Key": 532900,
        "Value": [
            {
                "Key": 532901,
                "Value": "大理市"
            },
            {
                "Key": 532922,
                "Value": "漾濞彝族自治县"
            },
            {
                "Key": 532923,
                "Value": "祥云县"
            },
            {
                "Key": 532924,
                "Value": "宾川县"
            },
            {
                "Key": 532925,
                "Value": "弥渡县"
            },
            {
                "Key": 532926,
                "Value": "南涧彝族自治县"
            },
            {
                "Key": 532927,
                "Value": "巍山彝族回族自治县"
            },
            {
                "Key": 532928,
                "Value": "永平县"
            },
            {
                "Key": 532929,
                "Value": "云龙县"
            },
            {
                "Key": 532930,
                "Value": "洱源县"
            },
            {
                "Key": 532931,
                "Value": "剑川县"
            },
            {
                "Key": 532932,
                "Value": "鹤庆县"
            }
        ]
    },
    {
        "Key": 533100,
        "Value": [
            {
                "Key": 533102,
                "Value": "瑞丽市"
            },
            {
                "Key": 533103,
                "Value": "芒市"
            },
            {
                "Key": 533122,
                "Value": "梁河县"
            },
            {
                "Key": 533123,
                "Value": "盈江县"
            },
            {
                "Key": 533124,
                "Value": "陇川县"
            }
        ]
    },
    {
        "Key": 533300,
        "Value": [
            {
                "Key": 533301,
                "Value": "泸水市"
            },
            {
                "Key": 533323,
                "Value": "福贡县"
            },
            {
                "Key": 533324,
                "Value": "贡山独龙族怒族自治县"
            },
            {
                "Key": 533325,
                "Value": "兰坪白族普米族自治县"
            }
        ]
    },
    {
        "Key": 533400,
        "Value": [
            {
                "Key": 533401,
                "Value": "香格里拉市"
            },
            {
                "Key": 533422,
                "Value": "德钦县"
            },
            {
                "Key": 533423,
                "Value": "维西傈僳族自治县"
            }
        ]
    },
    {
        "Key": 540000,
        "Value": [
            {
                "Key": 540100,
                "Value": "拉萨市"
            },
            {
                "Key": 540200,
                "Value": "日喀则市"
            },
            {
                "Key": 540300,
                "Value": "昌都市"
            },
            {
                "Key": 540400,
                "Value": "林芝市"
            },
            {
                "Key": 540500,
                "Value": "山南市"
            },
            {
                "Key": 542400,
                "Value": "那曲地区"
            },
            {
                "Key": 542500,
                "Value": "阿里地区"
            }
        ]
    },
    {
        "Key": 540100,
        "Value": [
            {
                "Key": 540102,
                "Value": "城关区"
            },
            {
                "Key": 540103,
                "Value": "堆龙德庆区"
            },
            {
                "Key": 540121,
                "Value": "林周县"
            },
            {
                "Key": 540122,
                "Value": "当雄县"
            },
            {
                "Key": 540123,
                "Value": "尼木县"
            },
            {
                "Key": 540124,
                "Value": "曲水县"
            },
            {
                "Key": 540126,
                "Value": "达孜县"
            },
            {
                "Key": 540127,
                "Value": "墨竹工卡县"
            }
        ]
    },
    {
        "Key": 540200,
        "Value": [
            {
                "Key": 540202,
                "Value": "桑珠孜区"
            },
            {
                "Key": 540221,
                "Value": "南木林县"
            },
            {
                "Key": 540222,
                "Value": "江孜县"
            },
            {
                "Key": 540223,
                "Value": "定日县"
            },
            {
                "Key": 540224,
                "Value": "萨迦县"
            },
            {
                "Key": 540225,
                "Value": "拉孜县"
            },
            {
                "Key": 540226,
                "Value": "昂仁县"
            },
            {
                "Key": 540227,
                "Value": "谢通门县"
            },
            {
                "Key": 540228,
                "Value": "白朗县"
            },
            {
                "Key": 540229,
                "Value": "仁布县"
            },
            {
                "Key": 540230,
                "Value": "康马县"
            },
            {
                "Key": 540231,
                "Value": "定结县"
            },
            {
                "Key": 540232,
                "Value": "仲巴县"
            },
            {
                "Key": 540233,
                "Value": "亚东县"
            },
            {
                "Key": 540234,
                "Value": "吉隆县"
            },
            {
                "Key": 540235,
                "Value": "聂拉木县"
            },
            {
                "Key": 540236,
                "Value": "萨嘎县"
            },
            {
                "Key": 540237,
                "Value": "岗巴县"
            }
        ]
    },
    {
        "Key": 540300,
        "Value": [
            {
                "Key": 540302,
                "Value": "卡若区"
            },
            {
                "Key": 540321,
                "Value": "江达县"
            },
            {
                "Key": 540322,
                "Value": "贡觉县"
            },
            {
                "Key": 540323,
                "Value": "类乌齐县"
            },
            {
                "Key": 540324,
                "Value": "丁青县"
            },
            {
                "Key": 540325,
                "Value": "察雅县"
            },
            {
                "Key": 540326,
                "Value": "八宿县"
            },
            {
                "Key": 540327,
                "Value": "左贡县"
            },
            {
                "Key": 540328,
                "Value": "芒康县"
            },
            {
                "Key": 540329,
                "Value": "洛隆县"
            },
            {
                "Key": 540330,
                "Value": "边坝县"
            }
        ]
    },
    {
        "Key": 540400,
        "Value": [
            {
                "Key": 540402,
                "Value": "巴宜区"
            },
            {
                "Key": 540421,
                "Value": "工布江达县"
            },
            {
                "Key": 540422,
                "Value": "米林县"
            },
            {
                "Key": 540423,
                "Value": "墨脱县"
            },
            {
                "Key": 540424,
                "Value": "波密县"
            },
            {
                "Key": 540425,
                "Value": "察隅县"
            },
            {
                "Key": 540426,
                "Value": "朗县"
            }
        ]
    },
    {
        "Key": 540500,
        "Value": [
            {
                "Key": 540502,
                "Value": "乃东区"
            },
            {
                "Key": 540521,
                "Value": "扎囊县"
            },
            {
                "Key": 540522,
                "Value": "贡嘎县"
            },
            {
                "Key": 540523,
                "Value": "桑日县"
            },
            {
                "Key": 540524,
                "Value": "琼结县"
            },
            {
                "Key": 540525,
                "Value": "曲松县"
            },
            {
                "Key": 540526,
                "Value": "措美县"
            },
            {
                "Key": 540527,
                "Value": "洛扎县"
            },
            {
                "Key": 540528,
                "Value": "加查县"
            },
            {
                "Key": 540529,
                "Value": "隆子县"
            },
            {
                "Key": 540530,
                "Value": "错那县"
            },
            {
                "Key": 540531,
                "Value": "浪卡子县"
            }
        ]
    },
    {
        "Key": 542400,
        "Value": [
            {
                "Key": 542421,
                "Value": "那曲县"
            },
            {
                "Key": 542422,
                "Value": "嘉黎县"
            },
            {
                "Key": 542423,
                "Value": "比如县"
            },
            {
                "Key": 542424,
                "Value": "聂荣县"
            },
            {
                "Key": 542425,
                "Value": "安多县"
            },
            {
                "Key": 542426,
                "Value": "申扎县"
            },
            {
                "Key": 542427,
                "Value": "索县"
            },
            {
                "Key": 542428,
                "Value": "班戈县"
            },
            {
                "Key": 542429,
                "Value": "巴青县"
            },
            {
                "Key": 542430,
                "Value": "尼玛县"
            },
            {
                "Key": 542431,
                "Value": "双湖县"
            }
        ]
    },
    {
        "Key": 542500,
        "Value": [
            {
                "Key": 542521,
                "Value": "普兰县"
            },
            {
                "Key": 542522,
                "Value": "札达县"
            },
            {
                "Key": 542523,
                "Value": "噶尔县"
            },
            {
                "Key": 542524,
                "Value": "日土县"
            },
            {
                "Key": 542525,
                "Value": "革吉县"
            },
            {
                "Key": 542526,
                "Value": "改则县"
            },
            {
                "Key": 542527,
                "Value": "措勤县"
            }
        ]
    },
    {
        "Key": 610000,
        "Value": [
            {
                "Key": 610100,
                "Value": "西安市"
            },
            {
                "Key": 610200,
                "Value": "铜川市"
            },
            {
                "Key": 610300,
                "Value": "宝鸡市"
            },
            {
                "Key": 610400,
                "Value": "咸阳市"
            },
            {
                "Key": 610500,
                "Value": "渭南市"
            },
            {
                "Key": 610600,
                "Value": "延安市"
            },
            {
                "Key": 610700,
                "Value": "汉中市"
            },
            {
                "Key": 610800,
                "Value": "榆林市"
            },
            {
                "Key": 610900,
                "Value": "安康市"
            },
            {
                "Key": 611000,
                "Value": "商洛市"
            }
        ]
    },
    {
        "Key": 610100,
        "Value": [
            {
                "Key": 610102,
                "Value": "新城区"
            },
            {
                "Key": 610103,
                "Value": "碑林区"
            },
            {
                "Key": 610104,
                "Value": "莲湖区"
            },
            {
                "Key": 610111,
                "Value": "灞桥区"
            },
            {
                "Key": 610112,
                "Value": "未央区"
            },
            {
                "Key": 610113,
                "Value": "雁塔区"
            },
            {
                "Key": 610114,
                "Value": "阎良区"
            },
            {
                "Key": 610115,
                "Value": "临潼区"
            },
            {
                "Key": 610116,
                "Value": "长安区"
            },
            {
                "Key": 610117,
                "Value": "高陵区"
            },
            {
                "Key": 610122,
                "Value": "蓝田县"
            },
            {
                "Key": 610124,
                "Value": "周至县"
            },
            {
                "Key": 610125,
                "Value": "户县"
            }
        ]
    },
    {
        "Key": 610200,
        "Value": [
            {
                "Key": 610202,
                "Value": "王益区"
            },
            {
                "Key": 610203,
                "Value": "印台区"
            },
            {
                "Key": 610204,
                "Value": "耀州区"
            },
            {
                "Key": 610222,
                "Value": "宜君县"
            }
        ]
    },
    {
        "Key": 610300,
        "Value": [
            {
                "Key": 610302,
                "Value": "渭滨区"
            },
            {
                "Key": 610303,
                "Value": "金台区"
            },
            {
                "Key": 610304,
                "Value": "陈仓区"
            },
            {
                "Key": 610322,
                "Value": "凤翔县"
            },
            {
                "Key": 610323,
                "Value": "岐山县"
            },
            {
                "Key": 610324,
                "Value": "扶风县"
            },
            {
                "Key": 610326,
                "Value": "眉县"
            },
            {
                "Key": 610327,
                "Value": "陇县"
            },
            {
                "Key": 610328,
                "Value": "千阳县"
            },
            {
                "Key": 610329,
                "Value": "麟游县"
            },
            {
                "Key": 610330,
                "Value": "凤县"
            },
            {
                "Key": 610331,
                "Value": "太白县"
            }
        ]
    },
    {
        "Key": 610400,
        "Value": [
            {
                "Key": 610402,
                "Value": "秦都区"
            },
            {
                "Key": 610403,
                "Value": "杨陵区"
            },
            {
                "Key": 610404,
                "Value": "渭城区"
            },
            {
                "Key": 610422,
                "Value": "三原县"
            },
            {
                "Key": 610423,
                "Value": "泾阳县"
            },
            {
                "Key": 610424,
                "Value": "乾县"
            },
            {
                "Key": 610425,
                "Value": "礼泉县"
            },
            {
                "Key": 610426,
                "Value": "永寿县"
            },
            {
                "Key": 610427,
                "Value": "彬县"
            },
            {
                "Key": 610428,
                "Value": "长武县"
            },
            {
                "Key": 610429,
                "Value": "旬邑县"
            },
            {
                "Key": 610430,
                "Value": "淳化县"
            },
            {
                "Key": 610431,
                "Value": "武功县"
            },
            {
                "Key": 610481,
                "Value": "兴平市"
            }
        ]
    },
    {
        "Key": 610500,
        "Value": [
            {
                "Key": 610502,
                "Value": "临渭区"
            },
            {
                "Key": 610503,
                "Value": "华州区"
            },
            {
                "Key": 610522,
                "Value": "潼关县"
            },
            {
                "Key": 610523,
                "Value": "大荔县"
            },
            {
                "Key": 610524,
                "Value": "合阳县"
            },
            {
                "Key": 610525,
                "Value": "澄城县"
            },
            {
                "Key": 610526,
                "Value": "蒲城县"
            },
            {
                "Key": 610527,
                "Value": "白水县"
            },
            {
                "Key": 610528,
                "Value": "富平县"
            },
            {
                "Key": 610581,
                "Value": "韩城市"
            },
            {
                "Key": 610582,
                "Value": "华阴市"
            }
        ]
    },
    {
        "Key": 610600,
        "Value": [
            {
                "Key": 610602,
                "Value": "宝塔区"
            },
            {
                "Key": 610603,
                "Value": "安塞区"
            },
            {
                "Key": 610621,
                "Value": "延长县"
            },
            {
                "Key": 610622,
                "Value": "延川县"
            },
            {
                "Key": 610623,
                "Value": "子长县"
            },
            {
                "Key": 610625,
                "Value": "志丹县"
            },
            {
                "Key": 610626,
                "Value": "吴起县"
            },
            {
                "Key": 610627,
                "Value": "甘泉县"
            },
            {
                "Key": 610628,
                "Value": "富县"
            },
            {
                "Key": 610629,
                "Value": "洛川县"
            },
            {
                "Key": 610630,
                "Value": "宜川县"
            },
            {
                "Key": 610631,
                "Value": "黄龙县"
            },
            {
                "Key": 610632,
                "Value": "黄陵县"
            }
        ]
    },
    {
        "Key": 610700,
        "Value": [
            {
                "Key": 610702,
                "Value": "汉台区"
            },
            {
                "Key": 610721,
                "Value": "南郑县"
            },
            {
                "Key": 610722,
                "Value": "城固县"
            },
            {
                "Key": 610723,
                "Value": "洋县"
            },
            {
                "Key": 610724,
                "Value": "西乡县"
            },
            {
                "Key": 610725,
                "Value": "勉县"
            },
            {
                "Key": 610726,
                "Value": "宁强县"
            },
            {
                "Key": 610727,
                "Value": "略阳县"
            },
            {
                "Key": 610728,
                "Value": "镇巴县"
            },
            {
                "Key": 610729,
                "Value": "留坝县"
            },
            {
                "Key": 610730,
                "Value": "佛坪县"
            }
        ]
    },
    {
        "Key": 610800,
        "Value": [
            {
                "Key": 610802,
                "Value": "榆阳区"
            },
            {
                "Key": 610803,
                "Value": "横山区"
            },
            {
                "Key": 610821,
                "Value": "神木县"
            },
            {
                "Key": 610822,
                "Value": "府谷县"
            },
            {
                "Key": 610824,
                "Value": "靖边县"
            },
            {
                "Key": 610825,
                "Value": "定边县"
            },
            {
                "Key": 610826,
                "Value": "绥德县"
            },
            {
                "Key": 610827,
                "Value": "米脂县"
            },
            {
                "Key": 610828,
                "Value": "佳县"
            },
            {
                "Key": 610829,
                "Value": "吴堡县"
            },
            {
                "Key": 610830,
                "Value": "清涧县"
            },
            {
                "Key": 610831,
                "Value": "子洲县"
            }
        ]
    },
    {
        "Key": 610900,
        "Value": [
            {
                "Key": 610902,
                "Value": "汉滨区"
            },
            {
                "Key": 610921,
                "Value": "汉阴县"
            },
            {
                "Key": 610922,
                "Value": "石泉县"
            },
            {
                "Key": 610923,
                "Value": "宁陕县"
            },
            {
                "Key": 610924,
                "Value": "紫阳县"
            },
            {
                "Key": 610925,
                "Value": "岚皋县"
            },
            {
                "Key": 610926,
                "Value": "平利县"
            },
            {
                "Key": 610927,
                "Value": "镇坪县"
            },
            {
                "Key": 610928,
                "Value": "旬阳县"
            },
            {
                "Key": 610929,
                "Value": "白河县"
            }
        ]
    },
    {
        "Key": 611000,
        "Value": [
            {
                "Key": 611002,
                "Value": "商州区"
            },
            {
                "Key": 611021,
                "Value": "洛南县"
            },
            {
                "Key": 611022,
                "Value": "丹凤县"
            },
            {
                "Key": 611023,
                "Value": "商南县"
            },
            {
                "Key": 611024,
                "Value": "山阳县"
            },
            {
                "Key": 611025,
                "Value": "镇安县"
            },
            {
                "Key": 611026,
                "Value": "柞水县"
            }
        ]
    },
    {
        "Key": 620000,
        "Value": [
            {
                "Key": 620100,
                "Value": "兰州市"
            },
            {
                "Key": 620200,
                "Value": "嘉峪关市"
            },
            {
                "Key": 620300,
                "Value": "金昌市"
            },
            {
                "Key": 620400,
                "Value": "白银市"
            },
            {
                "Key": 620500,
                "Value": "天水市"
            },
            {
                "Key": 620600,
                "Value": "武威市"
            },
            {
                "Key": 620700,
                "Value": "张掖市"
            },
            {
                "Key": 620800,
                "Value": "平凉市"
            },
            {
                "Key": 620900,
                "Value": "酒泉市"
            },
            {
                "Key": 621000,
                "Value": "庆阳市"
            },
            {
                "Key": 621100,
                "Value": "定西市"
            },
            {
                "Key": 621200,
                "Value": "陇南市"
            },
            {
                "Key": 622900,
                "Value": "临夏回族自治州"
            },
            {
                "Key": 623000,
                "Value": "甘南藏族自治州"
            }
        ]
    },
    {
        "Key": 620100,
        "Value": [
            {
                "Key": 620102,
                "Value": "城关区"
            },
            {
                "Key": 620103,
                "Value": "七里河区"
            },
            {
                "Key": 620104,
                "Value": "西固区"
            },
            {
                "Key": 620105,
                "Value": "安宁区"
            },
            {
                "Key": 620111,
                "Value": "红古区"
            },
            {
                "Key": 620121,
                "Value": "永登县"
            },
            {
                "Key": 620122,
                "Value": "皋兰县"
            },
            {
                "Key": 620123,
                "Value": "榆中县"
            }
        ]
    },
    {
        "Key": 620300,
        "Value": [
            {
                "Key": 620302,
                "Value": "金川区"
            },
            {
                "Key": 620321,
                "Value": "永昌县"
            }
        ]
    },
    {
        "Key": 620400,
        "Value": [
            {
                "Key": 620402,
                "Value": "白银区"
            },
            {
                "Key": 620403,
                "Value": "平川区"
            },
            {
                "Key": 620421,
                "Value": "靖远县"
            },
            {
                "Key": 620422,
                "Value": "会宁县"
            },
            {
                "Key": 620423,
                "Value": "景泰县"
            }
        ]
    },
    {
        "Key": 620500,
        "Value": [
            {
                "Key": 620502,
                "Value": "秦州区"
            },
            {
                "Key": 620503,
                "Value": "麦积区"
            },
            {
                "Key": 620521,
                "Value": "清水县"
            },
            {
                "Key": 620522,
                "Value": "秦安县"
            },
            {
                "Key": 620523,
                "Value": "甘谷县"
            },
            {
                "Key": 620524,
                "Value": "武山县"
            },
            {
                "Key": 620525,
                "Value": "张家川回族自治县"
            }
        ]
    },
    {
        "Key": 620600,
        "Value": [
            {
                "Key": 620602,
                "Value": "凉州区"
            },
            {
                "Key": 620621,
                "Value": "民勤县"
            },
            {
                "Key": 620622,
                "Value": "古浪县"
            },
            {
                "Key": 620623,
                "Value": "天祝藏族自治县"
            }
        ]
    },
    {
        "Key": 620700,
        "Value": [
            {
                "Key": 620702,
                "Value": "甘州区"
            },
            {
                "Key": 620721,
                "Value": "肃南裕固族自治县"
            },
            {
                "Key": 620722,
                "Value": "民乐县"
            },
            {
                "Key": 620723,
                "Value": "临泽县"
            },
            {
                "Key": 620724,
                "Value": "高台县"
            },
            {
                "Key": 620725,
                "Value": "山丹县"
            }
        ]
    },
    {
        "Key": 620800,
        "Value": [
            {
                "Key": 620802,
                "Value": "崆峒区"
            },
            {
                "Key": 620821,
                "Value": "泾川县"
            },
            {
                "Key": 620822,
                "Value": "灵台县"
            },
            {
                "Key": 620823,
                "Value": "崇信县"
            },
            {
                "Key": 620824,
                "Value": "华亭县"
            },
            {
                "Key": 620825,
                "Value": "庄浪县"
            },
            {
                "Key": 620826,
                "Value": "静宁县"
            }
        ]
    },
    {
        "Key": 620900,
        "Value": [
            {
                "Key": 620902,
                "Value": "肃州区"
            },
            {
                "Key": 620921,
                "Value": "金塔县"
            },
            {
                "Key": 620922,
                "Value": "瓜州县"
            },
            {
                "Key": 620923,
                "Value": "肃北蒙古族自治县"
            },
            {
                "Key": 620924,
                "Value": "阿克塞哈萨克族自治县"
            },
            {
                "Key": 620981,
                "Value": "玉门市"
            },
            {
                "Key": 620982,
                "Value": "敦煌市"
            }
        ]
    },
    {
        "Key": 621000,
        "Value": [
            {
                "Key": 621002,
                "Value": "西峰区"
            },
            {
                "Key": 621021,
                "Value": "庆城县"
            },
            {
                "Key": 621022,
                "Value": "环县"
            },
            {
                "Key": 621023,
                "Value": "华池县"
            },
            {
                "Key": 621024,
                "Value": "合水县"
            },
            {
                "Key": 621025,
                "Value": "正宁县"
            },
            {
                "Key": 621026,
                "Value": "宁县"
            },
            {
                "Key": 621027,
                "Value": "镇原县"
            }
        ]
    },
    {
        "Key": 621100,
        "Value": [
            {
                "Key": 621102,
                "Value": "安定区"
            },
            {
                "Key": 621121,
                "Value": "通渭县"
            },
            {
                "Key": 621122,
                "Value": "陇西县"
            },
            {
                "Key": 621123,
                "Value": "渭源县"
            },
            {
                "Key": 621124,
                "Value": "临洮县"
            },
            {
                "Key": 621125,
                "Value": "漳县"
            },
            {
                "Key": 621126,
                "Value": "岷县"
            }
        ]
    },
    {
        "Key": 621200,
        "Value": [
            {
                "Key": 621202,
                "Value": "武都区"
            },
            {
                "Key": 621221,
                "Value": "成县"
            },
            {
                "Key": 621222,
                "Value": "文县"
            },
            {
                "Key": 621223,
                "Value": "宕昌县"
            },
            {
                "Key": 621224,
                "Value": "康县"
            },
            {
                "Key": 621225,
                "Value": "西和县"
            },
            {
                "Key": 621226,
                "Value": "礼县"
            },
            {
                "Key": 621227,
                "Value": "徽县"
            },
            {
                "Key": 621228,
                "Value": "两当县"
            }
        ]
    },
    {
        "Key": 622900,
        "Value": [
            {
                "Key": 622901,
                "Value": "临夏市"
            },
            {
                "Key": 622921,
                "Value": "临夏县"
            },
            {
                "Key": 622922,
                "Value": "康乐县"
            },
            {
                "Key": 622923,
                "Value": "永靖县"
            },
            {
                "Key": 622924,
                "Value": "广河县"
            },
            {
                "Key": 622925,
                "Value": "和政县"
            },
            {
                "Key": 622926,
                "Value": "东乡族自治县"
            },
            {
                "Key": 622927,
                "Value": "积石山保安族东乡族撒拉族自治县"
            }
        ]
    },
    {
        "Key": 623000,
        "Value": [
            {
                "Key": 623001,
                "Value": "合作市"
            },
            {
                "Key": 623021,
                "Value": "临潭县"
            },
            {
                "Key": 623022,
                "Value": "卓尼县"
            },
            {
                "Key": 623023,
                "Value": "舟曲县"
            },
            {
                "Key": 623024,
                "Value": "迭部县"
            },
            {
                "Key": 623025,
                "Value": "玛曲县"
            },
            {
                "Key": 623026,
                "Value": "碌曲县"
            },
            {
                "Key": 623027,
                "Value": "夏河县"
            }
        ]
    },
    {
        "Key": 630000,
        "Value": [
            {
                "Key": 630100,
                "Value": "西宁市"
            },
            {
                "Key": 630200,
                "Value": "海东市"
            },
            {
                "Key": 632200,
                "Value": "海北藏族自治州"
            },
            {
                "Key": 632300,
                "Value": "黄南藏族自治州"
            },
            {
                "Key": 632500,
                "Value": "海南藏族自治州"
            },
            {
                "Key": 632600,
                "Value": "果洛藏族自治州"
            },
            {
                "Key": 632700,
                "Value": "玉树藏族自治州"
            },
            {
                "Key": 632800,
                "Value": "海西蒙古族藏族自治州"
            }
        ]
    },
    {
        "Key": 630100,
        "Value": [
            {
                "Key": 630102,
                "Value": "城东区"
            },
            {
                "Key": 630103,
                "Value": "城中区"
            },
            {
                "Key": 630104,
                "Value": "城西区"
            },
            {
                "Key": 630105,
                "Value": "城北区"
            },
            {
                "Key": 630121,
                "Value": "大通回族土族自治县"
            },
            {
                "Key": 630122,
                "Value": "湟中县"
            },
            {
                "Key": 630123,
                "Value": "湟源县"
            }
        ]
    },
    {
        "Key": 630200,
        "Value": [
            {
                "Key": 630202,
                "Value": "乐都区"
            },
            {
                "Key": 630203,
                "Value": "平安区"
            },
            {
                "Key": 630222,
                "Value": "民和回族土族自治县"
            },
            {
                "Key": 630223,
                "Value": "互助土族自治县"
            },
            {
                "Key": 630224,
                "Value": "化隆回族自治县"
            },
            {
                "Key": 630225,
                "Value": "循化撒拉族自治县"
            }
        ]
    },
    {
        "Key": 632200,
        "Value": [
            {
                "Key": 632221,
                "Value": "门源回族自治县"
            },
            {
                "Key": 632222,
                "Value": "祁连县"
            },
            {
                "Key": 632223,
                "Value": "海晏县"
            },
            {
                "Key": 632224,
                "Value": "刚察县"
            }
        ]
    },
    {
        "Key": 632300,
        "Value": [
            {
                "Key": 632321,
                "Value": "同仁县"
            },
            {
                "Key": 632322,
                "Value": "尖扎县"
            },
            {
                "Key": 632323,
                "Value": "泽库县"
            },
            {
                "Key": 632324,
                "Value": "河南蒙古族自治县"
            }
        ]
    },
    {
        "Key": 632500,
        "Value": [
            {
                "Key": 632521,
                "Value": "共和县"
            },
            {
                "Key": 632522,
                "Value": "同德县"
            },
            {
                "Key": 632523,
                "Value": "贵德县"
            },
            {
                "Key": 632524,
                "Value": "兴海县"
            },
            {
                "Key": 632525,
                "Value": "贵南县"
            }
        ]
    },
    {
        "Key": 632600,
        "Value": [
            {
                "Key": 632621,
                "Value": "玛沁县"
            },
            {
                "Key": 632622,
                "Value": "班玛县"
            },
            {
                "Key": 632623,
                "Value": "甘德县"
            },
            {
                "Key": 632624,
                "Value": "达日县"
            },
            {
                "Key": 632625,
                "Value": "久治县"
            },
            {
                "Key": 632626,
                "Value": "玛多县"
            }
        ]
    },
    {
        "Key": 632700,
        "Value": [
            {
                "Key": 632701,
                "Value": "玉树市"
            },
            {
                "Key": 632722,
                "Value": "杂多县"
            },
            {
                "Key": 632723,
                "Value": "称多县"
            },
            {
                "Key": 632724,
                "Value": "治多县"
            },
            {
                "Key": 632725,
                "Value": "囊谦县"
            },
            {
                "Key": 632726,
                "Value": "曲麻莱县"
            }
        ]
    },
    {
        "Key": 632800,
        "Value": [
            {
                "Key": 632801,
                "Value": "格尔木市"
            },
            {
                "Key": 632802,
                "Value": "德令哈市"
            },
            {
                "Key": 632821,
                "Value": "乌兰县"
            },
            {
                "Key": 632822,
                "Value": "都兰县"
            },
            {
                "Key": 632823,
                "Value": "天峻县"
            }
        ]
    },
    {
        "Key": 640000,
        "Value": [
            {
                "Key": 640100,
                "Value": "银川市"
            },
            {
                "Key": 640200,
                "Value": "石嘴山市"
            },
            {
                "Key": 640300,
                "Value": "吴忠市"
            },
            {
                "Key": 640400,
                "Value": "固原市"
            },
            {
                "Key": 640500,
                "Value": "中卫市"
            }
        ]
    },
    {
        "Key": 640100,
        "Value": [
            {
                "Key": 640104,
                "Value": "兴庆区"
            },
            {
                "Key": 640105,
                "Value": "西夏区"
            },
            {
                "Key": 640106,
                "Value": "金凤区"
            },
            {
                "Key": 640121,
                "Value": "永宁县"
            },
            {
                "Key": 640122,
                "Value": "贺兰县"
            },
            {
                "Key": 640181,
                "Value": "灵武市"
            }
        ]
    },
    {
        "Key": 640200,
        "Value": [
            {
                "Key": 640202,
                "Value": "大武口区"
            },
            {
                "Key": 640205,
                "Value": "惠农区"
            },
            {
                "Key": 640221,
                "Value": "平罗县"
            }
        ]
    },
    {
        "Key": 640300,
        "Value": [
            {
                "Key": 640302,
                "Value": "利通区"
            },
            {
                "Key": 640303,
                "Value": "红寺堡区"
            },
            {
                "Key": 640323,
                "Value": "盐池县"
            },
            {
                "Key": 640324,
                "Value": "同心县"
            },
            {
                "Key": 640381,
                "Value": "青铜峡市"
            }
        ]
    },
    {
        "Key": 640400,
        "Value": [
            {
                "Key": 640402,
                "Value": "原州区"
            },
            {
                "Key": 640422,
                "Value": "西吉县"
            },
            {
                "Key": 640423,
                "Value": "隆德县"
            },
            {
                "Key": 640424,
                "Value": "泾源县"
            },
            {
                "Key": 640425,
                "Value": "彭阳县"
            }
        ]
    },
    {
        "Key": 640500,
        "Value": [
            {
                "Key": 640502,
                "Value": "沙坡头区"
            },
            {
                "Key": 640521,
                "Value": "中宁县"
            },
            {
                "Key": 640522,
                "Value": "海原县"
            }
        ]
    },
    {
        "Key": 650000,
        "Value": [
            {
                "Key": 650100,
                "Value": "乌鲁木齐市"
            },
            {
                "Key": 650200,
                "Value": "克拉玛依市"
            },
            {
                "Key": 650400,
                "Value": "吐鲁番市"
            },
            {
                "Key": 650500,
                "Value": "哈密市"
            },
            {
                "Key": 652300,
                "Value": "昌吉回族自治州"
            },
            {
                "Key": 652700,
                "Value": "博尔塔拉蒙古自治州"
            },
            {
                "Key": 652800,
                "Value": "巴音郭楞蒙古自治州"
            },
            {
                "Key": 652900,
                "Value": "阿克苏地区"
            },
            {
                "Key": 653000,
                "Value": "克孜勒苏柯尔克孜自治州"
            },
            {
                "Key": 653100,
                "Value": "喀什地区"
            },
            {
                "Key": 653200,
                "Value": "和田地区"
            },
            {
                "Key": 654000,
                "Value": "伊犁哈萨克自治州"
            },
            {
                "Key": 654200,
                "Value": "塔城地区"
            },
            {
                "Key": 654300,
                "Value": "阿勒泰地区"
            },
            {
                "Key": 659000,
                "Value": "自治区直辖县级行政区划"
            }
        ]
    },
    {
        "Key": 650100,
        "Value": [
            {
                "Key": 650102,
                "Value": "天山区"
            },
            {
                "Key": 650103,
                "Value": "沙依巴克区"
            },
            {
                "Key": 650104,
                "Value": "新市区"
            },
            {
                "Key": 650105,
                "Value": "水磨沟区"
            },
            {
                "Key": 650106,
                "Value": "头屯河区"
            },
            {
                "Key": 650107,
                "Value": "达坂城区"
            },
            {
                "Key": 650109,
                "Value": "米东区"
            },
            {
                "Key": 650121,
                "Value": "乌鲁木齐县"
            }
        ]
    },
    {
        "Key": 650200,
        "Value": [
            {
                "Key": 650202,
                "Value": "独山子区"
            },
            {
                "Key": 650203,
                "Value": "克拉玛依区"
            },
            {
                "Key": 650204,
                "Value": "白碱滩区"
            },
            {
                "Key": 650205,
                "Value": "乌尔禾区"
            }
        ]
    },
    {
        "Key": 650400,
        "Value": [
            {
                "Key": 650402,
                "Value": "高昌区"
            },
            {
                "Key": 650421,
                "Value": "鄯善县"
            },
            {
                "Key": 650422,
                "Value": "托克逊县"
            }
        ]
    },
    {
        "Key": 650500,
        "Value": [
            {
                "Key": 650502,
                "Value": "伊州区"
            },
            {
                "Key": 650521,
                "Value": "巴里坤哈萨克自治县"
            },
            {
                "Key": 650522,
                "Value": "伊吾县"
            }
        ]
    },
    {
        "Key": 652300,
        "Value": [
            {
                "Key": 652301,
                "Value": "昌吉市"
            },
            {
                "Key": 652302,
                "Value": "阜康市"
            },
            {
                "Key": 652323,
                "Value": "呼图壁县"
            },
            {
                "Key": 652324,
                "Value": "玛纳斯县"
            },
            {
                "Key": 652325,
                "Value": "奇台县"
            },
            {
                "Key": 652327,
                "Value": "吉木萨尔县"
            },
            {
                "Key": 652328,
                "Value": "木垒哈萨克自治县"
            }
        ]
    },
    {
        "Key": 652700,
        "Value": [
            {
                "Key": 652701,
                "Value": "博乐市"
            },
            {
                "Key": 652702,
                "Value": "阿拉山口市"
            },
            {
                "Key": 652722,
                "Value": "精河县"
            },
            {
                "Key": 652723,
                "Value": "温泉县"
            }
        ]
    },
    {
        "Key": 652800,
        "Value": [
            {
                "Key": 652801,
                "Value": "库尔勒市"
            },
            {
                "Key": 652822,
                "Value": "轮台县"
            },
            {
                "Key": 652823,
                "Value": "尉犁县"
            },
            {
                "Key": 652824,
                "Value": "若羌县"
            },
            {
                "Key": 652825,
                "Value": "且末县"
            },
            {
                "Key": 652826,
                "Value": "焉耆回族自治县"
            },
            {
                "Key": 652827,
                "Value": "和静县"
            },
            {
                "Key": 652828,
                "Value": "和硕县"
            },
            {
                "Key": 652829,
                "Value": "博湖县"
            }
        ]
    },
    {
        "Key": 652900,
        "Value": [
            {
                "Key": 652901,
                "Value": "阿克苏市"
            },
            {
                "Key": 652922,
                "Value": "温宿县"
            },
            {
                "Key": 652923,
                "Value": "库车县"
            },
            {
                "Key": 652924,
                "Value": "沙雅县"
            },
            {
                "Key": 652925,
                "Value": "新和县"
            },
            {
                "Key": 652926,
                "Value": "拜城县"
            },
            {
                "Key": 652927,
                "Value": "乌什县"
            },
            {
                "Key": 652928,
                "Value": "阿瓦提县"
            },
            {
                "Key": 652929,
                "Value": "柯坪县"
            }
        ]
    },
    {
        "Key": 653000,
        "Value": [
            {
                "Key": 653001,
                "Value": "阿图什市"
            },
            {
                "Key": 653022,
                "Value": "阿克陶县"
            },
            {
                "Key": 653023,
                "Value": "阿合奇县"
            },
            {
                "Key": 653024,
                "Value": "乌恰县"
            }
        ]
    },
    {
        "Key": 653100,
        "Value": [
            {
                "Key": 653101,
                "Value": "喀什市"
            },
            {
                "Key": 653121,
                "Value": "疏附县"
            },
            {
                "Key": 653122,
                "Value": "疏勒县"
            },
            {
                "Key": 653123,
                "Value": "英吉沙县"
            },
            {
                "Key": 653124,
                "Value": "泽普县"
            },
            {
                "Key": 653125,
                "Value": "莎车县"
            },
            {
                "Key": 653126,
                "Value": "叶城县"
            },
            {
                "Key": 653127,
                "Value": "麦盖提县"
            },
            {
                "Key": 653128,
                "Value": "岳普湖县"
            },
            {
                "Key": 653129,
                "Value": "伽师县"
            },
            {
                "Key": 653130,
                "Value": "巴楚县"
            },
            {
                "Key": 653131,
                "Value": "塔什库尔干塔吉克自治县"
            }
        ]
    },
    {
        "Key": 653200,
        "Value": [
            {
                "Key": 653201,
                "Value": "和田市"
            },
            {
                "Key": 653221,
                "Value": "和田县"
            },
            {
                "Key": 653222,
                "Value": "墨玉县"
            },
            {
                "Key": 653223,
                "Value": "皮山县"
            },
            {
                "Key": 653224,
                "Value": "洛浦县"
            },
            {
                "Key": 653225,
                "Value": "策勒县"
            },
            {
                "Key": 653226,
                "Value": "于田县"
            },
            {
                "Key": 653227,
                "Value": "民丰县"
            }
        ]
    },
    {
        "Key": 654000,
        "Value": [
            {
                "Key": 654002,
                "Value": "伊宁市"
            },
            {
                "Key": 654003,
                "Value": "奎屯市"
            },
            {
                "Key": 654004,
                "Value": "霍尔果斯市"
            },
            {
                "Key": 654021,
                "Value": "伊宁县"
            },
            {
                "Key": 654022,
                "Value": "察布查尔锡伯自治县"
            },
            {
                "Key": 654023,
                "Value": "霍城县"
            },
            {
                "Key": 654024,
                "Value": "巩留县"
            },
            {
                "Key": 654025,
                "Value": "新源县"
            },
            {
                "Key": 654026,
                "Value": "昭苏县"
            },
            {
                "Key": 654027,
                "Value": "特克斯县"
            },
            {
                "Key": 654028,
                "Value": "尼勒克县"
            }
        ]
    },
    {
        "Key": 654200,
        "Value": [
            {
                "Key": 654201,
                "Value": "塔城市"
            },
            {
                "Key": 654202,
                "Value": "乌苏市"
            },
            {
                "Key": 654221,
                "Value": "额敏县"
            },
            {
                "Key": 654223,
                "Value": "沙湾县"
            },
            {
                "Key": 654224,
                "Value": "托里县"
            },
            {
                "Key": 654225,
                "Value": "裕民县"
            },
            {
                "Key": 654226,
                "Value": "和布克赛尔蒙古自治县"
            }
        ]
    },
    {
        "Key": 654300,
        "Value": [
            {
                "Key": 654301,
                "Value": "阿勒泰市"
            },
            {
                "Key": 654321,
                "Value": "布尔津县"
            },
            {
                "Key": 654322,
                "Value": "富蕴县"
            },
            {
                "Key": 654323,
                "Value": "福海县"
            },
            {
                "Key": 654324,
                "Value": "哈巴河县"
            },
            {
                "Key": 654325,
                "Value": "青河县"
            },
            {
                "Key": 654326,
                "Value": "吉木乃县"
            }
        ]
    },
    {
        "Key": 659000,
        "Value": [
            {
                "Key": 659001,
                "Value": "石河子市"
            },
            {
                "Key": 659002,
                "Value": "阿拉尔市"
            },
            {
                "Key": 659003,
                "Value": "图木舒克市"
            },
            {
                "Key": 659004,
                "Value": "五家渠市"
            },
            {
                "Key": 659006,
                "Value": "铁门关市"
            }
        ]
    }
]


function getProvince()
{
   let temp=[];
   let allp=map[0].Value;
   allp.forEach(function(item,index){
       temp.push(item.Value);
   })
   return temp;
}

function getCities(province)
{
    // debugger
    // console.log(province);
    let temp=[];
    let allp=map[0].Value;
    let key="";
    allp.forEach(function(item,index){
        if(item.Value==province)
        {
           key=item.Key; 
           return;          
        }
    });
    let citys=[];
    if(key!="")
    {
        map.forEach(function(item,index){
            if(item.Key==key)
            {
              citys=item.Value;
              return;
            }
        });
    }
    citys.forEach(function(item,index){
       temp.push(item.Value);
    });
    if(citys.length==0)
    {
        temp.push(province);
    }
    return temp;
}

export {getProvince,getCities}