<template>
  <div>
    <!--<van-nav-bar :title="$t('app.language')"
                     :left-text="$t('toback')"
                     :right-text="$t('ok')"
                     left-arrow
                     @click-left="back"
                     @click-right="confirm" />
        <van-radio-group v-model="radio">
            <van-cell-group>
                <van-cell title="English" clickable @click="radio = 'en'">
                    <template #right-icon>
                        <van-radio name="en" />
                    </template>
                </van-cell>
                <van-cell title="中文简体" clickable @click="radio = 'zh'">
                    <template #right-icon>
                        <van-radio name="zh" />
                    </template>
                </van-cell>
                <van-cell title="中文繁體" clickable @click="radio = 'hk'">
                    <template #right-icon>
                        <van-radio name="hk" />
                    </template>
                </van-cell>
                <van-cell title="日本語" clickable @click="radio = 'jp'">
                    <template #right-icon>
                        <van-radio name="jp" />
                    </template>
                </van-cell>
                <van-cell title="ภาษาไทย" clickable @click="radio = 'th'">
                    <template #right-icon>
                        <van-radio name="th" />
                    </template>
                </van-cell>
            </van-cell-group>
        </van-radio-group>-->

    <van-nav-bar
      :title="$t('app.language')"
      left-arrow
      @click-left="back"
      @click-right="confirm"
    />
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell title="English" clickable @click="changelang('en')">
          <template #right-icon>
            <van-radio name="en" />
          </template>
        </van-cell>
        <van-cell title="中文简体" clickable @click="changelang('zh')">
          <template #right-icon>
            <van-radio name="zh" />
          </template>
        </van-cell>
        <van-cell title="中文繁體" clickable @click="changelang('hk')">
          <template #right-icon>
            <van-radio name="hk" />
          </template>
        </van-cell>
        <van-cell title="日本語" clickable @click="changelang('jp')">
          <template #right-icon>
            <van-radio name="jp" />
          </template>
        </van-cell>
        <van-cell title="ภาษาไทย" clickable @click="changelang('th')">
          <template #right-icon>
            <van-radio name="th" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>

<script>
import { vantLocales } from "@/lang";
    import { Toast, Notify, Dialog } from "vant";
export default {
  name: "Language",
  data() {
    return {
      radio: "en",
    };
  },
  mounted() {
    // this.$store.commit("toggleTabbarShow", false);
  },
  created() {
    var lang = localStorage.getItem("lang") || "en";
    this.radio = lang;
  },
  methods: {
    back() {
      this.$router.back();
    },
    confirm() {
      var lang = this.radio;
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      vantLocales(lang);
        Notify({ type: 'success', message: this.$t("success") });
      //this.back();
    },
    changelang(lang) {
      this.radio = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      vantLocales(lang);
        Notify({ type: 'success', message: this.$t("success") });
    },
  },
};
</script>

<style scoped>
.i18n {
  background-size: 40px;
  width: 48px;
  height: 46px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.lang > div {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
div[aria-checked="false"].van-radio {
  display: none;
}
/*div[data-v-c99f84ba]{display:none;}*/
</style>
