<template>
  <div>
      <van-col
            v-text="$t('app.language')"
            span="6"
            style="font-size: 12px; color: #13b5b1;text-align:center;"
            @click="show = true"
          >
          </van-col>
      <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '50%' }">
          <div class="lang">
            <div @click="changelang('en');" ><span :style="{backgroundImage: 'url(' + gb + ')' }" class="i18n"></span>English</div>
            <div @click="changelang('zh');" ><span :style="{backgroundImage: 'url(' + cn + ')' }" class="i18n"></span>中文简体</div>
            <div @click="changelang('hk');" ><span :style="{backgroundImage: 'url(' + hk + ')' }" class="i18n"></span>中文繁體</div>
            <div @click="changelang('jp');" ><span :style="{backgroundImage: 'url(' + jp + ')' }" class="i18n"></span>日本語</div>
            <div @click="changelang('th');" ><span :style="{backgroundImage: 'url(' + th + ')' }" class="i18n"></span>ภาษาไทย</div>
          </div>
      </van-popup>
  </div>
</template>

<script>
  import gb from "@/assets/image/gb.svg";
  import hk from "@/assets/image/hk.svg";
  import th from "@/assets/image/th.svg";
  import cn from "@/assets/image/cn.svg";
  import jp from "@/assets/image/jp.svg";
  import { vantLocales } from "@/lang"
  export default {
    name: "NavlangBar",
    data(){
      return {
        hk:hk,
        th:th,
        gb:gb,
        cn:cn,
        jp:jp,
        show: false,
      }
    },
    methods: {
      close() {
        this.$router.back()
      },
      changelang(lang)
      {
          this.$i18n.locale = lang;
          localStorage.setItem('lang', lang);
          vantLocales(lang);
          this.show=false;
      }
    }
  }
</script>

<style scoped>
 .i18n{    
    background-size: 40px;
    width:48px;
    height:46px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
  }
  .lang>div{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

</style>
