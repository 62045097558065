<template>
  <div class="bankadd">
    <NavBar :title="bankAddTitle"></NavBar>
    <section style="padding: 10px; display: flex; flex-flow: column nowrap">
      <div>
        <van-form @submit="bankOnSubmit">
          <van-cell-group :border="false">
            <van-field
              v-model="BankCards.BankName"
              center
              clearable
              ref="BankName"
              :label="$t('Funds.bankName')"
              :placeholder="$t('Funds.pleaseInputBankName')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
              :rules="[{ required: BankCards.Type == 1 ? true : false }]"
            >
            </van-field>

            <van-field
              v-if="BankCards.Type == 1"
              v-model="BankCards.BranchName"
              center
              clearable
              ref="BranchName"
              :label="$t('Funds.branchName')"
              :placeholder="$t('Funds.pleaseInputBranchName')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
              :rules="[{ required: BankCards.Type == 1 ? true : false }]"
            >
            </van-field>

            <van-field
              v-if="BankCards.Type == 3"
              v-model="BankCards.BranchName"
              center
              clearable
              ref="BranchName"
              :label="$t('Funds.usdtTypeName')"
              :placeholder="$t('Funds.usdtTypeName')"
              @click="showUsdtTypePopup"
            />
            <van-popup
              v-model="UsdtType.show"
              bind:close="onClose"
              position="bottom"
            >
              <van-picker
                :title="$t('Funds.usdtTypeName')"
                show-toolbar
                :columns="UsdtType.columns"
                @confirm="onConfirmUsdtType"
                @cancel="onCancel"
                @change="onChange"
                :default-index="UsdtType.defaultIndex"
              />
            </van-popup>

            <van-field
              v-model="BankCards.CardID"
              center
              clearable
              ref="CardID"
              type:number
              :label="cardLabel"
              :placeholder="cardPlaceholder"
              :rules="[{ required: true }]"
            >
            </van-field>

            <van-field
              v-model="BankCards.Swift"
              center
              clearable
              ref="Swift"
              :label="$t('Funds.swiftCode')"
              :placeholder="$t('Funds.pleaseInputSwiftCode')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
            >
            </van-field>

            <van-field
              v-model="BankCards.BankAddress"
              center
              clearable
              ref="BankAddress"
              :label="$t('Funds.bankAddress')"
              :placeholder="$t('Funds.pleaseInputBankAddress')"
              :style="{ display: BankCards.Type == 1 ? 'inline-flex' : 'none' }"
              :rules="[{ required: BankCards.Type == 1 ? true : false }]"
            >
            </van-field>

            <van-field
              v-model="BankCards.code"
              center
              clearable
              ref="code"
              :label="$t('verificationCode')"
              :placeholder="$t('pleaseInputVerificationCode')"
              :rules="[{ required: true }]"
            >
              <template #button>
                <van-button
                  color="#13b5b1"
                  size="small"
                  type="info"
                  :disabled="sendCodeDisabled"
                  @click="showVCode"
                  >{{ $t("sendVerificationCode") }}</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
          <div class="bankadd-submit">
            <van-button
              color="#13b5b1"
              :type="type"
              native-type="submit"
              :loading="isLoading"
              :loading-text="$t('loading')"
              block
              :disabled="bankAddDisabled"
              >{{ $t("Funds.submitApplication") }}</van-button
            >
          </div>
        </van-form>
      </div>
    </section>
    <Vcode
      :show="vcodeShow"
      @success="success"
      @close="close"
      :successText="$t('app.sildesuccesstext')"
      :failText="$t('app.sildefailtext')"
      :sliderText="$t('app.slidertext')"
    />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import NavBar from "@/components/NavBar";
    import { Toast, Notify, Dialog } from "vant";
import { sendVerifyCode, addBank } from "@/api";
export default {
  name: "BankAdd",
  components: { NavBar, Vcode },
  data() {
    return {
      sendCodeDisabled: true,
      vcodeShow: false,
      type: "default",
      bankAddDisabled: true,
      userinfo: this.$store.state.userinfo,
      isLoading: false,
      bankAddTitle: this.$t("Funds.bankCardAdd"),
      cardLabel: this.$t("Funds.bankCardId"),
      cardPlaceholder: this.$t("Funds.pleaseInputBankCardId"),
      BankCards: {
        BankId: 0,
        BankName: "",
        UserName: "",
        CardID: "",
        Swift: "",
        BranchName: "",
        BankAddress: "",
        Type: 0,
        code: "",
      },
      UsdtType: {
        columns: ["ERC20", "HECO", "TRC20", "OMNI"],
        show: false,
        defaultIndex: "ERC20",
      },
    };
  },
  mounted() {
    // this.$store.commit("toggleTabbarShow", false);
    var type = this.$route.query.type;
    this.BankCards.Type = type;
    if (type == 2) {
      //BTC
      this.bankAddTitle = this.$t("Funds.bankBtcAdd");
      this.title = this.bankAddTitle;
      this.cardLabel = this.$t("Funds.bankCardBtc");
      this.cardPlaceholder = this.$t("Funds.pleaseInputBankCardBtc");
    }
    if (type == 3) {
      //USDT
      this.bankAddTitle = this.$t("Funds.bankUsdtAdd");
      this.cardLabel = this.$t("Funds.bankCardUsdt");
      this.cardPlaceholder = this.$t("Funds.pleaseInputBankCardUsdt");
      this.BankCards.BranchName = "ERC20";
    }
  },
  watch: {
    "BankCards.CardID"(newVal) {
      if (newVal.length >= 8) {
        this.sendCodeDisabled = false;
      } else {
        this.sendCodeDisabled = true;
      }
    },
    "BankCards.code"(newVal) {
      if (newVal.length == 8 && this.BankCards.CardID.length >= 8) {
        this.bankAddDisabled = false;
        this.type = "info";
      } else {
        this.bankAddDisabled = true;
        this.type = "default";
      }
    },
  },
  created() {},
  methods: {
    showUsdtTypePopup() {
      this.UsdtType.show = true;
    },
    onConfirmUsdtType(value, index) {
      this.UsdtType.show = false;
      this.BankCards.BranchName = value;
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.UsdtType.show = false;
    },
    showVCode() {
      this.sendCodeDisabled = true;
      this.vcodeShow = true;
    },
    success(msg) {
      this.vcodeShow = false;
      let params = {
        email: this.userinfo.email,
        name: this.userinfo.name,
        sendType: 4,
      };
        sendVerifyCode(params).then((res) => {
          if (res.code !== 200) {
              Dialog.alert({
                  message: this.$t(res.msg),
              }).then(() => {
                  // on close
              });
          } else {
              Notify({ type: 'success', message: this.$t(res.msg) });
        }
      });
    },
    close() {
      this.vcodeShow = false;
    },
    bankOnSubmit() {
      this.isLoading = true;
      this.bankAddDisabled = true;
      let params = {
        bankName: this.BankCards.BankName,
        code: this.BankCards.code,
        cardId: this.BankCards.CardID,
        swiftCode: this.BankCards.Swift,
        type: this.BankCards.Type,
        branchName: this.BankCards.BranchName,
        bankAddress: this.BankCards.BankAddress,
      };
      addBank(params).then((res) => {
        this.isLoading = false;
        this.bankAddDisabled = false;
          if (res.code !== 200) {
              Dialog.alert({
                  message: this.$t(res.msg),
              }).then(() => {
                  // on close
              });
        } else {
              Notify({ type: 'success', message: this.$t(res.msg) });
          this.$router.push("/funds/bankinfo");
        }
      });
    },
  },
};
</script>

<style scoped>
.bankadd {
  background-color: #f7f8fa;
  min-height: 100vh;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.van-loading span.van-loading__spinner {
  margin-top: 14px !important;
}
.bankadd-submit {
  border-radius: 5px;
  overflow: hidden;
}
.van-cell-group {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}
</style>
