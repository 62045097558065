<template>
  <div>
    <van-nav-bar
      :title="$t('copyapply')"
      left-arrow
      @click-left="$router.go(-1)"
      :placeholder="true"
      fixed
    />
    <!-- <NavBar :title="$t('copyapply')"></NavBar> -->
    <div class="box">
      <div class="box-content">
        <van-dropdown-menu active-color="#25a4bb">
          <van-dropdown-item
            v-model="value"
            :options="option"
            @change="fliter"
          />
          <van-dropdown-item :title="$t('sort')" ref="item">
            <van-cell
              center
              :title="$t('sortByTime')"
              @click="Sort('CreateTime')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  :style="{
                    display: OrderName == 'CreateTime' ? 'block' : 'none',
                  }"
                  aria-hidden="true"
                ></i>
              </template>
            </van-cell>
            <van-cell
              center
              :title="$t('signalname')"
              @click="Sort('SignalName')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  aria-hidden="true"
                  :style="{
                    display: OrderName == 'SignalName' ? 'block' : 'none',
                  }"
                ></i>
              </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>

        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item, index) in records" :key="index">
            <template #title>
              <div>
                <strong>{{ item.Login }}</strong>
              </div>
              <div>{{ item.SignalName }}</div>
            </template>
            <template #right-icon>
              <div>
                <div style="color: darkgray">
                  {{
                    item.CreateTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
                  }}
                </div>
                <div style="float: right">
                  <van-tag :type="formatStatecolor(item.State)">{{
                    formatStatus(item.State)
                  }}</van-tag>
                </div>
              </div>
            </template>
            <van-row>
              <van-col span="12"
                >{{ $t("copydirection")
                }}<span class="itemright">{{
                  formatdirection(item.Type)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("copytype")
                }}<span class="itemright">{{
                  formatFollowType(item.FollowType)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("copyvalue")
                }}<span class="itemright">{{
                  formatTypeValue(item.FollowType, item.FollowTypeValue)
                }}</span></van-col
              >
              <van-col span="12"
                >{{ $t("signalstate")
                }}<span class="itemright"
                  ><van-tag
                    :type="
                      formatSignalcolor(item.SignalIsDel, item.SignalState)
                    "
                    >{{
                      formatSignalStatus(item.SignalIsDel, item.SignalState)
                    }}</van-tag
                  ></span
                ></van-col
              >
            </van-row>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div style="margin-bottom: 100px; text-align: center; padding: 25px 0px">
        <span v-if="records.length < total" @click="nextpage()">{{
          $t("clicktoloadmore")
        }}</span>
        <span v-else>{{ $t("nomore") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
    import NavBar from "@/components/NavBar";
    import { Notify, Dialog } from 'vant';
import { getUserLogins, FollowSignalLogList, DeleteFollow } from "@/api";
export default {
  name: "applyingcopy",
  components: { NavBar },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      records: [],
      total: 0,
      value: 0,
      color: "green",
      option: [{ text: this.$t("alllogins"), value: 0 }],
      activeNames: [],
      OrderName: "CreateTime",
      OrderType: "desc",
      Search: "",
      tagtype: "success",
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {},
  created() {},
  methods: {
    loadData() {
      getUserLogins({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            this.option.push({ text: element, value: element });
          });
          this.loadPageData();
        }
      });
    },
    unsubscribe(ID) {
      this.$dialog
        .confirm({
          message: this.$t("areyousureleavecopy"),
          cancelButtonText: this.$t("cancel"),
          confirmButtonText: this.$t("ok"),
        })
        .then(() => {
          DeleteFollow({ ID: ID }).then((res) => {
            if (res.code == 200) {
                Notify({ type: 'success', message: this.$t(res.msg) });
            } else {
                Notify({ type: 'danger', message: this.$t(res.msg) });
            }
          });
        })
        .catch(() => {});
    },
    loadPageData() {
      FollowSignalLogList({
        offset: this.pageIndex,
        pageSize: this.pageSize,
        dateTime: "1970-01-01—9999-12-12",
        orderName: this.OrderName,
        order: this.OrderType,
        search: this.Search,
      }).then((res) => {
        if ((res.code = 200)) {
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    nextpage() {
      this.pageIndex++;
      this.loadPageData();
    },
    Sort(value) {
      // debugger
      this.$refs.item.toggle();
      this.OrderName = value;
      if (this.OrderType == "desc") {
        this.OrderType = "asc";
      } else {
        this.OrderType = "desc";
      }
      this.pageIndex = 1;
      this.loadPageData();
    },
    fliter(value) {
      this.Search = value;
      this.pageIndex = 1;
      this.loadPageData();
    },
    formatFollowType(value) {
      switch (value) {
        case 1:
          return this.$t("percentage");
        case 2:
          return this.$t("fixedlot");
        case 3:
          return this.$t("equityratio");
      }
    },
    formatTypeValue(type, value) {
      switch (type) {
        case 1:
          return value.toFixed(2) + "%";
          break;
        case 2:
          return value + " " + this.$t("lots");
          break;
        case 3:
          return this.$t("none");
          break;
      }
    },
    formatMoney(type, value) {
      switch (type) {
        case 1:
          return "+" + value;
          break;
        case 2:
          return "-" + value;
          break;
        case 3:
          return "-" + value;
          break;
      }
    },
    formatStatus(value) {
      switch (value) {
        case 0:
          return this.$t("failure");
        case 1:
          return this.$t("applying");
        case 2:
          return this.$t("success");
        case 3:
          return this.$t("disable");
        case 4:
          return this.$t("applying");
      }
    },
    formatSignalcolor(isdel, state) {
      if (isdel == true) {
        return "warning";
      } else {
        return "success";
      }
    },
    formatStatecolor(value) {
      switch (value) {
        case 0:
          return "danger";
        case 1:
          return "primary";
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
          return "primary";
      }
    },
    formatSignalStatus(isdel, state) {
      this.tagtype = "warning";
      if (isdel == true) {
        return this.$t("unavailable");
      } else {
        this.tagtype = "success";
        return this.$t("normal");
      }
    },
    formatdirection(value) {
      switch (value) {
        case 0:
          return this.$t("positive");
          break;
        case 1:
          return this.$t("reverse");
          break;
      }
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  padding: 10px;
  background-color: #f7f8fa;
  min-height: calc(100vh - 50px);
}
.box-content {
  border-radius: 8px;
  overflow: hidden;
}
.fa {
  color: #25a4bb;
}
.dropdown-menu-option-active-color {
  color: #25a4bb;
}
.itemright {
  float: right;
  padding-right: 10px;
}
</style>
